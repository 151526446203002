import rootReducer from "./reducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware, compose } from "redux";
import { select_colums } from "./actions/options";

const store = createStore(
  rootReducer,
  undefined,
  // composeWithDevTools(applyMiddleware(thunk,logger ))
  composeWithDevTools(applyMiddleware(thunk ))
);

let columns = JSON.parse(localStorage.getItem("columns"));
if (Boolean(columns)) store.dispatch(select_colums(columns));

export default store;
