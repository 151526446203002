/**
 * All the API endpoints are defined here. And the approach used to export them is the agregation method
 */
// const getSymbolsAPI = "http://tviapi.algotechapis.com/algosignals/searchSymbol";
const getSymbolsAPI = 'https://weeklysymbolsdriver.azurewebsites.net/api/csvsymbols/search';
// const stocksplitsSymbol = "http://symbolsapi.algotechapis.com/stocksplits/search";
// const stocksplitsSymbol = 'https://weeklysymbolsdriver.azurewebsites.net/api/stocksplits/search';//GEt Splits data from weeklysymbols driver
// const stocksplitsSymbol = 'http://localhost:7085/api/stocksplits/search'//Get Splits data on the basis of symbol from Orats driver
const stocksplitsSymbol = 'https://oratsstockhist.azurewebsites.net/api/stocksplits/search'
// const getAllsplitData = 'http://symbolsapi.algotechapis.com/stocksplits/getstock';
// const getAllsplitData = 'https://weeklysymbolsdriver.azurewebsites.net/api/stocksplits/getstock';//Get all stock from weeklysymbols driver production endpoint
// const getAllsplitData = 'http://localhost:7085/api/stocksplits/getstock';//Get all stock from orats driver
const getAllsplitData = 'https://oratsstockhist.azurewebsites.net/api/stocksplits/getstock';
// const csv_get = "http://symbolsapi.algotechapis.com/csvsymbols/csv_get";
const csv_get = 'https://weeklysymbolsdriver.azurewebsites.net/api/csvsymbols/csv_get';
// const automatedTest = "http://tviapi.algotechapis.com/algosignals/automatedTest";
const automatedTest ='https://tviapidriver.azurewebsites.net/api/algosignals/automatedTest';

const postSignals = "http://localhost:4000/algoSignals/postSignals";
const cpostSignals = "http://localhost:4000/algoSignals/cpostSignals";

// const postChartLayout = 'http://localhost:8000/chartTemplate/chartlayoutpush';
const postChartLayout = 'https://chartlayindictemdriver.azurewebsites.net/api/chartTemplate/chartlayoutpush';
// const deletechartlayout = 'http://localhost:8000/chartTemplate/deletechartlayout';
const deletechartlayout = 'https://chartlayindictemdriver.azurewebsites.net/api/chartTemplate/deletechartlayout';
// const getownchart = 'http://localhost:8000/chartTemplate/getownchart';
const getownchart = 'https://chartlayindictemdriver.azurewebsites.net/api/chartTemplate/getownchart';
// const getChartContent = 'http://localhost:8000/chartTemplate/chartContent';
const getChartContent = 'https://chartlayindictemdriver.azurewebsites.net/api/chartTemplate/chartContent';
// const postindicatorTemplate = 'http://localhost:8000/indicatorTemplate/saveTemplate';
const postindicatorTemplate = 'https://chartlayindictemdriver.azurewebsites.net/api/indicatorTemplate/saveTemplate';
// const getownindicatorTemplate = 'http://localhost:8000/indicatorTemplate/getownindicatorTemplate';
const getownindicatorTemplate = 'https://chartlayindictemdriver.azurewebsites.net/api/indicatorTemplate/getownindicatorTemplate';
// const deleteIndicatorTemplate = 'http://localhost:8000/indicatorTemplate/delete';
const deleteIndicatorTemplate = 'https://chartlayindictemdriver.azurewebsites.net/api/indicatorTemplate/delete';
// const getindicatorContent = 'http://localhost:8000/indicatorTemplate/indicatorContent';
const getindicatorContent = 'https://chartlayindictemdriver.azurewebsites.net/api/indicatorTemplate/indicatorContent';

const postduplicates = 'http://localhost:8000/postduplicates/postduplicatesymbol';
// const automationSlicetest = 'http://localhost:4000/algosignals/searchSymbol';
const automationSlicetest = 'https://tviapidriver.azurewebsites.net/api/algosignals/automatedTest'

export {getSymbolsAPI, stocksplitsSymbol, csv_get, automatedTest, postSignals, cpostSignals, 
    postChartLayout, deletechartlayout, getownchart, getChartContent,postindicatorTemplate,
    getownindicatorTemplate,deleteIndicatorTemplate,getindicatorContent,postduplicates,automationSlicetest,getAllsplitData}
