/**
 * createMultipointShape is a Charting library Api, creates different shapes on the chart.
 * Here we are using icon event/property to plot the Circles on the chart.
 * @param {*} tvWidget 
 * @param {*} time 
 * @param {*} price 
 * @param {*} color 
 * @function createCircleOnChart
 */

//#region Create Circles on chart
  const createCircleOnChart = (tvWidget,time,price,color) => {
    window.parent.circle = true;
    
    //#region Circle creation process
      var shapeId=  tvWidget.chart().createMultipointShape([{time: time/1000, price:price}],  {shape: 'icon',icon: 0xf111,lock: true,
    
      //#region Override the default properties of the shape the charting library have provide some more properties so we can customize them
        overrides: {
        color:color,
        size : 20
        }
        });
      //#endregion Override the default properties of the shape the charting library have provide some more properties so we can customize them

      tvWidget.chart().getShapeById(shapeId);
    //#endregion Circle creation process

    //#region To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them
      window.parent.circleShapeID[window.parent.circleShapeID_Index] = shapeId;
      window.parent.circleShapeID_Index += 1;
    //#endregion To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them

  }
//#endregion Create Circles on chart
export default createCircleOnChart;