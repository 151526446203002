import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const Market = ({ theme, spread }) => {
  const clr_theme = theme;

  const classes = makeStyles(t => {
    return {
      toggle: {
        height: "20px",
        fontSize: "12px"
      },
      margin: {
        margin: 0
      },
      icon: {
        fontSize: 16,
        padding: 0,
        marginBottom: 0,
        marginRight: "5px"
      },
      switch: {
        fontSize: "16px",
        height1: "16px"
      },
      inputLikeBox: {
        // borderRadius: ,
        position: "relative",
        // backgroundColor: "black",
        // border: "1px solid #ced4da",
        fontSize: 12,
        padding: "2.5px 5px 2.5px 5px",
        display: "inline-block",
        color: clr_theme === "Dark" ? "#d7d8db" : "#131721"
      },
      range: {
        width: "200px",
        paddingLeft: "20px",
        padding: "0"
      },
      rangeInp: {
        color: clr_theme === "Dark" ? "white" : "black"
      }
    };
  })();
  const styles = {
    header1: {
      height: "40px",
      width: "100",
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    header2: {
      //   height:"60px",
      backgroundColor: theme === "Dark" ? "#252e42" : "#f1f1f1",
      padding: "10px",
      paddingRight: "20px",
      paddingLeft: "20px",
      zIndex: 99999999
    },
    closeIcon: {
      zIndex: 200000,
      cursor: "pointer"
    },
    symbolName: {},
    symbolDesc: {},
    body: {
      padding: "20px",
      maxHeight: "300px"
    },
    smallInput: { width: "100px" }
  };
  return (
    <>
      <div className={classes.inputLikeBox}>
        <label htmlFor="spread_bid" className="d-block">
          spread bid <small>({spread.trade_type})</small>
        </label>
        <input
          type="text"
          id="spread_bid"
          readOnly
          style={{
            ...styles.smallInput,
            border: "none",
            backgroundColor: "transparent",
            color: theme === "Dark" ? "white" : "black"
          }}
          value={
            spread.spread_bid + (spread.trade_type === "debit" ? "" : " market")
          }
        />
      </div>

      <div className={classes.inputLikeBox}>
        <label htmlFor="spread_ask" className="d-block">
          spread ask <small>({spread.trade_type})</small>
        </label>
        <input
          type="text"
          id="spread_ask"
          readOnly
          style={{
            ...styles.smallInput,
            border: "none",
            backgroundColor: "transparent",
            color: theme === "Dark" ? "white" : "black"
          }}
          value={
            spread.spread_ask + (spread.trade_type !== "debit" ? "" : " market")
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  spread: state.ticket.spread
});
export default connect(mapStateToProps, {})(Market);
