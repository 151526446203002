import React, {  useEffect } from "react";
import Modal from "react-responsive-modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { select_colums } from "../../../actions/options";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Settings = ({
  open,
  close,
  theme,
  all_columns,
  selected_columns,
  select_colums
}) => {
  useEffect(() => {
    if (Boolean(document.getElementsByClassName("styles_closeButton__20ID4"))) {
      var mod = document.getElementsByClassName("styles_closeButton__20ID4");
      for (let i = 0; i < mod.length; i++) {
        mod[i].innerHTML = "X";
        mod[i].style.color = theme === "Light" ? "#131721" : "white";
      }
    }
  });
  const classes = makeStyles(themeI => ({
    root: {
      flexGrow: 1,
      position: "relative",
      backgroundColor: theme === "Light" ? "white" : "#131721",
      padding: "20px",
      paddingTop: "40px"
    },
    buttonContainer: {
      position: "absolute",
      top: "10px",
      right: "0",
      zIndex: "100000"
    },
    tabs: {
      backgroundColor: theme === "Light" ? "white" : "#131721",
      color: theme === "Light" ? "#131721" : "white",
      zIndex: "-00111111"
    },
    icon: {
      color: theme === "Light" ? "#131721" : "white",
      fontSize: "12px"
    }
  }))();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ zIndex: 1000000 }}>
      <Modal open={open} onClose={() => close(false)} center>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="simple tabs example"
            >
              <Tab label="Coloumns" {...a11yProps(0)} />
              <Tab label="setting 2" {...a11yProps(1)} />
              <Tab label="setting 3" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <div className="row">
              <div className="col-md-6">
                <h4 className={`text-${theme === "Dark" ? "light" : "dark"}`}>
                  Market
                </h4>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "column",
                    // minHeight: "50vh",
                    flexWrap: "wrap"
                  }}
                >
                  {all_columns.map(column => {
                    let isChecked = false;
                    selected_columns.forEach(c => {
                      if (c.name === column.name) return (isChecked = true);
                    });
                    return !column.hasOwnProperty("type") ? (
                      <div className="d-block" key={column.name}>
                        {" "}
                        <FormControlLabel
                          className={classes.icon}
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={() => {
                                if (!isChecked) {
                                  let s_c = [...selected_columns];
                                  let a_c = [...all_columns];
                                  s_c.push(column);
                                  s_c = a_c.filter(x => {
                                    let condition;
                                    s_c.forEach(sc => {
                                      if (sc.name === x.name) condition = true;
                                    });
                                    return condition;
                                  });
                                  select_colums(s_c);
                                } else {
                                  let s_c = [...selected_columns];
                                  s_c = s_c.filter(x => x.name !== column.name);
                                  select_colums(s_c);
                                }
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  className={classes.icon}
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon className={classes.icon} />
                              }
                              value="checkedI"
                            />
                          }
                          label={column.title}
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
              <div className="col-md-6">
                <h4 className={`text-${theme === "Dark" ? "light" : "dark"}`}>
                  Greeks
                </h4>
                <div style={{}}>
                  {all_columns.map(column => {
                    let isChecked = false;
                    selected_columns.forEach(c => {
                      if (c.name === column.name) return (isChecked = true);
                    });
                    return column.hasOwnProperty("type") ? (
                      <div className="d-block" key={column.name}>
                        {" "}
                        <FormControlLabel
                          className={classes.icon}
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={() => {
                                if (!isChecked) {
                                  let s_c = [...selected_columns];
                                  let a_c = [...all_columns];
                                  s_c.push(column);
                                  s_c = a_c.filter(x => {
                                    let condition;
                                    s_c.forEach(sc => {
                                      if (sc.name === x.name) condition = true;
                                    });
                                    return condition;
                                  });
                                  select_colums(s_c);
                                } else {
                                  let s_c = [...selected_columns];
                                  s_c = s_c.filter(x => x.name !== column.name);
                                  select_colums(s_c);
                                }
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  className={classes.icon}
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon className={classes.icon} />
                              }
                              value="checkedI"
                            />
                          }
                          label={column.title}
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <div style={{ width: "100%", height: "60px" }}>
            <button
              onClick={() => close(false)}
              className={`mr-5 btn btn-sm btn-outline-${
                theme === "Dark" ? "light" : "dark"
              }`}
              style={{ float: "right" }}
            >
              close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(
  state => ({
    all_columns: state.option.allColumns,
    selected_columns: state.option.selectedColumns
  }),
  { select_colums }
)(Settings);
