/**
 * @param {*} closePrice 
 * @param {*} entryPrice 
 * @param {*} context 
 * @function getTradePnL
 */
const getTradePnL = (closePrice , entryPrice, context) => {
    if (context.symbol.info !== null && context.symbol.info.type === "Forex")
    {
        if (context.symbol.script.symbols[0].minTick === .0001)//remove a 0
        {
            return ((closePrice - entryPrice) * 10000);
        }
        else if (context.symbol.script.symbols[0].minTick === .01)//remove a 0
        {
            return ((closePrice - entryPrice) * 100);
        }
    }
    else if (context.symbol.info !== null && context.symbol.info.type === "Future")
    {
        // return (closePrice - entryPrice) /  context.symbol.script.symbols[0].minTick;
    }
    else
    {
        return closePrice - entryPrice;
    }

    return closePrice - entryPrice;
}
export { getTradePnL };