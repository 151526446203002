import createTextOnChart from '../library_Utils/createTextOnChart';
import createArrowOnChart from '../library_Utils/createArrowOnChart';
/**
 * @param {*} context 
 * @function upTrend
 */
const upTrend = (context) => {
        
    context.Data[0].trendSignal = 1;
        if (!context.upArrPlot) {                 
        
            if (window.parent.DBsignal === false && window.parent.indicatorName === "AlgoSwing") {
                
                createArrowOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].Low - 1, "arrow_up");
                createTextOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].Low, context.Data[0].Close);
            
            }
            context.upArrPlot = true;
            context.dnArrPlot = false;
        }    
}
export {upTrend};