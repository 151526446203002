import {automatedTest} from '../config/middleware.links';
import {automationSlicetest} from '../config/middleware.links';
const axios = require("axios");
const { token } = require('../middleware/getToken');
// const apiURL = 'http://localhost:4000/algosignals/automatedTest';
let response = [];
let watch = false;
/**
 * This is automatic script for testing the Db version performance values and the chart version
 * performance values. 
 * @Purpose : Purpose of this automated script is to match the values of Db version or algosignals with the chart version of algosignals
 * @Logic : We are using the nested for loop to check the DB version value with the chart version algosignals, so when the values match
 * we set watch variable to "true" and when its true then we know that the values are natching successfully.
 * @class AutomatedTest
 * @function automatedTest
 */
export default class AutomatedTest {
    
    //#region Automated test script for comparing the optimization calculation for DB version algosignals and chart version algosignals
    automatedtest = (context) => {
        // console.log("Url : ", automationSlicetest, "Symbol : ", context.automatedSymbol, "Mlr : ", context.automationSlicetest)
        //#region Hitting the endpoint for getting the data against the symbol using token | Authentication
        token().then( (token) => {   
            
            //#region axios GET request  
            return axios({
                method: "get",
                url: automationSlicetest,
                params: { 
                     symbol: context.automatedSymbol,
                    mlr : context.automationSlicetest

                },//context.automationSlicetest//window.parent.automationSymbol
                headers: { Authorization: "Bearer " + token }
                 
            }).then(( res ) => {
                response = res.data;
                // console.log(response)
                //#region Nested For Loop to comapare the values from the DB that is being pushed by DB version algosignals and the values that are going to be calculated on the chart/runtime
                    for (let i = 0; i <= context.automatedTesting.length - 1; i++) {
                        for (let j = 0; j <= context.automatedTesting.length - 1; j++)
                        {
                            if(context.automatedTesting[j].signal == response[i].signal && context.automatedTesting[j].signalPrice == response[i].signalPrice && 
                                context.automatedTesting[j].mktValCap == response[i].mktValCap && context.automatedTesting[j].netGain == response[i].netGain && 
                                context.automatedTesting[j].avgGain == response[i].avgGain && context.automatedTesting[j].avgWin == response[i].avgWin && 
                                context.automatedTesting[j].avgLoss == response[i].avgLoss && context.automatedTesting[j].wlRatio == response[i].wlRatio && 
                                context.automatedTesting[j].winRate == response[i].winRate && context.automatedTesting[j].totalSignals == response[i].totalSignals && 
                                context.automatedTesting[j].maxGain == response[i].maxGain && context.automatedTesting[j].minGain == response[i].minGain && 
                                context.automatedTesting[j].avgMFE == response[i].avgMFE && context.automatedTesting[j].avgMAE == response[i].avgMAE && 
                                context.automatedTesting[j].aefeRatio == response[i].aefeRatio && context.automatedTesting[j].totalDuration == response[i].totalDuration && 
                                context.automatedTesting[j].totalDurationInHours == response[i].totalDurationInHours && context.automatedTesting[j].avgDuratio == response[i].avgDuratio && 
                                context.automatedTesting[j].averageDurationInHours == response[i].averageDurationInHours && context.automatedTesting[j].shortestDuration == response[i].shortestDuration && 
                                context.automatedTesting[j].shortestDurationInHours == response[i].shortestDurationInHours && context.automatedTesting[j].longestDuration == response[i].longestDuration && 
                                context.automatedTesting[j].longestDurationInHours == response[i].longestDurationInHours && 
                                context.automatedTesting[j].tickerAlgosignals == response[i].symbol )
                                {
                                    watch = true;
                                    // console.log(response[i].Signal_date_String, "I :", i, "J : ", j)
                                    
                                }
                               
                        }
                        if(watch){
                            // console.log('%c Successfully......! Values Match for symbol '+ context.automatedSymbol, 'background: 	#008000; color: 	#ffffff');
                        }
                        else{
                            // console.log('%c Failed......! '+ context.automatedSymbol, 'background: #FF0000; color: #ffffff');
                            // console.log(response[i])
                        }
                            watch = false;
                       
                    }
                //#endregion Nested For Loop to comapare the values from the DB that is being pushed by DB version algosignals and the values that are going to be calculated on the chart/runtime
                 
                //#region return the response to the promise
                 return res.data
                //#endregion return the response to the promise
             
            }).catch((e) => console.log ("axios error", e))
            //#endregion axios GET request   
        }).catch( (e) => console.log(e));
        //#endregion Hitting the endpoint for getting the data against the symbol using token | Authentication
    }
    //#endregion Automated test script for comparing the optimization calculation forDB version algosignals and chart version algosignals

}