import React, { useEffect } from "react";
import Modal from "react-responsive-modal";

const AccountSelector = ({
  open,
  close,
  theme,
  accounts,
  selected_account,
  on_select_account
}) => {
  useEffect(() => {
    if (Boolean(document.getElementsByClassName("styles_closeButton__20ID4"))) {
      var mod = document.getElementsByClassName("styles_closeButton__20ID4");
      for (let i = 0; i < mod.length; i++) {
        mod[i].innerHTML = "X";
        mod[i].style.color = theme === "Light" ? "#131721" : "white";
      }
    }
  });
  return (
    <>
      <div style={{ zIndex: 1000000 }}>
        <Modal open={open} onClose={() => close(false)} center>
          <div
            style={{
              width: "600px",
              padding: "20px 50px",
              backgroundColor: theme === "Light" ? "white" : "#131721",
              color: theme === "Light" ? "#131721" : "white"
            }}
          >
            {accounts.map(account => {
              return (
                <div
                  className="row mt-2 pt-2 pb-2 clr_change_hover"
                  onClick={() => on_select_account(account.account_number)}
                  style={{
                    backgroundColor:
                      account.account_number === selected_account
                        ? theme === "Light"
                          ? "#f1f1f1"
                          : "#131732"
                        : theme === "Light"
                        ? "white"
                        : "#131721"
                  }}
                >
                  <div className="col-4">
                    <h5>{account.account_number}</h5>
                  </div>
                  <div className="col-8">
                    {/* <h5>details</h5> */}
                    {Object.keys(account)
                      .filter(
                        key =>
                          key !== "account_number" &&
                          key !== "date_created" &&
                          key !== "day_trader" &&
                          key !== "last_update_date"
                      )
                      .map(key => {
                        return (
                          <p>
                            <small>
                              <b> {key} : </b> {account[key]}
                            </small>
                          </p>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AccountSelector;
