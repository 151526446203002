import {postindicatorTemplate} from '../../config/middleware.links';
import {getownindicatorTemplate} from '../../config/middleware.links';
import {deleteIndicatorTemplate} from '../../config/middleware.links';
import {getindicatorContent} from '../../config/middleware.links';
const axios = require("axios");
const { token } = require('../../middleware/getToken');

/**
 * This script is designed to address the four situations where we are getting the indicator template data through name
 * push the study data to Db, remove the study from Db by name and get all the study templates from the DB to load them 
 * on the chart. For all these purposes we have created the endpoints in symbolApi package to handle all the 4 use cases.
 * @param studyTemplateData
 * @function indicatorTemplate
 * @function getownindicatorTemplate
 * @function removeByName
 * @function getindicatorTemContent
 * @class IndicatorTemplate
 */
export default class IndicatorTemplate {

//#region PUSH the Indicator templates to DB  
    indicatorTemplate = (studyTemplateData) => {

//#region "POST" argument object
        const indicatorTemplates = {
        name : studyTemplateData.name,
        content: studyTemplateData.content
        
      };
//#endregion "POST" argument object

let token = localStorage.getItem("jwtToken");
//#region "POST" request to push the indicator templates to the DB      
        //    token().then( (token) => {      
            // console.log(token)      
             return axios({
                 method: "post",
                 url: postindicatorTemplate,
                 data: indicatorTemplates,
                //  headers: { Authorization: "Bearer " + window.parent.secrettoken }
                headers: { Authorization: token }
             }).then(( res ) =>{
                    //  console.log(res.data)
                 return res.data
             }).catch((e) => console.log("axios error"))
        //  }).catch( (e) => console.log(e)) 
//#endregion "POST" request to push the indicator templates to the DB

    }
//#endregion PUSH the Indicator templates to DB

//#region GET all indicator templates
    getallIndicatorTemplates = () => {
        let returnData;
        let token = localStorage.getItem("jwtToken");
        return new Promise( resolve => {

//#region "GET" request to get all the indicator templates from DB
        //  token().then( (token) => {      
             return axios({
                 method: "get",
                 url: getownindicatorTemplate,
                //  headers: { Authorization: "Bearer " + window.parent.secrettoken }
                headers: { Authorization: token }
             }).then(( res ) =>{
                 returnData = res.data 
                //  console.log("returnData: ", returnData)
                setTimeout(() => {
                    resolve(returnData)
                  }, 1000);
                 return res.data
             }).catch((e) => console.log("axios error"))
            //  }).catch( (e) => console.log(e));
             
//#endregion "GET" request to get all the indicator templates from DB

        })
       
         
    }
//#endregion GET all indicator templates

//#region DELETE the indicator template from DB
    removeByName = (studyTemplateData) => {
        console.log("studyTemplateData : ", studyTemplateData);
        let token = localStorage.getItem("jwtToken");

//#region "DELETE" request to delete all the indicator templates 
        // token().then( (token) => {      
            return axios({
                method: "delete",
                url: deleteIndicatorTemplate,
                params: { name: studyTemplateData},
                // headers: { Authorization: "Bearer " + window.parent.secrettoken }
                headers: { Authorization: token }
            }).then(( res ) =>{
                return res.data
            }).catch((e) => console.log("axios error"))
            // }).catch( (e) => console.log(e));
//#endregion "DELETE" request to delete all the indicator templates 

    }
//#endregion DELETE the indicator template from DB

//#region GET the indicator template stored content
    getindicatorTemContent = (studyTemplateData) => {
        let returnData;
        let token = localStorage.getItem("jwtToken");
    return new Promise( resolve => {

//#region "GET" request to get the indicator templates content/data
    // token().then( (token) => {      
        return axios({
            method: "get",
            url: getindicatorContent,
            params: { name: studyTemplateData},
            // headers: { Authorization: "Bearer " + window.parent.secrettoken }
            headers: { Authorization: token }
        }).then(( res ) =>{
            returnData = res.data;
            setTimeout(() => {
                resolve(returnData)
            }, 1000);
            return res.data
        }).catch((e) => console.log("axios error"))
        // }).catch( (e) => console.log(e));

        
//#endregion "GET" request to get the indicator templates content/data

    })

    }
//#endregion GET the indicator template stored content

}