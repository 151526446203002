// import axios from "axios"
// import {configServer} from './links.config'

// const server_access_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNjMjI1Zjg2YzA4MzAwMTcwNWJmYjMiLCJ1c2VybmFtZSI6IlNoZWhlcnlhciIsImlhdCI6MTU3MzY1OTIzMX0.qk6zWT1FkmAJqYhmLr1H42Xgy-u6w19wGRmgFEcoQpo";
// // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNhZmNmNDk4M2Q1NzAwMTcxYmI4NWEiLCJ1c2VybmFtZSI6IlNvaGFpYiIsImlhdCI6MTU3MzU4NDExNn0.QRoYq4UPkf7Z083MAQFZCogo_BgU2jh2mjMVQSDFb0g";
// // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNjMjI1Zjg2YzA4MzAwMTcwNWJmYjMiLCJ1c2VybmFtZSI6IlNoZWhlcnlhciIsImlhdCI6MTU3MzY1OTIzMX0.qk6zWT1FkmAJqYhmLr1H42Xgy-u6w19wGRmgFEcoQpo";

// export const config = () => {
//     var config = null
//     const set_config = (data) => {
//       config = data
//     }
//     return axios({
//       method: "get",
//       url: configServer,
//       headers: { Authorization: "Bearer " + server_access_token }
//     }).then(res => {
//       set_config(res.data)    
//       return config
//     })
//   };

// export const config = async () => {
//     try {
//         var config = null
//         const set_config = (data) => {
//           config = data
//         }
        
//         let res = await axios.get({url: configServer}, {Authorization: "Bearer " + server_access_token })
//         set_config(res.data)
//         console.log(`configServerOutput: ${res.data}`)        
//         return config
  
//     } catch (err) {
//         console.log('error with keys.config', err)
//     }            
//   }      
// //export {config}

//Using Async/Await
// const apiAuth = async function () {
//     try {
//         let conf = await config()
//         console.log(conf.apiAuth.username)
//         return conf.apiAuth.username  
//     }
//     catch (e) {
//         console.log(e)
//     }
// }

// apiAuth()



const axios = require("axios");
const config_server = "https://algopti-config.herokuapp.com/api/dev/get_config";

// const config_server = "http://localhost:4000/api/dev/get_config";
const server_access_token =
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNhZmNmNDk4M2Q1NzAwMTcxYmI4NWEiLCJ1c2VybmFtZSI6IlNvaGFpYiIsImlhdCI6MTU3MzU4NDExNn0.QRoYq4UPkf7Z083MAQFZCogo_BgU2jh2mjMVQSDFb0g"
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNjMjI1Zjg2YzA4MzAwMTcwNWJmYjMiLCJ1c2VybmFtZSI6IlNoZWhlcnlhciIsImlhdCI6MTU3MzY1OTIzMX0.qk6zWT1FkmAJqYhmLr1H42Xgy-u6w19wGRmgFEcoQpo";

module.exports = () => {
  var config = null;
  const set_config = data => {
    config = data;
  };
  return axios({
    method: "get",
    url: config_server,
    headers: {
      Authorization: "Bearer " + server_access_token
    }
  }).then(res => {
    set_config(res.data);
    // console.log("Config response : ", res.data)
    return config;
  }).catch(e=>{
    console.log('cannot connect to cofig server', e)
  })
};
