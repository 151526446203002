import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./../../styles/css/Form.css";
// import { Link } from "react-router-dom";

import tradier_logo from "./../../assets/images/tradier_logo.png";

const ConnectTradier = props => {
  useEffect(() => {
    console.log("*******Connect Tradier***", props.auth._id)
    localStorage.setItem("userID", props.auth._id)
    if (props.auth.is_Authenticated) {
      if (props.auth.is_completed) {
        if (props.auth.is_tradier_linked) return props.history.push("/main");
      } else return props.history.push("/complete_profile");
    } else return props.history.push("/login");
  }, [props.auth]);
  return (
    <>
      <div className="vldform vldauth">
        <h1>Connect Tradier Brokerage Account</h1>
        <h3>
          You're one step away please connect your tradier brokerage account
        </h3>

        {/* <span className="vldform__signuplink mt-3" href="#">
          Or login using
        </span> */}
        <a href="https://authdriverexp.azurewebsites.net/api/auth/tradier">
          <img src={tradier_logo} className="mt-3" height="50px" alt="" />
        </a>
        {/* <p className="vldform__signup">
          Don't have account?
          <Link className="vldform__signuplink" to="/Register">
            Sign up
          </Link>
        </p> */}
      </div>
    </>
  );
};

export default connect(
  state => ({ auth: state.auth, error: state.error }),
  null
)(ConnectTradier);
