/**
 * @param {*} val1 
 * @param {*} val2 
 * @function ApproxCompare
 */
const ApproxCompare = (val1, val2) => {
    if (val1 == val2)
        return 0;
    if (val1 > val2)
        return 1;
    else
        return -1;
}
export {ApproxCompare};