import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
import {
  on_select_strike_range,
  custom_strike_toggle
} from "../../../actions/options";
import MoreVertIcon from "@material-ui/icons/Settings";
import Settings from "./Settings";


function Optionbar({
  near,
  on_select_strike_range,
  strikeRange,
  selectedExpiration,
  symbol,
  theme,
  toggle_expiry,
  custom_strike_toggle
}) {
  const clr_theme = theme;
  const BootstrapInput = withStyles(theme => ({
    root: {
      "label + &": {
        //   marginTop: theme.spacing(3),
      }
    },
    input: {
      // borderRadius: ,
      position: "relative",
      // backgroundColor: "black",
      border: "1px solid #ced4da",
      fontSize: 12,
      padding: "5px 26px 5px 12px",
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    }
  }))(InputBase);
  const classes = makeStyles(theme => ({
    margin: {
      margin: 0
    },
    icon: {
      fontSize: 16,
      padding: 0,
      marginBottom: 0,
      marginRight: "5px"
    },
    switch: {
      fontSize: "16px",
      height1: "16px"
    },
    inputLikeBox: {
      // borderRadius: ,
      position: "relative",
      // backgroundColor: "black",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "2.5px 12px 2.5px 12px",
      display: "inline-block",
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721"
    }
  }))();
  const [optionType, setOptionType] = React.useState("call/put");

  const [setting_modal, set_setting_modal] = useState(false);
  return (
    <div>
      <Settings
        open={setting_modal}
        close={set_setting_modal}
        theme={clr_theme}
      />
      <FormControl className={classes.margin}>
        <Select
          id="demo-customized-select"
          value={optionType}
          onChange={e => setOptionType(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value={"call/put"}>call/put</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <Select
          id="select-strike"
          value={strikeRange}
          onChange={e =>
            on_select_strike_range(e, symbol, selectedExpiration, near)
          }
          input={<BootstrapInput />}
        >
          <MenuItem value={"select"}>Strike</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={16}>16</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <Select
          id="demo-customized-select"
          value={"overview"}
          input={<BootstrapInput />}
        >
          <MenuItem value={"overview"}>Overview</MenuItem>
          {/* <MenuItem value={"performance"}>Performance</MenuItem> */}
        </Select>
      </FormControl>
      <div className={classes.inputLikeBox}>
        Near :{" "}
        <span style={{ marginRight: "18px" }}>{Number(near).toFixed(2)}</span>{" "}
        <span onClick={toggle_expiry} style={{ cursor: "pointer" }}>
          <b>Expirations </b>
        </span>
      </div>

      <div className={classes.inputLikeBox}>
        <div>
          <MoreVertIcon
            className={classes.icon}
            onClick={() => set_setting_modal(true)}
          />
        </div>
      </div>
      <div className={classes.inputLikeBox}>
        {/* <Switch
        checked={true}
        // onChange={handleChange('checkedB')}
        value="checkedB"
        color="primary"
        size="small"
        className={classes.switch}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      /> */}
        <label htmlFor="custom-strikes" className={classes.icon}>
          Strikes Selector
        </label>
        <input
          type="checkbox"
          id="custom-strikes"
          value="custom-strikes"
          onChange={() => {
            if (Boolean(selectedExpiration)) {
              custom_strike_toggle(selectedExpiration, {
                symbol,
                selectedExpiration,
                strikeRange,
                near,
              });
            } else {
              custom_strike_toggle();
            }
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  near: state.option.near,
  strikeRange: state.option.strikeRange,
  symbol: state.option.symbol,
  selectedExpiration: state.option.selectedExpiration
});
export default connect(mapStateToProps, {
  on_select_strike_range,
  custom_strike_toggle
})(Optionbar);
