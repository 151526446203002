/**
 * We are subscribing to the **study_event**. This event triggers when we remove the indicator from
 * the chart from the user end by clicking on the close button on the chart.
 * This  script is removing the shapes from the chart through removeEntity api by passing the shapes ID 
 * to the removeEntity method.
 * @param {*} tvWidget 
 * @function subscribe_study_event
 * @event study_event
 */
let studyID;
const subscribe_study_event = (tvWidget) => {
    /**
     * An event related to the study. The callback function receives two arguments: a study ID and an 
     * event type (currently possible values for this argument are remove and at version 1.16 - price_scale_changed).
     * @param {*} id 
     * @param {*} studyEventType 
     */
    
    //#region Subscribe to study_event accur when we remove an instance of indicator from the chart
    tvWidget.subscribe('study_event', (id, studyEventType) => {
       

        //#region Remove the custom shapes only if the indicator is loaded on the chart
        // if(window.parent.checkIndicatorLoadedONChart == true)
        {
            //#region After removing the indicator instance we set it to false
            window.parent.checkIndicatorLoadedONChart = false;
            //#endregion After removing the indicator instance we set it to false
        if(window.parent.indicatorName == "_Algopti\xAE Signal Engine")
        {
            console.log(window.parent.indicatorName);
            studyID = id;
        }

        if(studyID == id){

             //#region Console Logs
        console.log("Remove Indicator");
        console.log(id, studyEventType);
        //#endregion Console Logs
            //#region Clear/remove the callouts/markers
            tvWidget.chart().clearMarks();
            //#endregion Clear/remove the callouts/markers

            //#region Remove the Text shapes
                for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++) {

                    tvWidget.chart().removeEntity(window.parent.textShapeID[i])
                    
                }
            //#endregion Remove the Text shapes
            window.parent.textShapeID.length = 0;


        }

            //#region Remove the Arrow shapes
                for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++) {
                    
                    tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);
                }
            //#endregion Remove the Arrow shapes

            //#region Remove the Circle shapes

                for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++) {
                    
                    tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);
                }

            //#endregion Remove the Circle shapes
            
            //#region Reset Shape IDs
                window.parent.arrowShapeID.length = 0;
                window.parent.circleShapeID.length = 0;
            //#endregion Reset Shape IDs

            //#region This method shows a dialog with the custom title and text along with the "OK" and "CANCEL" buttons.
                // tvWidget.showConfirmDialog({
                //     title: 'Remove Indicator ' + window.parent.indicatorName,
                //     body:'You may Loss the changes',
                //     callback: (val) => {
                    // }
                // });
            //#endregion This method shows a dialog with the custom title and text along with the "OK" and "CANCEL" buttons.    
        }
        //#endregion Remove the custom shapes only if the indicator is loaded on the chart

        //#region Remove the custom shapes only when we have the study template loaded on the chart
    if(window.parent.loadstudyTemplate == true && window.parent.indicatorName == '_Algopti\xAE Signal Engine')
    {
        console.log("**********************")
        tvWidget.chart().clearMarks();
                    for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++){
                        tvWidget.chart().removeEntity(window.parent.textShapeID[i]);
                    }
                    for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++){
                        tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);
                    }
                    for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++){
                        tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);
                    }
    }
//#endregion Remove the custom shapes only when we have the study template loaded on the chart

    });
    //#endregion Subscribe to study_event accur when we remove an instance of indicator from the chart
}
export default subscribe_study_event;