import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../config/links.config';
import { token } from '../../../configMiddleware/getToken';
import { symbol } from 'prop-types';
const axios = require("axios");
let history = {};
let historyData = {};
let url = 'https://charthistoricaldriver.azurewebsites.net/api/chart/historical_data?symbol'//'http://localhost:7072/api/chart/historical_data?symbol';
var rp = require('request-promise').defaults({json: true})
const api_root = 'https://min-api.cryptocompare.com';
const api_key = '82380ba81cee28c37a94cf37777d10e67762d52634c749cf1342f922a6f421da' ;

const AllSymbols = JSON.parse(localStorage.getItem("all_symbols"));
async function cryptoCompareExchnages() {
    
  let token = localStorage.getItem("jwtToken");
 await axios({
  method: "get",
  url: 'https://cryptocompareex.azurewebsites.net/api/getCryptoCompare',//'http://localhost:7072/api/getCryptoCompare',//https://cryptocompareex.azurewebsites.net/api/getCryptoCompare
  headers: { Authorization: token }
}).then(( res ) =>{
  for(let i = 0; i <= res.data.length - 1; i++){
    AllSymbols.push(res.data[i])
  }
  })
}

async function getCryptos(){
  
  await axios({
   method :'get',
   url : "https://eodhdallsymbols.azurewebsites.net/api/getcryptos",
   headers: { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw" }
 }).then((res)=>{
   for(let i = 0; i <= res.data.length - 1; i++){
    AllSymbols.push(res.data[i])
  }
 }).catch((E)=>{
   console.log(E)
 })

}
cryptoCompareExchnages() 
getCryptos()
export default {
  history: history,
  historyData : historyData,
  getBars: async function(symbolInfo, resolution, from, to, first, limit) {
    let url = 'https://charthistoricaldriver.azurewebsites.net/api/chart/historical_data?symbol'
    // let url = 'http://localhost:7072/api/chart/historical_data?symbol'
   // console.log("***************** Symbol *************", symbolInfo.name)
    let token = localStorage.getItem("jwtToken"); 
    if(symbolInfo.name.includes('Coinbase') ){
      symbolInfo.name = symbolInfo.name.replace('Coinbase:', '')
    }
    if(symbolInfo.name.includes('BitTrex'))
    {
      symbolInfo.name = symbolInfo.name.replace('BitTrex:', '')
    }
   
    if(AllSymbols){
      for(let i = 0; i <= AllSymbols.length - 1; i++){
        if(symbolInfo.name == AllSymbols[i].symbol || symbolInfo.name.replace('/','-') == AllSymbols[i].symbol)
        {
          console.log("symbolInfo.name == AllSymbols[i].symbol :", symbolInfo.name , AllSymbols[i].symbol)
          if(AllSymbols[i].exchange == "FOREX"){
            // url = 'http://localhost:7079/api/chart/historical_data/Forex?symbol'
            url = 'https://charthistoricalforexdriver.azurewebsites.net/api/chart/historical_data/Forex?symbol'
          }
           if(AllSymbols[i].exchange == "COMM"){
            // url = 'http://localhost:7082/api/chart/historical_data/Comm?symbol'
            url = 'https://charthistoricalcommdriver.azurewebsites.net/api/chart/historical_data/Comm?symbol'
          }
           if(AllSymbols[i].exchange == "crypto" || AllSymbols[i].exchange == "Coinbase" || AllSymbols[i].exchange == "BitTrex"|| AllSymbols[i].exchange == "CC"){
            
            // url = 'http://localhost:7081/api/chart/historical_data/Crypto?symbol';
            url = 'https://charthistoricalcryptodriver.azurewebsites.net/api/chart/historical_data/Crypto?symbol'
          }
           if(AllSymbols[i].exchange == "INDX"){
            // url = 'http://localhost:7080/api/chart/historical_data/Index?symbol';
            url = 'https://charthistoricalindxdriver.azurewebsites.net/api/chart/historical_data/Index?symbol'
          }
          if(AllSymbols[i].exchange == "INDX"){
            // url = 'http://localhost:7080/api/chart/historical_data/Index?symbol';
            url = 'https://charthistoricalindxdriver.azurewebsites.net/api/chart/historical_data/Index?symbol'
          }
          // if(AllSymbols[i].exchange == " " || AllSymbols[i].exchange == " " |AllSymbols[i].exchange == " ")
          // else{
          //   // http://localhost:7072/api/chart/historical_data?symbol
          //   // https://charthistoricaldriver.azurewebsites.net/api/chart/historical_data?symbol
          //   url = 'https://charthistoricaldriver.azurewebsites.net/api/chart/historical_data?symbol';
          // }
        }
      }
  }
  // if(symbolInfo.type == "Forex" && symbolInfo.exchange =="FOREX"){
  //   url = 'https://charthistoricalforexdriver.azurewebsites.net/api/chart/historical_data/Forex?symbol'
  // }
  //   if( symbolInfo.type == "futures" && symbolInfo.exchange =="Future"){
  //   url = 'https://charthistoricalcommdriver.azurewebsites.net/api/chart/historical_data/Comm?symbol'
  // }

  //   if( symbolInfo.type == "crypto" && symbolInfo.exchange =="CC"){
  //   url = 'https://charthistoricalcryptodriver.azurewebsites.net/api/chart/historical_data/Crypto?symbol'

  // }

  //   if(symbolInfo.type == "Index" && symbolInfo.exchange =="INDX"){
  //   url = 'https://charthistoricalindxdriver.azurewebsites.net/api/chart/historical_data/Index?symbol'

  // }

  // if(symbolInfo.type == "stock" && symbolInfo.exchange =="stock"){
  //   url = 'https://charthistoricaldriver.azurewebsites.net/api/chart/historical_data?symbol'
  // }


    //#region historical data
    
    console.log("historical data crypto name :", symbolInfo, url)

    if(symbolInfo.exchange == "Coinbase" && (resolution === "1" || resolution === "60") || symbolInfo.exchange == "BitTrex" && 
    (resolution === "1" || resolution === "60") || symbolInfo.exchange != " " && resolution != "1D" || symbolInfo.exchange != "stock" && resolution != "1D")
    {
      if(symbolInfo.name.includes('-')){
        symbolInfo.name = symbolInfo.name.replace('-','/')
      }
      var split_symbol = symbolInfo.name.split(/[:/]/)
      console.log("resolution : ", resolution, "split_symbol :", split_symbol) 
			const url = resolution === '11D' ? '/data/v2/histoday' : resolution >= 60 ? '/data/v2/histohour' : '/data/v2/histominute'
			const qs = {
					e: symbolInfo.exchange,
					fsym: split_symbol[0],
					tsym: split_symbol[1],
					toTs:  to ? to : '',
          limit: limit ? limit : 2000, 
          // api_key : api_key
					// aggregate: 1//resolution 
				}
			// console.log({qs})

        return rp({
                url: `${api_root}${url}`,
                qs,
                headers: {
                  authorization: api_key
              },
            })
            .then(data => {
                console.log(" Crypto data :", data)
				if (data.Response && data.Response === 'Error') {
					console.log('CryptoCompare API error:',data.Message)
					return []
				}
				if (data.Data.Data.length) {
					console.log(`Actually returned: ${new Date(data.Data.TimeFrom * 1000).toISOString()} - ${new Date(data.Data.TimeTo * 1000).toISOString()}`)
					var bars = data.Data.Data.map(el => {
						return {
							time: el.time * 1000, //TradingView requires bar time in ms
							low: el.low,
							high: el.high,
							open: el.open,
							close: el.close,
							volume: el.volumefrom 
						}
					})
						if (first) {

							var lastBar = bars[bars.length - 1]
							history[symbolInfo.name] = {lastBar: lastBar}
                        }
                        console.log("Crypto bars :", bars)
                        console.log(" Crypto Compare symbolInfo.name :", symbolInfo.name)
					return bars
				} else {
					return []
				}
			})
    }
    else{
      return await axios(
        // `/api/chart/historical_data?symbol=${symbolInfo.name}&resolution=${resolution}`
        `${url}=${symbolInfo.name}&resolution=${resolution}`,{headers: { Authorization: token }}
      ).then(data => {
        // console.log("HIstorical data : ", data.data.length)
        if (data.data.length) {
          var bars;
          // console.log(symbolInfo.name);
          if (!symbolInfo.name.includes("*")) {
            if (resolution === "1" || resolution === "60") {
              bars = data.data.map(el => {
                return {
                  time: el.timestamp * 1000, //TradingView requires bar time in ms
                  low: el.low,
                  high: el.high,
                  open: el.open,
                  close: el.close,
                  volume: el.volume
                };
              });



            } else if (resolution === "1D" || resolution === "D") {
              bars = data.data
              .map(el => {
                let timestamp = new Date(
                  new Date(el.date).toLocaleString("en-US", {
                    timeZone: "America/New_York"
                  })
                );
                  //Below Not valid for crypto data
                  if(symbolInfo.type == 'stock')
                timestamp.setHours(40);
                //#region timeStamp Offset Comment
                /**Ideally, the set hrs should be 16 to force 16:00 EST; however there is a native error                   in this built-in function that sets the timestamp one data back, which is why we are                        adding a 24hr offset. */
                //#endregion

                timestamp = timestamp.getTime() / 1000;
                return {
                  time: timestamp * 1000,
                  open: el.open,
                  close: el.close,
                  high: el.high,
                  low: el.low,
                  volume: el.volume
                };
              });
            }
          }  
          
        else {
            if (resolution === "1" || resolution === "60") {
              throw new Error(
                "* symbols are not supported for interday data please change resolution"
              );
              return null;
            }

            bars = data.data
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map(el => {
                return {
                  time: new Date(el.date),
                  open: el.open,
                  close: el.close,
                  high: el.high,
                  low: el.low,
                  volume: el.volume
                };
              });
          }
          if (first) {
            
            var lastBar = bars[bars.length - 1];
            console.log("last bar :", lastBar)
            // var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            // var yyyy = today.getFullYear();

            // today = yyyy + '-' + mm + '-' + dd;
            // console.log("today : ", today.getTime())
            // console.log("first : ", first, "lastBar : ", lastBar )
            
            if(symbolInfo.exchange == "crypto" || symbolInfo.exchange == "Coinbase" || symbolInfo.exchange == "BitTrex"){
              console.log("history Symbol Info :", symbolInfo)
              if(symbolInfo.name.includes('Coinbase') ||symbolInfo.name.includes('BitTrex')){
                symbolInfo.name = symbolInfo.name;
                if(symbolInfo.name.includes('-')){
                  symbolInfo.name = symbolInfo.name.replace('-', '/')
                }
              }
              else {
                if(symbolInfo.name.includes('-'))
                {
                  symbolInfo.name = symbolInfo.cryptoComapreEx+":"+symbolInfo.name
                  
                  // symbolInfo.name = "Coinbase"+":"+symbolInfo.name
                  symbolInfo.name = symbolInfo.name.replace('-', '/')
                }
                else {
                  symbolInfo.name = symbolInfo.cryptoComapreEx+":"+symbolInfo.name
                  
                  
    
                }
              }
              
              
              
            }
            
  console.log(" symbolInfo.name :", symbolInfo.name)
            history[symbolInfo.name] = { lastBar: lastBar };
            // history[CryptoStreamSymbol] = { lastBar: lastBar };
          }
          console.log(bars);
          return bars;
        } else {
          return [];
        }
      });
  }
//#endregion historical data
   

//  const value =
//  return await token().then( async(token) => {     
  // let token = localStorage.getItem("jwtToken"); 
  // console.log("historical token : ", token)
  //     return await axios({
  //           method: "get",
  //           url: `${chartURL}/api/chart/historical_data`,
  //           params : {
  //                 symbol : symbolInfo.name,
  //                 resolution : resolution
  //     },
  //           // headers: { Authorization: "Bearer " + token }
  //           headers: { Authorization: token }
  //     }).then(( data ) =>{
  //       if (data.data.length) {
  //         var bars;
  //         console.log(symbolInfo.name);
  //         if (!symbolInfo.name.includes("*")) {
  //           if (resolution === "1" || resolution === "60") {
  //             bars = data.data.map(el => {
  //               return {
  //                 time: el.timestamp * 1000, //TradingView requires bar time in ms
  //                 low: el.low,
  //                 high: el.high,
  //                 open: el.open,
  //                 close: el.close,
  //                 volume: el.volume
  //               };
  //             });
  //           } else if (resolution === "1D" || resolution === "D") {
  //             bars = data.data.map(el => {
  //               let timestamp = new Date(
  //                 new Date(el.date).toLocaleString("en-US", {
  //                   timeZone: "America/New_York"
  //                 })
  //               );
  
  //               timestamp.setHours(40);
  //               //#region timeStamp Offset Comment
  //               /**Ideally, the set hrs should be 16 to force 16:00 EST; however there is a native error                   in this built-in function that sets the timestamp one data back, which is why we are                        adding a 24hr offset. */
  //               //#endregion
  
  //               timestamp = timestamp.getTime() / 1000;
  //               return {
  //                 time: timestamp * 1000,
  //                 open: el.open,
  //                 close: el.close,
  //                 high: el.high,
  //                 low: el.low,
  //                 volume: el.volume
  //               };
  //             });
  //           }
  //         } else {
  //           if (resolution === "1" || resolution === "60") {
  //             throw new Error(
  //               "* symbols are not supported for interday data please change resolution"
  //             );
  //             return null;
  //           }
  
  //           bars = data.data
  //             .sort((a, b) => new Date(a.date) - new Date(b.date))
  //             .map(el => {
  //               return {
  //                 time: new Date(el.date),
  //                 open: el.open,
  //                 close: el.close,
  //                 high: el.high,
  //                 low: el.low,
  //                 volume: el.volume
  //               };
  //             });
  //         }
  //         if (first) {
  //           var lastBar = bars[bars.length - 1];
  //           history[symbolInfo.name] = { lastBar: lastBar };
  //         }
  //         // console.log(bars);
  //         // console.log("History bars : ", bars);
  //         historyData = bars;
  //         return bars;
  //       } else {
  //         return [];
  //       }
  //           // return data.data
  //     }).catch((e) => console.log("axios error"))
      // }).catch( (e) => console.log(e))

  }
};
