import AlgoSWING from "../IndicatorLogic/algoSWING";
let algoswingobject = new AlgoSWING();
/**
 * @object algoswing -> algoswing indicator object
 * @object metaInfo -> Contains the info about the indicator like "inputs", "Plots", "name", "description", 
 * "Plot styles", "defaults".
 * @property is_hidden_study -> set to true means hide from the list of indicators on the chart and false means
 *  include it in the list of indicators on the chart.
 * @property is_price_study -> true means plot the indicator on the main chart bars. false means plot the 
 * indicator in a seperate window on the chart.
 * @property shortDescription -> is the name of the indicator you see on the chart.
 * @property id -> is what the charting linbaray used to call the custom indicator. 
 * Actually when the indicator load on the chart the charting library creates a unique id for an indicator 
 * which we can get through like below.
 * @function constructor
 * @function this.init called once when the indicator loads on the chart.
 * @function this.main indicator logic as an indicator written in "main" function . Calls on each Bar.
 * @DynamicSymbolHandler ((PineJS.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
 */
export const algoswing = {
    name: "AlgoSwing",
    //#region contains the info about the custom indicator.
    metainfo: {
        "_metainfoVersion": 40,
        /** id is what the charting linbaray used to call the custom indicator. Actually when the indicator
         * load on the chart the charting library creates a unique id for an indicator.
         */ 
        "id": "AlgoSwing@tv-basicstudies-1",
        "scriptIdPart": "",
        "name": "AlgoSwing",
        // This description will be displayed in the Indicators window
        // It is also used as a "name" argument when calling the createStudy method
        "description": "AlgoSwing",
        //shortDescription is the name of the indicator you see on the chart.This description will be displayed on the chart
        "shortDescription": "AlgoSwing",
        //set to "true" means hide from the list of indicators on the chart and "false" means include it in the list of indicators on the chart.
        "is_hidden_study": true,
        //"true" means plot the indicator on the main chart bars. "false" means plot the indicator in a seperate window on the chart.
        "is_price_study": true,

        //Plots are the plotting of data through different types like line, line_with_breaks and more, these options are given by the charting library
        "plots": [{
            "id": "plot_0",
            "type": "line"
        }],

        //Defaults are the default values we want to assign to the styles(plots), precision, inputs
        "defaults": {

            "styles": {
                "plot_0": {
                    "linestyle": 0,
                    "visible": true,

                    // Make the line thinner
                    "linewidth": 1,

                    // Plot type is Line
                    "plottype": 0,

                    // Show price line
                    "trackPrice": true,

                    "transparency": 35,

                    // Set the dark red color for the plot line

                }
            },

            // Precision is one digit, like 777.7
            "precision": 4,


            "inputs": {
                "SwingStrength": 5
            }
        },
        "styles": {
            "plot_0": {
                // Output name will be displayed in the Style window
                "title": "AlgoSwing value",
                "histogramBase": 0,
            }
        },
        //An array of inputs added as a property to the custom study.Values assign to inputs, intialization and declaration of inputs. Data types are also assigned here
        "inputs": [{
            "id": "SwingStrength",
            "type": "integer",
            "name": "SwingStrength Algo",
            "defval": 5

        }],
    },
    //#endregion contains the info about the custom indicator.
    
    constructor: function() {    
        /* eslint-disable no-unused-expressions */

        //#region "init" function called once when the indicator loads on the chart.
        this.init = function(context, inputCallback) {
            /*context is an object provided by the charting library and we are using this oject to complete our calculations. 
            we are adding our variables, arrays every thing we need for the calculation in the context object so we can access it anywhere.
            and it helps the script to run properly.*/
            this._context = context;
            //inputCallback call the input from the inputs array
            this._input = inputCallback;

            //Dynamic way to get the symbol that is loaded on the chart. || Dynamic handler for symbol while changing the symbols on the chart.
            var symbol =((window.parent.pinejs.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
            //new_sym handle/controls the server-side studies
            this._context.new_sym(symbol, window.parent.pinejs.Std.period(this._context), window.parent.pinejs.Std.period(this._context));

            var Strength = 5;

            context.CurrentBar = 0; //-1
            context.upArrPlot = false;
            context.dnArrPlot = false;

            //#region Swing Data varables
            context.currentSwingHigh = 0;
            context.currentSwingLow = 0;
            context.lastSwingHighValue = 0;
            context.lastSwingLowValue = 0;
            context.saveCurrentBar = -1;
            context.constant = (2 * Strength) + 1;

            context.swingHighSeries = [];
            context.swingHighSwings = [];
        
            context.swingLowSeries = [];
            context.swingLowSwings = [];

            context.lastHighCache = []; //ArrayList
            context.lastLowCache = []; //ArrayList

            context.SwingHighPlot = [];
            context.SwingLowPlot = [];

            context.SwingHighPlot_Valid = [];
            context.SwingLowPlot_Valid = [];
            //#endregion Swing Data varables
                            
            if (context.Data === undefined)
                context.Data = [];
        }
        //#endregion "init" function called once when the indicator loads on the chart.

        //#region "main" function called on each bar. The main logic of the custom indicator will be wrote in "this.main"
        this.main = function(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;

            //By select_sym we can retrieve several "server-side" studies and combine it in one custom study implementation.
            this._context.select_sym(1);
            var CurrentBar = this._context.CurrentBar;
            if(isNaN(context.symbol.high)){
                return;
            }
            /*context.Data array is an array in which we are adding our core values used for calculation of custom indicator that are defined as *lists* 
            in Ninja Trader and unshift is an array method to add the elements at the start of an array.*/
            context.Data.unshift({
                High: context.symbol.high || 0,
                Low: context.symbol.low || 0,
                Close: context.symbol.close || 0,
                Open:context.symbol.open || 0,
                dmPlus: 0,
                dmMinus: 0,
                sumDmPlus: 0,
                sumDmMinus: 0,
                sumTr: 0,
                tr: 0,
                Value: 0,
                Index: context.symbol.index,
                plotDirection: 0,
                Time: context.symbol.time
            });

            /*This line indicates/control/manages the custom indicator when it approaches the last bar to stop its calculations. Becauseafter the last bar
            the index for the last bar is going to repeat.We are handling the indicator when it approaches to lastBar*/
            if (context.Data.length < 2 || (context.Data[0].Index == context.Data[1].Index))
            return;

            //This statement will allow us to use the input in our logic. This is how we access input
                var SwingStrength = inputCallback(0);
                context.CurrentBar++;

              //Calling the Core Logic functin by passing the values as an arguments
              algoswingobject.algoSwing(SwingStrength,context,CurrentBar)
        }
        //#endregion "main" function called on each bar. The main logic of the custom indicator will be wrote in "this.main"

    }
}