import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import set_auth_token from "./../../services/setAuthToken";
import { get_user } from "./../../actions/auth.action";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../config/links.config'
import Axios from "axios";

const TradierRedirect = ({ get_user, history, auth, error }) => {
  // get_user();
  console.log("*********Hit get Tradier********", auth);
  const [get_user_action_statusjj,set_get_user_action_status] = useState(false);
  const [get_user_action_status] = useState(true);
  useEffect(() => {
    console.log("Get tradier*************", auth.is_Authenticated)
    if (auth.is_Authenticated) return set_get_user_action_status(true);
  }, [auth]);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const name = params.get("name");
  const tradier_id = params.get("id");
  const refreshToken = params.get("refreshToken");

  
  if (auth.is_Authenticated && auth.is_tradier_linked) {
    history.push("/login");
  }
  const JWT = localStorage.getItem("jwtToken");
  console.log("auth : ", auth, "JWT : ", JWT, "get_user_action_status : ", get_user_action_status);
  if (JWT && get_user_action_status) {
    auth._id = localStorage.getItem("userID")
    console.log("ISer ID Auth : ", auth._id)
    setTimeout(() => {
      Axios.post(
        `${authURL}/api/auth/register_tradier?is_Authenticated=${auth.is_Authenticated}&_id=${auth._id}`,
        {
          token,
          name,
          id: tradier_id,
          refreshToken
        }
      ).then(res => {
        localStorage.setItem("jwtToken", res.data);
        set_auth_token(res.data);
        get_user();
      });
    }, 5000);
  }
  if (!JWT) {
    console.log("******************* Without JWT ***************")
    Axios.post(`${authURL}/api/auth/register_tradier`, {
      token,
      name,
      refreshToken,
      id: tradier_id
    }).then(res => {
      localStorage.setItem("jwtToken", res.data);
      set_auth_token(res.data);
      get_user();
    });
  }

  //   localStorage.setItem('jwtToken', token)
  //   set_auth_token(token);
  //   get_user();
  //   if(!auth.is_completed) history.push('/complete_profile')
  return (
    <>
      <h1>{error.token ? error.token : " Redirecting . . ."}</h1>
    </>
  );
};

export default connect(state => ({ auth: state.auth, error: state.error }), {
  get_user
})(TradierRedirect);
