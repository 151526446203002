import React, { useState } from "react";


export default props => {
  const [columns] = useState([{ name: "strike", title: "Strike" }]);
  const rows = props.rows.map(row => {
    return {
      strike: row
    };
  });
 
  return (
    <table
      className="table"
      style={{
        fontSize: "12px",
        backgroundColor: props.theme === "Light" ? "rgb(105, 120, 163)" : "black",
        color: props.theme === "Dark" ? "#d7d8db" : "#fff"
      }}
    >
      <thead>
        <tr>
          {columns.map(c => (
            <th key={c.name}><div>{"STRIKE"}</div></th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr key={i}>
              {Object.keys(row).map(r => (
                <td key={row[r]}>{row[r]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
