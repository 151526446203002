import React, { useEffect ,useState,setState} from "react";
import axios from 'axios';
import Main from "./Views/Main";
import "./styles/css/index.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Views/Login";
import OauthRedirect from "./Views/OauthRedirect/index";
import CompleteProfile from "./Views/CompleteProfile";
import Register from "./Views/Register/Register";
import ConnectTradier from "./Views/ConnectTradier";
import TradierOAuth from "./Views/GetTradier";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from './config/links.config'
import IdleTimer from "./IdleTimer/IdleTimer";
// console.log(`optionsURL: => ${optionsURL}`)
const App = () => {
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(async () => {
    console.log("APP.JS.................")
    // await getAllSymbols()
    const timer = new IdleTimer({
      timeout: 10, //expire after 10 seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        //do something if expired on load
        setIsTimeout(true);
      }
    });

    
    // else{return props.history.push("/login");}

    return () => {
      timer.cleanUp();
    };
  }, []);
  // return <div>{isTimeout ? "Timeout" : "Active"}</div>;
  // if (isTimeout ) {
  // //  return (<Redirect push to="/register" />)


  //   return (<div>{isTimeout ? "Timeout" : "Active"}</div>)
  //   // return (
  //   // <React.Fragment>
  //   {/* <Switch> */}
  //   {/* <Route path="/Register" component={Login} /> */}
  //   {/* <Redirect to="/login"/> */}
      
  //   {/* </Switch> */}
  // {/* </React.Fragment> */}
  // // <div>{"Timeout" }</div>
  // // )
  // }
  return (
    <React.Fragment>
      <Switch>
        <Route path="/main" component={Main} />
        <Route exact path="/" component={Main} />
        <Route path="/get_token" component={OauthRedirect} />
        <Route path="/complete_profile" component={CompleteProfile} />
        <Route path="/login" component={Login} />
        <Route path="/Register" component={Register} />
        <Route path="/connect_tradier" component={ConnectTradier} />
        <Route path="/get_tradier" component={TradierOAuth} />
        <Redirect to="/main" />
      </Switch>
    </React.Fragment>
  );
};

export default App;
