const to_positive = num => {
  if (num >= 0) return num;
  else return -num;
};

const market_calculator = options => {
  let spread_ask = 0;
  let spread_bid = 0;
  let spread_mid = 0;
  let market_price = 0;
  let trade_type = "debit";
  options.forEach((opt, index) => {
    if (opt.action === "buy") {
      spread_bid += opt.bid * opt.quantity;
      spread_ask += opt.ask * opt.quantity;
    } else if (opt.action === "sell") {
      spread_bid -= opt.ask * opt.quantity;
      spread_ask -= opt.bid * opt.quantity;
    }
  });
  spread_mid = (spread_ask + spread_bid) / 2;
  market_price = spread_mid >= 0 ? spread_ask : spread_bid;
  trade_type = market_price === spread_ask ? "debit" : "credit";
  return {
    spread_ask: to_positive(spread_ask).toFixed(2),
    spread_bid: to_positive(spread_bid).toFixed(2),
    spread_mid: to_positive(spread_mid).toFixed(2),
    market_price: to_positive(market_price).toFixed(2),
    trade_type
  };
};

/**
const close_market_calculator = options => {
  let spread_ask = 0;
  let spread_bid = 0;
  let spread_mid = 0;
  let market_price = 0;
  let trade_type = "debit";
  options.forEach((opt, index) => {
    if (opt.action === "buy") {
      spread_bid += opt.ask;
      spread_ask += opt.bid;
    } else if (opt.action === "sell") {
      spread_bid -= opt.bid;
      spread_ask -= opt.ask;
    }
  });
  spread_mid = (spread_ask + spread_bid) / 2;
  market_price = spread_mid >= 0 ? spread_ask : spread_bid;
  trade_type = market_price === spread_ask ? "debit" : "credit";
  return { spread_ask, spread_bid, spread_mid, market_price, trade_type };
};

*/

export default (side, options) => market_calculator(options);

/**
 * symbol(pin):"AAPL191220C00265000"
description(pin):"AAPL Dec 20 2019 $265.00 Call"
exch(pin):"Z"
type(pin):"option"
last(pin):5.3
change(pin):-0.15
volume(pin):244
open(pin):6
high(pin):6.17
low(pin):5.3
close(pin):null
bid(pin):5
ask(pin):5.1
underlying(pin):"AAPL"
strike(pin):265

change_percentage(pin):-2.76
average_volume(pin):0
last_volume(pin):30
trade_date(pin):1575988681857
prevclose(pin):5.45
week_52_high(pin):0
week_52_low(pin):0
bidsize(pin):93
bidexch(pin):"X"
bid_date(pin):"1575988730000"
asksize(pin):7
askexch(pin):"X"
ask_date(pin):"1575988731000"
open_interest(pin):19093
contract_size(pin):100
expiration_date(pin):"2019-12-20"
expiration_type(pin):"standard"
option_type(pin):"call"
root_symbol(pin):"AAPL"

 */
