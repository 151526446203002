import React, {  } from "react";
import { connect } from "react-redux";
import "./../../styles/css/Form.css";
import tradier_logo from "./../../assets/images/tradier_logo.png";
import { on_input_change, register } from "./../../actions/auth.action";

const Register = props => {
  //   useEffect(() => {
  //     if (props.auth.is_completed) {
  //       if (props.auth.is_tradier_linked) return props.history.push("/main");
  //       else return props.history.push("/connect_tradier");
  //     }
  //   }, [props.auth]);
  return (
    <>
      <div className="vldform vldauth">
        <h1>Register</h1>
        <div className="row">
          <div className="col-md-4">
            <input
              className="vldform__textbox"
              type="text"
              name="first_name"
              placeholder="First Name"
              value={props.auth.first_name}
              onChange={props.on_input_change}
            />
            <br />
            <span className="text-danger">
              <small>{props.error.first_name}</small>
            </span>
          </div>
          <div className="col-md-4">
            {" "}
            <input
              className="vldform__textbox"
              type="text"
              name="last_name"
              value={props.auth.last_name}
              placeholder="Last Name"
              onChange={props.on_input_change}
            />
            <span className="text-danger">
              <small>{props.error.last_name}</small>
            </span>
          </div>
          <div className="col-md-4">
            <input
              className="vldform__textbox"
              type="text"
              name="username"
              placeholder="Username"
              value={props.auth.username}
              onChange={props.on_input_change}
            />
            <span className="text-danger">
              <small>{props.error.username}</small>
            </span>
          </div>
          <div className="col-md-4">
            <input
              className="vldform__textbox"
              type="email"
              name="email"
              placeholder="Email"
              value={props.auth.email}
              onChange={props.on_input_change}
            />
            <span className="text-danger">
              <small>{props.error.email}</small>
            </span>
          </div>
          <div className="col-md-4">
            <select
              name="account_type"
              id=""
              className="vldform__textbox"
              placeholder="Account Type"
              value={props.auth.account_type}
              onChange={props.on_input_change}
            >
              <option value="paper">Paper (Demo Account)</option>
              <option value="live">Live Account</option>
            </select>
            <span className="text-danger">
              <small>{props.error.account_type}</small>
            </span>
          </div>
          <div className="col-md-4">
            <input
              className="vldform__textbox"
              type="password"
              name="password"
              id=""
              value={props.auth.password}
              placeholder="Password"
              onChange={props.on_input_change}
            />
            <span className="text-danger">
              <small>{props.error.password}</small>
            </span>
          </div>
        </div>
        <button
          className="vldform__button"
          onClick={() => props.register(props.history)}
        >
          Register
        </button>
        <div className="row justify-content-center mt-3">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <span className="vldform__signuplink mt-3" href="#">
              Or Register using
            </span>
            <a href="https://charthistoricaldriver.azurewebsites.net/api/auth/tradier">
              <img src={tradier_logo} className="mt-3" height="50px" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(state => ({ auth: state.auth, error: state.error }), {
  on_input_change,
  register
})(Register);
