import {csv_get} from '../config/middleware.links';
const axios = require("axios");
const { token } = require('../middleware/getToken');
let data ;
let OratsHistDataarray = [];
const oratsHistdata = () => {
    token().then( (token) => {      
		return axios({
			method: "get",
			url: csv_get,
			headers: { Authorization: "Bearer " + token }
		}).then(async( res ) =>{
            console.log("OratsHist symbols", res.data);
            let symbols = ["ZTS", "FB"]
            for(let i = 0; i <= res.data.length - 1; i++){      
        
    
                console.log("Enter in Loop", i, res.data[i].symbol)
              await axios({
                method: "get",
                url: 'https://api.orats.io/data/hist/dailies',
                params: { 
                    tickers: res.data[i].symbol
                },
                headers: { Authorization: 'a75baa0c-558d-4939-b03d-4759a7ace481' }
            }).then(async( res ) =>{        
                
                console.log("Hit : ", i)
                data = res.data.data;
                for (let i = 0; i <= data.length - 1; i++){
                    OratsHistDataarray.push({
                        _id : data[i].ticker+ "_" + data[i].tradeDate,
                        sb : data[i].ticker + "*",
                        dt : data[i].tradeDate,
                        adjC : data[i].clsPx,
                        adjH : data[i].hiPx,
                        adjL : data[i].loPx,
                        adjO : data[i].open,
                        adjV : data[i].stockVolume,
                        c : data[i].unadjClsPx,
                        h : data[i].unadjHiPx,
                        l : data[i].unadjLoPx,
                        o : data[i].unadjOpen,
                        v : data[i].unadjStockVolume,
                        updt : data[i].updatedAt
                    })
                }
console.log("orats data push : ", OratsHistDataarray)
            // for(let j = 0; j <= OratsHistDataarray.length - 1; j++)
            // {
                await axios({
                    method: "post",
                    //URL is coming from middleware config and points to the TVIAPI service.
                    url: 'http://localhost:7085/api/oratsStockHist',
                    data: OratsHistDataarray,
                    // headers: { Authorization: "Bearer " + token }
                }).then(( res ) =>{
                    console.log("res.data", res.data)
                    OratsHistDataarray = [];
                }).catch((e) => console.log("axios error"))
            
            // }
            
            console.log("data : ",data)
               
            
            }).catch((e) => console.log("axios error",e))     
            
            }

		}).catch((e) => console.log("axios error"))
		}).catch( (e) => console.log(e)) 

}

export{oratsHistdata}