/**
 * This module is dependent on the weeklyOptionsSymbolList API service - which is designed to get a list of all of the 
 * stock symbols that trade weeklies from our database .
 * 
 * @function getSymbolsFromBackend
 * @class Csv_Symbols
 */

import {csv_get} from '../config/middleware.links';
const axios = require("axios");
const { token } = require('../middleware/getToken');
window.symbolArray = [];
window.weeklies = [];
window.Forex = [];
window.Crypto = []
window.Comm = []
window.aLLsymbol = []; 
window.INDX = [];
window.Stock = [];
window.allsymbols = []
window.NYSEMKT = []
window.NYSEArca = []
window.NYSE = []
window.NASDAQOMX = []
window.NationalStockExchange = []
//#region Reference URLS
	// const apiURL = 'http://localhost:3030/csvsymbols/csv_get';
//#endregion Reference URLS
export default class Csv_symbols {
	getSymbolsFromBackend = function getSymbolsFromBackend() {

		//#region Getting the response without auth and token
	// 	 return await axios.get("http://localhost:3030/csvsymbols/csv_get")
	// .then(res => {
	// 	return res.data;
		
	// })
	// .catch(e => console.log("Error", e));
//#endregion Getting the response without auth and token

		//#region Getting the respnse with authentication/auth -Get request to get the list of symbols from weeklysymbolsoption list
		// token().then( (token) => {      
		// return 
		let token = localStorage.getItem("jwtToken");
		axios({
			method: "get",
			url: csv_get,
			headers: { Authorization: token }
		}).then(( res ) =>{
				window.symbolArray = res.data;
				window.symbolArray.forEach((s) => {
					window.symbolArray.push({
					  symbol: s.symbol + "*",
					  full_name : s.symbol + "*",
					  description: s.description,
					  type: "stock",
					  exchange: "",
					});
					window.parent.weeklies.push({
						symbol: s.symbol + "*",
						full_name : s.symbol + "*",
						description: s.description,
						type: "stock",
						exchange: "Weeklies",
					  });
					  
				  });			 
				
				
				

				//   console.log("window.stock symbols csv file : ", window.parent.Stock)
			return res.data
		}).catch((e) => console.log("axios error"))

		// }).catch( (e) => console.log(e)) 
//#endregion Getting the respnse with authentication/auth -Get request to get the list of symbols from weeklysymbolsoption list		
	
	}
}