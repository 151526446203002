//#region Import required modules
  import API from '../algoSignalsAPI/Api'
  import TimeSpan from '../TimeSpan/TimeSpan'
  import GetMarks from '../PLot_Marks/get_Marks';
  import GetTimeScaleMarks from "../PLot_Marks/get_timescale_Marks";
  import refreshMarks from '../library_Utils/refreshMarks';
  import { getTradePnL } from './Middleware';
  import createTextOnChart from '../library_Utils/createTextOnChart';
//#endregion Import required modules

//#region Declare/Initialize variables
  var dateFormat = require('dateformat');
  let index = 0;
  let api = new API();
  let getmarks = new GetMarks();
  let timescalemarks = new GetTimeScaleMarks();
  const SignalSide = {
    SignalDirectionFilter : {
        Long: "Long",
        Short:"Short",
        Both:"Both"
    }
  }
  let SignalDirection = SignalSide.SignalDirectionFilter.Both;
//#endregion

//#region AlgoSignals Api 
let mktValueCap ;
let netGainVal ;
let avgGainVal ;
let avgWinVal ;
let avgLossVal ;
let wlRatioVal ;
let winRateVal ;
let totalSignalsVal;
let maxGainVal;
let minGainVal;
let avgMFEVal;
let avgMAEVal;
let aefeRatioVal;
let totalDurationVal;
let totalDurationInHoursVal;
let avgDuratioVal;
let averageDurationInHoursVal;
let shortestDurationVal;
let shortestDurationInHoursVal;
let longestDurationVal;
let longestDurationInHoursVal;
let periodAlgosignals;
let timeAlgosignals;
let tickerAlgosignals;
//#endregion AlgoSignals Api 

/**
 * @param {*} k 
 * @param {*} l 
 * @param {*} context 
 * @function iexitShort
 */

const iexitShort = (k, l, context) =>
{
  //#region Plot chart objects and flip switches
    context.shortSig = false;
    context.skipShtEntry = true;
    // console.log("IExitShort");

    //{context.yOffset = context.Data[0].High + context.txtMult1* context.symbol.script.symbols[0].minTick; yPixOffset = 15; yPixOffset2 = 30;} 
    context.yOffset = context.Data[k].Low - context.txtMult1 * context.symbol.script.symbols[0].minTick; context.yPixOffset = -15; context.yPixOffset2 = -30; 

    //Must reference the oringal "Long" tags here to overwrite with with the New plots
    // Draw.Diamond(this, "LongExt" + l, false, k, yOffset, Brushes.Green);
    // Draw.Text(this, "longSigExt" + l, false, Close[k].ToString(fmtString), k, yOffset, yPixOffset, StatColor, PrintFont, TextAlignment.Center, Brushes.Transparent, Brushes.Transparent, 0);
    // Draw.Text(this, "TradeResult" + l, false, Math.Round((getTradePnL(Close[k], sEntryPrice) * -1), 2).ToString(), k, yOffset, yPixOffset2, StatColor, PrintFont, TextAlignment.Center, Brushes.Transparent, Brushes.Transparent, 0);

    // window.parent.createDaimondOnChart(context.Data[0].Time,context.Data[0].price, "LongExt" + context.CurrentBar);
    //window.parent.createTextOnChart(context.Data[0].Time,context.Data[0].Close, "longSigExt" + context.CurrentBar);
    //console.log("context.Data[0].Close iExitShort :",context.Data[0].Close)
    //   window.parent.createCircleOnChartForAlgosignals( context.Data[0].Time,context.Data[0].Close, "#FF0000");
    //  window.parent.createTextOnChartForAlgosignals(context.Data[k].Time,context.Data[k].price, context.Data[k].Close);
    //   window.parent.createTextOnChartForAlgosignals(context.Data[0].Time,context.Data[0].price, context.yOffset);
    //window.parent.createCircleOnChartForAlgosignals(context.Data[0].Time,context.Data[k].Close, "#9acd32");

    // window.parent.createTextOnChartForAlgosignals(context.Data[0].Time,Math.round((getTradePnL(context.Data[0].Close, context.sEntryPrice, context)) * - 1), context.Data[0].Close);
    //window.parent.createTextOnChart(context.Data[0].Time,Math.round((getTradePnL(context.Data[0].Close, context.sEntryPrice, context) * -1)), context.Data[0].Close);
    // window.parent.createTextOnChartForAlgosignals(context.Data[0].Time,Math.round((getTradePnL(context.Data[k].Close, context.sEntryPrice, context)) * - 1), context.yOffset);
  //#endregion Plot chart objects and flip switches

  //#region Add the duration of each trade to the "context.tradeDuration" List object, then get the average of all of the stored List values. 
    context.closeTrade = context.Data[k].Time;
    context.duration = context.closeTrade - context.openTrade;
    context.duration = context.duration / (1000 * 3600 * 24);//Calculated in days
    context.totalDuration = context.closeTrade - context.firstTrade;
    context.totalDuration = context.totalDuration / (1000 * 3600 * 24);//Calculated in days
    context.tradeDuration.push(context.duration);
    // context.avgDuration = TimeSpan.FromDays((context.tradeDuration.reduce((a, b) => a + b) / context.tradeDuration.length));
    // context.longestDuration = TimeSpan.FromDays(context.tradeDuration.reduce((a, b) => Math.max(a, b)));
    // context.shortestDuration = TimeSpan.FromDays(context.tradeDuration.reduce((a, b) => Math.min(a, b)));
    var max = TimeSpan.FromDays(context.tradeDuration.reduce((a, b) => Math.max(a, b)));
    var min = TimeSpan.FromDays(context.tradeDuration.reduce((a, b) => Math.min(a, b)));
    var average = TimeSpan.FromDays(context.tradeDuration.reduce((a, b) => a + b) / context.tradeDuration.length);
    context.avgDuration = average.days();
    context.longestDuration = max.days();
    context.shortestDuration = min.days();
  //#endregion Add the duration of each trade to the "context.tradeDuration" List object, then get the average of all of the stored List values. 

  //#region Calculate the total number of signals generated and determine the winRate.
    context.totalSignals += 1;
    context.tradePnL = getTradePnL(context.Data[k].Close, context.sEntryPrice, context) * -1;

    if (context.tradePnL <= 0) { context.lossCount += 1; }
    else if (context.tradePnL > 0) { context.winCount += 1;   }
    context.winRate = context.winCount / context.totalSignals;
  //#endregion Calculate the total number of signals generated and determine the winRate.

  //#region Performance Calculations
    //Calculate MAE & MFE 
    context.maxMAE = 0;
    context.maxMFE = 0;

    // for (int j = 0; j <= (l - (sigBar)); j++) //"j" is a Bars ago index from teh current bar, so from Exit Bar going back to the Sig Bar to evaluate MAE / MFE 
    //We have to make J = k or the Bars Ago Index, because Highs only accepts a Bars Ago Index and not an Absolute Index
    // { 
    // //The following "j" reference won't work because j is a Bars Ago Index in real time processing ... for it to work below, we have to use the absolute bar index to reference the correct sequence of bars. 
    // if(Highs[0][j] > lEntryPrice && getTradePnL(Highs[0][j], lEntryPrice) > maxMFE) {maxMFE = getTradePnL(Highs[0][j], lEntryPrice);}
    // if(Lows[0][j] < lEntryPrice && getTradePnL(lEntryPrice, Lows[0][j]) > maxMAE) {maxMAE = getTradePnL(lEntryPrice, Lows[0][j]);} 

    // } 

    for (let j = context.sigBarIdx; j >= k; j--)
    {        
        if (context.Data[j].High > context.sEntryPrice && getTradePnL(context.Data[j].High, context.sEntryPrice, context) > context.maxMAE)
        { context.maxMAE = getTradePnL(context.Data[j].High, context.sEntryPrice, context); }
        if (context.Data[j].Low < context.sEntryPrice && getTradePnL(context.sEntryPrice, context.Data[j].Low, context) > context.maxMFE)
        { context.maxMFE = getTradePnL(context.sEntryPrice, context.Data[j].Low, context); }
    }

    context.maeList.push(context.maxMAE);
    context.mfeList.push(context.maxMFE);

    //Calcualte total netGain
    context.netGain = context.netGain + getTradePnL(context.Data[k].Close, context.sEntryPrice, context)* -1;
    //console.log("NetGain", context.netGain);
    context.tradeGainTally.push(context.netGain); //this is not really needed in the inverted exits

    //if (Instrument.MasterInstrument.InstrumentType != InstrumentType.Forex)
    // context.mktValCap = Math.round((context.netGain / context.Data[0].Close) * 100, 1);
    if (context.symbol.info.type !== "Forex")
    context.mktValCap = (context.netGain / context.Data[k].Close) * 100;
    //console.log("IExit Short", context.mktValCap);
    context.mktValCapList.push(context.mktValCap);

    //Add each trade result to the tradeGain List object & calculate avgGain, minGain and maxGain from the tradeGain List object 
    context.tradeGain.push((getTradePnL(context.Data[k].Close, context.sEntryPrice, context) * -1));
    // context.avgGain = Math.round((context.tradeGain.reduce((a, b) => a + b) / context.tradeDuration.length),2);
    // context.minGain = Math.round(context.tradeGain.reduce((a, b) => Math.min(a, b)),2);
    // context.maxGain = Math.round(context.tradeGain.reduce((a, b) => Math.max(a, b)),2);

    // context.avgMAE = Math.round((context.maeList.reduce((a, b) => a + b) / context.tradeDuration.length),2);
    // context.avgMFE = Math.round((context.context.mfeList.reduce((a, b) => a + b) / context.tradeDuration.length),2);
    // context.aefeRatio = Math.round(Math.abs(context.avgMFE / context.avgMAE),1);
    context.avgGain = (context.tradeGain.reduce((a, b) => a + b) / context.tradeDuration.length);
    context.minGain = context.tradeGain.reduce((a, b) => Math.min(a, b));
    context.maxGain = context.tradeGain.reduce((a, b) => Math.max(a, b));

    context.avgMAE = (context.maeList.reduce((a, b) => a + b) / context.maeList.length);
    context.avgMFE = (context.mfeList.reduce((a, b) => a + b) / context.mfeList.length);
    context.aefeRatio = Math.abs(context.avgMFE / context.avgMAE);


    //Get the avgWinner & avgLoser from the context.tradeGain List
    context.totWin = 0;
    context.totLoss = 0;
    for (let j = 0; j < context.tradeGain.length; j++)
    {
        if (context.tradeGain[j] > 0) { context.totWin += context.tradeGain[j]; }
        else if (context.tradeGain[j] <= 0) { context.totLoss += context.tradeGain[j]; }
    }
    // context.avgWin = Math.round(context.totWin / context.winCount,2);
    // context.avgLoss = Math.round(context.totLoss / context.lossCount,2);
    // context.wlRatio = Math.round(Math.abs(context.avgWin / context.avgLoss),1);
    context.avgWin = context.totWin / context.winCount;
    context.avgLoss = context.totLoss / context.lossCount;
    context.wlRatio = Math.abs(context.avgWin / context.avgLoss);

    //signalType = "Long";

    //Reset lEntryPrice for next Signal
    context.sEntryPrice = 0;
  //#endregion Performance Calculations
  
  //#region For an Api to save values in mongoDB
  
    //#region Variables
    //console.log("Market Value Captured: " + context.mktValCap + "% (Benchmark)");
    mktValueCap = context.mktValCap;
    //console.log("Net Gain : " + context.netGain ," | Avg Gain/Signal: " + context.avgGain);
    netGainVal = context.netGain;
    avgGainVal = context.avgGain;
    //console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
    avgWinVal = context.avgWin;
    avgLossVal = context.avgLoss;
    wlRatioVal = context.wlRatio;
    // console.log("Win Rate : " + context.winRate + "%", "/" + context.totalSignals + " Signals");
    winRateVal = context.winRate;
    totalSignalsVal = context.totalSignals;
    //console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
    maxGainVal = context.maxGain;
    minGainVal = context.minGain;
    //console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio.toFixed(1) + ":1" );
    avgMFEVal = context.avgMFE;
    avgMAEVal = context.avgMAE;
    aefeRatioVal = context.aefeRatio;
    var d1 = (context.avgDuration) * (60*60*24*1000);
    var d2 = (context.longestDuration) * (60*60*24*1000);
    var d3 = (context.shortestDuration)* (60*60*24*1000);
    var d4 = (context.totalDuration) * (60*60*24*1000);
    var new_d1 = new Date(d1);
    var new_d2 = new Date(d2);
    var new_d3 = new Date(d3);
    var new_d4 = new Date(d4);
    new_d1 = TimeSpan.FromDays(new_d1);
    new_d2 = TimeSpan.FromDays(new_d2);
    new_d3 = TimeSpan.FromDays(new_d3);
    new_d4 = TimeSpan.FromDays(new_d4);
    var averageDurationInHours  = new_d1//d1;
    var longestDurationInHours = new_d2;
    var shortestDurationInHours = new_d3;
    var totalDurationInHours = new_d4;

    totalDurationVal = Math.round(context.totalDuration) ;
    totalDurationInHoursVal = totalDurationInHours.hours();
    context.totalDurationInHoursVal = totalDurationInHours.hours();
    avgDuratioVal = Math.round(context.avgDuration) ;
    averageDurationInHoursVal = averageDurationInHours.hours();
    context.averageDurationInHoursVal = averageDurationInHours.hours();
    shortestDurationVal = Math.round(context.shortestDuration) ;
    shortestDurationInHoursVal = shortestDurationInHours.hours();
    context.shortestDurationInHoursVal = shortestDurationInHours.hours();
    longestDurationVal =  Math.round(context.longestDuration);
    longestDurationInHoursVal = longestDurationInHours.hours();
    context.longestDurationInHoursVal = longestDurationInHours.hours();
    periodAlgosignals = context.symbol.period;
    timeAlgosignals = context.Data[k].Time;
    tickerAlgosignals = context.symbol.ticker;

    if (isNaN(avgLossVal)) {
      avgLossVal = 0;
    }
    if (isNaN(avgWinVal)) {
      avgWinVal = 0;
    }
    if (isNaN(wlRatioVal)) {
      wlRatioVal = 0;
    }

    //Will convert a from any "falsey" value to 0
    totalDurationVal = totalDurationVal || 0;
    avgDuratioVal = avgDuratioVal || 0;
    shortestDurationVal = shortestDurationVal || 0;
    longestDurationVal = longestDurationVal || 0;
    //aefeRatioVal = aefeRatioVal || 0;
    aefeRatioVal = aefeRatioVal ? aefeRatioVal : 0;
    if(isNaN(aefeRatioVal) ){
      aefeRatioVal = 0;
    }

    //console.log("wlRatioVal ExitLong :",wlRatioVal);
    let UseCoreLogic = context.UseCoreLogic;
    let UseStructureLogic = context.UseStructureLogic;
    let UseHighLow = context.UseHighLow;
    let RevOnExit = context.RevOnExit;
    let MktValCapThresh  = context.MktValCapThresh;
    let PiPThresh  = context.PiPThresh;
    let TickThresh  = context.TickThresh;
    let ThreshMult = context.ThreshMult;
    let AvgBarsPerSignal = context.AvgBarsPerSignal
    let Fast = 12;
    let Slow = 26;
    let Smooth = 9;
    let signalPrice = context.Data[k].Close;
    let signal = 'Long';
    const signalSide = SignalSide;
  //#endregion Variables
    
    let name = "Algosignals Cognitive";
    if(UseCoreLogic === true & UseStructureLogic === true & UseHighLow === true & RevOnExit === true &Fast === 12 & Slow === 26 & Smooth === 9 & MktValCapThresh === -25 & PiPThresh === -500 & TickThresh === -250 & ThreshMult === 0.8 & AvgBarsPerSignal ===53 & periodAlgosignals === "1D") {
    
    // if(window.parent.DBsignal === true)
    //   api.apiAlgoSignals(name,mktValueCap,netGainVal,avgGainVal,avgWinVal,avgLossVal,wlRatioVal,winRateVal,totalSignalsVal,maxGainVal,minGainVal,avgMFEVal,avgMAEVal,aefeRatioVal,totalDurationVal,totalDurationInHoursVal,avgDuratioVal,averageDurationInHoursVal,shortestDurationVal,shortestDurationInHoursVal,longestDurationVal,longestDurationInHoursVal,tickerAlgosignals,periodAlgosignals,timeAlgosignals,UseCoreLogic,UseStructureLogic,UseHighLow,RevOnExit,Fast,Slow,Smooth,MktValCapThresh,PiPThresh,TickThresh,ThreshMult,AvgBarsPerSignal,context); 
    
    }

  //#endregion For an Api to save values in mongoDB 

  //#region PLotting Callouts on chart

    //#region An array *Arr* is for plotting the getmarks(that is plot above the bars) on the chart.
    var Arr = [   
      {
        id:   context.Data[k].Time / 1000 + context.Data[k].Close,
        time: context.Data[k].Time / 1000,
        color: 'green',
        text: 'Buy',//IEnter Long
        label: 'B',
        labelFontColor: 'blue',
        border: 'blue',
        minSize: 20,        
      },
    ]
    //#endregion An array *Arr* is for plotting the getmarks(that is plot above the bars) on the chart.

    //#region An array *Timescale* is for plotting the timescalemarks(that plots below the bar. We can add info which we wanna display on the chart).
    if( context.symbol.info.type === "Forex" && context.symbol.script.symbols[0].minTick == 0.0001){
      var TimeScale = [
        {
          id:   context.Data[k].Time / 1000 + context.Data[k].Close,
          time:context.Data[k].Time / 1000,
          color: 'green',
          label: 'cEL',
          // minSize: 5,
          background: '#00ff00',
          tooltip:[
            "Exit Short / Enter Long "+ "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" 
            + new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear()  + "<br>" + "<br>"  
            +  "Net Gain: " + context.netGain.toFixed(4) + "<br>" + "WinRate: " + (context.winRate * 100).toFixed(0) + "%" + " / " + context.totalSignals 
            + " Signals" + "<br>" + "Mkt Val Captured: " + context.mktValCap.toFixed(4) + "%",
        "<br>",
        "Signal price: " + context.Data[k].Close.toFixed(4),
        "Last Trade P&L: " + context.tradePnL.toFixed(4),      
      
        "<br>",
        "Avg Gain/Signal: " + context.avgGain.toFixed(4),
        "Avg Win: " + context.avgWin.toFixed(2) , "Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1",
        "Max Winner: " + context.maxGain.toFixed(4),
        "Max Loser: " + context.minGain.toFixed(4),
        "<br>",
        "Avg MFE: " + context.avgMFE.toFixed(4) ,
        "Avg MAE: " + context.avgMAE.toFixed(4) , 
        "Avg MFE / Avg MAE -> " + context.aefeRatio.toFixed(4) + ":1",
        "<br>",
        "Tot Dur: " + Math.round(context.totalDuration) + "d " +  totalDurationInHours.hours() + "h",
        "Avg Dur: " + Math.round(context.avgDuration ) +"d " +  averageDurationInHours.hours() + "h",
        "Min Dur: " + Math.round(context.shortestDuration ) + "d " +  shortestDurationInHours.hours() + "h",
        "Max Dur: " + Math.round(context.longestDuration ) + "d " +  longestDurationInHours.hours() + "h"          

          ]
        }
    ]
    }
    else if(context.symbol.info.type === "Forex" && context.symbol.script.symbols[0].minTick == 0.01){
      var TimeScale = [
        {
          id:   context.Data[k].Time / 1000 + context.Data[k].Close,
          time:context.Data[k].Time / 1000,
          color: 'green',
          label: 'cEL',
          // minSize: 5,
          background: '#00ff00',
          tooltip:[
            "Exit Short / Enter Long "+ "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" 
            + new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear() + "<br>" + "<br>"  
            +  "Net Gain: " + context.netGain.toFixed(2) + "<br>" + "WinRate: " + (context.winRate * 100).toFixed(0) + "%" + " / " + context.totalSignals 
            + " Signals" + "<br>" + "Mkt Val Captured: " + context.mktValCap.toFixed(2) + "%",
        "<br>",
        "Signal price: " + context.Data[k].Close.toFixed(2),
        "Last Trade P&L: " + context.tradePnL.toFixed(2),      
      
        "<br>",
        "Avg Gain/Signal: " + context.avgGain.toFixed(2),
        "Avg Win: " + context.avgWin.toFixed(2) , "Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1",
        "Max Winner: " + context.maxGain.toFixed(2),
        "Max Loser: " + context.minGain.toFixed(2),
        "<br>",
        "Avg MFE: " + context.avgMFE.toFixed(2) ,
        "Avg MAE: " + context.avgMAE.toFixed(2) , 
        "Avg MFE / Avg MAE -> " + context.aefeRatio.toFixed(1) + ":1",
        "<br>",
        "Tot Dur: " + Math.round(context.totalDuration) + "d " +  totalDurationInHours.hours() + "h",
        "Avg Dur: " + Math.round(context.avgDuration ) +"d " +  averageDurationInHours.hours() + "h",
        "Min Dur: " + Math.round(context.shortestDuration ) + "d " +  shortestDurationInHours.hours() + "h",
        "Max Dur: " + Math.round(context.longestDuration ) + "d " +  longestDurationInHours.hours() + "h"          

          ]
        }
    ]
    }
    else if(context.symbol.info.type === "crypto" && context.symbol.script.symbols[0].minTick == 0.0001){
      var TimeScale = [
        {
          id:   context.Data[k].Time / 1000 + context.Data[k].Close,
          time:context.Data[k].Time / 1000,
          color: 'green',
          label: 'cEL',
          // minSize: 5,
          background: '#00ff00',
          tooltip:[
            "Exit Short / Enter Long "+ "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" 
            + new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear()  + "<br>" + "<br>"  
            +  "Net Gain: " + context.netGain.toFixed(4) + "<br>" + "WinRate: " + (context.winRate * 100).toFixed(0) + "%" + " / " + context.totalSignals 
            + " Signals" + "<br>" + "Mkt Val Captured: " + context.mktValCap.toFixed(4) + "%",
        "<br>",
        "Signal price: " + context.Data[k].Close.toFixed(4),
        "Last Trade P&L: " + context.tradePnL.toFixed(4),      
      
        "<br>",
        "Avg Gain/Signal: " + context.avgGain.toFixed(4),
        "Avg Win: " + context.avgWin.toFixed(2) , "Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1",
        "Max Winner: " + context.maxGain.toFixed(4),
        "Max Loser: " + context.minGain.toFixed(4),
        "<br>",
        "Avg MFE: " + context.avgMFE.toFixed(4) ,
        "Avg MAE: " + context.avgMAE.toFixed(4) , 
        "Avg MFE / Avg MAE -> " + context.aefeRatio.toFixed(4) + ":1",
        "<br>",
        "Tot Dur: " + Math.round(context.totalDuration) + "d " +  totalDurationInHours.hours() + "h",
        "Avg Dur: " + Math.round(context.avgDuration ) +"d " +  averageDurationInHours.hours() + "h",
        "Min Dur: " + Math.round(context.shortestDuration ) + "d " +  shortestDurationInHours.hours() + "h",
        "Max Dur: " + Math.round(context.longestDuration ) + "d " +  longestDurationInHours.hours() + "h"          

          ]
        }
    ]
    }
    else{
      var TimeScale = [
        {
          id:   context.Data[k].Time / 1000 + context.Data[k].Close,
          time:context.Data[k].Time / 1000,
          color: 'green',
          label: 'cEL',
          // minSize: 5,
          background: '#00ff00',
          tooltip:[
            "Exit Short / Enter Long "+ "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" 
            + new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear() + " 4pm ET" + "<br>" + "<br>"  
            +  "Net Gain: " + context.netGain.toFixed(2) + "<br>" + "WinRate: " + (context.winRate * 100).toFixed(0) + "%" + " / " + context.totalSignals 
            + " Signals" + "<br>" + "Mkt Val Captured: " + context.mktValCap.toFixed(2) + "%",
        "<br>",
        "Signal price: " + context.Data[k].Close.toFixed(2),
        "Last Trade P&L: " + context.tradePnL.toFixed(2),      
      
        "<br>",
        "Avg Gain/Signal: " + context.avgGain.toFixed(2),
        "Avg Win: " + context.avgWin.toFixed(2) , "Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1",
        "Max Winner: " + context.maxGain.toFixed(2),
        "Max Loser: " + context.minGain.toFixed(2),
        "<br>",
        "Avg MFE: " + context.avgMFE.toFixed(2) ,
        "Avg MAE: " + context.avgMAE.toFixed(2) , 
        "Avg MFE / Avg MAE -> " + context.aefeRatio.toFixed(1) + ":1",
        "<br>",
        "Tot Dur: " + Math.round(context.totalDuration) + "d " +  totalDurationInHours.hours() + "h",
        "Avg Dur: " + Math.round(context.avgDuration ) +"d " +  averageDurationInHours.hours() + "h",
        "Min Dur: " + Math.round(context.shortestDuration ) + "d " +  shortestDurationInHours.hours() + "h",
        "Max Dur: " + Math.round(context.longestDuration ) + "d " +  longestDurationInHours.hours() + "h"          

          ]
        }
    ]
    }
    
    //#endregion An array *Timescale* is for plotting the timescalemarks(that plots below the bar. We can add info which we wanna display on the chart).

    //#region refresh the callouts -> getmarks and timescalemarks.
    refreshMarks(window.parent.tvWidget);
    //#endregion refresh the callouts -> getmarks and timescalemarks.

    //#region Handle Split Exits
      if(context.splitExit) {
        var Arr = [   
            {
              id:   context.Data[k].Time / 1000 + context.Data[k].Close,
              time: context.Data[k].Time / 1000,
              color: 'green',
              text: 'Buy',
              label: 'B',
              labelFontColor: 'blue',
              border: 'blue',
              minSize: 20,            
            },    
        ]
        var TimeScale = [
            {
              id: context.Data[k].Time / 1000 + context.Data[k].Close,
              time: context.Data[k].Time  / 1000,
              color: 'green',
              label: 'cEL',
              // minSize: 5,
              background: '#00ff00',
              tooltip:[
                "Exit Short / Split Exit "+ "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" 
                + new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear() + " 4pm ET" + "<br>" + "<br>"  
                +  "Net Gain: " + context.netGain.toFixed(2) + "<br>" + "WinRate: " + (context.winRate * 100).toFixed(0) + "%" + " / " + context.totalSignals 
                + " Signals" + "<br>" + "Mkt Val Captured: " + context.mktValCap.toFixed(2) + "%",
            "<br>",
            "Signal price: " + context.Data[k].Close.toFixed(2),
            "Last Trade P&L: " + context.tradePnL.toFixed(2),      
          
            "<br>",
            "Avg Gain/Signal: " + context.avgGain.toFixed(2),
            "Avg Win: " + context.avgWin.toFixed(2) , "Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1",
            "Max Winner: " + context.maxGain.toFixed(2),
            "Max Loser: " + context.minGain.toFixed(2),
            "<br>",
            "Avg MFE: " + context.avgMFE.toFixed(2) ,
            "Avg MAE: " + context.avgMAE.toFixed(2) , 
            "Avg MFE / Avg MAE -> " + context.aefeRatio.toFixed(1) + ":1",
            "<br>",
            "Tot Dur: " + Math.round(context.totalDuration) + "d " +  totalDurationInHours.hours() + "h",
            "Avg Dur: " + Math.round(context.avgDuration ) +"d " +  averageDurationInHours.hours() + "h",
            "Min Dur: " + Math.round(context.shortestDuration ) + "d " +  shortestDurationInHours.hours() + "h",
            "Max Dur: " + Math.round(context.longestDuration ) + "d " +  longestDurationInHours.hours() + "h"          

              ],
            }
        ]    
        context.SplitExitPeformaceCal = {
          mktValCap : context.mktValCap.toFixed(2),
          netGain : context.netGain.toFixed(2),
          avgGain : context.avgGain.toFixed(2),
          avgWin : context.avgWin.toFixed(2),
          avgLoss : context.avgLoss.toFixed(2),
          wlRatio : context.wlRatio.toFixed(2),
          winRate : (context.winRate * 100).toFixed(0),
          totalSignals : context.totalSignals,
          maxGain : context.maxGain.toFixed(2),
          minGain : context.minGain.toFixed(2),
          avgMFE : context.avgMFE.toFixed(2),
          avgMAE : context.avgMAE.toFixed(2),
          aefeRatio : context.aefeRatio.toFixed(1),
          totalDuration : Math.round(context.totalDuration),
          totalDurationInHours : context.totalDurationInHoursVal,
          avgDuratio : Math.round(context.avgDuration ),
          averageDurationInHours : context.averageDurationInHoursVal,
          shortestDuration : Math.round(context.shortestDuration ),
          shortestDurationInHours : context.shortestDurationInHoursVal,
          longestDuration : Math.round(context.longestDuration ),
          longestDurationInHours : context.longestDurationInHoursVal,
          time: context.Data[k].Time,
          signalPrice : context.Data[k].Close,
          tradePnL : context.tradePnL.toFixed(2),
          name : name,
          signal : signal,
          tickerAlgosignals : tickerAlgosignals,
          periodAlgosignals : periodAlgosignals,
          timeAlgosignals :context.Data[k].Time,
          UseCoreLogic : UseCoreLogic,
          UseStructureLogic : UseStructureLogic,
          UseHighLow : UseHighLow,
          RevOnExit : RevOnExit,
          Fast : Fast,
          Slow : Slow,
          Smooth : Smooth,
          MktValCapThresh : MktValCapThresh,
          PiPThresh : PiPThresh,
          TickThresh : TickThresh,
          ThreshMult : ThreshMult,
          AvgBarsPerSignal : AvgBarsPerSignal
        }
        if(window.parent.DBsignal === false) {

          getmarks.createMarksOnChart(Arr);
          timescalemarks.createTimeScaleMarksOnChart(TimeScale);
          // createTextOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].price, context.Data[0].Close.toFixed(2));
        
        }

      
        if (window.parent.DBsignal === true) {//true
          context.PerformaceCalcCognative[context.PerformaceCalcCognativeIndex] = context.SplitExitPeformaceCal;
          context.PerformaceCalcCognativeIndex += 1;
        }

        //#region Script for automated testing
        if (window.parent.DBsignal === false) {
          context.automatedTesting[context.automatedTestingIndex] = context.SplitExitPeformaceCal;
          context.automatedTestingIndex += 1;
        }
        //#endregion Script for automated testing
        context.splitExit = false;
        context.firstEntryAfterSplit = true;
      }
    //#endregion Handle Split Exits

    else {
      if(context.totalSignals === 1) {
        if(window.parent.DBsignal === false) {
        getmarks.createMarksOnChart(Arr);
        // createTextOnChart(window.parent.tvWidget,context.Data[k].Time,context.Data[k].price, context.Data[k].Close.toFixed(2));
        }
      }
      if(context.totalSignals !== 1) {
        if(window.parent.DBsignal === false) {

          getmarks.createMarksOnChart(Arr);
          timescalemarks.createTimeScaleMarksOnChart(TimeScale);
          // createTextOnChart(window.parent.tvWidget,context.Data[k].Time,context.Data[k].price, context.Data[k].Close.toFixed(2));
        }

        //#region Performance Calcuations storage Object and Array for DB version
        let performanceCalculations = {
          name : name,
          signal : signal,
          signalPrice : signalPrice,
          mktValCap : mktValueCap,
          netGain : netGainVal,
          avgGain : avgGainVal,
          avgWin : avgWinVal,
          avgLoss : avgLossVal,
          wlRatio : wlRatioVal,
          winRate : winRateVal,
          totalSignals : totalSignalsVal,
          maxGain : maxGainVal,
          minGain : minGainVal,
          avgMFE : avgMFEVal,
          avgMAE : avgMAEVal,
          aefeRatio : aefeRatioVal,
          totalDuration : totalDurationVal,
          totalDurationInHours : totalDurationInHoursVal,
          avgDuratio : avgDuratioVal,
          averageDurationInHours : averageDurationInHoursVal,
          shortestDuration : shortestDurationVal,
          shortestDurationInHours : shortestDurationInHoursVal, 
          longestDuration : longestDurationVal,
          longestDurationInHours : longestDurationInHoursVal,
          tickerAlgosignals : tickerAlgosignals,
          periodAlgosignals : periodAlgosignals,
          timeAlgosignals :timeAlgosignals,
          UseCoreLogic : UseCoreLogic,
          UseStructureLogic : UseStructureLogic,
          UseHighLow : UseHighLow,
          RevOnExit : RevOnExit,
          Fast : Fast,
          Slow : Slow,
          Smooth : Smooth,
          MktValCapThresh : MktValCapThresh,
          PiPThresh : PiPThresh,
          TickThresh : TickThresh,
          ThreshMult : ThreshMult,
          AvgBarsPerSignal : AvgBarsPerSignal
        }
        if (window.parent.DBsignal === true) {//true
          context.PerformaceCalcCognative[context.PerformaceCalcCognativeIndex] = performanceCalculations;
          context.PerformaceCalcCognativeIndex += 1;
        }
        //#endregion Performance Calcuations storage Object and Array for DB version

        //#region Script for automated testing
        if (window.parent.DBsignal === false) {
          context.automatedTesting[context.automatedTestingIndex] = performanceCalculations;
          context.automatedTestingIndex += 1;
        }
        //#endregion Script for automated testing


      }
    }
  
  //#endregion PLotting Callouts on chart

 


  //#region localstorage object for storing the performance calculation to the localstorage.
  let localstorageObject =  {
    id:context.Data[k].Time / 1000 + context.Data[k].Close,
    symbol : context.symbol.ticker,
    resolution:context.symbol.period,
    mktValCap: mktValueCap,
    netGain: netGainVal,
    avgGain: avgGainVal,
    avgWin: avgWinVal,
    avgLoss: avgLossVal,
    wlRatio: wlRatioVal,
    winRate: winRateVal,
    totalSignals: totalSignalsVal,
    maxGain: maxGainVal,
    minGain: minGainVal,
    avgMFE: avgMFEVal,
    avgMAE: avgMAEVal,
    aefeRatio: aefeRatioVal,
    totalDuration: totalDurationVal,
    totalDurationInHours: totalDurationInHoursVal,
    avgDuratio: avgDuratioVal,
    averageDurationInHours: averageDurationInHoursVal,
    shortestDuration: shortestDurationVal,
    shortestDurationInHours: shortestDurationInHoursVal,
    longestDuration: longestDurationVal,
    longestDurationInHours: longestDurationInHoursVal  
  }
  //#endregion localstorage object for storing the performance calculation to the localstorage.

  //#region Stores the performance calculations in local storage only for the non DB version of algosignals.
  if (window.parent.DBsignal === false) {
    if(!window.localStorage.getItem('Performance_Calculations') || window.localStorage.getItem('Performance_Calculations') != null) {
      
      context.Performance_Calculations_Array[context.Performance_Calculations_Index] = localstorageObject;
      localStorage.setItem('Performance_Calculations',JSON.stringify(context.Performance_Calculations_Array));
      context.Performance_Calculations_Index += 1;
    
    }
  }
  //#endregion Stores the performance calculations in local storage only for the non DB version of algosignals.

}
export { iexitShort };
