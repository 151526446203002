
/**
 * Getmarks Plot the callouts on the chart above the bars.
 * @class GetMarks
 * @function createMarksOnChart
 */
export default class GetMarks {
    createMarksOnChart = function(Arr) {
        //#region Function responsible to create the callouts above the Bars
            window.parent.storeCallBackFunction(Arr);  
        //#endregion Function responsible to create the callouts above the Bars
       
    }           
} 