import {postduplicates} from '../config/middleware.links';
const axios = require("axios");
const { token } = require('../middleware/getToken');

/** Finding the duplicate historical records in **Stockquotes collection
 * Purpose : Create an automated script that find the duplicates records from a *Stock quotes collection * through the list that we have in 
 * weekltsymbols option list.
 * Logic : Loop through the list of symbols and every time we loop through them we also need to hit the end point from where we are getting
 * the historical data against the symbols. If there is no response then we do nothing there is catch block that is going to catch the *no response/no data* 
 * but the if the resonse is empty/undefined/nodata we are also handling it through a check. Now if the response is there then we have a script
 * that creates a new array and we have an endpoint through which we are pushing the symbol name and data which is duplicate in the stockquotes collection
 * the purpose of this is to find the symbols which have duplicate records*/ 

const symbolduplicateRecords = () => {
    
    //#region Nested for loop to find the duplicate records
    for(let i = 0; i <= window.symbolArray.length - 1; i++) {
        
        axios.get("http://localhost:5001/api/chart/getduplicaterecords?symbol="+window.symbolArray[i].symbol+"*"+"&resolution=1D")
        .then(res => {
            
            //#region check if the response is empty or defined
            if(res.data === undefined || res.data.length == 0) {
                    
                console.log("Empty Response : ")            
            
            }
            //#endregion check if the response is empty or defined
            else {
                let newArray = [];
                
                for(let i = 0; i <= res.data.length - 1; i++) {
                    
                    //#region Create an array for the date
                        newArray[i] = res.data[i].date;
                    //#endregion Creating an array for the date
                
                }

                //#region POST argument to pass the symbols data to the endpoint
                    const duplicates = {
                        _id : window.symbolArray[i]._id,
                        symbol : window.symbolArray[i].symbol,
                        date: newArray
                    }
                //#endregion POST argument to pass the symbols data to the endpoint
                    
                //#region Hit the endpoint with the POST request
                token().then( (token) => {      
                    // console.log(token)      
                     return axios({
                         method: "post",
                         url: postduplicates,
                         data:duplicates,
                         headers: { Authorization: "Bearer " + token }
                     }).then(( res ) => {
                             console.log(res.data)
                        //  return res.data
                     }).catch((e) => console.log("axios error"))
                 }).catch( (e) => console.log(e)) 
                 //#endregion Hit the endpoint with the POST request

            }
        })
    }
    //#endregion Nested for loop to find the duplicate records
   
}
export {symbolduplicateRecords}