import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import AccountInfo from "./components/Account/index";

export default props => {
  return (
    <Provider store={store}>
      <div style={{ width: "100%" }}>
        {/* <h1>Account tab</h1> */}
        <AccountInfo theme={props.theme} user={props.user} />
      </div>
    </Provider>
  );
};
