import React from "react";
import { connect } from "react-redux";
import { set_symbol } from "../../../actions/options";
import { token } from '../../../../../configMiddleware/getToken'
const axios = require("axios");
const StockePannel = ({ theme, symbol, set_symbol, stock }) => {
  const styles = {
    Symbol_input: {
      backgroundColor: "transparent",
      color: theme === "Dark" ? "#a4aeb3" : "#6a6e75",
      padding: "3px",
      fontSize: "12px",
      borderColor: theme === "Dark" ? "#a4aeb3" : "#6a6e75",
      borderStyle: "solid"
    },
    labelStyle: {
      color: theme === "Dark" ? "#a4aeb3" : "#6a6e75",
      fontSize: "10px",
      display: "block"
    },
    bLabel: {
      color: theme === "Dark" ? "#a4aeb3" : "#6a6e75",
      fontSize: "12px",
      display: "block",
      textAlign: "center"
    },
    borderLessInput: {
      backgroundColor: "transparent",
      color: theme === "Dark" ? "#a4aeb3" : "#6a6e75",
      // padding: "3px",
      // fontSize: "12px",
      border: "none",
      width: "70px",
      textAlign: "center"
    }
  };

    let symbols = JSON.parse(localStorage.getItem("all_symbols"));
   return (
     
    <div className="mb-3">
      <span className="d-inline-block">
        <label htmlFor="StockeSymbol" style={styles.labelStyle}>
          Symbol
        </label>
        <input
          onChange={e => set_symbol(e.target.value)}
          type="text"
          list="symbol-list"
          name=""
          style={styles.Symbol_input}
          id="StockeSymbol"
          placeholder=""
          value={symbol}
        />
        <datalist id="symbol-list">
          {symbols.map(symbol => (
            <option key={symbol.symbol} value={symbol.symbol} />
          ))}
          
        </datalist>
      </span>
    </div>
  );
};

const mapStateToProps = state => ({
  symbol: state.option.symbol,
  stock: state.option.stock
});

export default connect(mapStateToProps, { set_symbol })(StockePannel);

/**
 
 <span className="d-inline-block">
        <label htmlFor="stock-open" style={styles.bLabel}>
          Open
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-open"
          value={stock.open}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
      <span className="d-inline-block">
        <label htmlFor="stock-high" style={styles.bLabel}>
          High
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-high"
          value={stock.high}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
      <span className="d-inline-block">
        <label htmlFor="stock-low" style={styles.bLabel}>
          Low
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-low"
          value={stock.low}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
      <span className="d-inline-block">
        <label htmlFor="stock-close" style={styles.bLabel}>
          Close
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-close"
          value={stock.close}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
      <span className="d-inline-block">
        <label htmlFor="stock-bid" style={styles.bLabel}>
          Ask
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-bid"
          value={stock.bid}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
      <span className="d-inline-block">
        <label htmlFor="stock-ask" style={styles.bLabel}>
          Bid
        </label>
        <input
          type="text"
          name=""
          style={styles.borderLessInput}
          id="stock-ask"
          value={stock.ask}
          onChange={e=>{}}
          contentEditable="false"
        />
      </span>
   

      
 */
