function formatDate(date) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return monthNames[monthIndex] + " " + day + " " + year;
}
function transformString(value, order) {
  // console.log(order);
  if (!value) return "";
  let formated = "";
  value.split("_").forEach((element, i) => {
    formated += element + " ";
  });
  return formated.trim();
}
function for_date(date) {
  var monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "May",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];

  let year, month, day;
  year = date.slice(0, 2);
  month = monthNames[date.slice(2, 4) - 1];
  day = date.slice(4, 6);
  return month + " " + day + " " + "20" + year;
}
function formate_strike_price(strike) {
  let f_strike, n_d, d;
  n_d = strike.slice(0, -3);
  d = strike.slice(-3);
  strike = Number(n_d + "." + d);
  return strike;
}

function formate_call_put(type) {
  if (type === "C") return "Call";
  else if (type === "P") return "Put";
}
// function get_desc(symbol, opt_sym) {
//   // var myString = "SPX141122P00019500";
//   var get_digits = /\d+/g;
//   let date_strike = [];
//   let m;
//   while ((m = get_digits.exec(opt_sym)) != null) {
//     date_strike.push(m[0]);
//   }
//   let desc =
//     symbol +
//     " " +
//     for_date(date_strike[0]) +
//     " $" +
//     formate_strike_price(date_strike[1]) +
//     " " +
//     formate_call_put(opt_sym.slice(symbol.length + 6, symbol.length + 7));
//   return desc;
// }
function get_desc(symbol, opt_sym) {
  // var myString = "SPX141122P00019500";
  var get_digits = /\d+/g;
  let date_strike = [];
  let m;
  while ((m = get_digits.exec(opt_sym)) != null) {
    date_strike.push(m[0]);
  }
  const lengthabc = date_strike[0].concat(date_strike[1]).length + 1;
  // console.log(  opt_sym.slice(0, opt_sym.length - lengthabc).length,);
  let desc =
    opt_sym.slice(0, opt_sym.length - lengthabc) +
    " " +
    for_date(date_strike[0]) +
    " $" +
    formate_strike_price(date_strike[1]) +
    " " +
    formate_call_put(
      opt_sym.slice(
        opt_sym.slice(0, opt_sym.length - lengthabc).length + 6,
        opt_sym.slice(0, opt_sym.length - lengthabc).length + 7
      )
    );
  return desc;
}
//helper
let currency_formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  style: "currency",
  currency: "USD"
});
let percentage_formatter = {
  format: value => value.toFixed(2) + "%"
};

let test_percent_formater = new Intl.NumberFormat({
  style: "percent",
  maximumFractionDigits: 2
});

function removeDups(names) {
  let unique = {};
  names.forEach(function(i) {
    if (!unique[i]) {
      unique[i] = true;
    }
  });
  return Object.keys(unique);
}

export {
  formatDate,
  get_desc,
  transformString,
  currency_formatter,
  percentage_formatter,
  removeDups,
  test_percent_formater
};
