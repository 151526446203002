import rootReducer from "./reducer";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

const store = createStore(
  rootReducer,
  undefined,
  compose(applyMiddleware(thunk))
);

export default store;
