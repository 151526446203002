const axios = require("axios");
let invalidsymbol = [];
/**
 * In this script we are checking which symbols have data and which symbols don't have data are 
 * invalid symbols for the charting library and here we are creating the list
 * that don't have data.
 */
const invaidsymbol = () => {
    
    console.log("Starting Invalid symbols process")
    
    //#region For loop to create a list of Invalid/No data symbols
        for(let i = 0; i <= window.symbolArray.length - 1; i++) {
            axios.get("http://localhost:5001/api/chart/historical_data?symbol="+window.symbolArray[i].symbol+"*"+"&resolution=1D")
            .then(res => {
                //#region If response then valid symbols else invalid symbols
                if(res.data){
                    console.log("Valid Symbols : ", window.symbolArray[i].symbol)
                }
                else{
                    console.log("Invalid Symbol response : ",window.symbolArray[i].symbol )
                }
                //#endregion If response then valid symbols else invalid symbols
            })
                //#region Catch the erros if it have no respnse/no data/network error
                .catch(err => {
                    if(err.request)
                    {
                        //#region creating a list of invalid symbols
                        invalidsymbol[i] = window.symbolArray[i].symbol;
                        //#endregion creating a list of invalid symbols
                        console.log("Invalid Symbol request : ",invalidsymbol )
                    }
                })
                //#endregion Catch the erros if it have no respnse/no data/network error
        }
    //#endregion For loop to create a list of Invalid/No data symbols

}
export {invaidsymbol}