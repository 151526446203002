import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./../../styles/css/Form.css";
import { Link } from "react-router-dom";
import { on_input_change, login } from "../../actions/auth.action";
import {getAllSymbols} from '../../services/apis/dataService';

const Login = props => {
  getAllSymbols()
  useEffect(() => {
    if (props.auth.is_Authenticated) {
      return props.history.push("/main");
    //   if (props.auth.is_completed) {
    //     if (props.auth.is_tradier_linked) return props.history.push("/main");
    //     else return props.history.push("/connect_tradier");
    //   } else return props.history.push("/complete_profile");
    }
  }, [props.auth]);
  return (
    <>
      <div className="vldform vldauth">
        <h1>Log in</h1>
        <input
          className="vldform__textbox"
          type="text"
          name="username"
          placeholder="Username"
          value={props.auth.username}
          onChange={props.on_input_change}
        />
        <span className="text-danger">
          <small>{props.error.username}</small>
        </span>
        <input
          className="vldform__textbox"
          type="password"
          name="password"
          id=""
          value={props.auth.password}
          placeholder="Password"
          onChange={props.on_input_change}
        />
        <span className="text-danger">
          <small>{props.error.password}</small>
        </span>
        <Link className="vldform__recoverypassword" to="/forget_password">
          Forgot password?
        </Link>
        <button
          className="vldform__button"
          onClick={props.login}
          value="Log in"
        >
          Login
        </button>
        {/* <span className="vldform__signuplink mt-3" href="#">
          Or login using
        </span>
        <a href="http://localhost:5000/api/auth/tradier">
          <img src={tradier_logo} className="mt-3" height="50px" alt="" />
        </a> */}
        <p className="vldform__signup">
          Don't have account?
          <Link className="vldform__signuplink" to="/Register">
            Sign up
          </Link>
        </p>
      </div>
    </>
  );
};

export default connect(state => ({ auth: state.auth, error: state.error }), {
  on_input_change,
  login
})(Login);
