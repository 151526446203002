/* eslint-disable no-unused-expressions */

//#region Imports
import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library/charting_library";
import Datafeed from "./api/";
// import Datafeed from './api/cryptoIndex'
import Datafeed_no_stream from "./api/index.no-stream";
import AccountSelector from "./components/AccountSelector";
import { algoadxy } from "./../../chartIndicator_library/indicators/algoAdxy"; // '../../../indicators/algoAdxy';
import { algoppoy } from "./../../chartIndicator_library/indicators/algoPPOY";
import { algoswing } from "./../../chartIndicator_library/indicators/algoSwing";
import { algopivtz } from "./../../chartIndicator_library/indicators/algoPivtz";
import { algosignal } from "./../../chartIndicator_library/indicators/algoSignal";
import studyName from "./../../chartIndicator_library/library_Utils/studyname";
import subscribe_study_event from "./../../chartIndicator_library/library_Utils/study_event";
import onIntervalChanged from "./../../chartIndicator_library/library_Utils/onIntervalChanged";
import onSymbolChanged from "./../../chartIndicator_library/library_Utils/onSymbolChanged";
import createStudy from "./../../chartIndicator_library/library_Utils/createStudy";
import onDataLoaded from "./../../chartIndicator_library/library_Utils/onDataLoaded";
import AlgoPPOY from "./../../chartIndicator_library/IndicatorLogic/algoPPOY";
import AlgoSWING from "./../../chartIndicator_library/IndicatorLogic/algoSWING";
import AlgoAdxy from "./../../chartIndicator_library/IndicatorLogic/algoadxy";
import algosignalLogic from "./../../chartIndicator_library/IndicatorLogic/algoSignal";
import AddIndicator from "./../../chartIndicator_library/library_Utils/AddIndicator";
import removeIndicator from "./../../chartIndicator_library/library_Utils/removeIndicator";
import saveChartData from "./../../chartIndicator_library/chartTemplates/chartLayouts/saveChart";
import removeChartData from "./../../chartIndicator_library/chartTemplates/chartLayouts/removeChart";
import loadChartList from "./../../chartIndicator_library/chartTemplates/chartLayouts/loadChartList";
import study_properties_changed from "./../../chartIndicator_library/library_Utils/studyPropertiesChanged";
import load_study_template from "./../../chartIndicator_library/library_Utils/loadstudyTemplate";
import getChartData from "./../../chartIndicator_library/chartTemplates/chartLayouts/getChartContent";
import saveindicatorTemplate from "./../../chartIndicator_library/chartTemplates/indicatorTemplates/saveStudyTemplate";
import loadindicatorList from "./../../chartIndicator_library/chartTemplates/indicatorTemplates/getAllStudyTemplates";
import removeindicatorTemplate from "./../../chartIndicator_library/chartTemplates/indicatorTemplates/removeStudyTemplate";
import getindicatorTemplateData from "./../../chartIndicator_library/chartTemplates/indicatorTemplates/getStudyTemplateContent";
import { symbolduplicateRecords } from "./../../chartIndicator_library/symbolDuplicateRecords/dupliicateRecords";
import { invaidsymbol } from "./../../chartIndicator_library/invalidSymbol/invalidsymbol";
import { oratsHistdata } from "./../../chartIndicator_library/OratsHistData/oratsHistdata";
import Modal from "../TvChartContainer/components/Modal.jsx";
import createPositionLine from "../../chartIndicator_library/library_Utils/createPositionLine";
import { useState } from "react";
//#endregion Imports
//#region Declare/Initialize Variables
// var CronJob = require('cron').CronJob;
const axios = require("axios");
var dateFormat = require("dateformat");
let algoPPOYObject = new AlgoPPOY();
let algoswingObject = new AlgoSWING();
let algoAdxyObject = new AlgoAdxy();
let chartState;
//#endregion Initialize Variables
//#region Charting Library Helper Method
function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
//#endregion Charting Library Helper Method

//#region Variables
window.textShapeID = [];
window.arrowShapeID = [];
window.circleShapeID = [];
window.textShapeID_Index = 0;
window.arrowShapeID_Index = 0;
window.circleShapeID_Index = 0;
window.parent.circle = false;
window.parent.text = false;
window.parent.arrow = false;
window.indicatorName = "";
window.DBsignal = false;
window.indicatorName_ID;
window.checkIndicatorLoadedONChart = false;
window.LastSimpleSignal = []
window.LastSimpleSignalForCalculations = []
window.LastSimpleSignalIndex = 0
window.PineJSExport = (PineJS) => {
  return PineJS;
};

window.indicatorCount = 0;
window.index = 0;
window.returnTrue;
window.indicatorID = [];
window.symbolName;
window.DayName;
window.automationSymbol;
window.chartsData = [];
window.sliceCount = 1;
window.loadstudyTemplate = false;
window.slicethreeCreation = [];
window.slicethreeCreationIndex = 0;
window.getAllstockSplitsData;
window.uToken;
//#endregion Variables

const BUTTON_WRAPPER_STYLES = {
  position: "relative",
  zIndex: 1,
};

const OTHER_CONTENT_STYLES = {
  position: "relative",
  zIndex: 2,
  backgroundColor: "red",
  padding: "10px",
};
export default class TVChartContainer extends React.PureComponent {
  state = {
    account_select_modal: false,
    show_api_key_modal: false,
  };

  static defaultProps = {
    symbol: "AAPL",
    interval: "1D",
    containerId: "tv_chart_container",
    // libraryPath: "/../../charting_library/",
    libraryPath: "charting_library/",
    chartsStorageUrl: "http://127.0.0.1:8000",
    chartsStorageApiVersion: "1.1",
    clientId: "Algotech",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    timezone: "America/New_York",
    theme: "Light",
    loading_screen: { backgroundColor: "#ffffff" },
  };

  componentDidMount() {
    const widgetOptions = {
      debug: false,
      symbol: this.props.symbol,
      datafeed:
        this.props.account_type === "live" ? Datafeed : Datafeed_no_stream,
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [""], //widget_logo
      timezone: this.props.timezone,
      enabled_features: [
        "use_localstorage_for_settings",
        "study_templates",
        "hide_last_na_study_output",
        "custom_resolutions",
        "header_screenshot",
        "border_around_the_chart",
        "countdown",
        "same_data_requery",
        "header_symbol_search",
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      // studies_overrides: this.props.studiesOverrides,
      theme: this.props.theme,
      studies_overrides: {
        "volume.volume ma.transparency": 100,
        "volume.show ma": true,
        "mainSeriesProperties.minTick": "default",
      },
      favorites: {
        intervals: ["1D", "3D", "3W", "W", "M"],
        chartTypes: ["Area", "Line"],
      },

      /*One of the parameters in Widget Construcor, this is basically an object containing the save/load functions.
    It is used to customize the Save button behaviour. */
      save_load_adapter: {
        studyTemplates: [],
        getAllCharts: function () {
          let chart;
          return Promise.resolve().then(function () {
            let value = loadChartList(chart);
            return value.then((data) => {
              console.log(data);
              return data;
            });
          });
        },

        removeChart: function (id) {
          console.log("Remove ID: ", id);
          removeChartData(id);
          return Promise.resolve();
        },

        saveChart: function (chartData) {
          if (!chartData.id) {
            chartData.id = Math.random().toString();
          } else {
            console.log("SameID: ", chartData.id);
            this.removeChart(chartData.id);
          }

          chartData.timestamp = new Date().valueOf();

          // charts.push(chartData);
          saveChartData(chartData);
          return Promise.resolve(chartData.id);
        },

        getChartContent: function (id) {
          console.log("Chart Content ID: ", id);
          return Promise.resolve().then(function () {
            let value = getChartData(id);
            return value.then((data) => {
              return data;
            });
          });
        },

        removeStudyTemplate: function (studyTemplateData) {
          removeindicatorTemplate(studyTemplateData.name);
          return Promise.resolve();
        },

        getStudyTemplateContent: function (studyTemplateData) {
          console.log("studyTemplateData: ", studyTemplateData.name);
          return Promise.resolve().then(function () {
            let value = getindicatorTemplateData(studyTemplateData.name);
            return value.then((data) => {
              return data;
            });
          });
        },

        saveStudyTemplate: function (studyTemplateData) {
          console.log(studyTemplateData);
          for (var i = 0; i < this.studyTemplates.length; ++i) {
            if (this.studyTemplates[i].name === studyTemplateData.name) {
              this.studyTemplates.splice(i, 1);
              break;
            }
          }

          this.studyTemplates.push(studyTemplateData);
          saveindicatorTemplate(studyTemplateData);
          return Promise.resolve();
        },

        getAllStudyTemplates: function () {
          let indicator;
          const promiseTimeout = (time) => (result) =>
            new Promise((resolve) => setTimeout(resolve, time, result));
          return Promise.resolve()
            .then(promiseTimeout(1500))
            .then(function () {
              let value = loadindicatorList(indicator);
              return value.then((data) => {
                console.log("data: ", data);
                return data;
              });
            });
          // return Promise.resolve(window.parent.studyTemplatesDB);
        },
      },

      //#region Step 2 of 'Add Custom Indicator Into the Framework -- This is where the bulk of our custom logic is defined
      custom_indicators_getter: function (PineJS) {
        //Access PineJS outside of this file.
        window.pinejs = window.PineJSExport(PineJS);
        return Promise.resolve([
          algoadxy,
          algoppoy,
          algoswing,
          algopivtz,
          algosignal, //algoSignal_MongoDB

          /*We need to define the entire logic for the AlgosignalMongoDB version of our indicator bleow as part of the array of indicators that are being passed into the 
            Promise resolution request above ... Ideally, we should have put this code in a seperate indicator file and just imported it into this file and passed it in as we did with the other indicators like 'algoadxy, algoppoy, etc. but for the case of AlgosignalMongoDB this streamlined approach did not work becuase this version of AlgoSignal has a unique purpose different than just loading on the chart once. We actaully have to keep to loading and unloading this version to push the AlgoSignals data into the DB. The continuous calls to createstudy(tvWidget) required to load and unload AlgosignalMongoDB is not supported by the Charting Library via the modularized approach. We are handling this requirement via this conditional -> 'if ((context.Data[0].Index === context.Data[1].Index ))' in the region -> "Logic for getting the symbols data and checking the data against the symbol also instantiating another instance of an indicator"
            */
          {
            name: "AlgosignalMongoDb",
            metainfo: {
              _metainfoVersion: 40,
              id: "AlgosignalMongoDb@tv-basicstudies-1",
              scriptIdPart: "",
              name: "AlgosignalMongoDb",
              // This description will be displayed in the Indicators window
              // It is also used as a "name" argument when calling the createStudy method
              description: "AlgosignalMongoDb",
              // This description will be displayed on the chart
              shortDescription: "AlgosignalMongoDb",
              //is_hidden_study **ture** means the indicator is hidden on the chart we cannot see the indicator in the indicators menu.
              is_hidden_study: true,
              //is_price_study is applicable when the use the plots from the meta info. And what is does is, it plots the indicator in a seperate window.
              is_price_study: true,
              isCustomIndicator: true,
              //Defining the plots for the custom indicator, its *ID* and its *type*.
              plots: [{ id: "plot_0", type: "line" }],
              //Default properties for the custom indicator.
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,

                    // Plot line width.
                    linewidth: 2,

                    // Plot type:
                    //    1 - Histogram
                    //    2 - Line
                    //    3 - Cross
                    //    4 - Area
                    //    5 - Columns
                    //    6 - Circles
                    //    7 - Line With Breaks
                    //    8 - Area With Breaks
                    plottype: 2,

                    // Show price line?
                    trackPrice: false,

                    // Plot transparency, in percent.
                    transparency: 40,

                    // Plot color in #RRGGBB format
                    color: "#0000FF",
                  },
                },
                //Percision is the upto decimal place value want to show on the chart.
                precision: 2,
                //inputs object, byfefault values assigned to the inputs
                inputs: {
                  "Start Day": 1,
                  "Start Month": 1,
                  "Start Year": 2010,
                  "ML End Day": 31,
                  "ML End Month": 12,
                  "ML End Year": 2014,
                  Period: 14,
                  UseHighLow: true,
                  UseCoreLogic: true,
                  UseStructureLogic: true,
                  slow: 26,
                  fast: 12,
                  smooth: 9,
                  MktValCapThresh: -25,
                  PiPThresh: -500,
                  TickThresh: -250,
                  ThreshMult: 0.8,
                  AvgBarsPerSignal: 53,
                  RevOnExit: true,
                },
              },
              styles: {
                plot_0: {
                  // Output name will be displayed in the Style window
                  title: "-- output name --",
                  histogramBase: 0,
                },
              },
              //Values assign to inputs intialization and declaration of inputs. Definind there data types.
              inputs: [
                {
                  id: "Start Day",
                  type: "integer",
                  name: "Start Day",
                  defval: 1,
                  min: 1,
                  max: 31,
                },
                {
                  id: "Start Month",
                  type: "integer",
                  name: "Start Month",
                  defval: 1,
                  min: 1,
                  max: 12,
                },
                {
                  id: "Start Year",
                  type: "integer",
                  name: "Start Year",
                  defval: 2010,
                  max: 2020,
                },
                {
                  id: "ML End Day",
                  type: "integer",
                  name: "ML End Day",
                  defval: 31,
                  min: 1,
                  max: 31,
                },
                {
                  id: "ML End Month",
                  type: "integer",
                  name: "ML End Month",
                  defval: 12,
                  min: 1,
                  max: 12,
                },
                {
                  id: "ML End Year",
                  type: "integer",
                  name: "ML End Year",
                  defval: 2014,
                  max: 2020,
                },
                {
                  id: "Period",
                  type: "integer",
                  name: "Algo Period",
                  defval: 14,
                },
                {
                  id: "UseHighLow",
                  type: "bool",
                  name: "Use High Low",
                  options: ["true", "false"],
                  defval: "true",
                },
                {
                  id: "UseCoreLogic",
                  type: "bool",
                  name: "Use Core Logic",
                  options: ["true", "false"],
                  defval: "true",
                },
                {
                  id: "UseStructureLogic",
                  type: "bool",
                  name: "Use Structure Logic",
                  options: ["true", "false"],
                  defval: "true",
                },
                {
                  id: "slow",
                  type: "integer",
                  name: "Slow",
                  defval: 26,
                },
                {
                  id: "fast",
                  type: "integer",
                  name: "Fast",
                  defval: 12,
                },
                {
                  id: "smooth",
                  type: "integer",
                  name: "Smooth",
                  defval: 9,
                },
                {
                  id: "MktValCapThresh",
                  type: "integer",
                  name: "MktValCapThresh",
                  defval: -25,
                },
                {
                  id: "PiPThresh",
                  type: "integer",
                  name: "PiPThresh",
                  defval: -500,
                },
                {
                  id: "TickThresh",
                  type: "integer",
                  name: "TickThresh",
                  defval: -250,
                },
                {
                  id: "ThreshMult",
                  type: "integer",
                  name: "ThreshMult",
                  defval: 0.8,
                },
                {
                  id: "AvgBarsPerSignal",
                  type: "integer",
                  name: "AvgBarsPerSignal",
                  defval: 53,
                },
                {
                  id: "RevOnExit",
                  type: "bool",
                  name: "Rev On Exit",
                  options: ["true", "false"],
                  defval: "true",
                },
              ],
            },
            //Have change the Symbol so we need to change the SYMBOL to symbol
            constructor: function () {
              /* eslint-disable no-unused-expressions */
              this.init = function (context, inputCallback) {
                //#region context is an object provided by teh charting library and we are using this oject for our problem. we are adding our variables, arrays every thing we need for the calculation in the context object so we can access it anywhere. and it helps the script to run properly.*/
                this._context = context;
                //#endregion

                //#region inputCallback call the input from the inputs array
                this._input = inputCallback;
                //#endregion

                //#region Dynamic way to get the symbol that is loaded on the chart.
                var symbol = window.parent.pinejs.Std.ticker(this._context)
                  .replace(/[{}]/g, "")
                  .replace(/\"/g, "");
                //new_sym handle/controls the new symbol
                this._context.new_sym(
                  window.symbolArray[window.parent.indicatorCount].symbol,
                  window.parent.pinejs.Std.period(this._context),
                  window.parent.pinejs.Std.period(this._context)
                );
                this._context.CurrentBar = -1;

                if (this._context.Data == undefined) this._context.Data = [];
                //#endregion

                //#region Get stocksplits
                context.splitDate = [];
                context.dayName = [];
                context.symbolFromlist =
                  window.symbolArray[window.parent.indicatorCount].symbol;
                console.log("symbolFromlist : ", context.symbolFromlist);
                if (
                  context.symbolFromlist.charAt(
                    context.symbolFromlist.length - 1
                  ) === "*"
                ) {
                  context.symbolFromlist = context.symbolFromlist.replace(
                    "*",
                    ""
                  );
                  let j = 0;
                  for (
                    var i = 0;
                    i < window.parent.getAllstockSplitsData.length;
                    i++
                  ) {
                    if (
                      window.parent.getAllstockSplitsData[i].symbol ===
                      context.symbolFromlist
                    ) {
                      var d = new Date(
                        window.parent.getAllstockSplitsData[i].splitDate
                      );
                      context.dayName[j] = d.toString().split(" ")[0];
                      // console.log("Day: ",context.dayName[j]);
                      context.splitDate[j] =
                        window.parent.getAllstockSplitsData[i].splitDate;
                      console.log(context.splitDate);
                      j++;
                    }
                  }
                }

                //#endregion

                //#region Swing	Data
                context.upArrPlot = false;
                context.dnArrPlot = false;
                var Strength = 5;
                context.currentSwingHigh = 0;
                context.currentSwingLow = 0;
                context.lastSwingHighValue = 0;
                context.lastSwingLowValue = 0;
                context.saveCurrentBar = -1;
                context.constant = 2 * Strength + 1;

                context.swingHighSeries = [];
                context.swingHighSwings = [];

                context.swingLowSeries = [];
                context.swingLowSwings = [];

                context.lastHighCache = []; //ArrayList
                context.lastLowCache = []; //ArrayList

                context.SwingHighPlot = [];
                context.SwingLowPlot = [];

                context.SwingHighPlot_Valid = [];
                context.SwingLowPlot_Valid = [];
                //#endregion Swing	Data

                //#region Performance Variables
                context.lEntryPrice = 0;
                context.lExitPrice = 0;
                context.sEntryPrice = 0;
                context.sExitPrice = 0;
                context.tradePnL = 0;
                context.netGain = 0;
                context.mktValCap = 0;
                context.wlRatio = 0;
                context.aefeRatio = 0;
                context.avgGain = 0;
                context.minGain = 0;
                context.maxGain = 0;
                context.avgWin = 0;
                context.avgLoss = 0;
                context.totWin = 0;
                context.totLoss = 0;
                context.winRate = 0;
                context.winCount = 0;
                context.lossCount = 0;
                context.maxMAE = 0;
                context.maxMFE = 0;
                context.avgMAE = 0;
                context.avgMFE = 0;

                context.totalSignals = 0;

                context.barsSinceSignal = 0;
                context.avgBPS = 0;
                context.sigBar = 0;
                context.sigBarIdx = 0;
                context.tG = 0; //tradeGainTally List index counter variable

                //The following temps are used for Multi-Timeframe Cognitive Optimization
                context.temp1 = 0;
                context.temp2 = 0;
                context.temp3 = 0;
                context.temp4 = 0;

                context.firstTrade;
                context.openTrade;
                context.closeTrade;

                //In NT8 which is based on .Net 4 I can use the TimeSpan type formats. However, In NT7 (.Net 3.5) TimeSpan formatting features are not available.
                context.duration = 0; //Initilize time span to 0
                context.avgDuration = 0;
                context.longestDuration = 0;
                context.shortestDuration = 0;
                context.totalDuration = 0; //The total timespan of all signals on the chart

                context.tradeDuration = [];
                context.tradeGain = [];
                context.tradeGainTally = [];
                context.winList = [];
                context.lossList = [];
                context.maeList = [];
                context.mfeList = [];

                //#endregion Performance Variables

                //#region Text Display Offset Variables
                context.txtMult1 = 0;
                context.txtMult2 = 0;
                context.txtMult3 = 0;
                context.sigCount = 0;

                context.yPixOffset = 0;
                context.yPixOffset2 = 0;
                context.yOffset = 0;
                //#endregion Text Display Offset Variables

                //#region Series<double> Declarations
                // context.eSignal;			//eSignal representing the final Signal evaluation
                context.barsPastSignal = [];

                //these are used for Cognitive Optimization Referencing
                context.bip1LngSeries = [];
                context.bip1ShtSeries = [];

                context.bip2LngSeries = [];
                context.bip2ShtSeries = [];

                //Used for Strategy to determine Inversion
                context.invertSeries = [];

                context.totsigSeries = [];

                context.gainSeries = [];
                context.wrateSeries = [];
                context.maxGainSeries = [];
                context.maxLossSeries = [];
                context.avgGainSeries = [];
                context.avgWinSeries = [];
                context.avgLossSeries = [];
                context.maeSeries = []; //Avg Adverse Excursion of signals
                context.mfeSeries = [];
                //#endregion Series<double> Declarations

                //#region Boolean Values
                context.firstSignal = true;

                context.longSig = false;
                context.bip1longSig = false;
                context.bip2longSig = false;
                context.skipLngEntry = false;

                context.shortSig = false;
                context.bip1shortSig = false;
                context.bip2shortSig = false;
                context.skipShtEntry = false;

                context.reversal = false;
                context.OppSigExit = true; //This was originally a user definable variable, but due to interoperatiblity issues with the AlgoSignalsATO auto trader, this has to always be true.

                context.cOptimize = true;
                context.invertSignals = false;
                context.invertStructure = false;
                context.doOnce = false;
                context.initialDelVert = false;
                context.initialDelBSprd = false;
                //#endregion Boolean Values

                //#region List Variables
                context.mktValCapList = [];
                //#endregion List Variables

                //#region Internal Script Variables
                context.date = 1495630800000; //
                context.signalArray = [];
                context.marketvaluecapArray = [];
                context.netgainAnalysisArray = [];
                context.signalArrayIndex = 0;
                context.Performance_Calculations_Array = [];
                context.Performance_Calculations_Index = 0;
                context.exist = false;
                context.exitLong = false;
                context.exitShort = false;
                context.splitExit = false;
                context.PerformaceCalc = [];
                context.PerformaceCalcIndex = 0;
                context.PerformaceCalcCognative = [];
                context.PerformaceCalcCognativeIndex = 0;
                context.firstEntryAfterSplit = false;
                context.SplitExitPeformaceCal = {};
                context.automatedTesting = [];
                context.automatedTestingIndex = 0;
                context.Count = 0;
                //#endregion Internal Script Variables
              };

              this.main = function (context, inputCallback) {
                //#region Initialize Values
                this._context = context;
                this._input = inputCallback;
                var CurrentBar = this._context.CurrentBar;
                this._context.select_sym(1);
                var sigBar = context.sigBar;
                context.exitLong = false;
                context.exitShort = false;
                //#endregion

                //Getting the inpouts from an array through inputCallback.
                //#region Intialization of variables
                var SwingStrength = 5;
                context.Start_Day = 1; //inputCallback(0);
                context.Start_Month = 1; //inputCallback(1);
                context.Start_Year = 2010; //inputCallback(2);
                if (window.parent.sliceCount === 2) {
                  context.Start_Year = 2015;
                }
                if (window.parent.sliceCount === 3) {
                  context.Start_Year = 2010;
                }
                context.Start_Day_Month_Year =
                  context.Start_Month +
                  "/" +
                  context.Start_Day +
                  "/" +
                  context.Start_Year;
                context.Start_Day_Month_Year = new Date(
                  context.Start_Day_Month_Year
                ).getTime();
                context.Start_Day_Month_Year =
                  context.Start_Day_Month_Year + 32400000;
                context.ML_End_Day = 31; //inputCallback(3);
                context.ML_End_Month = 12; //inputCallback(4);
                context.ML_End_Year = 2014; //inputCallback(5);

                if (window.parent.sliceCount === 2) {
                  context.ML_End_Year = 2019;
                }

                if (window.parent.sliceCount === 3) {
                  context.ML_End_Year = 2019;
                }
                context.ML_End_Day_Month_Year =
                  context.ML_End_Month +
                  "/" +
                  context.ML_End_Day +
                  "/" +
                  context.ML_End_Year;
                context.ML_End_Day_Month_Year = new Date(
                  context.ML_End_Day_Month_Year
                ).getTime();
                context.ML_End_Day_Month_Year =
                  context.ML_End_Day_Month_Year + 32400000;
                var Period = inputCallback(6);
                context.UseHighLow = inputCallback(7);
                context.UseCoreLogic = inputCallback(8);
                context.UseStructureLogic = inputCallback(9);

                let slow = inputCallback(10);
                let fast = inputCallback(11);
                let smooth = inputCallback(12);

                context.slow = inputCallback(12);
                context.MktValCapThresh = inputCallback(13);
                context.PiPThresh = inputCallback(14);
                context.TickThresh = inputCallback(15);
                context.ThreshMult = inputCallback(16);
                context.AvgBarsPerSignal = inputCallback(17);
                context.RevOnExit = inputCallback(18);

                context.OppSigExit = true;
                //#endregion Intialization of variables

                //#region context.Data array is an array in which we are adding our core values used for calculation of our custom indicator that are defined as *lists* in Ninja Trader and unshift is an array method to add the elements at the start of an array.
                context.Data.unshift({
                  High: context.symbol.high || 0,
                  Low: context.symbol.low || 0,
                  Close: context.symbol.close || 0,
                  Open: context.symbol.open || 0,
                  dmPlus: 0,
                  dmMinus: 0,
                  sumDmPlus: 0,
                  sumDmMinus: 0,
                  sumTr: 0,
                  tr: 0,
                  Value: 0,
                  Index: context.symbol.index,
                  plotDirection: 0,
                  Time: context.symbol.time,
                  Smoothed: 0,
                  Default: 0,
                  Algosignals_eSignal: 0, //eSignal
                  PPO_Esignal: 0,
                  ADXY_Esignal: 0,
                  eSignal: 0,
                  EMA: 0,
                  trendSignal: 0,
                  DiPlus: 0,
                  DiMinus: 0,
                });
                //#endregion

                //#region This line indicates/controls/manages the custom indicator when it approaches the last bar to stop its calculations. Otherwise, after the last bar the index for the last bar continues to repeat.
                if (context.Data.length < 2) return;

                this._context.CurrentBar++;
                //#endregion

                //#region Count -> this is the dynamic way to calculate the number of bars that are loaded on the chart against the specific symbol. The barsets array @ index 0 won't update when a symbol is changed - but using index 1 the data gets updated when we change the symbol.
                context.Count =
                  context.symbol.script.runner.barsets[1].bars.length;
                //#endregion

                //#region Calling the algoswing indicator function to get the required values from the algoswing.
                algoswingObject.algoSwing(SwingStrength, context, CurrentBar);
                //#endregion

                //#region Calling the algoadxy indicator function to get the required values from the algoadxy.
                algoAdxyObject.algoAdxy(context, CurrentBar, Period);
                //#endregion

                //#region Calling the algoppoy indicator function to get the required values from the algoppoy.
                algoPPOYObject.AlgoPPOY(
                  context,
                  CurrentBar,
                  fast,
                  slow,
                  smooth,
                  sigBar,
                  window.parent.pinejs
                );
                //#endregion

                //#region Check if Enough Bars are available for indicator calculations
                if (
                  this._context.CurrentBar < slow ||
                  (!context.UseCoreLogic && !context.UseStructureLogic)
                )
                  return;
                //#endregion

                //#region Stop the process after the 3 MLR Data Slices are processed
                if (window.parent.sliceCount == 4) return;
                //#endregion

                //#region Logic for getting the symbols data and checking the data against the symbol also instantiating another instance of an indicator
                if (context.Data[0].Index === context.Data[1].Index) {
                  removeIndicator(
                    window.parent.tvWidget,
                    window.parent.indicatorID[window.parent.index]
                  );
                  console.log(
                    "Remove Index",
                    window.parent.indicatorID[window.parent.index],
                    window.symbolArray[window.parent.indicatorCount].symbol
                  );

                  window.parent.index += 1;
                  window.parent.indicatorCount += 1;
                  axios
                    .get(
                      "http://localhost:5001/api/chart/historical_data?symbol=" +
                        window.symbolArray[window.parent.indicatorCount]
                          .symbol +
                        "&resolution=1D"
                    )
                    .then((res) => {
                      if (res.data) {
                        window.parent.returnTrue = true;
                        console.log(window.parent.returnTrue);
                        AddIndicator(
                          window.parent.tvWidget,
                          window.parent.index
                        );
                        if (
                          window.symbolArray[window.parent.indicatorCount]
                            .symbol == "ZTS*"
                        ) {
                          console.log(
                            "Last Symbol",
                            window.symbolArray[window.parent.indicatorCount]
                              .symbol,
                            "Index Count: ",
                            window.parent.index
                          );
                          window.parent.indicatorCount = 0;
                          window.parent.sliceCount += 1;

                          console.log(
                            "Inside New Block",
                            " Indicator Count :",
                            window.parent.indicatorCount,
                            "SliceCount: ",
                            window.parent.sliceCount
                          );
                          if (window.parent.sliceCount == 2) {
                            setTimeout(() => {
                              removeIndicator(
                                window.parent.tvWidget,
                                window.parent.indicatorID[0]
                              );
                            }, 3000);
                          }

                          if (window.parent.sliceCount == 3) {
                            setTimeout(() => {
                              removeIndicator(
                                window.parent.tvWidget,
                                window.parent.indicatorID[0]
                              );
                            }, 3000);
                          }
                          if (window.parent.sliceCount == 4) {
                            setTimeout(() => {
                              removeIndicator(
                                window.parent.tvWidget,
                                window.parent.indicatorID[
                                  window.parent.indicatorID.length - 1
                                ]
                              );
                            }, 3000);
                          }
                          if (
                            window.parent.sliceCount == 1 ||
                            window.parent.sliceCount == 2 ||
                            window.parent.sliceCount == 3
                          )
                            createStudy(window.parent.tvWidget);
                        }
                      }
                    })
                    .catch((err) => {
                      //  console.log("error getting symbols", err);
                      if (err.response) {
                        window.parent.returnTrue = false;
                        console.log(window.parent.returnTrue);
                        if (window.parent.returnTrue === false) {
                          window.parent.indicatorCount += 1;
                          AddIndicator(
                            window.parent.tvWidget,
                            window.parent.index
                          );
                        }
                        // client received an error response (5xx, 4xx)
                      }

                      if (err.request) {
                        window.parent.returnTrue = false;
                        console.log(window.parent.returnTrue);
                        if (window.parent.returnTrue === false) {
                          window.parent.indicatorCount += 1;
                          AddIndicator(
                            window.parent.tvWidget,
                            window.parent.index
                          );
                        }
                        // client never received a response, or request never left
                      }
                    });
                }
                //#endregion Logic for getting the symbols data and checking the data against the symbol also instantiating another instance of an indicator

                //#region Start the indicator from a specific start date.
                if (context.Data[0].Time >= context.Start_Day_Month_Year) {
                  // console.log(dateFormat(new Date(context.Data[0].Time)),"context.CurrentBar : ",context.CurrentBar,
                  // "context.Count : ", context.Count, "context.Data[0].Index", context.Data[0].Index);
                  // Calling the algoSignals logic to perform its main logic calculations.
                  algosignalLogic(context, fast, slow, smooth);
                }
                //#endregion
              };
            },
          },
        ]);
      },
      //#endregion Step 2 of 'Add Custom Indicator Into the Framework
    };

    const user = this.props.user;
    //#region Step 3 of 'Add Custom Indicator Into the Framework'
    const tvWidget = new widget(widgetOptions);
    window.tvWidget = tvWidget;
    this.tvWidget = tvWidget;
    //#endregion Step 3 of 'Add Custom Indicator Into the Framework'

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // const button = tvWidget.createButton();
        // button.setAttribute("title", "Click to show a notification popup");
        // button.classList.add("apply-common-tooltip");
        // // button.addEventListener("click", () =>
        // //   tvWidget.showNoticeDialog({
        // //     title: "Notification",
        // //     body: "TradingView Charting Library API works correctly",
        // //     callback: () => {
        // //       console.log("Noticed!");
        // //     }
        // //   })
        // // );

        // button.innerHTML = "Check API";
        const linkTradier = tvWidget.createButton();
        linkTradier.classList.add("apply-common-tooltip");
        linkTradier.innerHTML = "Link Tradier";
        linkTradier.addEventListener("click", () => {
          console.log("this.props : ", this.props);
          this.props.history.push("/connect_tradier");
          // this.props.ConnectTradier()
        });

        const newButton = tvWidget.createButton();
        newButton.classList.add("apply-common-tooltip");
        newButton.innerHTML = this.props.theme === "Light" ? "Dark" : "Light";
        newButton.addEventListener("click", () => {
          newButton.innerHTML = this.props.theme;
          let theme = this.props.theme === "Dark" ? "Light" : "Dark";
          this.props.setTheme(theme);
          tvWidget.changeTheme(theme);
        });

        // require('../../Views/ConnectTradier')

        const account_btn = tvWidget.createButton();
        account_btn.classList.add("apply-common-tooltip");
        account_btn.innerHTML = user.selected_tradier_account;
        account_btn.addEventListener("click", () => {
          this.setState({ account_select_modal: true });
        });

        const refreshHist_btn = tvWidget.createButton();
        refreshHist_btn.classList.add("apply-common-tooltip");
        refreshHist_btn.innerHTML = "Refresh Chart";
        refreshHist_btn.addEventListener("click", () => {
          window.storeResetCacheCallBackFunction();
          tvWidget.chart().resetData();
        });

        const logout_btn = tvWidget.createButton();
        logout_btn.classList.add("apply-common-tooltip");
        logout_btn.innerHTML = "Logout";
        logout_btn.addEventListener("click", () => {
          this.props.on_logout();
          // this.props.history.push('/')
        });

        const APIkey_MOdal = tvWidget.createButton();
        APIkey_MOdal.classList.add("apply-common-tooltip");
        APIkey_MOdal.innerHTML = "API KEYS";
        APIkey_MOdal.addEventListener("click", () => {
          this.setState({ show_api_key_modal: true });
        });
      });

      window.localStorage.removeItem("Performance_Calculations");
      subscribe_study_event(tvWidget);
      onIntervalChanged(tvWidget);
      studyName(tvWidget);
      onSymbolChanged(tvWidget);
      // createStudy(tvWidget);
      onDataLoaded(tvWidget);
      study_properties_changed(tvWidget);
      load_study_template(tvWidget);
      // symbolduplicateRecords();
      // invaidsymbol();
      // createPositionLine(window.parent.tvWidget)

      //User changed the chart. Chart change means any user action that can be undone.
      // tvWidget.subscribe('onAutoSaveNeeded', (id, studyEventType) => {
      //   tvWidget.showConfirmDialog({
      //     title: 'Remove Indicator ' + window.parent.indicatorName,
      //     body:'You may Loss the changes',
      //     callback: (val) => {
      //         if(val){
      //         }
      //         else{
      //         }
      //     }
      // });
      // });


    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    // return ;
    return (
      <>
        <AccountSelector
          open={this.state.account_select_modal}
          theme={this.props.theme}
          selected_account={this.props.user.selected_tradier_account}
          close={() => this.setState({ account_select_modal: false })}
          accounts={this.props.user.tradier_accounts}
          on_select_account={this.props.on_select_account}
        />
        <div>
          <Modal
            open={this.state.show_api_key_modal}
            close={() => this.setState({ show_api_key_modal: false })}
          >
            Test Modal
          </Modal>
        </div>
        {/* <div style={BUTTON_WRAPPER_STYLES} onClick={() => console.log('clicked')}>
        <button onClick={() => setIsOpen(true)}>Open Modal</button>

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          Fancy Modal
        </Modal>
      </div>

      <div style={OTHER_CONTENT_STYLES}>Other Content</div> */}

        <div
          style={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div
            id={this.props.containerId}
            className={"TVChartContainer"}
            style={{ height: "100%" }}
          />
        </div>
      </>
    );
  }
}

// class Application extends React.Component {
//   constructor() {
//     super();
//     this.state = {};
//   }

//   render() {
//     return (
//       <div>
//         <h1>ReactJS Resizable Panels</h1>
//         <ResizablePanels>
//           <div>
//             This is the first panel. It will use the rest of the available
//             space.
//           </div>
//           <div>This is the second panel. Starts with 300px.</div>
//           <div>This is the third panel. Starts with 300px.</div>
//         </ResizablePanels>
//       </div>
//     );
//   }
// }

/*
 * Render the above component into the div#app
 */
// ReactDOM.render(<Application />, document.getElementById('app'));
