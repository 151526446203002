/**
 * Charting Library API/Method -> createStudy creates a new instance of indicator on the chart.
 * The AddIndicator method is used in creation of new instane of Db version algosignals.
 * @param {*} tvWidget 
 * @param {*} index 
 * @function AddIndicator
 */
const AddIndicator = (tvWidget,index) => {

  //#region Global variable for shapes when it is true then don't plot the shapes else plot the shapes
    window.parent.DBsignal = true;
  //#endregion Global variable for shapes when it is true then don't plot the shapes else plot the shapes

  console.log("Creating new indicator")
  
  //#region creates a new instance of indicator on the chart.
  tvWidget.chart().createStudy('AlgosignalMongoDb', false, true).then(ID => {
    
    //#region Check if the ID is there then add the ID to the Array
    if(ID){

      //Global Array having an indicator instance created ID's
        window.parent.indicatorID[index] = ID;
      // console.log("indicatorID[index]",window.parent.indicatorID);

    }
    //#endregion Check if the ID is there then add the ID to the Array    
  
  });
  //#endregion creates a new instance of indicator on the chart.   

}
export default AddIndicator;