import chartLayoutAPI from '../endpoints/chartapi';
let chartLayoutObj = new chartLayoutAPI();
/**
 * Load the chartlayouts from the DB. Async await is used to handles the promise.
 * @param {*} chart 
 * @function loadChartList
 */
export default async function loadChartList(chart) {

  //#region Handling the promise-method to "GET" all the chart layouts
      chart = await chartLayoutObj.getallcharts();
  //#endregion Handling the promise-method to "GET" all the chart layouts

  //#region Returning the response
      return chart;
  //#endregion Returning the response

}