/* eslint-disable no-unused-expressions */
/**
 * This module is dependent on the weeklyOptionsSymbolList API service - 
 * which is designed to get a list of all of the stock splits associated with all the weeklies stock symbols
 * from our database.  This is used to integrate the stock splits into our performance considerations.
 * @function stockSplits
 * @class StockSplits
 */
import {stocksplitsSymbol} from '../config/middleware.links';
import{getAllsplitData} from '../config/middleware.links'
const axios = require("axios");
const { token } = require('../middleware/getToken');
// const apiURL = 'http://localhost:3030/stocksplits/search';
window.stocksplitsVal = [];
export default class StockSplits {

        //#region Function for handling the stocksplits 
	stockSplits = (symbol) => {
                let token = localStorage.getItem("jwtToken");
                //#region **chatAt** is the method that returns the character from a string. In our case we are looking for "*"
                        if(symbol.charAt(symbol.length - 1) == '*')
                //#endregion **chatAt** is the method that returns the character from a string. In our case we are looking for "*"

                //#region Stocksplits collection have symbols without "*" so we are removing the "*" from a string so we can hit the endpoint to get the response against the symbol
                         symbol = symbol.replace('*','')
                         
                //#endregion Stocksplits collection have symbols without "*" so we are removing the "*" from a string so we can hit the endpoint to get the response against the symbol                
    
                //#region Hit the GET request to get the data against the symbol
                        // token().then( (token) => {   
                                // console.log("Symbol: " ,  symbol)   
                                // return 
                                axios({
                                method: "get",
                                url: stocksplitsSymbol,
                                params: { symbol: symbol},
                                headers: { Authorization: token }
                                }).then(( res ) =>{
                                window.stocksplitsVal = res.data;
                                // console.log("Split Exit Data By symbol:", window.stocksplitsVal)
                                return res.data                                
                                }).catch((e) => console.log("axios error"))                                
                        // }).catch( (e) => console.log(e)) 
                //#endregion Hit the GEt request to get the data against the symbol
        }
        //#endregion Function for handling the stocksplits

         //#region Method to get all the stocksplits 
	getAllstockSplits = () => {

                //#region Hit the GET request to get the data against the symbol
                        // token().then( (token) => {      
                                // return 
                                let token = localStorage.getItem("jwtToken");
                                axios({
                                method: "get",
                                url: getAllsplitData,
                                headers: { Authorization:  token}
                                }).then(( res ) =>{
                                window.parent.getAllstockSplitsData = res.data;
                                // console.log("Split Exit Data :", window.parent.getAllstockSplitsData);

                                return res.data                                
                                }).catch((e) => console.log("axios error"))                                
                        // }).catch( (e) => console.log(e)) 
                //#endregion Hit the GEt request to get the data against the symbol
        }
        //#endregion Method to get all the stocksplits 
}