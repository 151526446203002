/**
 * refreshMarks refresh the "marker/callouts -> getmarks and timescalemarks" on the chart.
 * @param {*} tvWidget 
 * @function refreshMarks
 */
const refreshMarks = (tvWidget) => {
  
  //#region Used to refresh the callouts/markrs  - So there values get upadted 
    tvWidget.chart().refreshMarks();
  //#endregion Used to refresh the callouts/markrs  - So there values get upadted 
  
}
export default refreshMarks;