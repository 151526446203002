import React from "react";
import { connect } from "react-redux";
import set_auth_token from "./../../services/setAuthToken";
import { get_user } from "./../../actions/auth.action";

const OAuthRedirect = ({ get_user, history, auth }) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  localStorage.setItem('jwtToken', token)
  set_auth_token(token);
  get_user();
  if(!auth.is_completed) history.push('/complete_profile')
  return <></>;
};

export default connect(state => ({ auth: state.auth }), { get_user })(
  OAuthRedirect
);
