/**
 * removeIndicator : removes an instance of indicator from the chart. When we create an 
 * indicator on the chart the charting library creates an ID of an indicator instance 
 * we are passing the ID to remove that instance from the chart.
 * @param {*} tvWidget 
 * @param {*} ID 
 * @function removeIndicator
 */
const removeIndicator = (tvWidget,ID) => {
  
  //#region Remove Entitty like instance of indicator, shapes - Used to remove them(Indicator Instance and shapes) from chart
    tvWidget.chart().removeEntity(ID)
  //#endregion Remove Entitty like instance of indicator, shapes - Used to remove them(Indicator Instance and shapes) from chart
  
  console.log("Remove indicator: " + window.parent.indicatorName, "ID: ", ID )

}
export default removeIndicator;