import React from "react";
import { connect } from "react-redux";

const Table = props => {
  // const columns = props.columns;
  const columns = props.columns;
  localStorage.setItem("coloumns", JSON.stringify(columns));

  const numFixer = num => {
    let new_number;
    
    if(!Boolean(num)) return 0
    let split_array = num.toString().split(".");
    if (split_array.length >= 2  && Boolean(num)) {
      let nonDec = split_array[0];
      let stdec = "" + split_array[1].charAt(0);
      // let nddec = "" + split_array[1].charAt(1);
      // let rddec = "" + split_array[1].charAt(2);
      new_number = nonDec + "." + stdec
    } else {
      new_number = num;
    }

    return new_number;
  };

  const handleDragStart = e => {
    const { id } = e.target;
    const idx = columns.findIndex(ele => ele.name === id);
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = e => e.preventDefault();
  const handleDragEnter = e => {};

  const handleOnDrop = e => {
    const { id } = e.target;
    const droppedColIdx = columns.findIndex(ele => ele.name === id);
    const draggedColIdx = e.dataTransfer.getData("colIdx");
    const tempCols = [...columns];

    tempCols[draggedColIdx] = columns[droppedColIdx];
    tempCols[droppedColIdx] = columns[draggedColIdx];
    props.select_colums(tempCols);
  };
  const rows = props.rows.map(row => {
    let newRow = {};
    columns.forEach(c => {
      if (c.type === "greek") return (newRow[c.name] = row.greeks[c.name]);
      newRow[c.name] = row[c.name];
    });
    return newRow;
  });

  return (
    <>
      <table
        className={`table ${
          props.theme === "Light" ? "table-light" : "table-dark"
        }`}
        style={{
          fontSize: "12px",
          backgroundColor: props.theme === "Light" ? "white" : "#131721",
          color: props.theme === "Dark" ? "#d7d8db" : "#131721"
        }}
      >
        <thead>
          <tr>
            {columns.map(col => (
              <th
                style={{ cursor: "move" }}
                id={col.name}
                key={col.name}
                draggable
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDrop={handleOnDrop}
                onDragEnter={handleDragEnter}
                // dragOver={col.name === dragOver}
              >
                <div id={col.name}>{col.title}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            let strike = props.rows[i].strike;
            let option_type = props.rows[i].option_type;

            // let symbol = props.rows[i].symbol;
            let is_shaded =
              option_type === "call"
                ? strike > props.last_price
                  ? true
                  : false
                : strike < props.last_price
                ? true
                : false;
            return (
              <tr
                key={strike}
                onClick={() => props.set_info_ticket(props.rows[i])}
                style={{
                  backgroundColor: is_shaded
                    ? props.theme === "Dark"
                      ? ""
                      : "rgba(105, 120, 163, 0.712)"
                    : ""
                }}
                className={`${
                  is_shaded
                    ? props.theme === "Dark"
                      ? "bg-dark"
                      : "text-light"
                    : ""
                }`}
              >
                {Object.keys(row).map(r => (
                  <td
                    key={r}
                    onClick={e => {
                      if (r === "bid" || r === "ask") {
                        e.stopPropagation();
                        props.set_option_ticket(props.rows[i]);
                      }
                    }}
                  >
                    {numFixer(row[r])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default connect(null, {})(Table);
