/**
 * @param {*} barsAgo 
 * @param {*} instance 
 * @param {*} lookBackPeriod 
 * @param {*} Strength 
 * @param {*} context 
 * @function swingHighSwings
 */
const SwingHighBar = (barsAgo, instance, lookBackPeriod, Strength, context) => {
        
        //Update();
        //var ReturnValue = null;
        for (var idx = context.CurrentBar - barsAgo - Strength; idx >= context.CurrentBar - barsAgo - Strength - lookBackPeriod; idx--) {
           // console.log("idx: " + idx + "  idx >= context.swingHighSwings.length: " + (idx >= context.swingHighSwings.length) + "   instance: " + instance);
            if (idx < 0)
                return -1;

            if (idx >= context.swingHighSwings.length)
                continue;

            if (context.swingHighSwings[idx] == 0.0) {
              //console.log("  context.swingHighSwings["+idx+"]:  " + context.swingHighSwings[idx]);
              
              if(context.CurrentBar == 42) {
                //console.log("  context.swingHighSwings["+23+"]:  " + context.swingHighSwings[23]);
              }
              continue;

            } else {
              //console.log(" -------------------- context.swingHighSwings["+idx+"]:  " + context.swingHighSwings[idx]);
            }

            if (instance <= 1) { // 1-based, < to be save              
              //  console.log("CurrentBar: " + context.CurrentBar + "  lookBackPeriod:" + lookBackPeriod + "  con " + (context.CurrentBar - idx) + "  barsAgo" + barsAgo + " strangth  " + Strength + " idx:" + idx);
              //console.log("CurrentBar: " + context.CurrentBar + "  lookBackPeriod:"+ lookBackPeriod + "  con " + (context.CurrentBar - idx ) + "  barsAgo" + barsAgo + " Strength " + Strength + " idx:" + idx + "  swingHighSwings.GetValueAt(idx):" + context.swingHighSwings[idx] + "swingHighSwings.Count: " + context.swingHighSwings.length);
              return context.CurrentBar - idx;
            }
            instance--;
        }
        return -1;
    
}
export {SwingHighBar};