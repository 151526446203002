import axios from "axios";
import calc_greeks from "../services/calcgreeks/index";
import spread_calculator from "./../services/option_ticket/market_calculator";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../config/links.config'
import { token } from '../../../configMiddleware/getToken';
var io = require("socket.io-client");
//var socket_url = "/stream";
// var socket_url = `${orderURL}/stream`;
// var socket_url = 'http://localhost:5001/stream';
var socket_url = 'https://cstream.algotechapis.com/stream'
// var socket_url = 'http://localhost:5000/stream';
var socket = io(socket_url);

export const set_info_ticket = option => (dispatch, getState) => {
  socket.disconnect();
  dispatch({ type: "SET_INFO_TICKET", payload: option });
};

export const close_info_ticket = () => dispatch => {
  socket.disconnect();
  dispatch({ type: "CLOSE_INFO_TICKET" });
};

export const set_option_ticket = (option, type) => (dispatch, getState) => {
  let token = localStorage.getItem("jwtToken");
  axios
    .get(
      `${optionsURL}/api/options/get_strikes?symbol=${option.root_symbol}&expiration=${option.expiration_date}`,
      {headers: { Authorization:  token }}
    )
    .then(res => {
      option.all_strikes = res.data;
      option.action = "buy";
      option.quantity = 1;
      dispatch({ type: "SET_OPTION_TICKET", payload: option });
      dispatch(get_expiration(false));
      dispatch(calc_spread());
      // dispatch(update_option());
    });

    //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_strikes`,
      //         params : {
      //           symbol: option.root_symbol,
      //           expiration : option.expiration_date
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     option.all_strikes = res.data;
      //     option.action = "buy";
      //     option.quantity = 1;
      //     dispatch({ type: "SET_OPTION_TICKET", payload: option });
      //     dispatch(get_expiration(false));
      //     dispatch(calc_spread());
      //     // dispatch(update_option());
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => console.log(e))
//#endregion auth endpoint

};

// export const set_options_ticket = (un_symbol, options) => (dispatch, getState) => {
//   // const un_symbol = getState().ticket.un_symbol;
//   const selected_options = [...getState().ticket.selected_options];
//   const type = selected_options[i].option_type;
//   const expiry = selected_options[i].expiration_date;
//   const all_strikes = selected_options[i].all_strikes;
//   let opt = options.map((opt, i)=>{
//     const type = opt.type;
//     const type
//   })
//   axios
//     .get(
//       `/api/options/get_options_chain?symbol=${un_symbol}&expiration=${expiry}&greeks=true&strike=${strike}&option_type=${type}`
//     )
//     .then(n_res => {
//       selected_options[i] = n_res.data[0];
//       selected_options[i]["all_strikes"] = all_strikes;
//       selected_options[i]["action"] = "buy";
//       selected_options[i]["quantity"] = 1;
//       dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
//       dispatch(calc_spread());
//     });
// };

export const on_symbol_change = input => dispatch => {
  // const symbol = JSON.parse(
  //   localStorage.getItem("all_symbols")
  // ).filter(symbol => symbol.symbol.includes(input.toUpperCase()));
  // if (symbol.length)
  dispatch({ type: "SET_TICKET_SYMBOL", payload: input.toUpperCase() });
};

export const add_stock = () => (dispatch, getState) => {
  const symbol = getState().ticket.un_symbol;
  // axios
  //   .get(
  //     `${orderURL}/api/order_ticket_ticket/get_quotes?symbol=${symbol}&type=stock`
  //   )
  //   .then(res => {
  //     let data = res.data;
  //     data.action = "buy";
  //     data.quantity = 100;
  //     dispatch({ type: "ADD_STOCK", payload: data });
  //   });

    //#region auth endpoint
    token().then( (token) => {      
      return axios({
            method: "get",
            url: `${orderURL}/api/order_ticket_ticket/get_quotes`,
            params : {
                  symbol : symbol,
                  type : "stock"
      },
            headers: { Authorization: "Bearer " + token }
      }).then(( res ) =>{
        let data = res.data;
        data.action = "buy";
        data.quantity = 100;
        dispatch({ type: "ADD_STOCK", payload: data });
      }).catch((e) => console.log("axios error"))
      }).catch( (e) => console.log(e))
  //#endregion auth endpoint


};

export const on_select_symbol = e => (dispatch, getState) => {
  // console.log(e.target)
  if (e.keyCode === undefined || e.keyCode === 13) {
    let match = Boolean(
      JSON.parse(localStorage.getItem("all_symbols")).filter(
        symbol => symbol.symbol === getState().ticket.selecting_un_symbol
      ).length
    );

    e.target.blur();
    if (match) {
      dispatch({
        type: "SET_P_TICKET_SYMBOL",
        payload: getState().ticket.selecting_un_symbol
      });
      dispatch(get_expiration(true));
    } else return dispatch({ type: "SET_P_TICKET_SYMBOL_ERROR" });
  }
};

export const get_expiration = add => (dispatch, getState) => {
  const symbol = getState().ticket.un_symbol;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(`${optionsURL}/api/options/get_expirations?symbol=${symbol}`,{headers: { Authorization:  token }})
    .then(res => {
      dispatch({ type: "GET_EXPIRATION_TICKET", payload: res.data });
      if (add) {
        dispatch(add_option_ticket(res.data[0]));
      }
    })
    .catch(e => dispatch({ type: "ERROR", payload: e }));

    //#region auth endpoint
    // token().then( (token) => {      
    //   return axios({
    //         method: "get",
    //         url: `${optionsURL}/api/options/get_expirations`,
    //         params : {
    //           symbol: symbol
    //   },
    //         headers: { Authorization: "Bearer " + token }
    //   }).then(( res ) =>{
    //     dispatch({ type: "GET_EXPIRATION_TICKET", payload: res.data });
    //   if (add) {
    //     dispatch(add_option_ticket(res.data[0]));
    //   }
    //         return res.data
    //   }).catch((e) => console.log("axios error"))
    //   }).catch( (e) => dispatch({ type: "ERROR", payload: e }))
//#endregion auth endpoint

};

export const on_delete_option = index => (dispatch, getState) => {
  const selected_options = [...getState().ticket.selected_options];
  alert(index);
  alert(selected_options[index].type);
  const type = selected_options[index].type;
  selected_options.splice(index, 1);
  dispatch({
    type: "REMOVE_OPTION",
    payload: {
      opt: selected_options,
      isStockDeleted: type == "stock" ? true : ""
    }
  });
  dispatch(calc_spread());
};

export const on_change_duration = value => ({
  type: "DURATION_CHANGE",
  payload: value
});

export const add_option_ticket = (expiry, type) => (dispatch, getState) => {
  expiry = Boolean(expiry) ? expiry : getState().ticket.all_expirations[0];
  const symbol = getState().ticket.un_symbol;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(`${optionsURL}/api/options/get_strikes?symbol=${symbol}&expiration=${expiry}`,
    {headers: { Authorization:  token }})
    .then(res => {
      const strikes = res.data;
      axios
        .get(
          `${optionsURL}/api/options/get_options_chain?symbol=${symbol}&expiration=${expiry}&greeks=true&strike=${strikes[0]}`,
          {headers: { Authorization:  token }}
        )
        .then(n_res => {
          let option = n_res.data;
          type = type ? type : "call";
          option = option.filter(opt => opt.option_type === type)[0];
          option.all_strikes = strikes;
          option.action = "buy";
          option.quantity = 1;
          // spread_calculator("open", getState().ticket.selected_options)
          dispatch({ type: "ADD_OPTION_TICKET", payload: option });
          dispatch(calc_spread());
        });
    });

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_strikes`,
      //         params : {
      //           symbol: symbol,
      //           expiration : expiry
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     const strikes = res.data;
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_options_chain`,
      //         params : {
      //           symbol: symbol,
      //           expiration : expiry,
      //           greeks : true,
      //           strike : strikes[0]

      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( n_res ) =>{
      //     let option = n_res.data;
      //     type = type ? type : "call";
      //     option = option.filter(opt => opt.option_type === type)[0];
      //     option.all_strikes = strikes;
      //     option.action = "buy";
      //     option.quantity = 1;
      //     // spread_calculator("open", getState().ticket.selected_options)
      //     dispatch({ type: "ADD_OPTION_TICKET", payload: option });
      //     dispatch(calc_spread());
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => console.log(e))

      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => console.log(e))
//#endregion auth endpoint

};

export const on_change_expiry = (expiry, i) => (dispatch, getState) => {
  // console.log(expiry, i, "hello");
  const un_symbol = getState().ticket.un_symbol;
  const selected_options = [...getState().ticket.selected_options];
  selected_options[i].expiration_date = expiry;
  const type = selected_options[i].option_type;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(`${optionsURL}/api/options/get_strikes?symbol=${un_symbol}&expiration=${expiry}`,{headers: { Authorization:  token }})
    .then(res => {
      const all_strikes = res.data;
      axios
        .get(
          `${optionsURL}/api/options/get_options_chain?symbol=${un_symbol}&expiration=${expiry}&greeks=true&strike=${all_strikes[0]}`,
          {headers: { Authorization:  token }}
        )
        .then(n_res => {
          selected_options[i] = n_res.data.filter(
            d => d.option_type === type
          )[0];
          selected_options[i]["all_strikes"] = all_strikes;
          selected_options[i]["quantity"] = 1;
          dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
          dispatch(calc_spread());
        });
    });

     //#region auth endpoint
    //  token().then( (token) => {      
    //   return axios({
    //         method: "get",
    //         url: `${optionsURL}/api/options/get_strikes`,
    //         params : {
    //           symbol: un_symbol,
    //           expiration : expiry
    //   },
    //         headers: { Authorization: "Bearer " + token }
    //   }).then(( res ) =>{
    //     const all_strikes = res.data;
    // token().then( (token) => {      
    //   return axios({
    //         method: "get",
    //         url: `${optionsURL}/api/options/get_options_chain`,
    //         params : {
    //           symbol: un_symbol,
    //           expiration : expiry,
    //           greeks : true,
    //           strike : all_strikes[0]

    //   },
    //         headers: { Authorization: "Bearer " + token }
    //   }).then(( n_res ) =>{
    //     selected_options[i] = n_res.data.filter(
    //       d => d.option_type === type
    //     )[0];
    //     selected_options[i]["all_strikes"] = all_strikes;
    //     selected_options[i]["quantity"] = 1;
    //     dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
    //     dispatch(calc_spread());
    //   }).catch((e) => console.log("axios error"))
    //   }).catch( (e) => console.log(e))

    //   }).catch((e) => console.log("axios error"))
    //   }).catch( (e) => console.log(e))
//#endregion auth endpoint



};

export const on_change_strike = (strike, i) => (dispatch, getState) => {
  // console.log(expiry, i, "hello");
  const un_symbol = getState().ticket.un_symbol;
  const selected_options = [...getState().ticket.selected_options];
  const type = selected_options[i].option_type;
  const expiry = selected_options[i].expiration_date;
  const all_strikes = selected_options[i].all_strikes;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(
      `${optionsURL}/api/options/get_options_chain?symbol=${un_symbol}&expiration=${expiry}&greeks=true&strike=${strike}&option_type=${type}`,
      {headers: { Authorization:  token }}
    )
    .then(n_res => {
      selected_options[i] = n_res.data[0];
      selected_options[i]["all_strikes"] = all_strikes;
      selected_options[i]["action"] = "buy";
      selected_options[i]["quantity"] = 1;
      dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
      dispatch(calc_spread());
    });

    //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_options_chain`,
      //         params : {
      //           symbol: un_symbol,
      //           expiration : expiry,
      //           greeks : true,
      //           strikes : strike,
      //           option_type : type

      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( n_res ) =>{
      //     selected_options[i] = n_res.data[0];
      //     selected_options[i]["all_strikes"] = all_strikes;
      //     selected_options[i]["action"] = "buy";
      //     selected_options[i]["quantity"] = 1;
      //     dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
      //     dispatch(calc_spread());
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => console.log(e))
//#endregion auth endpoint


};

export const on_change_type = i => (dispatch, getState) => {
  const un_symbol = getState().ticket.un_symbol;
  const selected_options = [...getState().ticket.selected_options];
  const type = selected_options[i].option_type === "put" ? "call" : "put";
  const expiry = selected_options[i].expiration_date;
  const all_strikes = selected_options[i].all_strikes;
  const strike = selected_options[i].strike;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(
      `${optionsURL}/api/options/get_options_chain?symbol=${un_symbol}&expiration=${expiry}&greeks=true&strike=${strike}&option_type=${type}`,
      {headers: { Authorization:  token }}
    )
    .then(n_res => {
      selected_options[i] = n_res.data[0];
      selected_options[i]["all_strikes"] = all_strikes;
      selected_options[i]["quantity"] = 1;
      dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
      dispatch(calc_spread());
    });

        //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_options_chain`,
      //         params : {
      //           symbol: un_symbol,
      //           expiration : expiry,
      //           greeks : true,
      //           strikes : strike,
      //           option_type : type

      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( n_res ) =>{
      //   selected_options[i] = n_res.data[0];
      //   selected_options[i]["all_strikes"] = all_strikes;
      //   selected_options[i]["quantity"] = 1;
      //   dispatch({ type: "UPDATE_OPTION_TICKET", payload: selected_options });
      //   dispatch(calc_spread());
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => console.log(e))
//#endregion auth endpoint



};

export const update_option = () => (dispatch, getState) => {
  socket.disconnect();
  let last_price = 265;
  const all_options_symbol = getState().ticket.selected_options.map(option => ({
    symbol: option.symbol,
    type: option.option_type,
    option: option
  }));
  socket.connect();
  socket.emit("SubAdd", {
    subs: getState().ticket.selected_options.map(opt => opt.symbol)
  });
  socket.on("connect", () => {});
  socket.on("disconnect", e => {
    console.log("===Socket disconnected:", e);
  });
  socket.on("error", err => {
    console.log("====socket error", err);
  });

  let upt = getState().ticket.selected_options;
  socket.on("m", e => {
    all_options_symbol.forEach((symbol, i) => {
      if (symbol.symbol === e.symbol) {
        if (e.type === "trade") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: { ...option, volume: e.cvol, last: e.last }
          };
          calc_greeks(
            update.update,
            option.expiration_date,
            option.root_symbol,
            last_price
          ).then(data => {
            if (Object.keys(data).length) {
              console.log("data", data);
              upt[i] = { ...data };
              dispatch({
                type: "UPDATE_OPTION_TICKET",
                payload: [...upt]
              });
            } else {
              upt[i] = { update };
              dispatch({ type: "UPDATE_OPTION_TICKET", payload: [...upt] });
            }
          });
        } else if (e.type === "summary") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: {
              ...option,
              high: e.high,
              low: e.low,
              open: e.open,
              open_interest: e.openInterest,
              prevclose: e.prevClose
            }
          };
          calc_greeks(
            update.update,
            option.expiration_date,
            option.root_symbol,
            last_price
          ).then(data => {
            console.log("data", data);
            if (Object.keys(data).length) {
              upt[i] = { ...data };
              dispatch({
                type: "UPDATE_OPTION_TICKET",
                payload: [...upt]
              });
            } else {
              upt[i] = { update };
              dispatch({ type: "UPDATE_OPTION_TICKET", payload: [...upt] });
            }
          });
        } else if (e.type === "quote") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: {
              ...option,
              ask_date: e.askdate,
              ask: e.ask,
              asksize: e.asksz,
              bid_date: e.biddate,
              bid: e.bid,
              bidsize: e.bidsz
            }
          };
          calc_greeks(
            update.update,
            option.expiration_date,
            option.root_symbol,
            last_price
          ).then(data => {
            console.log("data", data);
            if (Object.keys("data", data).length) {
              upt[i] = { ...data };
              dispatch({
                type: "UPDATE_OPTION_TICKET",
                payload: [...upt]
              });
            } else {
              upt[i] = { update };
              dispatch({ type: "UPDATE_OPTION_TICKET", payload: [...upt] });
            }
          });
        }
      }
    });
  });
};

export const on_change_quantity = (value, index) => (dispatch, getState) => {
  if (typeof Number(value) === "number" && isInt(value) && value > 0) {
    const options = [...getState().ticket.selected_options];
    options[index].quantity = value;
    dispatch({ type: "UPDATE_OPTION_TICKET", payload: options });
    dispatch(calc_spread());
  } else {
    alert("quantity must be non 0 and integer value");
  }
};

export const set_options = option => {};

export const get_option = (strike, symbol, all_strikes) => {};

export const close_option_ticket = () => {
  return { type: "CLOSE_OPTION_TICKET" };
};

export const on_change_trade_type = value => dispatch => {
  if (value === null) return false;
  dispatch({ type: "TRADE_TYPE_CHANGE" });
  dispatch(calc_spread());
};

export const on_change_option_action = (value, index) => (
  dispatch,
  getState
) => {
  if (value === null) return false;
  const options = [...getState().ticket.selected_options];
  options[index].action = options[index].action === "buy" ? "sell" : "buy";
  dispatch({ type: "CHANGE_OPTION_ACTION", payload: options });
  dispatch(calc_spread());
};

export const on_change_price_type = value => dispatch =>
  dispatch({ type: "CHANGE_PRICE_TYPE", payload: value });

export const calc_spread = () => (dispatch, getState) => {
  const side = getState().ticket.trade_type;
  const options = [...getState().ticket.selected_options];
  const spread = spread_calculator(side, options);
  console.log(spread);
  dispatch({ type: "CALC_SPREAD", payload: spread });
};

export const on_change_limit_price = value => dispatch => {
  console.log(value);
  if (typeof Number(value) === "number" && !(value < 0)) {
    dispatch({ type: "CHANGE_LIMIT_PRICE", payload: Number(value) });
  } else {
    alert("Limit must be not less than 0 ");
  }
};

export const on_change_stop_price = value => dispatch => {
  if (typeof Number(value) === "number" && !(value < 0)) {
    dispatch({ type: "CHANGE_STOP_PRICE", payload: Number(value) });
  } else {
    alert("Stop must be not less than 0 ");
  }
};

const build_multileg_order = (getState, preview) => {
  const {
    un_symbol,
    price_type,
    limit_price,
    trade_type,
    selected_options,
    duration
  } = getState().ticket;
  const opt_data = selected_options.map(opt => ({
    option_symbol: opt.symbol,
    side: opt.action,
    quantity: opt.quantity
  }));
  const url = `${orderURL}/api/order_ticket_ticket/place_multileg_order?preview=${preview}&symbol=${un_symbol}&type=${price_type}&duration=${duration}&side=${trade_type}&price=${limit_price}`;
  return { url, opt_data };
};

const build_option_order = (getState, preview) => {
  const {
    un_symbol,
    price_type,
    limit_price,
    trade_type,
    stop_price,
    selected_options,
    duration
  } = getState().ticket;
  const option = selected_options[0];
  const { action, symbol, quantity } = option;
  const url = `${orderURL}/api/order_ticket_ticket/place_option_order?preview=${preview}&symbol=${un_symbol}&option_symbol=${symbol}&type=${price_type}&quantity=${quantity}&duration=${duration}&side=${action +
    "_to_" +
    trade_type}&price=${limit_price}&stop_price=${stop_price}`;
  return { url, opt_data: [] };
};

const build_equity_order = (getState, preview) => {
  const {
    un_symbol,
    price_type,
    limit_price,
    trade_type,
    stop_price,
    selected_options,
    duration
  } = getState().ticket;
  const option = selected_options[0];
  const { action, symbol, quantity } = option;
  const url = `${orderURL}/api/order_ticket_ticket/place_equity_order?preview=${preview}&symbol=${un_symbol}&option_symbol=${symbol}&type=${price_type}&quantity=${quantity}&duration=${duration}&side=${action}&price=${limit_price}&stop_price=${stop_price}`;
  return { url, opt_data: [] };
};
const build_order = {
  option_order: build_option_order,
  multileg_order: build_multileg_order,
  equity_order: build_equity_order
};

export const place_order = preview => (dispatch, getState) => {
  let order_type;

  if (
    getState().ticket.selected_options.length < 2 &&
    !getState().ticket.isStockAdded
  )
    order_type = "option_order";
  else if (
    getState().ticket.selected_options.length > 1 &&
    !getState().ticket.isStockAdded
  )
    order_type = "multileg_order";
  else if (
    getState().ticket.selected_options.length < 2 &&
    getState().ticket.isStockAdded
  )
    order_type = "equity_order";
  else if (
    getState().ticket.selected_options.length > 1 &&
    getState().ticket.isStockAdded
  )
    order_type = "equity_order";
  const { url, opt_data } = build_order[order_type](getState, preview);
  console.log(url);

  // axios.post(url, opt_data).then(res => {
  //   if ("errors" in res.data)
  //     return alert("invalid order " + res.data.errors.error);
  //   console.log(res.data);
  //   if (preview)
  //     return dispatch({ type: "PREVIEW_ORDER", payload: res.data.order });
  //   else return dispatch({ type: "PLACE_ORDER", payload: res.data.order });
  // });

  token().then( (token) => {      
    return axios({
          method: "post",
          url: url,
          data: opt_data,
          headers: { Authorization: "Bearer " + token }
    }).then(( res ) =>{
      if ("errors" in res.data)
      return alert("invalid order " + res.data.errors.error);
    console.log(res.data);
    if (preview)
      return dispatch({ type: "PREVIEW_ORDER", payload: res.data.order });
    else return dispatch({ type: "PLACE_ORDER", payload: res.data.order });
    }).catch((e) => console.log("axios error"))
    }).catch( (e) => {if (e.response) console.log(e.response.data);})
};

export const edit_order = () => ({ type: "EDIT_ORDER" });

// symbol, type, duration, price
/***
  let data = [
    {
      option_symbol: "",
      side: "",
      quantity: ""
    },
    {
      option_symbol: "",
      side: "",
      quantity: ""
    }
  ];
 */
function isInt(n) {
  return n % 1 === 0;
}
