
// const optionsURL = 'http://localhost:5000';
// const chartURL = 'http://localhost:5000';
// const authURL = 'http://localhost:5000';
// const acctURL = 'http://localhost:5000';
// const orderURL = 'http://localhost:5000';
// const optionsURL = 'http://localhost:5000';
// const authURL = 'http://localhost:7076';
// const acctURL = 'http://localhost:7078';
// const acctURL = 'http://localhost:7082';//Azure Middleware approach
// const chartURL = 'http://localhost:7072';


const chartURL = 'https://charthistoricaldriver.azurewebsites.net';
const authURL = 'https://authdriverexp.azurewebsites.net';
const acctURL = 'https://accountdriverexp.azurewebsites.net';
const optionsURL = 'https://optionsdriverexp.azurewebsites.net';
const orderURL = 'https://orderticketdriverexp.azurewebsites.net';
// const optionsURL = 'http://localhost:7076';
// const orderURL = 'http://localhost:5004';

const configServer = 'https://algopti-config.herokuapp.com/api/dev/get_config'

export {optionsURL, chartURL, authURL, acctURL, orderURL, configServer}
// 