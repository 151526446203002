import chartLayoutAPI from '../endpoints/chartapi';
let chartLayoutObj = new chartLayoutAPI();
/**
 * remove the chart from the DB on the basis of chartID.
 * @param chartId
 * @function removeChartData
 */
export default function removeChartData(chartId){
  console.log("ChartID: ", chartId)

//#region Calling the "Remove" method to remove the chartlayout
     chartLayoutObj.removeByID(chartId);
//#endregion Calling the "Remove" method to remove the chartlayout

  }