import axios from "axios";
import set_Auth_Token from "./../services/setAuthToken";
import set_tradier_account from "./../services/setTradierAccount";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../config/links.config'
import swal from 'sweetalert';
// export const on_input_change = e => ({
//   type: "ON_INPUT_CHANGE",
//   payload: e.target
// });

// export const login = () => (dispatch, getState) => {
//   const user = getState().auth;
//   console.log("user : ",user);
//   // const _id = user._id;
//   axios
//     .post(`${authURL}/api/auth/login`, user)
//     .then(res => {
//       console.log("Res : ", res)
//       localStorage.setItem("jwtToken", res.data);
//       window.parent.uToken = res.data;
//       if(res.status == 201){
//         console.log("status  :", res.status);
//         swal({
//           title: "Are you sure you want to proceed?",
//           text: "If you continue logging in via this device, your other session will be disconnected.!",
//           icon: "warning",
//           buttons: true,
//           dangerMode: true,
//         })
//         .then((willDelete) => {
//           if (willDelete) {
//             swal("Logout successfully!", {
//               icon: "success",
//             }).then(()=>{
//               console.log("Logout hit**");
//               axios({
//                 method: "post",
//                 url: `${authURL}/api/auth/logout`,
//                 headers: { Authorization: res.data}
//             }).then(( res ) =>{
//               localStorage.removeItem("jwtToken");   
//               window.parent.uToken = null;
//             }).catch((e) => {
//               console.log("error : ", e)
//             })
//             })
            
            
//           }
//           //  else {
//           //   swal("Your imaginary file is safe!");
//           // }
//         });

//       }
//       else{set_Auth_Token(res.data);
//         dispatch(get_user());}
      
//     })
//     .catch(e => {
//       if (e.response && e.response.status >= 400 && e.response.status <= 500) {
//         dispatch({ type: "ERROR", payload: e.response.data });
//       }
//     });
// };
// export const register = history => (dispatch, getState) => {
//   const user = getState().auth;
//   axios
//     .post(`${authURL}/api/auth/register`, user)
//     .then(res => history.push("/login"))
//     .catch(e => {
//       if (e.response && e.response.status >= 400 && e.response.status <= 500) {
//         dispatch({ type: "ERROR", payload: e.response.data });
//       }
//     });
// };

// export const get_user = () => dispatch => {
//   axios
//     .get(`${authURL}/api/auth/me`)
//     .then(res => {
//       if (res.data.tradier_accounts.length) {
//         set_tradier_account(res.data.tradier_accounts[0].account_number);
//       }
//       dispatch({ type: "SET_USER", payload: res.data });
//     })
//     .catch(e => {
//       localStorage.removeItem("jwtToken");
//       window.parent.uToken = null;
//       set_Auth_Token(false);
//       dispatch({ type: "ERROR", payload: e.response.data });
//     });
// };

// export const complete_profile = () => (dispatch, getState) => {
//   const user = getState().auth;
//   const _id = user._id;
//   axios
//     .post(`${authURL}/api/auth/complete_profile/${_id}`, user)
//     .then(res => dispatch({ type: "SET_USER", payload: res.data }))
//     .catch(e => {
//       if (e.response && e.response.status >= 400 && e.response.status <= 500) {
//         dispatch({ type: "ERROR", payload: e.response.data });
//       }
//     });
// };

// export const logout = () => dispatch => {
//   let token = localStorage.getItem("jwtToken");
//   // let token = window.parent.uToken;
//   console.log("token : ", token)
//   axios({
//   method: "post",
//   url: `${authURL}/api/auth/logout`,
//   headers: { Authorization: token}
// }).then(( res ) =>{
// localStorage.removeItem("jwtToken");
// window.parent.uToken = null;
// set_Auth_Token(false);
// dispatch({ type: "LOGOUT" });
   
// }).catch((e) => {
// console.log("error : ", e)
// dispatch({ type: "ERROR", payload: e.response.data });
// })
// };




export const on_input_change = e => ({
  type: "ON_INPUT_CHANGE",
  payload: e.target
});
export const setCurrentUser = user => ({
  type: "SET_CURRENT_USER",
  payload: user
});

export const login = () => (dispatch, getState) => {
  console.log("***********************LOGIN************************")
  const user = getState().auth;
  // console.log("user : ",user);
  // const _id = user._id;
  axios
    .post(`${authURL}/api/auth/login`, user)
    .then(res => {

      localStorage.setItem("jwtToken", res.data);
      set_Auth_Token(res.data);
      dispatch(get_user());
    })
    .catch(e => {
      if (e.response && e.response.status >= 400 && e.response.status <= 500) {
        dispatch({ type: "ERROR", payload: e.response.data });
      }
    });
};

export const register = history => (dispatch, getState) => {
  const user = getState().auth;
  user.tradier_ID = "_id"+user.username
  // console.log("Register user : ", user)
  axios
    .post(`${authURL}/api/auth/register`, user)
    .then(res => history.push("/login"))
    .catch(e => {
      if (e.response && e.response.status >= 400 && e.response.status <= 500) {
        dispatch({ type: "ERROR", payload: e.response.data });
      }
    });
};

export const get_user = () => (dispatch, getState) => {
  axios
    .get(`${authURL}/api/auth/me`)
    .then(res => {
      // console.log("Get user response : ", res.data)
      getState().auth = res.data;
      if (res.data.tradier_accounts.length) {
        set_tradier_account(res.data.tradier_accounts[0].account_number);
      }
      dispatch({ type: "SET_USER", payload: res.data });
    })
    .catch(e => {
      localStorage.removeItem("jwtToken");
      set_Auth_Token(false);
      dispatch({ type: "ERROR", payload: e.response.data });
    });
};

export const complete_profile = () => (dispatch, getState) => {
  const user = getState().auth;
  const _id = user._id;
  axios
    .post(`${authURL}/api/auth/complete_profile/${_id}`, user)
    .then(res => dispatch({ type: "SET_USER", payload: res.data }))
    .catch(e => {
      if (e.response && e.response.status >= 400 && e.response.status <= 500) {
        dispatch({ type: "ERROR", payload: e.response.data });
      }
    });
};

export const logout = () => dispatch => {
  console.log("********************Logout****")
  let token = localStorage.getItem("jwtToken");
  // console.log("token  : ", token)
    axios({
    method: "post",
    url: `${authURL}/api/auth/logout`,
    headers: { Authorization: token}
}).then(( res ) =>{
  localStorage.removeItem("jwtToken");
  set_Auth_Token(false);
  dispatch({ type: "LOGOUT" });
     
}).catch((e) => {
  console.log("error : ", e)
  dispatch({ type: "ERROR", payload: e.response.data });
})
};

export const ADDApiKEy = () => (dispatch, getState) => {
  console.log("***********************LOGIN************************")
  const user = getState().auth;
  // console.log("user : ",user);
  // const _id = user._id;
  axios
    .post(`${authURL}/api/auth/AddApiKey`, user)
    .then(res => {
      alert(res)
    })
    .catch(e => {
      if (e.response && e.response.status >= 400 && e.response.status <= 500) {
        dispatch({ type: "ERROR", payload: e.response.data });
      }
    });
};
