import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { widget } from "../charting_library/charting_library";
import { Resizable } from "re-resizable";
import BottomWidget from "../components/BottomWidget";
import TVChartContainer from "./../Apps/TvChartContainer/index";
import TicketApp from "./../Apps/Ticket";
import { select_tradier_account } from "./../actions/profile.action";
import { logout,APIModal } from "./../actions/auth.action";

// import { ConnectTradier } from '../Views/ConnectTradier/index'
const style = {};
function Main(props) {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "Light"
  );
  localStorage.setItem("theme", theme);

  useEffect(() => {
    if (!props.auth.is_Authenticated) return props.history.push("/login");
  }, [props.auth, props.history]);

  useEffect(() => {
    document.body.style.backgroundColor =
      theme === "Dark" ? "#131721" : "white";
  });
  const [width, setWidth] = React.useState((window.innerWidth * 80) / 100);
  const [width2, setWidth2] = React.useState((window.innerWidth * 20) / 100);

  const [height, setHeight] = React.useState(400);
  if (height > window.innerHeight - 50) {
    setHeight(window.innerHeight - 50);
  }
  return !props.auth.is_Authenticated ? (
    <h1>Redirecting</h1>
  ) : (
    <div className="App">
      <TicketApp theme={theme} />

      <div style={{ overflow: "", height: "100vh" }}>
        <div style={{ display: "inline-block", width: width }}>
          {" "}
          <Resizable
            style={style}
            size={{ width: width, height: "100vh" }}
            onResizeStop={(e, direction, ref, d) => {
              setWidth2(window.innerWidth - (width + d.width));
              setWidth(width + d.width);
            }}
          >
            <Resizable
              style={style}
              size={{ width: "100%", height }}
              onResizeStop={(e, direction, ref, d) => {
                setHeight(height + d.height);
              }}
            >
              <TVChartContainer
                theme={theme}
                setTheme={setTheme}
                widget={widget}
                height={height}
                user={props.auth}
                account_type={props.auth.account_type}
                on_select_account={account =>
                  props.select_tradier_account(account)
                }
                history = {props.history}
                on_logout={props.logout}
                // ConnectTradier = {props.ConnectTradier}
              />
            </Resizable>
            <div
              style={{
                overflowY: "scroll",
                height: "100%",
                paddingBottom: "500px",
                width: "100%"
              }}
              id="style-3"
            >
              <BottomWidget
                theme={theme}
                BottomWidgetStatus={height}
                setBottomWidgetStatus={setHeight}
                user={props.auth}
              />
            </div>
          </Resizable>
        </div>
        <div style={{ width: width2, display: "inline-block" }}>
          Side Toolbar
        </div>
        {/* <Resizable
          style={style}
          size={{ width: width2, height: "100vh" }}
          onResizeStop={(e, direction, ref, d) => {
            setWidth2(width2 + d.width);
          }}
        ></Resizable> */}
      </div>
    </div>
  );
}

export default connect(state => ({ auth: state.auth }), {
  select_tradier_account,
  logout,
  // ConnectTradier
})(Main);
