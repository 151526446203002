const initial_state = {
  infoTicket: false,
  ticket: false,
  selected_symbol: "",
  near: 0,
  un_symbol_error: false,
  selecting_un_symbol: "",
  un_symbol: "",
  selected_options: [],
  all_options: [],
  all_expirations: [],
  trade_type: "open",
  price_type: "market",
  isStockAdded: false,
  price_type_preset: [
    { title: "Market", value: "market" },
    { title: "Limit Net Debit", value: "debit" },
    { title: "Limit Net Credit", value: "credit" },
    { title: "Even", value: "even" }
  ],
  price_type_preset_single_option: [
    { title: "Market", value: "market" },
    { title: "Limit", value: "limit" },
    { title: "Stop", value: "stop" },
    { title: "Stop Limit", value: "stop_limit" }
  ],
  allColumns: [
    {
      name: "asksize",
      title: "Ask Size"
    },
    {
      name: "bidsize",
      title: "Bid Size"
    },
    {
      name: "open",
      title: "Open"
    },
    {
      name: "high",
      title: "High"
    },
    {
      name: "low",
      title: "Low"
    },
    {
      name: "last",
      title: "Last"
    },
    {
      name: "last_volume",
      title: "Last Volume"
    },

    {
      name: "open_interest",
      title: "Open Interest"
    },

    {
      name: "prevclose",
      title: "Previous Close"
    },
    {
      name: "ask_iv",
      title: "Ask IV",
      type: "greek"
    },
    {
      name: "bid_iv",
      title: "Bid IV",
      type: "greek"
    },
    {
      name: "delta",
      title: "Delta",
      type: "greek"
    },
    {
      name: "gamma",
      title: "Gamma",
      type: "greek"
    },
    {
      name: "mid_iv",
      title: "Mid IV",
      type: "greek"
    },
    {
      name: "phi",
      title: "Phi",
      type: "greek"
    },
    {
      name: "rho",
      title: "Rho",
      type: "greek"
    },
    {
      name: "theta",
      title: "Theta",
      type: "greek"
    },
    {
      name: "vega",
      title: "Vega",
      type: "greek"
    }
  ],
  limit_price: 0,
  stop_price: 0,
  spread: {
    spread_ask: 0,
    spread_bid: 0,
    spread_mid: 0,
    market_price: 0,
    trade_type: "debit"
  },
  duration: "day",
  preview_status: false,
  preview: {},
  order_status: false,
  order: {}
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case "SET_INFO_TICKET":
      return {
        ...state,
        selected_options: [action.payload],
        selected_symbol: action.payload.symbol,
        selecting_un_symbol: action.payload.root_symbol,
        un_symbol: action.payload.root_symbol,
        infoTicket: true
      };
    case "UPDATE_NEAR_VALUE":
      return { ...state, near: action.payload };
    case "CLOSE_INFO_TICKET":
      return { ...state, infoTicket: false };
    case "SET_OPTION_TICKET":
      return {
        ...state,
        selected_options: [action.payload],
        un_symbol: action.payload.root_symbol,
        selecting_un_symbol: action.payload.root_symbol,

        // symbol: action.payload.symbol,
        ticket: true
      };
    case "ADD_STOCK":
      return {
        ...state,
        selected_options: [...state.selected_options, action.payload],
        isStockAdded: true
      };
    case "DURATION_CHANGE":
      return { ...state, duration: action.payload };
    case "REMOVE_OPTION":
      return {
        ...state,
        selected_options: action.payload.opt,
        isStockAdded: action.payload.isStockDeleted ? false : state.isStockAdded
      };
    case "SET_TICKET_SYMBOL":
      return { ...state, selecting_un_symbol: action.payload };
    case "CLOSE_OPTION_TICKET":
      return { ...initial_state, ticket: false };
    case "CHANGE_LIMIT_PRICE":
      return { ...state, limit_price: action.payload };
    case "CHANGE_STOP_PRICE":
      return { ...state, stop_price: action.payload };
    case "SET_P_TICKET_SYMBOL":
      return {
        ...state,
        un_symbol: action.payload,
        un_symbol_error: false,
        selected_options: []
        // selecting_un_symbol: action.payload
      };
    case "SET_P_TICKET_SYMBOL_ERROR":
      return {
        ...state,
        un_symbol_error: true
      };
    case "TRADE_TYPE_CHANGE":
      return {
        ...state,
        trade_type: state.trade_type === "open" ? "close" : "open"
      };
    case "CALC_SPREAD":
      return {
        ...state,
        spread: action.payload,
        limit_price: action.payload.spread_mid,
        stop_price: action.payload.spread_mid
      };
    case "CHANGE_OPTION_ACTION":
      return { ...state, selected_options: [...action.payload] };
    case "GET_EXPIRATION_TICKET":
      return { ...state, all_expirations: action.payload };
    case "ADD_OPTION_TICKET":
      return {
        ...state,
        selected_options: [...state.selected_options, action.payload]
      };
    case "UPDATE_OPTION_TICKET":
      return { ...state, selected_options: action.payload };
    case "CHANGE_PRICE_TYPE":
      return { ...state, price_type: action.payload };
    case "PREVIEW_ORDER":
      return { ...state, preview_status: true, preview: action.payload };
    case "EDIT_ORDER":
      return { ...state, preview_status: false, preview: {} };
    case "PLACE_ORDER":
      return { ...state, order_status: true, order: action.payload };
    default:
      return state;
  }
};
