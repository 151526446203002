import historyProvider from "./historyProvider";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../config/links.config'
// import { set_symbol } from "../../../actions/options";

// import store from "./../../../store";
// import{ useDispatch} from "react-redux";
import {getAllSymbols} from '../../../services/apis/dataService';
const axios = require("axios");
// const dispatch = useDispatch();
// getAllSymbols()
// axios
//   //.get("/api/chart/all_symbols")
//   .get(`${chartURL}/api/chart/all_symbols`)
//   .then(res => {
//     localStorage.setItem("all_symbols", JSON.stringify(res.data));
//   })
//   .catch(e => console.log("error getting symbols", e));

const supportedResolutions = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "60",
  "120",
  "240",
  "D",
  "1W",
  "M",
  "6M"
];

const config = {
  supported_resolutions: supportedResolutions
};

export default {
  /**  --------- ON Ready Callback  -----*/
  onReady: cb => {
    console.log("=====onReady running");
    setTimeout(() => cb(config), 0);
  },

  /**------- Search for symbol as user types in---- */
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    const symbol = JSON.parse(
      localStorage.getItem("all_symbols")
    ).filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    onResultReadyCallback(symbol);
  },

  /**------ resolve the symbol as user clicked the desired symbol---- */
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    var symbol_stub = {
      name: symbolName,
      //   description: split_data[1],
      type: "stock",
      session: "0900-1630",
      timezone: "America/New_York",
      ticker: symbolName,
      minmov: 0.1,
      pricescale: 100,
      has_intraday: true,
      intraday_multipliers: ["1", "60"],
      supported_resolution: supportedResolutions,
      volume_precision: 8,
      data_status: "delayed"
      // has_empty_bars:true
    };
    // store.dispatch(set_symbol(symbolName))

    setTimeout(function() {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },

  /**--- will get the bars for the chart after resolving symbol */
  getBars: function(
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) {
    // store.dispatch(set_symbol(symbolInfo.name));
    historyProvider
      .getBars(symbolInfo, resolution, from, to, firstDataRequest)
      .then(bars => {
        if (bars.length) {
          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch(err => {
        if (err.response) {
          if (typeof err.response.data == "string") alert(err.response.data);
          if (err.response.data.tradier_token)
            return onErrorCallback(err.response.data.tradier_token);
          else return onErrorCallback(err.response.data);
        } else {
          alert(err.message);
          if (err.message) return onErrorCallback(err.message);
        }
      });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    console.log("=====subscribeBars runnning => no streaming on demo account");
    // stream.subscribeBars(
    //   symbolInfo,
    //   resolution,
    //   onRealtimeCallback,
    //   subscribeUID,
    //   onResetCacheNeededCallback
    // );
  },
  unsubscribeBars: subscriberUID => {
    console.log("=====unsubscribeBars running");
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    // console.log("=====calculateHistoryDepth running");
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : undefined;
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    console.log("=====getMarks running");
  },
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    //optional
    console.log("=====getTimeScaleMarks running");
  },
  getServerTime: cb => {
    console.log("=====getServerTime running");
  }
};
