import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpreadMarket from "./components/SpreadMarket";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Modal from "react-modal-resizable-draggable";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import DeleteForever from "@material-ui/icons/DeleteForever";
import OptionMarket from "./components/OptionMarket";

import {
  close_option_ticket,
  on_symbol_change,
  on_select_symbol,
  add_option_ticket,
  on_change_expiry,
  on_change_strike,
  on_change_type,
  on_change_trade_type,
  on_change_option_action,
  on_change_quantity,
  place_order,
  on_delete_option,
  edit_order,
  add_stock
} from "./../../actions/optionTicket";

const OptionInfo = ({
  un_symbol,
  isStockAdded,
  un_symbol_error,
  ticket,
  theme,
  selected_options,
  close_option_ticket,
  on_symbol_change,
  selecting_un_symbol,
  on_select_symbol,
  all_expirations,
  add_option_ticket,
  on_change_expiry,
  on_change_strike,
  on_change_type,
  trade_type,
  on_change_trade_type,
  on_change_option_action,
  on_change_quantity,
  place_order,
  on_delete_option,
  preview_status,
  preview,
  edit_order,
  order_status,
  order,
  add_stock
}) => {
  const clr_theme = theme;

  const classes = makeStyles(t => {
    return {
      toggle: {
        height: "20px",
        fontSize: "12px"
      },
      margin: {
        margin: 0
      },
      icon: {
        fontSize: 16,
        padding: 0,
        marginBottom: 0,
        marginRight: "5px"
      },
      switch: {
        fontSize: "16px",
        height1: "16px"
      },
      inputLikeBox: {
        position: "relative",
        fontSize: 12,
        padding: "2.5px 5px 2.5px 5px",
        display: "inline-block",
        color: clr_theme === "Dark" ? "#d7d8db" : "#131721"
      },
      range: {
        width: "200px",
        paddingLeft: "20px",
        padding: "0"
      },
      rangeInp: {
        color: clr_theme === "Dark" ? "white" : "black"
      },
      option_bar: {
        width: "100%",
        display: "flex",
        paddingBottom: "5px"
      }
    };
  })();
  const styles = {
    header1: {
      height: "40px",
      width: "100",
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    header2: {
      //   height:"60px",
      backgroundColor: theme === "Dark" ? "#252e42" : "#f1f1f1",
      padding: "10px",
      paddingRight: "20px",
      paddingLeft: "20px",
      zIndex: 99999999
    },
    closeIcon: {
      zIndex: 200000,
      cursor: "pointer"
    },
    symbolName: {},
    symbolDesc: {},
    body: {
      padding: "20px",
      maxHeight: "300px"
    },
    smallInput: { width: "65px" }
  };

  const symbols = JSON.parse(localStorage.getItem("all_symbols"));
  return (
    <>
      <Modal
        // disableMove
        isOpen={ticket}
        onRequestClose={close_option_ticket}
        onFocus={() => console.log("Modal is clicked")}
        className={"my-modal-custom-class"}
        initWidth={730}
        initHeight={600}
      >
        <div
          id="style-3"
          style={{
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            backgroundColor: theme === "Dark" ? "#131721" : "white",
            color: theme === "Light" ? "#131721" : "white"
          }}
        >
          <div style={styles.header1}>
            <div>
              <h6>heading</h6>
            </div>
            <div style={styles.closeIcon} onClick={() => close_option_ticket()}>
              <CloseIcon />
            </div>
          </div>

          <div style={styles.header2}>
            <span className="selection">
              <input
                onChange={e => on_symbol_change(e.target.value)}
                type="text"
                list="symbol-list"
                name="browser"
                style={{ width: "100px" }}
                value={selecting_un_symbol}
                onKeyUp={e => on_select_symbol(e)}
              />
              <datalist id="symbol-list">
                {symbols.map(symbol => (
                  <option key={symbol.symbol} value={symbol.symbol} />
                ))}
              </datalist>
              {un_symbol_error ? (
                <p className="text-danger mb-0">
                  <small>invalid symbol</small>
                </p>
              ) : (
                <></>
              )}
            </span>
            <div className={classes.inputLikeBox}>
              <ToggleButtonGroup
                value={trade_type}
                exclusive
                onChange={(e, data) => on_change_trade_type(data)}
              >
                <ToggleButton className={classes.toggle} value="open">
                  {/* <FormatAlignLeftIcon /> */}open
                </ToggleButton>
                <ToggleButton className={classes.toggle} value="close">
                  close
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {/* <h6>{symbol}</h6> */}
            {/* <p className="mb-0">{symbolDesc}</p> */}
          </div>
          {!preview_status && !order_status ? (
            <div style={styles.body}>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "scroll",
                  maxHeight: "280px"
                }}
                id="style-3"
              >
                {selected_options.map((data, index) => {
                  console.log(data);
                  return data.type === "option" ? (
                    <div key={index} className={classes.option_bar}>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor=""
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Action
                        </label>
                        <ToggleButtonGroup
                          value={data.action}
                          exclusive
                          onChange={(e, value) => {
                            console.log(value, "form v");
                            on_change_option_action(value, index);
                          }}
                        >
                          <ToggleButton className={classes.toggle} value="buy">
                            {/* <FormatAlignLeftIcon /> */}buy
                          </ToggleButton>
                          <ToggleButton className={classes.toggle} value="sell">
                            sell
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="qty"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Qty
                        </label>

                        <input
                          type="number"
                          value={data.quantity}
                          onChange={e =>
                            on_change_quantity(e.target.value, index)
                          }
                          name=""
                          id="qty"
                          pattern="[0-9]"
                          style={styles.smallInput}
                        />
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="expiry"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Expiry
                        </label>
                        <select
                          name=""
                          id="expiry"
                          value={data.expiration_date}
                          onChange={e =>
                            on_change_expiry(e.target.value, index)
                          }
                        >
                          {/* <option value={data.expiration_date}>
                        {data.expiration_date}
                      </option> */}
                          {all_expirations.map(expiris => (
                            <option key={expiris} value={expiris}>
                              {expiris}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="strike"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Strike
                        </label>
                        <select
                          name=""
                          id="strike"
                          value={data.strike}
                          style={styles.smallInput}
                          onChange={e =>
                            on_change_strike(e.target.value, index)
                          }
                        >
                          {data.all_strikes.map(strike => (
                            <option key={strike} value={strike}>
                              {strike}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor=""
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          type
                        </label>

                        <ToggleButtonGroup
                          value={data.option_type}
                          exclusive
                          onChange={e => on_change_type(index)}
                          // aria-label="text alignment"
                        >
                          <ToggleButton
                            className={classes.toggle}
                            value={"call"}
                            aria-label="left aligned"
                          >
                            {/* <FormatAlignLeftIcon /> */}call
                          </ToggleButton>
                          <ToggleButton className={classes.toggle} value="put">
                            put
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="bid"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          bid
                        </label>
                        <input
                          type="number"
                          readOnly
                          style={{
                            ...styles.smallInput,
                            border: "none",
                            backgroundColor: "transparent",
                            color: theme === "Dark" ? "white" : "black"
                          }}
                          id="bid"
                          value={data.bid}
                        />
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="ask"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Ask
                        </label>
                        <input
                          type="text"
                          id="ask"
                          readOnly
                          style={{
                            ...styles.smallInput,
                            border: "none",
                            backgroundColor: "transparent",
                            color: theme === "Dark" ? "white" : "black"
                          }}
                          value={data.ask}
                        />
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor=""
                          style={{ opacity: 0 }}
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          .
                        </label>
                        <span style={{ cursor: "pointer" }}>
                          <DeleteForever
                            fontSize="small"
                            onClick={() => on_delete_option(index)}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className={classes.option_bar}>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor=""
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Action
                        </label>
                        <ToggleButtonGroup
                          value={data.action}
                          exclusive
                          onChange={(e, value) => {
                            console.log(value, "form v");
                            on_change_option_action(value, index);
                          }}
                        >
                          <ToggleButton className={classes.toggle} value="buy">
                            {/* <FormatAlignLeftIcon /> */}buy
                          </ToggleButton>
                          <ToggleButton className={classes.toggle} value="sell">
                            sell
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="qty"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Qty
                        </label>

                        <input
                          type="number"
                          value={data.quantity}
                          onChange={e =>
                            on_change_quantity(e.target.value, index)
                          }
                          name=""
                          id="qty"
                          pattern="[0-9]"
                          style={styles.smallInput}
                        />
                      </div>
                      <div style={{ width: 100 + 80 + 105 + "px" }}></div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="bid"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          bid
                        </label>
                        <input
                          type="number"
                          readOnly
                          style={{
                            ...styles.smallInput,
                            border: "none",
                            backgroundColor: "transparent",
                            color: theme === "Dark" ? "white" : "black"
                          }}
                          id="bid"
                          value={data.bid}
                        />
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor="ask"
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          Ask
                        </label>
                        <input
                          type="text"
                          id="ask"
                          readOnly
                          style={{
                            ...styles.smallInput,
                            border: "none",
                            backgroundColor: "transparent",
                            color: theme === "Dark" ? "white" : "black"
                          }}
                          value={data.ask}
                        />
                      </div>
                      <div className={classes.inputLikeBox}>
                        <label
                          htmlFor=""
                          style={{ opacity: 0 }}
                          className={` ${index > 0 ? "d-none" : "d-block"}`}
                        >
                          .
                        </label>
                        <span style={{ cursor: "pointer" }}>
                          <DeleteForever
                            fontSize="small"
                            onClick={() => on_delete_option(index)}
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <br />
              <span
                className="link mb-2 pl-2"
                style={{ cursor: "pointer", marginTop: "15px" }}
                onClick={() => add_option_ticket()}
              >
                Add Option
              </span>
              <span
                className={`link mb-2 pl-2 d-${isStockAdded?"none":""}`}
                style={{ cursor: "pointer", marginTop: "15px" }}
                onClick={() => add_stock()}
              >
                Add Stock
              </span>

              <br />
              {selected_options.length > 1 ? (
                <SpreadMarket theme={theme} />
              ) : (
                <OptionMarket theme={theme} />
              )}

              <br />
              <div
                className=""
                style={{ position: "absolute", bottom: "20px", right: "20px" }}
              >
                <button
                  className={`mr-3 btn btn-outline-${
                    theme === "Dark" ? "light" : "dark"
                  } btn-sm`}
                  onClick={() => place_order(true)}
                >
                  Preview
                </button>
                <button
                  className={`btn btn-outline-${
                    theme === "Dark" ? "light" : "dark"
                  } btn-sm`}
                  onClick={() => place_order(false)}
                >
                  trade
                </button>
              </div>
            </div>
          ) : preview_status && !order_status ? (
            <div style={styles.body}>
              <h4>Preview</h4>
              <div className="mt-3">
                {selected_options.map((option, i) => {
                  return (
                    <p
                      key={option.symbol}
                      style={{
                        color: option.action === "buy" ? "green" : "red"
                      }}
                    >
                      {option.action +
                        " " +
                        option.quantity +
                        " " +
                        option.description +
                        " to " +
                        trade_type}
                    </p>
                  );
                })}
              </div>
              <div className="mt-2">
                <p>
                  <b>Status: </b> {preview.status}
                </p>

                <p>
                  <b>Fees: </b> {preview.fees}
                </p>
                <p>
                  <b>Cost: </b> {preview.cost}
                </p>
                <p>
                  <b>Type: </b> {preview.type}
                </p>
                <p>
                  <b>Order Cost: </b> {preview.order_cost}
                </p>
              </div>
              <div
                className=""
                style={{ position: "absolute", bottom: "20px", right: "20px" }}
              >
                <button
                  className={`mr-3 btn btn-outline-${
                    theme === "Dark" ? "light" : "dark"
                  } btn-sm`}
                  onClick={() => edit_order()}
                >
                  Edit Order
                </button>
                <button
                  className={`mr-3 btn btn-outline-${
                    theme === "Dark" ? "light" : "dark"
                  } btn-sm`}
                  onClick={() => place_order(false)}
                >
                  Process Order
                </button>
              </div>
            </div>
          ) : (
            <div style={styles.body}>
              <h4>Orer Placed Successfull</h4>
              <h5>Order ID : {order.id}</h5>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    un_symbol: state.ticket.un_symbol,
    ticket: state.ticket.ticket,
    isStockAdded: state.ticket.isStockAdded,
    selected_options: state.ticket.selected_options,
    selecting_un_symbol: state.ticket.selecting_un_symbol,
    un_symbol_error: state.ticket.un_symbol_error,
    all_expirations: state.ticket.all_expirations,
    trade_type: state.ticket.trade_type,
    preview_status: state.ticket.preview_status,
    preview: state.ticket.preview,
    order_status: state.ticket.order_status,
    order: state.ticket.order
  };
};

export default connect(mapStateToProps, {
  close_option_ticket,
  on_symbol_change,
  on_select_symbol,
  add_option_ticket,
  on_change_expiry,
  on_change_strike,
  on_change_type,
  on_change_trade_type,
  on_change_option_action,
  on_change_quantity,
  place_order,
  on_delete_option,
  edit_order,
  add_stock
})(OptionInfo);
