import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { removeDups, currency_formatter } from "../../../helpers/index";
import SortIcon from "@material-ui/icons/Sort";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { Close } from "@material-ui/icons";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../../config/links.config'

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { token } from '../../../../../configMiddleware/getToken';
const axios = require("axios");

const Position = props => {
  const theme = props.theme;
  const clr_theme = theme;
  const classes = makeStyles(theme => ({
    margin: {
      margin: 0
    },
    icon: {
      fontSize: 16,
      padding: 0,
      marginBottom: 0,
      marginRight: "5px"
    },
    switch: {
      fontSize: "16px",
      height1: "16px"
    },
    inputLikeBox: {
      position: "relative",
      backgroundColor: "transparent",
      border: "none",
      marginRight: "10px",
      borderBottom: "2px solid #ced4da",
      fontSize: 16,
      padding: "2.5px 12px 2.5px 12px",
      display: "inline-block",
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721",
      outline: "none"
    }
  }))();

  const style = {
    container: {
      overflowX: "scroll",
      color: theme === "Dark" ? "#d7d8db" : "#131721"
    },
    innerContainer: {
      minWidth: "1080px"
    }
  };

  const [position, set_position] = useState([]);
  const [filtered_positions, set_filltered_position] = useState([]);
  const [error, set_error] = useState({});
  const [sort_status, set_sort_status] = useState(-1);
  const [filter_bar_visiblity, set_filter_bar_visibility] = useState(false);
  const [s_symbol, set_s_symbol] = useState("");
  const [refresh_count, set_refresh] = useState(0);
  const [groups, set_groups] = useState([]);
  const [selected_group_syb, set_selected_group_syb] = useState("");
  const get_position = () => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_positions?acct_number=${props.user.selected_tradier_account}`,{headers: { Authorization:  token }}
    )
      .then(res => {
        set_position([...res.data]);
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

 //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_positions`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     set_position([...res.data]);
      //         return res.data
      //   }).catch((e) => console.log("axios error", e))
      //   }).catch( (e) =>{ console.log("Error : ",e)
      //     if (e.response) set_error({ ...error, ...e.response.data });
      //   })
//#endregion auth endpoint

  };
  useEffect(() => {
    get_position();
  }, [props.user.selected_tradier_account, refresh_count]);
  useEffect(() => {
    set_filltered_position([...position]);
  }, [position]);
  useEffect(() => {
    const syb_group = removeDups(
      filtered_positions.map(p => {
        return p.ul_symbol;
      })
    );
    set_groups([...syb_group]);
  }, [filtered_positions]);
  const sort_by_symbol = () => {
    let pos = [...position];
    let sorted = pos.sort((a, b) => {
      return a.ul_symbol > b.ul_symbol ? -1 * sort_status : 1 * sort_status;
    });
    set_position(sorted);
    set_sort_status(-1 * sort_status);
  };
  const sort_data = d => {
    const pos = [...position];
    const sorted = pos.sort((a, b) => {
      return sort_status < 1 ? a[d] - b[d] : b[d] - a[d];
    });
    set_position(sorted);
    set_sort_status(-1 * sort_status);
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "10px", right: "15px" }}>
          <button
            className={`btn mr-2 btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={() => set_filter_bar_visibility(!filter_bar_visiblity)}
          >
            <SearchIcon />
          </button>
          <button
            className={`btn btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={() => set_refresh(refresh_count + 1)}
          >
            <RefreshIcon />
          </button>
        </div>

        <div className="container-fluid " id="style-3" style={style.container}>
          <div
            className={`inner-container pb-2  flex-row pt-2 d-${
              filter_bar_visiblity ? "flex" : "none"
            }  flex-wrap`}
            style={style.innerContainer}
          >
            <h6 className="mr-4 pt-2">
              <SearchIcon />
            </h6>
            <span className="d-inline-block mr-2 position-relative">
              <input
                onChange={e => {
                  set_s_symbol(e.target.value);
                  set_filltered_position(
                    position.filter(position => {
                      return position.ul_symbol
                        .toLowerCase()
                        .includes(e.target.value.toLocaleLowerCase());
                    })
                  );
                }}
                value={s_symbol}
                type="text"
                list="position-symbol-list"
                name=""
                placeHolder="symbol"
                className={classes.inputLikeBox}
                id="position_symbol"
                placeholder="Symbol"
                // value={filter_preset.symbol}
              />
              <datalist id="position-symbol-list">
                {removeDups(position.map(pos => pos.ul_symbol)).map(sym => (
                  <option key={sym} value={sym} />
                ))}
              </datalist>
              <span
                className={`position-absolute text-secondary cursor d-${
                  s_symbol ? "inline" : "none"
                }`}
                style={{ right: "40px", cursor: "pointer" }}
                onClick={() => {
                  set_s_symbol("");
                  set_filltered_position(position);
                }}
              >
                <Close fontSize="small" />
              </span>
            </span>
          </div>
          <div
            className={`inner-container pb-5 pt-${
              filter_bar_visiblity ? "0" : "5"
            }`}
            style={style.innerContainer}
          >
            <div className="row tab-row">
              <div className="col-2 tab-col">
                <span style={{ cursor: "pointer" }} onClick={sort_by_symbol}>
                  <SortIcon />
                </span>
                <small>Symbol</small>
              </div>
              <div className="col-1 tab-col">
                <small>Qty</small>
              </div>
              <div
                className="col-1 tab-col"
                style={{
                  backgroundColor:
                    theme === "Dark" ? "#adaca810" : "rgba(249, 203, 163, 0.2)"
                }}
              >
                <small>Bid</small>
              </div>
              <div
                className="col-1 tab-col"
                style={{
                  backgroundColor:
                    theme === "Dark" ? "#adaca810" : "rgba(249, 203, 163, 0.2)"
                }}
              >
                <small>Ask</small>
              </div>{" "}
              <div
                className="col-1 tab-col"
                style={{
                  backgroundColor:
                    theme === "Dark" ? "#adaca810" : "rgba(249, 203, 163, 0.2)"
                }}
              >
                <small>Last</small>
              </div>
              <div
                className="col-1 d-flex tab-col"
                style={{
                  backgroundColor:
                    theme === "Dark" ? "#adaca810" : "rgba(249, 203, 163, 0.2)"
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sort_data("spread")}
                >
                  <SortIcon />
                </span>
                <small>Spread</small>
              </div>
              {/* <div className="col-1 tab-col ">
                <small>Net Change</small>
              </div> */}
              <div className="col-1 tab-col">
                <small>Value</small>
              </div>
              <div className="col-1 tab-col">
                <small>Cost Basis</small>
              </div>
              <div className="col-1 tab-col">
                <small>Gain/Loss</small>
              </div>
              <div className="col-1 tab-col">
                <small>select</small>
              </div>
            </div>
            {groups.map(g => {
              return (
                <>
                  <div className="row tab-row">
                    <div className="col-2 tab-col">
                      <div className="inner-col">
                        <p>
                          <strong>{g} </strong>
                          <span
                            className={`clickable_scale d-${
                              selected_group_syb !== g ? "" : "none"
                            }`}
                          >
                            {" "}
                            <ExpandMoreIcon
                              onClick={() => set_selected_group_syb(g)}
                            />
                          </span>
                          <span
                            className={`clickable_scale d-${
                              selected_group_syb === g ? "" : "none"
                            }`}
                          >
                            {" "}
                            <ExpandLessIcon
                              onClick={() => set_selected_group_syb("")}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {filtered_positions
                    .filter(gl => gl.ul_symbol === g)
                    .map((gl, i) => {
                      return (
                        <div
                          className={`row tab-row d-${
                            selected_group_syb === g ? "" : "none"
                          }`}
                        >
                          <div className="col-2 tab-col">
                            <div className="inner-col">
                              <p className="position-relative">
                                <strong>{gl.ul_symbol} </strong>
                                <small className=" position-absolute" style={{left:"60px"}} >
                                  <small
                                    className={`badge badge-sm badge-${
                                      gl.quantity > 0 ? "success" : "danger"
                                    } ${!gl.itm ? "d-none" : ""}`}
                                  >
                                    ITM
                                  </small>{" "}
                                  <small
                                    className={`badge badge-sm badge-${
                                      gl.quantity < 0 ? "success" : "danger"
                                    } ${!gl.otm ? "d-none" : ""}`}
                                  >
                                    OTM
                                  </small>{" "}
                                  <small
                                    className={`badge badge-sm badge-warning ${
                                      !gl.is_expiring ? "d-none" : ""
                                    }`}
                                  >
                                    expiring
                                  </small>
                                </small>{" "}
                                <div></div>
                                <small
                                  className={`d-${
                                    gl.type === "option" ? "" : "none"
                                  }`}
                                  style={{lineHeight:"5px"}}
                                >
                                  <small>
                                    Last: {gl.stock_last.toFixed(2)}
                                  </small>{" "}
                                </small>
                              </p>
                              <p style={{padding:"0px", fontSize:"9px", fontWeight:"bold"}}>
                              {gl.description}                              </p>
                            </div>
                          </div>
                          <div className="col-1 tab-col">
                            <div className="inner-col">
                              <small>{gl.quantity}</small>
                            </div>
                          </div>

                          <div
                            className="col-1 tab-col"
                            style={{
                              backgroundColor:
                                theme === "Dark"
                                  ? "#adaca810"
                                  : "rgba(249, 203, 163, 0.2)"
                            }}
                          >
                            <div className="inner-col">
                              {" "}
                              <small>
                                {currency_formatter.format(Number(gl.bid))}
                              </small>
                            </div>
                          </div>
                          <div
                            className="col-1 tab-col"
                            style={{
                              backgroundColor:
                                theme === "Dark"
                                  ? "#adaca810"
                                  : "rgba(249, 203, 163, 0.2)"
                            }}
                          >
                            <div className="inner-col">
                              {" "}
                              <small>
                                {currency_formatter.format(Number(gl.ask))}
                              </small>
                            </div>
                          </div>
                          <div
                            className="col-1 tab-col"
                            style={{
                              backgroundColor:
                                theme === "Dark"
                                  ? "#adaca810"
                                  : "rgba(249, 203, 163, 0.2)"
                            }}
                          >
                            <div className="inner-col">
                              {" "}
                              <small>{gl.last}</small>
                            </div>
                          </div>
                          <div
                            className="col-1 tab-col"
                            style={{
                              backgroundColor:
                                theme === "Dark"
                                  ? "#adaca810"
                                  : "rgba(249, 203, 163, 0.2)"
                            }}
                          >
                            <div className="inner-col">
                              {" "}
                              <small>
                                {currency_formatter.format(Number(gl.spread))}
                              </small>
                            </div>
                          </div>
                          {/* <div className="col-1 tab-col ">
                    <div
                      className={`inner-col text-${
                        gl.net_change_percent < 0 ? "danger" : "success"
                      }`}
                    >
                      {" "}
                      <p>
                        <small>
                          <strong>
                            {currency_formatter.format(gl.net_change)}
                          </strong>
                        </small>
                      </p>
                      <p>
                        <small>
                          {percentage_formatter.format(
                            Number(gl.net_change_percent)
                          )}
                        </small>
                      </p>
                    </div>
                  </div> */}
                          <div className="col-1 tab-col">
                            <div className="inner-col">
                              <small>{gl.value}</small>
                            </div>
                          </div>
                          <div className="col-1 tab-col">
                            <div className="inner-col">
                              <small>
                                <strong>
                                  {currency_formatter.format(gl.cost_basis)}
                                </strong>
                              </small>
                              <div></div>
                              <small>
                                <small>
                                  <small>unit price</small>
                                </small>
                              </small>
                              <div></div>
                              <small style={{ lineHeight: "5px" }}>
                                <small>
                                  {gl.type === "stock"
                                    ? gl.cost_basis.toFixed(2)
                                    : (gl.cost_basis / 100).toFixed(2)}
                                </small>
                              </small>
                            </div>
                          </div>
                          <div className="col-1 tab-col">
                            <div
                              className={`inner-col text-${
                                Number(
                                  gl.pnl.split("$")[0] + gl.pnl.split("$")[1]
                                ) < 0
                                  ? "danger"
                                  : "success"
                              }`}
                            >
                              <small>
                                <strong> {gl.pnl}</strong>
                              </small>
                              <div></div>

                              <small>
                                <small>{gl.pnl_percent}</small>
                              </small>
                            </div>
                          </div>
                          <div className="col-1 tab-col">
                            <input type="checkbox" name="" id="" />
                          </div>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Position;
