import React from "react";
import OptionWidget from "./components/Bottom_Option_Widget";
import { Provider } from "react-redux";
import store from "./store";

export default props => {
  return (
    <Provider store={store}>
      <OptionWidget
        theme={props.theme}
        onClickRows={props.onClickRows}
        onClickAskBid={props.onClickAskBid}
      />
    </Provider>
  );
};
