const axios = require("axios")
const config = require('../config/keys.config')
//config returns a promise and should be handled accordingly

//***Reference Using Async/Await****
export const token = async () => {
    // try {
    //     let conf = await config()
    //     console.log(`printing config: ${conf.apiAuth}`)
    //     const apiAuth = conf.apiAuth
        
    //     let res = await axios.post(apiAuth.url, 
    //         { username: apiAuth.username, password: apiAuth.password},
    //         {headers:{'Authorization': ""},
    //         "Access-Control-Allow-Origin": "*",
    //         'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}
    //         )
    //     let Token = res.data.apiuser.token                
    //     // console.log("Token : ",Token)
    //     return Token

    // } catch (err) {
    //     console.log(err)
    // }            

    try {
        let conf = await config();
        const apiAuth = conf.apiAuth
        // let res = await axios.post(apiAuth.url, { username: apiAuth.username, password: apiAuth.password})
        // let Token = res.data.apiuser.token  ;
        return axios({
            method: "post",
            url: apiAuth.url,
            data: { username: apiAuth.username, password: apiAuth.password }
        }).then(( res ) =>{
            return res.data;
        }).catch((e)=>{throw Error})
        // return Token

    } catch (err) {
        console.log(err)
    }  
} 

//***Reference Using Promises****
// module.exports = {
//     token:
//         config.then((config) => {           
//             const apiAuth = config.apiAuth    

//             return axios.post(apiAuth.url, { username: apiAuth.username, password: apiAuth.password })
//                 .then((res) => {
//                     const apiToken = res.data.apiuser.token
//                     //console.log(apiToken)
//                     return apiToken
//                 }).catch( (e) => { console.log(e) })  
//         }).catch( (e) => { console.log(e)} )
// }



