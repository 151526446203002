import React, { useState, useEffect } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../../config/links.config'
import {
  formatDate,
  get_desc,
  currency_formatter
} from "../../../helpers/index";
import SortIcon from "@material-ui/icons/Sort";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { TrendingUp, TrendingDown, BarChart } from "@material-ui/icons";
import { token } from '../../../../../configMiddleware/getToken';
const axios = require("axios");

const History = props => {
  const theme = props.theme;
  const clr_theme = theme;
  const style = {
    container: {
      overflowX: "scroll",
      color: theme === "Dark" ? "#d7d8db" : "#131721"
    },
    innerContainer: {
      minWidth: "1080px"
    }
  };
  const classes = makeStyles(theme => ({
    text_color: {
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721"
    }
  }))();

  const [history, set_history] = useState([]);
  const [refresh_count, set_refresh] = useState(0);
  const [page, set_page] = useState(1);
  const [error, set_error] = useState({});
  const [date_sort_order, set_date_sort_order] = useState(1);
  const get_history = page => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_history?acct_number=${props.user.selected_tradier_account}&page=${page}`,
      {headers: { Authorization:  token }}
    )
      .then(res => {
        set_page(page);
        if (Array.isArray(res.data.history.event)) {
          set_history([...history, ...res.data.history.event]);
        } else {
          set_history([...history, res.data.history.event]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_history`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account,
      //           page : page
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{

      //     set_page(page);
      //   if (Array.isArray(res.data.history.event)) {
      //     set_history([...history, ...res.data.history.event]);
      //   } else {
      //     set_history([...history, res.data.history.event]);
      //   }
      //         return res.data
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => {if (e.response) set_error({ ...error, ...e.response.data })})
//#endregion auth endpoint
  };
  useEffect(() => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_history?acct_number=${props.user.selected_tradier_account}&page=${1}`,{headers: { Authorization:  token }})
      .then(res => {
        set_page(page);
        if (Array.isArray(res.data.history.event)) {
          set_history([...res.data.history.event]);
        } else {
          set_history([res.data.history.event]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

    //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_history`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account,
      //           page : 1
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     set_page(page);
      //     if (Array.isArray(res.data.history.event)) {
      //       set_history([...res.data.history.event]);
      //     } else {
      //       set_history([res.data.history.event]);
      //     }
      //         return res.data
      //   }).catch((e) => console.log("axios error",e))
      //   }).catch( (e) => {if (e.response) set_error({ ...error, ...e.response.data })})
//#endregion auth endpoint

  }, [props.user, refresh_count]);

  const sort_by_date = () => {
    let data = [...history];
    data = data.sort((a, b) => {
      return date_sort_order < 1
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    });
    set_date_sort_order(date_sort_order * -1);
    set_history(data);
  };
  return (
    <>
      <div>
        <div className="container-fluid " id="style-3" style={style.container}>
          <button
            className={`btn btn-outline-${
              theme === "Dark" ? "light" : "dark"
            } float-right btn-sm `}
            onClick={() => set_refresh(refresh_count + 1)}
          >
            <RefreshIcon />
          </button>
          <div className="inner-container mt-5" style={style.innerContainer}>
            <div className="row tab-row">
              <div className="col-3 tab-col">
                <span style={{ cursor: "pointer" }}></span>
                <small>Symbol</small>
              </div>
              <div className="col-1 tab-col">
                <span style={{ cursor: "pointer" }}>
                  <SortIcon onClick={() => sort_by_date()} />
                </span>{" "}
                <small>Date</small>
              </div>
              <div className="col-1 tab-col">
                <small>Price</small>
              </div>
              <div className="col-1 tab-col">
                <small>Amount</small>
              </div>
              <div className="col-1 tab-col">
                <small>Commission</small>
              </div>
              <div className="col-1 tab-col">
                <small>Type</small>
              </div>

              <div className="col-1">
                <small>Trade Type</small>
              </div>
              <div className="col-1">
                <small>Option Type</small>
              </div>
            </div>
            {history.map((event, i) => {
              return (
                <div className="row tab-row">
                  <div className="col-3 tab-col">
                    <span style={{ cursor: "pointer" }}></span>
                    <strong>
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].symbol
                        : event["adjustment"].symbol}
                    </strong>
                    <br />
                    <small>
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].symbol &&
                          event.type === "trade" &&
                          event[event.type].trade_type === "option"
                          ? get_desc("", event[event.type].symbol)
                          : event[event.type].description
                        : event["adjustment"].symbol}
                    </small>
                  </div>
                  <div className="col-1 tab-col">
                    <small>{formatDate(new Date(event.date))}</small>
                  </div>
                  <div className="col-1 tab-col">
                    <small>
                      {" "}
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].price
                          ? currency_formatter.format(event[event.type].price)
                          : ""
                        : event["adjustment"].price
                        ? currency_formatter.format(event["adjustment"].price)
                        : ""}
                    </small>
                  </div>
                  <div className="col-1 tab-col">
                    <small>
                      {event.amount
                        ? currency_formatter.format(event.amount)
                        : ""}
                    </small>
                  </div>
                  <div className="col-1 tab-col">
                    <small>
                      {" "}
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].commission
                          ? currency_formatter.format(
                              event[event.type].commission
                            )
                          : ""
                        : event["adjustment"].commission}
                    </small>
                  </div>
                  <div className="col-1 tab-col">
                    <small>{event.type}</small>
                  </div>

                  <div className="col-1">
                    <small>
                      {" "}
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].trade_type
                        : event["adjustment"].trade_type}
                    </small>
                  </div>
                  <div className="col-1">
                    <small>
                      {" "}
                      {event.type !== "money_movements" &&
                      event.type !== "DIVADJ" &&
                      event.type !== "position_adjustments"
                        ? event[event.type].option_type
                        : event["adjustment"].option_type}
                    </small>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
