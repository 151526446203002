/**
 * @param {*} tvWidget
 * @function createPositionLine
 */

//#region Creates a new trading position on the chart and returns an API-object that you can use to adjust its properties and behavior.
const createPositionLine = (tvWidget,StreaminglastBarClosePrice,CurrSigPrice) => {
  let CurrPrice;
  if (tvWidget != undefined && StreaminglastBarClosePrice.close != undefined && CurrSigPrice != undefined) {
    CurrPrice = StreaminglastBarClosePrice.close;
    let openPnl = CurrPrice - CurrSigPrice ;
    let openPnlPercntage =
      ((CurrPrice - CurrSigPrice) / CurrSigPrice) *100;

    // let removeMethod = tvWidget
    //   .chart()
    //   .createPositionLine()
    //   .onModify(function () {
    //     this.setText("onModify called");
    //   })
    //   .onReverse("onReverse called", function (text) {
    //     // this.setText(text);
    //   })
    //   .onClose("onClose called", function (text) {
    //     // this.setText(text);
    //   })
    //   .setText(`Open PnL = ${openPnl}`)
    //   // .setTooltip("Additional position information")
    //   .setProtectTooltip("OPen PnL %")
    //   .setCloseTooltip("Close position")
    //   .setReverseTooltip("Reverse position")
    //   .setQuantity(openPnlPercntage.toFixed(2))
    //   .setPrice(StreaminglastBarClosePriceNew.toFixed(2))
    //   .setExtendLeft(false)
    //   .setLineStyle(0)
    //   .setLineLength(5);

    //   removeMethod
    //   .remove()
    var shapeId=  tvWidget.chart().createMultipointShape([{time: StreaminglastBarClosePrice.time/1000, price:CurrPrice.toFixed(2)}], 
     {
       shape: 'text',
       icon: 0xf111,
       lock: true,
       text:`Open PnL = ${openPnl} / ${openPnlPercntage.toFixed(2)}%`,
    //#region Override the default properties of the shape the charting library have provide some more properties so we can customize them
      overrides: {
      color:"#9acd32",
      size : 10
      }
      });
    //#endregion Override the default properties of the shape the charting library have provide some more properties so we can customize them
    tvWidget.chart().getShapeById(shapeId)//.bringToFront();
    // CreatePositionArray = shapeId;
  //#endregion Circle creation process

  }
};
//#endregion Create Circles on chart
export default createPositionLine;
