import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function BottomWidget({
  theme,
  setBottomWidgetStatus,
  BottomWidgetStatus,
  tabs
}) {
  const classes = makeStyles(themeI => ({
    root: {
      // flexGrow: 1,
      position: "relative",
      backgroundColor: theme === "Light" ? "white" : "#131721",
      width: "100%"
    },
    buttonContainer: {
      position: "absolute",
      top: "10px",
      right: "0",
      zIndex: "100000"
    },
    tabs: {
      backgroundColor: theme === "Light" ? "#f1f1f1" : "#131734",
      color: theme === "Light" ? "#131721" : "white",
      zIndex: "-00111111"
    },
    icon: {
      color: theme === "Light" ? "#131721" : "white"
    }
  }))();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      
      <AppBar position="static">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label=""
          size="small"
        >
          {tabs.map((tab, i) => (
            <Tab size="small" key={i} label={tab.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, i) => (
        <TabPanel key={i} value={value} index={i}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
}
