import React, { useState, useEffect } from "react";
import Axios from "axios";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../../config/links.config'
import {
  formatDate,
  currency_formatter,
  percentage_formatter,
  removeDups
} from "../../../helpers/index";
import SortIcon from "@material-ui/icons/Sort";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { TrendingUp, TrendingDown, BarChart, Close } from "@material-ui/icons";
import { token } from '../../../../../configMiddleware/getToken';
const axios = require("axios");

const GainLoss = props => {
  const theme = props.theme;
  const clr_theme = theme;
  const style = {
    container: {
      overflowX: "scroll",
      color: theme === "Dark" ? "#d7d8db" : "#131721"
    },
    innerContainer: {
      minWidth: "720px"
    }
  };

  const classes = makeStyles(theme => ({
    margin: {
      margin: 0
    },
    icon: {
      fontSize: 16,
      padding: 0,
      marginBottom: 0,
      marginRight: "5px"
    },
    switch: {
      fontSize: "16px",
      height1: "16px"
    },
    inputLikeBox: {
      // borderRadius: 10,
      // marginRight: "10px",
      position: "relative",
      backgroundColor: "transparent",
      border: "none",
      marginRight: "10px",
      borderBottom: "2px solid #ced4da",
      fontSize: 16,
      padding: "2.5px 12px 2.5px 12px",
      display: "inline-block",
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721",
      outline: "none"
    }
  }))();
  const [gain_loss, set_gain_loss] = useState([]);
  const [symbols, set_symbols] = useState([]);
  const [filtered_gain_loss, set_filtered_gain_loss] = useState([]);
  const [filter_preset, set_filter_preset] = useState({
    symbol: "",
    open_date: "",
    close_date: ""
  });
  const [summary, set_summary] = useState({
    total_cost_basis: 0,
    total_proceeds: 0,
    total_pnl: 0,
    total_pnl_percent: 0
  });
  const [page, set_page] = useState(1);
  const [error, set_error] = useState({});
  const [sort_status, set_sort_status] = useState(-1);
  const [filter_bar_visiblity, set_filter_bar_visibility] = useState(false);
  const [refresh_count, set_refresh] = useState(0);
  const get_gain_loss = page => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_gain_loss?acct_number=${props.user.selected_tradier_account}&page=${page}`,{headers: { Authorization:  token }}
    )
      .then(res => {
        set_page(page);
        if (Array.isArray(res.data.gainloss.closed_position)) {
          set_gain_loss([...gain_loss, ...res.data.gainloss.closed_position]);
        } else {
          set_gain_loss([...gain_loss, res.data.gainloss.closed_position]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

      //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_gain_loss`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account,
      //           page : page
      //         },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) => {
      //     set_page(page);
      //     if (Array.isArray(res.data.gainloss.closed_position)) {
      //       set_gain_loss([...gain_loss, ...res.data.gainloss.closed_position]);
      //     } 
      //     else {
      //       set_gain_loss([...gain_loss, res.data.gainloss.closed_position]);
      //     }
      //     return res.data
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => { 
      //     if (e.response) set_error({ ...error, ...e.response.data });
      //   })
      //#endregion auth endpoint
  };
  useEffect(() => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_gain_loss?acct_number=${props.user.selected_tradier_account}&page=${page}`,{headers: { Authorization:  token }}
    )
      .then(res => {
        set_page(page);
        if (Array.isArray(res.data.gainloss.closed_position)) {
          set_gain_loss([...res.data.gainloss.closed_position]);
        } else {
          set_gain_loss([res.data.gainloss.closed_position]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

//#region auth endpoint
            // token().then( (token) => {      
            //   return axios({
            //         method: "get",
            //         url: `${acctURL}/api/account/get_gain_loss`,
            //         params : {
            //           acct_number : props.user.selected_tradier_account,
            //           page : page
            //   },
            //         headers: { Authorization: "Bearer " + token }
            //   }).then(( res ) =>{
            //     set_page(page);
            //     if (Array.isArray(res.data.gainloss.closed_position)) {
            //       set_gain_loss([...res.data.gainloss.closed_position]);
            //     } else {
            //       set_gain_loss([res.data.gainloss.closed_position]);
            //     }
            //         return res.data
            //   }).catch((e) => console.log("axios error"))
            //   }).catch( (e) =>{ console.log(e)
            //     if (e.response) set_error({ ...error, ...e.response.data });
            //   })
      //#endregion auth endpoint
      
  }, [props.user, refresh_count]);
  useEffect(() => set_filtered_gain_loss(gain_loss), [gain_loss]);
  useEffect(() => {
    const sym = removeDups(
      gain_loss.map(gain_loss => {
        return gain_loss.symbol;
      })
    );
    let token = localStorage.getItem("jwtToken"); 
    Axios.post(`${acctURL}/api/account/get_ul_symbols`, sym,{headers: { Authorization:  token }})
      .then(res => {
        set_symbols(res.data);
      })
      .catch(e => {
        if (e.response) console.log(e.response.data);
      });

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "post",
      //         url: `${acctURL}/api/account/get_ul_symbols`,
      //         data: sym,
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     set_symbols(res.data);
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => {if (e.response) console.log(e.response.data);})
//#endregion auth endpoint



  }, [gain_loss]);
  useEffect(() => {
    let total_cost_basis = 0,
      total_proceeds = 0,
      total_pnl,
      total_pnl_percent;
    filtered_gain_loss.forEach(gnl => {
      total_cost_basis += gnl.cost;
      total_proceeds += gnl.proceeds;
    });
    total_pnl = total_proceeds - total_cost_basis;
    total_pnl_percent = total_pnl / total_cost_basis;
    set_summary({
      total_cost_basis,
      total_proceeds,
      total_pnl,
      total_pnl_percent
    });
  }, [filtered_gain_loss]);
  const filter_gain_loss = filter_preset => {
    const { symbol, open_date, close_date } = filter_preset;

    set_filtered_gain_loss(
      gain_loss.filter(
        gain_loss =>
          gain_loss.symbol
            .toLocaleLowerCase()
            .includes(symbol.toLocaleLowerCase()) &&
          formatDate(new Date(gain_loss.open_date))
            .toLocaleLowerCase()
            .includes(open_date.toLocaleLowerCase()) &&
          formatDate(new Date(gain_loss.close_date))
            .toLocaleLowerCase()
            .includes(close_date.toLocaleLowerCase())
      )
    );
  };
  const sort_by_symbol = () => {
    let gNl = [...gain_loss];
    let sorted = gNl.sort((a, b) => {
      return a.symbol > b.symbol ? -1 * sort_status : 1 * sort_status;
    });
    set_gain_loss(sorted);
    set_sort_status(-1 * sort_status);
  };
  const sort_data = d => {
    const gNl = [...gain_loss];
    const sorted = gNl.sort((a, b) => {
      return sort_status < 1
        ? new Date(a[d]) - new Date(b[d])
        : new Date(b[d]) - new Date(a[d]);
    });
    set_gain_loss(sorted);
    set_sort_status(-1 * sort_status);
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "10px", right: "15px" }}>
          <button
            className={`btn mr-2 btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={() => set_filter_bar_visibility(!filter_bar_visiblity)}
          >
            <SearchIcon />
          </button>
          <button
            className={`btn btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={() => set_refresh(refresh_count + 1)}
          >
            <RefreshIcon />
          </button>
        </div>

        <div
          className="container-fluid pt-2"
          id="style-3"
          style={style.container}
        >
          <div
            className={`inner-container pb-2 d-${
              filter_bar_visiblity ? "flex" : "none"
            } flex-row flex-wrap`}
            style={{ ...style.innerContainer, paddingTop: "5px" }}
          >
            <h6 className="mr-4 pt-1">
              <SearchIcon />
            </h6>
            <span className="d-inline-block mr-2 position-relative">
              <input
                onChange={e => {
                  filter_gain_loss({
                    ...filter_preset,
                    symbol: e.target.value
                  });
                  set_filter_preset({
                    ...filter_preset,
                    symbol: e.target.value
                  });
                }}
                type="text"
                list="gain-loss-symbol-list"
                name=""
                placeHolder="symbol"
                className={classes.inputLikeBox}
                id="gainloss_symbol"
                placeholder="Symbol"
                value={filter_preset.symbol}
              />
              <datalist id="gain-loss-symbol-list">
                {symbols.map(sym => {
                  return <option key={sym} value={sym} />;
                })}
              </datalist>
              <span
                className={`position-absolute text-secondary cursor d-${
                  filter_preset.symbol ? "inline" : "none"
                }`}
                style={{ right: "40px", cursor: "pointer" }}
                onClick={() => {
                  filter_gain_loss({
                    ...filter_preset,
                    symbol: ""
                  });
                  set_filter_preset({
                    ...filter_preset,
                    symbol: ""
                  });
                }}
              >
                <Close fontSize="small" />
              </span>
            </span>
            <span className="d-inline-block mr-2 position-relative">
              <input
                type="text"
                onChange={e => {
                  filter_gain_loss({
                    ...filter_preset,
                    open_date: e.target.value
                  });
                  set_filter_preset({
                    ...filter_preset,
                    open_date: e.target.value
                  });
                }}
                list="gain-loss-open-date"
                name=""
                placeHolder="Open Date"
                className={classes.inputLikeBox}
                id="gainloss_open_date"
                value={filter_preset.open_date}
              />
              <datalist id="gain-loss-open-date">
                {removeDups(
                  gain_loss.map(gain_loss =>
                    formatDate(new Date(gain_loss.open_date))
                  )
                ).map(open_date => {
                  return <option key={open_date} value={open_date} />;
                })}
              </datalist>

              <span
                className={`position-absolute text-secondary cursor d-${
                  filter_preset.open_date ? "inline" : "none"
                }`}
                style={{ right: "40px", cursor: "pointer" }}
                onClick={() => {
                  filter_gain_loss({
                    ...filter_preset,
                    open_date: ""
                  });
                  set_filter_preset({
                    ...filter_preset,
                    open_date: ""
                  });
                }}
              >
                <Close fontSize="small" />
              </span>
            </span>
            <span className="d-inline-block mr-2 position-relative">
              <input
                type="text"
                onChange={e => {
                  filter_gain_loss({
                    ...filter_preset,
                    close_date: e.target.value
                  });
                  set_filter_preset({
                    ...filter_preset,
                    close_date: e.target.value
                  });
                }}
                list="gain-loss-close-date"
                name=""
                placeHolder="Close Date"
                className={classes.inputLikeBox}
                id="gainloss_close_date"
                value={filter_preset.close_date}
              />
              <datalist id="gain-loss-close-date">
                {removeDups(
                  gain_loss.map(gain_loss =>
                    formatDate(new Date(gain_loss.close_date))
                  )
                ).map(close_date => {
                  return <option key={close_date} value={close_date} />;
                })}
              </datalist>

              <span
                className={`position-absolute text-secondary cursor d-${
                  filter_preset.close_date ? "inline" : "none"
                }`}
                style={{ right: "40px", cursor: "pointer" }}
                onClick={() => {
                  filter_gain_loss({
                    ...filter_preset,
                    close_date: ""
                  });
                  set_filter_preset({
                    ...filter_preset,
                    close_date: ""
                  });
                }}
              >
                <Close fontSize="small" />
              </span>
            </span>
          </div>
          <div
            className="inner-container w-25 d-flex flex-row flex-wrap pt-1"
            style={{
              ...style.innerContainer,
              paddingTop: "11px"
            }}
          >
            <span className="d-inline-block mr-2">
              <h6
                className="mb-0"
                style={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                <small>
                  <BarChart /> P&L Summary:{" "}
                  <span
                    style={{
                      color: summary.total_pnl > 0 ? "green" : "#d92353"
                    }}
                  >
                    {currency_formatter.format(summary.total_pnl)}{" "}
                  </span>
                  <span
                    style={{
                      color: summary.total_pnl > 0 ? "green" : "#d92353"
                    }}
                  >
                    (
                    {percentage_formatter.format(
                      summary.total_pnl_percent * 100
                    )}
                    ){summary.total_pnl > 0 ? <TrendingUp /> : <TrendingDown />}
                  </span>
                </small>
              </h6>
            </span>
          </div>

          <div className="inner-container" style={style.innerContainer}>
            <div className="row tab-row">
              <div className="col-3 tab-col">
                <span style={{ cursor: "pointer" }} onClick={sort_by_symbol}>
                  <SortIcon />
                </span>
                <small>Symbol</small>
              </div>
              <div className="col-1 tab-col">
                <small>Qty</small>
              </div>
              <div className="col-1 tab-col">
                <small>
                  Cost <br /> <small> Basis</small>
                </small>
              </div>
              <div className="col-1 tab-col">
                <small>Proceeds</small>
              </div>
              <div className="col-1 tab-col">
                <div className="d-flex">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sort_data("gain_loss")}
                  >
                    <SortIcon />
                  </span>
                  <p>
                    <small>P&L</small>
                  </p>
                </div>
              </div>
              <div className="col-1 tab-col">
                <div className="d-flex">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sort_data("open_date")}
                  >
                    <SortIcon />
                  </span>
                  <p>
                    <small>
                      Open <small> Date</small>
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-1 tab-col">
                <div className="d-flex">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sort_data("close_date")}
                  >
                    <SortIcon />
                  </span>
                  <p>
                    <small>
                      Close <small> Date</small>
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-1 tab-col">
                <div className="d-flex">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sort_data("term")}
                  >
                    <SortIcon />
                  </span>
                  <p>
                    <small>
                      Duration <small> (Days)</small>
                    </small>
                  </p>
                </div>{" "}
              </div>
            </div>
            {filtered_gain_loss.map((gl, i) => {
              return (
                <div className="row tab-row">
                  <div className="col-3 tab-col">
                    <div className="inner-col">
                      <p>
                        <strong>{gl.symbol} </strong>
                      </p>
                    </div>
                  </div>
                  <div className="col-1 tab-col">
                    <div className="inner-col">
                      <small>{gl.quantity}</small>
                    </div>
                  </div>
                  <div className="col-1 tab-col">
                    <div className="inner-col">
                      {" "}
                      <small> {currency_formatter.format(gl.cost)}</small>
                    </div>
                  </div>

                  <div className="col-1 tab-col ">
                    <div className={`inner-col`}>
                      {" "}
                      <p>
                        <small>
                          <strong>
                            {currency_formatter.format(gl.proceeds)}
                          </strong>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-1 tab-col">
                    <div
                      className={`inner-col text-${
                        gl.gain_loss < 0 ? "danger" : "success"
                      }`}
                    >
                      <p>
                        <small>
                          <strong>
                            {" "}
                            {currency_formatter.format(gl.gain_loss)}
                          </strong>
                        </small>
                      </p>
                      <p>
                        {
                          <small>
                            <small>
                              {percentage_formatter.format(
                                gl.gain_loss_percent
                              )}
                            </small>
                          </small>
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-1 tab-col ">
                    <div className={`inner-col`}>
                      {" "}
                      <p>
                        <small>
                          <strong>{formatDate(new Date(gl.open_date))}</strong>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-1 tab-col ">
                    <div className={`inner-col`}>
                      {" "}
                      <p>
                        <small>
                          <strong>{formatDate(new Date(gl.close_date))}</strong>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-1 tab-col ">
                    <div className={`inner-col`}>
                      {" "}
                      <p>
                        <small>
                          <strong>{gl.term}{" "}days</strong>
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <p className="text-danger">{error.gain_loss}</p>
        <button
          className={`btn btn-sm ${
            theme === "Dark" ? "btn-outline-light" : "btn-outline-dark"
          }`}
          onClick={() => get_gain_loss(page + 1)}
        >
          Load more
        </button>
      </div>
    </>
  );
};

export default GainLoss;
