import React from "react";
import SubMain from "./components/Ticket/index";
import { Provider } from "react-redux";
import store from "./store";
import { set_info_ticket, set_option_ticket } from "./actions/optionTicket";

const Main = props => {
  return (
    <Provider store={store}>
      <SubMain theme={props.theme} />
    </Provider>
  );
};

Main.defaultProps = {
  theme: "Dark"
};

export default Main;

export const open_info_ticket = option =>
  store.dispatch(set_info_ticket(option));

export const open_ticket = option => store.dispatch(set_option_ticket(option));
