const initialState = {
  symbol: "",
  optionSymbol: "",
  stock: {
    open: 0,
    high: 0,
    low: 0,
    close: 0,
    price: 0,
    vol: 0,
    bid: 0,
    ask: 0
  },
  allColumns: [
    {
      name: "ask",
      title: "Ask"
    },
    {
      name: "asksize",
      title: "Ask Size"
    },
    {
      name: "bid",
      title: "Bid "
    },
    {
      name: "bidsize",
      title: "Bid Size"
    },

    {
      name: "high",
      title: "High"
    },
    {
      name: "last",
      title: "Last"
    },
    {
      name: "last_volume",
      title: "Last Volume"
    },
    {
      name: "low",
      title: "Low"
    },
    {
      name: "open",
      title: "Open"
    },
    {
      name: "open_interest",
      title: "Open Interest"
    },

    {
      name: "prevclose",
      title: "Previous Close"
    },

    {
      name: "volume",
      title: "Volume"
    },
    {
      name: "ask_iv",
      title: "Ask IV",
      type: "greek"
    },
    {
      name: "bid_iv",
      title: "Bid IV",
      type: "greek"
    },
    {
      name: "delta",
      title: "Delta",
      type: "greek"
    },
    {
      name: "gamma",
      title: "Gamma",
      type: "greek"
    },
    {
      name: "mid_iv",
      title: "Mid IV",
      type: "greek"
    },
    {
      name: "phi",
      title: "Phi",
      type: "greek"
    },
    {
      name: "rho",
      title: "Rho",
      type: "greek"
    },
    {
      name: "theta",
      title: "Theta",
      type: "greek"
    },
    {
      name: "vega",
      title: "Vega",
      type: "greek"
    }
  ],
  selectedColumns: [
    {
      name: "ask",
      title: "Ask"
    },
    {
      name: "asksize",
      title: "Ask Size"
    },
    {
      name: "bid",
      title: "Bid "
    },
    {
      name: "bidsize",
      title: "Bid Size"
    },

    {
      name: "high",
      title: "High"
    },
    {
      name: "last",
      title: "Last"
    },
    {
      name: "last_volume",
      title: "Last Volume"
    },
    {
      name: "low",
      title: "Low"
    },
    {
      name: "open",
      title: "Open"
    },
    {
      name: "open_interest",
      title: "Open Interest"
    },

    {
      name: "prevclose",
      title: "Previous Close"
    },

    {
      name: "volume",
      title: "Volume"
    },
    {
      name: "ask_iv",
      title: "Ask IV",
      type: "greek"
    },
    {
      name: "bid_iv",
      title: "Bid IV",
      type: "greek"
    },
    {
      name: "delta",
      title: "Delta",
      type: "greek"
    },
    {
      name: "gamma",
      title: "Gamma",
      type: "greek"
    },
    {
      name: "mid_iv",
      title: "Mid IV",
      type: "greek"
    },
    {
      name: "phi",
      title: "Phi",
      type: "greek"
    },
    {
      name: "rho",
      title: "Rho",
      type: "greek"
    },
    {
      name: "theta",
      title: "Theta",
      type: "greek"
    },
    {
      name: "vega",
      title: "Vega",
      type: "greek"
    }
  ],
  near: 0,
  allExpirations: [],
  selectedExpiration: "",
  strikeRange: 2,
  strikeRangePreset: [4, 8, 16],
  strikes_to_select: [],
  strikes: [],
  custom_srike: false,
  options: { put_options: [], call_options: [] },
  last_price_update_interval_ID: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SYMBOL":
      return {
        ...initialState,
        selectedColumns: state.selectedColumns,
        symbol: action.payload.toUpperCase()
      };
    case "SET_LAST_PRICE_INTERVAL_ID":
      return { ...state, last_price_update_interval_ID: action.payload };
    case "UPDATE_STOCK":
      return { ...state, stock: { ...state.stock, ...action.payload } };
    case "UPDATE_NEAR_VALUE":
      return { ...state, near: action.payload };
    case "SET_STRIKE_RANGE":
      return { ...state, strikeRange: action.payload };
    case "STRIKE_TOGGLE":
      return { ...state, custom_srike: !state.custom_srike };
    case "GET_EXPIRATION":
      return { ...state, allExpirations: [...action.payload] };
    case "SELECT_EXPIRY":
      return {
        ...state,
        selectedExpiration: action.payload
      };
    case "SELECT_COLUMNS":
      return { ...state, selectedColumns: action.payload };
    case "GET_STRIKES":
      return { ...state, strikes_to_select: action.payload };
    case "SELECT_STRIKE":
      return { ...state, strikes: action.payload };
    case "GET_OPTIONS_CHAIN":
      return { ...state, options: action.payload };
    case "UPDATE_OPTION":
      return { ...state, options: action.payload };
    case "ERASE":
      return { ...state, options: {put_options:[], call_options:[]} };
    default:
      return state;
  }
};
