
import {postChartLayout} from '../../config/middleware.links';
import {getownchart} from '../../config/middleware.links';
import {deletechartlayout} from '../../config/middleware.links';
import {getChartContent} from '../../config/middleware.links';
const axios = require("axios");
const { token } = require('../../middleware/getToken');
/**
 * THis script is designed to handle the "PUSH" chart layouts, "GET" the chart layouts on the basis of chartID. "Remove" the chart
 * layouts on the basis of chartID and "GET" the chart content on the basis of chartID. To handle these 4 use cases
 * we have designed/develop the endpoints in the symbolApi package to address them.
 * @param chartData
 * @param chartId
 * @function chartLayout
 * @function getallcharts
 * @function removeByID
 * @function getChartContent
 * @class chartLayoutAPI
 */
export default class chartLayoutAPI {

    //#region PUSh charts layouts to DB        
        chartLayout = (chartData) => {
            let token = localStorage.getItem("jwtToken");
            //#region Declare/Define "POST" object and properties
            const chartproperties = {
                id : chartData.id,
                name : chartData.name,
                resolution : chartData.resolution,
                symbol : chartData.symbol,
                timestamp : chartData.timestamp,
                content: chartData.content        
            };
            //#endregion "POST" argument

            //#region "POST" request to push the chartlayouts to DB
            // token().then( (token) => {      
                // console.log(token)      
                return axios({
                    method: "post",
                    url: postChartLayout,
                    data: chartproperties,
                    // headers: { Authorization: "Bearer " + window.parent.secrettoken }
                    headers: { Authorization: token }
                }).then(( res ) => {
                        //  console.log(res.data)
                    return res.data
                }).catch((e) => console.log("axios error"))
            // }).catch( (e) => console.log(e)) 
            //#endregion "POST" request to push the chartlayouts to DB
        }   
    //#endregion PUSh charts layouts to DB

    //#region GET all the chartlayouts
        getallcharts = () => {
            let returnData;
            let token = localStorage.getItem("jwtToken");
            return new Promise( resolve => {
                //#region "GET" request to get all the chart layouts
                // token().then( (token) => {      
                return axios({
                    method: "get",
                    url: getownchart,
                    // headers: { Authorization: "Bearer " + window.parent.secrettoken }
                    headers: { Authorization: token }
                }).then(( res ) =>{
                    returnData = res.data 
                    console.log("returnData: ", returnData)
                    setTimeout(() => {
                        resolve(returnData)
                    }, 0);
                    return res.data;

                }).catch((e) => console.log("axios error"))
                // }).catch( (e) => console.log(e));
                
                //#endregion "GET" request to get all the chart layouts
        
            })              
        }
    //#endregion GET all the chartlayouts

    //#region REMOVE the chartlayout on the basis of chartID
    removeByID = (chartId) => {
        console.log("chart ID : ", chartId)
        let token = localStorage.getItem("jwtToken");

        //#region "REMOVE" request to remove the chart layout 
        // token().then( (token) => {      
            return axios({
                method: "delete",
                url: deletechartlayout,
                params: { id: chartId},
                // headers: { Authorization: "Bearer " + window.parent.secrettoken }
                headers: { Authorization: token }
            }).then(( res ) =>{
                return res.data
            }).catch((e) => console.log("axios error"))
        // }).catch( (e) => console.log(e));
        //#endregion "REMOVE" request to remove the chart layout 

    }
    //#endregion REMOVE the chartlayout on the basis of chartID

    //#region GET the chart content/data on the basis of chartID
    getChartContent = (chartId) => {
        let returnData;
        let token = localStorage.getItem("jwtToken");
        return new Promise( resolve => {

            //#region "GET" reguest to get the chart content
            
            // token().then( (token) => {      
                return axios({
                    method: "get",
                    url: getChartContent,
                    params: { id: chartId},
                    // headers: { Authorization: "Bearer " + window.parent.secrettoken }
                    headers: { Authorization: token }
                }).then(( res ) =>{
                    returnData = res.data;
                    setTimeout(() => {
                        resolve(returnData)
                    }, 1000);
                    return res.data
                }).catch((e) => console.log("axios error"))
            // }).catch( (e) => console.log(e));

            
            
            //#endregion "GET" reguest to get the chart content

        })

    }
    //#endregion GET the chart content/data on the basis of chartID

}