/**
 * study_properties_changed : Trigger when Study properties are changed. Entity ID will be passed as an argument
 * @param {*} tvWidget 
 * @function study_properties_changed
 * @event study_properties_changed
 */

const study_properties_changed = (tvWidget) => {

    //#region Subscribe to event "study_properties_changed" triggers when the inputs/properties changes on the chart
    tvWidget.subscribe('study_properties_changed', ()=> {
        
        console.log("study_properties_changed Trigger");       

        //#region Script to remove the "text", "arrows", "circles/icons", and "callouts" when we change the inputs/properties        
            tvWidget.chart().clearMarks();

            for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++) {
            tvWidget.chart().removeEntity(window.parent.textShapeID[i]);
            }
            for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++) {
                tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);
            }
            for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++) {
                tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);
            }
        //#endregion Script to remove the "text", "arrows", "circles/icons", and "callouts" when we change the inputs/properties        
        
    });
    //#endregion Subscribe to event "study_properties_changed" triggers when the inputs/properties changes on the chart

}
export default study_properties_changed;