import refreshMarks from '../library_Utils/refreshMarks';

/**
 * onDataLoaded is an API/Method or an event accur when more historical data loaded.
 * @param {*} tvWidget 
 * @function onDataLoaded
 */


const onDataLoaded = (tvWidget) => {
    //#region Subscribe to ondataloaded event as it tells us that the historical data is loading as if there is historical data
    tvWidget.chart().onDataLoaded().subscribe(null, () => {
        
        console.log('New history bars are loaded');
        // if(window.parent.checkIndicatorLoadedONChart == true)
        // {
        // //#region Refesh the markers when the interval changes
        // refreshMarks(tvWidget);
        // //#endregion Refesh the markers when the interval changes

        // //#region remove the Text on the basis on shapes ID
        // for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++){
        //     tvWidget.chart().removeEntity(window.parent.textShapeID[i]);
        // }
        // //#endregion remove the Text on the basis on shapes ID

        // //#region remove the Arrow on the basis on shapes ID
        // for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++){
        //     tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);
        // }
        // //#endregion remove the Arrow on the basis on shapes ID

        // //#region remove the Circles on the basis on shapes ID
        // for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++){
        //     tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);
        // }
        // //#endregion remove the Circles on the basis on shapes ID

        // }
    });
    //#endregion Subscribe to ondataloaded event as it tells us that the historical data is loading as if there is historical data
}
export default onDataLoaded;