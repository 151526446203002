import createTextOnChart from '../library_Utils/createTextOnChart';
import createArrowOnChart from '../library_Utils/createArrowOnChart';
/**
 * @param {*} context 
 * @function dnTrend
 */
const dnTrend = (context) => {
        context.Data[0].trendSignal = -1;

        if (!context.dnArrPlot) {
            if(window.parent.DBsignal === false && window.parent.indicatorName === "AlgoSwing"){
                createArrowOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].High + 1, "arrow_down");
                createTextOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].High, context.Data[0].Close);
            }
            context.dnArrPlot = true;
            context.upArrPlot = false;
        }    
  }
  export {dnTrend};