import {
  calculateSeries,
  getDaysDiffInYear,
  getInterestRateVariable
} from "./helper";
const iv = require("implied-volatility");
var greeks = require("greeks");

const numFixer = num => {
  let new_number;
  let split_array = num.toString().split(".");
  if (split_array.length >= 2) {
    let nonDec = split_array[0];
    let stdec = "" + split_array[1].charAt(0);
    let nddec = "" + split_array[1].charAt(1);
    let rddec = "" + split_array[1].charAt(2);
    new_number = nonDec + "." + stdec + nddec + rddec;
  } else {
    new_number = num;
  }

  return new_number;
};

export default async (option, date, symbol, last_price) => {
  // console.log("prev option");
  const series = calculateSeries(date);
  const daysDifferenceInYear = getDaysDiffInYear(date);
  let se_au = JSON.parse(localStorage.getItem("se_au"));
  let current_date = new Date();
  current_date = {
    day: current_date.getDate(),
    month: current_date.getMonth(),
    time: Number(
      current_date.getHours() + "" + ("0" + current_date.getMinutes()).slice(-2)
    )
  };
  if (Boolean(se_au) && se_au.date.time < 930 && se_au.date.time > 1630) return {};
  let annualRisk;
  if (
    Boolean(se_au) &&
    se_au.series === series &&
    se_au.date.day === current_date.day &&
    se_au.date.month === current_date.month
  ) {
    annualRisk = se_au.annualRisk;
  } else {
    annualRisk = (await getInterestRateVariable(series)) / 100;
    annualRisk = numFixer(annualRisk)
    localStorage.setItem(
      "se_au",
      JSON.stringify({ series, annualRisk, date: current_date })
    );
  }
  // console.log(series, daysDifferenceInYear, annualRisk, "series", current_date);
  // const stock = option;
  const stockPrice = last_price;
  let expectedCost = undefined;
  let strike = undefined;
  let type = undefined;
  let volatility = undefined;

  expectedCost = option.last ? option.last : option.ask;
  strike = option.strike;
  type = option.option_type;
  volatility = iv.getImpliedVolatility(
    expectedCost,
    stockPrice,
    strike,
    daysDifferenceInYear,
    annualRisk,
    type
  );
  option.greeks.iv = volatility;
  option.greeks.delta = greeks.getDelta(
    stockPrice,
    strike,
    daysDifferenceInYear,
    volatility,
    annualRisk,
    type
  );
  option.greeks.ask_iv = iv.getImpliedVolatility(
    option.ask,
    stockPrice,
    strike,
    daysDifferenceInYear,
    annualRisk,
    type
  );
  option.greeks.bid_iv = iv.getImpliedVolatility(
    option.bid,
    stockPrice,
    strike,
    daysDifferenceInYear,
    annualRisk,
    type
  );
  option.greeks.mid_iv = iv.getImpliedVolatility(
    (option.ask + option.bid) / 2,
    stockPrice,
    strike,
    daysDifferenceInYear,
    annualRisk,
    type
  );
  option.greeks.gamma = greeks.getGamma(
    stockPrice,
    strike,
    daysDifferenceInYear,
    volatility,
    annualRisk,
    type
  ); // 0.06573105549942765
  option.greeks.vega = greeks.getVega(
    stockPrice,
    strike,
    daysDifferenceInYear,
    volatility,
    annualRisk,
    type
  );
  option.greeks.theta = greeks.getTheta(
    stockPrice,
    strike,
    daysDifferenceInYear,
    volatility,
    annualRisk,
    type
  );
  option.greeks.rho = greeks.getRho(
    stockPrice,
    strike,
    daysDifferenceInYear,
    volatility,
    annualRisk,
    type
  );
  return option;
};
