import React, { useState, useEffect } from "react";
import Axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { Close } from "@material-ui/icons";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../../config/links.config'
import {
  get_desc,
  transformString,
  removeDups
} from "./../../../helpers/index";

import { token } from '../../../../../configMiddleware/getToken';
const axios = require("axios");

const dummy_order = [
  {
    id: 228175,
    type: "limit",
    symbol: "AAPL",
    side: "buy",
    quantity: 50.0,
    status: "expired",
    duration: "pre",
    price: 22.0,
    avg_fill_price: 0.0,
    exec_quantity: 0.0,
    last_fill_price: 0.0,
    last_fill_quantity: 0.0,
    remaining_quantity: 0.0,
    create_date: "2018-06-01T12:02:29.682Z",
    transaction_date: "2018-06-01T12:30:02.385Z",
    class: "equity"
  },
  {
    id: 228749,
    type: "market",
    symbol: "SPY",
    side: "buy_to_open",
    quantity: 1.0,
    status: "expired",
    duration: "pre",
    avg_fill_price: 0.0,
    exec_quantity: 0.0,
    last_fill_price: 0.0,
    last_fill_quantity: 0.0,
    remaining_quantity: 0.0,
    create_date: "2018-06-06T20:16:17.342Z",
    transaction_date: "2018-06-06T20:16:17.357Z",
    class: "option",
    option_symbol: "SPY180720C00274000"
  },
  {
    id: 229063,
    type: "debit",
    symbol: "SPY",
    side: "buy",
    quantity: 1.0,
    status: "canceled",
    duration: "pre",
    price: 42.0,
    avg_fill_price: 0.0,
    exec_quantity: 0.0,
    last_fill_price: 0.0,
    last_fill_quantity: 0.0,
    remaining_quantity: 0.0,
    create_date: "2018-06-12T21:13:36.076Z",
    transaction_date: "2018-06-12T21:18:41.604Z",
    class: "combo",
    num_legs: 2,
    strategy: "covered call",
    leg: [
      {
        id: 229064,
        type: "debit",
        symbol: "SPY",
        side: "buy",
        quantity: 100.0,
        status: "canceled",
        duration: "pre",
        price: 42.0,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-12T21:13:36.076Z",
        transaction_date: "2018-06-12T21:18:41.587Z",
        class: "equity"
      },
      {
        id: 229065,
        type: "debit",
        symbol: "SPY",
        side: "sell_to_close",
        quantity: 1.0,
        status: "canceled",
        duration: "pre",
        price: 42.0,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-12T21:13:36.076Z",
        transaction_date: "2018-06-12T21:18:41.597Z",
        class: "option",
        option_symbol: "SPY180720C00274000"
      }
    ]
  },
  {
    id: 229123,
    type: "credit",
    symbol: "SPY",
    side: "buy",
    quantity: 1.0,
    status: "expired",
    duration: "pre",
    price: 0.8,
    avg_fill_price: 0.0,
    exec_quantity: 0.0,
    last_fill_price: 0.0,
    last_fill_quantity: 0.0,
    remaining_quantity: 0.0,
    create_date: "2018-06-13T16:54:39.812Z",
    transaction_date: "2018-06-13T20:55:00.069Z",
    class: "multileg",
    num_legs: 4,
    strategy: "condor",
    leg: [
      {
        id: 229124,
        type: "credit",
        symbol: "SPY",
        side: "buy_to_open",
        quantity: 1.0,
        status: "expired",
        duration: "pre",
        price: 0.8,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-13T16:54:39.812Z",
        transaction_date: "2018-06-13T20:55:00.069Z",
        class: "option",
        option_symbol: "SPY180720C00274000"
      },
      {
        id: 229125,
        type: "credit",
        symbol: "SPY",
        side: "sell_to_open",
        quantity: 1.0,
        status: "expired",
        duration: "pre",
        price: 0.8,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-13T16:54:39.812Z",
        transaction_date: "2018-06-13T20:55:00.069Z",
        class: "option",
        option_symbol: "SPY180720C00275000"
      },
      {
        id: 229126,
        type: "credit",
        symbol: "SPY",
        side: "sell_to_open",
        quantity: 1.0,
        status: "expired",
        duration: "pre",
        price: 0.8,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-13T16:54:39.812Z",
        transaction_date: "2018-06-13T20:55:00.069Z",
        class: "option",
        option_symbol: "SPY180720C00276000"
      },
      {
        id: 229127,
        type: "credit",
        symbol: "SPY",
        side: "buy_to_open",
        quantity: 1.0,
        status: "expired",
        duration: "pre",
        price: 0.8,
        avg_fill_price: 0.0,
        exec_quantity: 0.0,
        last_fill_price: 0.0,
        last_fill_quantity: 0.0,
        remaining_quantity: 0.0,
        create_date: "2018-06-13T16:54:39.812Z",
        transaction_date: "2018-06-13T20:55:00.069Z",
        class: "option",
        option_symbol: "SPY180720C00277000"
      }
    ]
  }
];

const Orders = props => {
  const theme = props.theme;
  const clr_theme = theme;

  const classes = makeStyles(theme => ({
    margin: {
      margin: 0
    },
    icon: {
      fontSize: 16,
      padding: 0,
      marginBottom: 0,
      marginRight: "5px"
    },
    switch: {
      fontSize: "16px",
      height1: "16px"
    },
    inputLikeBox: {
      position: "relative",
      backgroundColor: "transparent",
      border: "none",
      marginRight: "10px",
      borderBottom: "2px solid #ced4da",
      fontSize: 16,
      padding: "2.5px 12px 2.5px 12px",
      display: "inline-block",
      color: clr_theme === "Dark" ? "#d7d8db" : "#131721",
      outline: "none"
    }
  }))();
  const style = {
    container: {
      overflowX: "scroll",
      color: theme === "Dark" ? "#d7d8db" : "#131721"
    },
    innerContainer: {
      minWidth: "880px"
    }
  };
  const [orders, set_orders] = useState([]);
  const [filterd_order, set_filterd_order] = useState(orders);
  const [expanded_order, set_expanded_order] = useState(new Number());
  const [page, set_page] = useState(1);
  const [error, set_error] = useState({});
  const [s_symbol, set_s_symbol] = useState("");
  const [filter_bar_visiblity, set_filter_bar_visibility] = useState(false);
  const [refresh_count, set_refresh] = useState(0);
  const get_orders = page => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_orders?acct_number=${props.user.selected_tradier_account}&page=${page}`,{headers: { Authorization:  token }}
    )
      .then(res => {
        set_page(page);
        if (Array.isArray(res.data.orders.order)) {
          set_orders([...orders, ...res.data.orders.order]);
        } else {
          set_orders([...orders, res.data.orders.order]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_orders`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account,
      //           page : page
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     set_page(page);
      //     if (Array.isArray(res.data.orders.order)) {
      //       set_orders([...orders, ...res.data.orders.order]);
      //     } else {
      //       set_orders([...orders, res.data.orders.order]);
      //     }
      //         return res.data
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) =>{if (e.response) set_error({ ...error, ...e.response.data })})
//#endregion auth endpoint



  };
  useEffect(() => {
    let token = localStorage.getItem("jwtToken"); 
    Axios.get(
      `${acctURL}/api/account/get_orders?acct_number=${
        props.user.selected_tradier_account
      }&page=${1}`,{headers: { Authorization:  token }}
    )
      .then(res => {
        set_page(1);
        if (Array.isArray(res.data.orders.order)) {
          set_orders([...res.data.orders.order]);
        } else {
          set_orders([res.data.orders.order]);
        }
      })
      .catch(e => {
        if (e.response) set_error({ ...error, ...e.response.data });
      });

      //#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${acctURL}/api/account/get_orders`,
      //         params : {
      //           acct_number : props.user.selected_tradier_account,
      //           page : 1
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     set_page(1);
      //     if (Array.isArray(res.data.orders.order)) {
      //       set_orders([...res.data.orders.order]);
      //     } else {
      //       set_orders([res.data.orders.order]);
      //     }
      //         return res.data
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => {console.log(e);
      //     if (e.response) set_error({ ...error, ...e.response.data });
      //   })
//#endregion auth endpoint



  }, [props.user.selected_tradier_account, refresh_count]);
  useEffect(() => set_filterd_order(orders), [orders]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "10px", right: "15px" }}>
          <button
            className={`btn mr-2 btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={() => set_filter_bar_visibility(!filter_bar_visiblity)}
          >
            <SearchIcon />
          </button>
          <button
            className={`btn btn-outline-${
              theme === "Dark" ? "light" : "dark"
            }  btn-sm`}
            onClick={()=>set_refresh(refresh_count+1)}
          >
            <RefreshIcon />
          </button>
        </div>
        <div className="container-fluid " id="style-3" style={style.container}>
          <div
            className={`inner-container pb-2  flex-row pt-2 d-${
              filter_bar_visiblity ? "flex" : "none"
            }  flex-wrap`}
            style={style.innerContainer}
          >
            <h6 className="mr-4 pt-2">
              <SearchIcon />
            </h6>
            <span className="d-inline-block mr-2 position-relative">
              <input
                onChange={e => {
                  set_s_symbol(e.target.value);
                  set_filterd_order(
                    orders.filter(ord => {
                      if(!ord ) return ''
                      return ord.symbol
                        .toLowerCase()
                        .includes(e.target.value.toLocaleLowerCase());
                    })
                  );
                }}
                type="text"
                list="order-symbol-list"
                name=""
                placeHolder="symbol"
                className={classes.inputLikeBox}
                id="orderlist_symbil"
                placeholder="Symbol"
                value={s_symbol}
              />
              <datalist id="order-symbol-list">
                {removeDups(orders.map(order => order.symbol)).map(sym => (
                  <option key={sym} value={sym} />
                ))}
              </datalist>
              <span
                className={`position-absolute text-secondary cursor d-${
                  s_symbol ? "inline" : "none"
                }`}
                style={{ right: "40px", cursor: "pointer" }}
                onClick={() => {
                  set_s_symbol("");
                  set_filterd_order(orders);
                }}
              >
                <Close fontSize="small" />
              </span>
            </span>
          </div>
          <div
            className={`inner-container pb-5 pt-${
              filter_bar_visiblity ? "0" : "5"
            }`}
            style={style.innerContainer}
          >
            <div className="row tab-row">
              <div className="col-3 tab-col">
                <small>Symbol</small>
              </div>
              <div className="col-2 tab-col">
                <small>Side</small>
              </div>
              <div className="col-1 tab-col">
                <small>Qty</small>
              </div>
              <div className="col-1 tab-col">
                <small>Type</small>
              </div>
              <div className="col-1 tab-col">
                <small>Duration</small>
              </div>
              <div className="col-1 tab-col">
                <small>Filled/Qty</small>
              </div>
              <div className="col-1 tab-col">
                <small>Status</small>
              </div>
              <div className="col-1 tab-col">
                <small>Status</small>
              </div>
            </div>
            {filterd_order.map((order, index) => {
              return (
                <div>
                  <div className="row tab-row">
                    <div className="col-3 tab-col">
                      <div className="inner-col">
                        <p>
                          <strong>
                            {order.symbol}{" "}
                            {order.class === "multileg" ||
                            order.class === "combo" ? (
                              expanded_order !== index ? (
                                <span className="clickable_scale">
                                  {" "}
                                  <ExpandMoreIcon
                                    onClick={() => set_expanded_order(index)}
                                  />
                                </span>
                              ) : (
                                <span className="clickable_scale">
                                  <ExpandLessIcon
                                    onClick={() =>
                                      set_expanded_order(new Number())
                                    }
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </strong>
                        </p>
                        <p>
                          <small>
                            {order.class === "multileg" ||
                            order.class === "combo"
                              ? "spread"
                              : "-"}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="col-2 tab-col">
                      <div className="inner-col">
                        <small>
                          {order.class != "multileg" && order.class != "combo"
                            ? transformString(order.side, order)
                            : "-"}
                        </small>
                      </div>
                    </div>
                    <div className="col-1 tab-col">
                      <div className="inner-col">
                        {" "}
                        <small>{order.quantity}</small>
                      </div>
                    </div>
                    <div className="col-1 tab-col ">
                      <div className="inner-col">
                        {" "}
                        <p>
                          <small>
                            <strong>{order.type}</strong>
                          </small>
                        </p>
                        <p>
                          <small>at ${order.price}</small>
                        </p>
                      </div>
                    </div>
                    <div className="col-1 tab-col">
                      <div className="inner-col">
                        <small>{order.duration}</small>
                      </div>
                    </div>
                    <div className="col-1 tab-col">
                      <div className="inner-col">
                        <p>
                          <small>
                            <strong>
                              {order.last_fill_quantity} / {order.quantity}
                            </strong>
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="col-1 tab-col">
                      <div className="inner-col text-danger">
                        <p>
                          <span
                            className={`badge badge-${
                              order.status === "open"
                                ? "secondary"
                                : order.status === "expired"
                                ? "danger"
                                : order.status === "filled"
                                ? "primary"
                                : "warning"
                            }`}
                          >
                            <small>{order.status}</small>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <input type="checkbox" name="" id="" />
                    </div>
                  </div>

                  {order.class === "multileg" || order.class === "combo" ? (
                    <div
                      style={{
                        display: expanded_order === index ? "block" : "none"
                      }}
                    >
                      {order.leg.map(leg => {
                        let description;
                        if (leg.class === "option") {
                          description = get_desc(leg.symbol, leg.option_symbol);
                        }
                        return (
                          <div className="row tab-row">
                            <div className="col-3 tab-col">
                              <div className="inner-col">
                                <p>
                                  <small>
                                    {leg.class === "option"
                                      ? description
                                      : leg.symbol}{" "}
                                  </small>
                                </p>
                                <p>
                                  {/* <small>Jan 17 2020 $45.00 call</small> */}
                                </p>
                              </div>
                            </div>
                            <div className="col-2 tab-col">
                              <div className="inner-col">
                                <small>{transformString(leg.side, leg)}</small>
                              </div>
                            </div>
                            <div className="col-1 tab-col">
                              <div className="inner-col">
                                <p>
                                  <small>
                                    <strong>{leg.quantity}</strong>
                                  </small>
                                </p>
                              </div>
                            </div>
                            <div className="col-1 tab-col"></div>
                            <div className="col-1 tab-col"></div>
                            <div className="col-1 tab-col">
                              <div className="inner-col">
                                <p>
                                  <small>
                                    <strong>
                                      {leg.last_fill_quantity} / {leg.quantity}
                                    </strong>
                                  </small>
                                </p>
                              </div>
                            </div>
                            <div className="col-1 tab-col">
                              <div className="inner-col text-danger">
                                <p>
                                  <span
                                    className={`badge badge-${
                                      leg.status === "open"
                                        ? "primary"
                                        : leg.status === "expired"
                                        ? "danger"
                                        : "warning"
                                    }`}
                                  >
                                    <small>{leg.status}</small>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <p className="text-danger">{error.order}</p>
        <button
          className={`btn mb-3 btn-sm ${
            theme === "Dark" ? "btn-outline-light" : "btn-outline-dark"
          }`}
          onClick={() => get_orders(page + 1)}
        >
          Load more
        </button>
      </div>
    </>
  );
};

export default Orders;
