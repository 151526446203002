import refreshMarks from '../library_Utils/refreshMarks';
import removeIndicator from '../library_Utils/removeIndicator';
import studyName from '../library_Utils/studyname';

/**
 * onIntervalChanged : calls when we change the resolution on the chart.
 * These lines of code are performing multiple tasks. When we change the resolution we are removing
 * the shapes from the chart and we are removing the performace calculation that are stored
 * in the local storage.
 * This script is also doing on more thing that is when we change the resolution we are removing the 
 * indicator from the chart and load the new instance of an indicator.
 * removeEntity : is removing the one shape at a time from the chart. We are passing the shape ID we remove
 * the shape from the chart for an indicator.
 * @param {*} tvWidget 
 * @function onIntervalChanged
 */
const onIntervalChanged = (tvWidget) => {
    //#region Subscribe to onIntervalChanged event trigger when the interval/resolution on the chart changes
    tvWidget.chart().onIntervalChanged().subscribe(null, function(interval, obj) { 
        console.log("Interval Chnage",interval, obj, window.parent.indicatorName);        
        
        // if(window.parent.indicatorName_ID){removeIndicator(tvWidget,window.parent.indicatorName_ID)}
        // removeIndicator(tvWidget,window.parent.indicatorName_ID)
        window.localStorage.removeItem('Performance_Calculations');
        /**
         * Only remove the custom shapes when the indicator is loaded on the chart.
         */
        
        //#region process start only when there is an indicator on the chart
            if(window.parent.checkIndicatorLoadedONChart == true)
            {
                //#region Set the indexes to zero
                    window.parent.textShapeID_Index = 0;
                    window.parent.arrowShapeID_Index = 0;
                    window.parent.circleShapeID_Index = 0;
                //#endregion Set the indexes to zero

                //#region Refesh the markers when the interval changes
                    // refreshMarks(tvWidget);
                    tvWidget.chart().clearMarks();
                //#endregion Refesh the markers when the interval changes

                //#region remove the Text on the basis on shapes ID
                    for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++) {
                        
                        tvWidget.chart().removeEntity(window.parent.textShapeID[i])
                        
                    }
                    // console.log("Remove Entity : ", window.parent.textShapeID.length);
                //#endregion remove the Text on the basis on shapes ID

                //#region remove the Arrow on the basis on shapes ID
                    for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++) {
                        
                        tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);

                    }        
                //#endregion remove the Arrow on the basis on shapes ID

                //#region remove the Circles on the basis on shapes ID
                    for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++) {
                        
                        tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);

                    }
                
                //#endregion remove the Circles on the basis on shapes ID

                //#region Empty the array as another instance of indicator is loaded then we have the new set of array
                    window.parent.textShapeID.length = 0;
                    window.parent.arrowShapeID.length = 0;
                    window.parent.circleShapeID.length = 0;
                //#endregion Empty the array as another instance of indicator is loaded then we have the new set of array

                //#region Remove all the studies from chart
                    tvWidget.chart().removeAllStudies();
                //#endregion Remove all the studies from chart

                //#region Add another instance of indicator with the time interval - Time intervals is added to accomplish the goal that the indicator is loaded when we the tha data against the new interval so that the chart have the data on which indicator needs to be loaded
                //This code block resolved the issue we were having with quirky signal plots (i.e. we were getting 10+ signal plots on the same bar when loading intraday charts)    
                setTimeout(() => {
                        
                        tvWidget.chart().createStudy(window.parent.indicatorName, false, false).then(ID => {
                            window.parent.indicatorName_ID = ID;
                            console.log("Create New Indicator: "+ window.parent.indicatorName + window.parent.indicatorName_ID)
                            studyName(tvWidget);
                            });
                            refreshMarks(tvWidget);
                    }, 1000);
                    tvWidget.chart().createStudy('Volume', true, false)
                 //#endregion Add another instance of indicator with the time interval - Time intervals is added to accomplish the goal that the indicator is loaded when we the tha data against the new interval so that the chart have the data on which indicator needs to be loaded
            }
        //#endregion process start only when there is an indicator on the chart   

    });
    //#endregion Subscribe to onIntervalChanged event trigger when the interval/resolution on the chart changes
}
export default onIntervalChanged;