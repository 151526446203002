//#region Imoprt required modules
    import { ienterLong, ienterShort, iexitShort, iexitLong, exitLong, exitShort } from '../Helper_Methods/Middleware';
    import API from '../algoSignalsAPI/Api';
    import cAPI from '../algoSignalsAPI/cApi';
    import AutomatedTest from '../automatedTesting/automatedTest';
//#endregion Imoprt required modules

//#region Declare / Initialize variables
    let api = new API();
    let capi = new cAPI();
    var dateFormat = require('dateformat');
    let automatedTestObject = new AutomatedTest();
    const SignalSide = {
        SignalDirectionFilter : {
            Long: "Long",
            Short:"Short",
            Both:"Both"
        }
    }
    let SignalDirection = SignalSide.SignalDirectionFilter.Both;
    let  dynamicIndex; 
    let barCount = 0;
//#endregion Declare / Initialize variables

/**
 * Congnitive Logic 
 * In the Trading View Charting Library, we don't have access to the "GetValueAt" function 
 * so we have to use the 'k' BarsAgoIndex in our inversion logic here ... and wherever we have '[i - 1]' in NT we have to use 
 * '[k + 1]' in our TV code to arrive at the same bar reference.
 * @param {*} context 
 * @param {*} fast 
 * @param {*} slow 
 * @param {*} smooth 
 * @function inversion contains the cognitive logic for algosignals
 */

export default function inversion(context,fast,slow,smooth) {
    
    context.userEndDate = context.ML_End_Day_Month_Year;
        
    //#region Signal Optimization

        // console.log("context.userEndDate", context.userEndDate, new Date(context.userEndDate))
        // console.log(dateFormat(new Date(context.Data[0].Time)),"context.CurrentBar : ",context.CurrentBar, "context.Count : ", context.Count)

        if(context.userEndDate >= context.Data[0].Time && context.CurrentBar >= context.Count - 2 && !context.doOnce && context.cOptimize) //<= Assumes endDate = currDate 
        {	
                console.log("Enter into context.userEndDate >= context.Data[0].Time")
                // console.log("signalArray Original",context.signalArray)
                context.doOnce = true;										
                                                                                            
                //#region Dynamic Check To Determine If Optimization Is Necessary
                    context.avgBPS = (context.Count / context.totalSignals);						
                    
                    //#region Stocks
                        if(context.symbol.info.type == "stock" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10)
                        {															
                            let k = context.mktValCapList.length - 1;
                            //PrintTo = PrintTo.OutputTab2;
                            //Print(Time[0].ToString() + " | " + "Current Bar : " + CurrentBar + " | " + " Count : " + Count + " | " + " totalsignal : " + totalSignals + " | " +" avgBPS : " + avgBPS + " | "+ " k : "+ k );//+ " | " + "mktValCapList" + mktValCapList[k]
                            //Print("MktValCapThresh => " + (MktValCapThresh).ToString() + " | mktValCapList.Count - 1 => " + k + " | mktValCapList[k] => " + mktValCapList[k]);
                                //The MktValCap is <= the THRESHOLD for up to teh last 3 trades OR (The MktValCap is <= 80% of the Threshold AND MktValCap <= the THRESHOLD for up to the last 3 trades)										
                            if(context.mktValCapList[k] <= context.MktValCapThresh || context.mktValCapList[(k - 1)] <= context.MktValCapThresh || context.mktValCapList[(k -2)] <= context.MktValCapThresh ||
                                (context.mktValCapList[k] <= (context.ThreshMult * context.MktValCapThresh) && (context.mktValCapList[k] <= context.MktValCapThresh || context.mktValCapList[(k - 1)] <= context.MktValCapThresh || context.mktValCapList[(k - 2)] <= context.MktValCapThresh)))
                            {									
                                context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                                context.invertStructure = true;										
                            }
                                                                                
                        }
                    //#endregion Stocks

                    //#region Notes
                        /**************************************************************************************************************************************************************************
                        Need to add a detailed conditional with the netGain series for FX & Futures to avoid wacky inversion toggling around the toggle point. 	
                        ***************************************************************************************************************************************************************************
                        gainSeries accepts a BarsAgoIndex, therefore - Since we need to evaluate the netGain from the current bar after all bars are loaded, we can simply use 0, 1, 2, 3 etc. 
                        However, this won't work because we need the P&L associated with the LAST Several Trades and gainSeries give us the bar by bar gain - so we have to use the tradeGainTally List*/
                    //#endregion Notes
                    
                    //#region Forex
                        if(context.symbol.info.type == "Forex" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10 && context.netGain <= -500) //500 in this case means 500 pips
                        {	
                            context.tG = context.tradeGainTally.length - 1;
                            //The Gain is <= the THRESHOLD for up to teh last 3 trades OR (The Gain is <= 80% of the Threshold AND Gain <= the THRESHOLD for up to the last 3 trades)
                            if(context.tradeGainTally[context.tG] <= context.PiPThresh || context.tradeGainTally[context.tG - 1] <= context.PiPThresh || context.tradeGainTally[context.tG - 2] <= context.PiPThresh ||
                                (context.tradeGainTally[context.tG] <= (context.ThreshMult * context.PiPThresh) && (context.tradeGainTally[context.tG] <= context.PiPThresh || context.tradeGainTally[context.tG - 1] <= context.PiPThresh || context.tradeGainTally[context.tG - 2] <= context.PiPThresh)))
                            {	
                                context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                                context.invertStructure = true;	
                            }
                        }
                    //#endregion Forex
                    
                    //#region Futures
                        if(context.symbol.info.type == "Future" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10 && context.netGain <= -100)  //100 in this case means Ticks						
                        {
                            context.tG = context.tradeGainTally.length - 1;
                            //The Gain is <= the THRESHOLD for up to teh last 3 trades OR (The Gain is <= 80% of the Threshold AND Gain <= the THRESHOLD for up to the last 3 trades)
                            if(context.tradeGainTally[context.tG] <= context.TickThresh || context.tradeGainTally[context.tG - 1] <= context.TickThresh || context.tradeGainTally[context.tG - 2] <= context.TickThresh ||
                                (context.tradeGainTally[context.tG] <= (context.ThreshMult * context.TickThresh) && (context.tradeGainTally[context.tG] <= context.TickThresh || context.tradeGainTally[context.tG - 1] <= context.TickThresh || context.tradeGainTally[context.tG - 2] <= context.TickThresh)))
                            {	
                                context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                                context.invertStructure = true;	
                            }
                            context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                            context.invertStructure = true;	
                        }		
                    //#endregion Futures
                    
                    //#region Reset Variables For Inversion
                        if(context.invertSignals)
                        {                    
                            console.log("-------------------------------------- Contrarian Logic (" + context.symbol.ticker + ") --------------------------------------");
                            window.localStorage.removeItem('Performance_Calculations');                                                                      
                            
                            //#region Reset Bool Variables
                                //reset all relavant bool variables to thier initialized settings
                                context.firstSignal = true; 

                                context.longSig = false;						
                                context.skipLngEntry = false; 
                                
                                context.shortSig = false;						
                                context.skipShtEntry = false; 
                            
                                context.reversal = false; 																		                                                            
                            //#endregion reset Bool Variables																		

                            //#region Reset All Performance Variables                            
                                context.lEntryPrice = 0; 
                                context.lExitPrice = 0; 
                                context.sEntryPrice = 0; 
                                context.sExitPrice = 0; 
                                context.tradePnL = 0; 
                                context.netGain = 0; 
                                context.mktValCap = 0; 
                                context.wlRatio = 0;
                                context.aefeRatio = 0; 
                                context.avgGain = 0; 
                                context.minGain = 0;
                                context.maxGain = 0;
                                context.avgWin = 0;	
                                context.avgLoss = 0;
                                context.totWin = 0; 
                                context.totLoss = 0; 			
                                context.winRate = 0; 
                                context.winCount = 0; 
                                context.lossCount = 0; 				
                                context.maxMAE = 0; 
                                context.maxMFE = 0; 
                                context.avgMAE = 0; 
                                context.avgMFE = 0;				
                                
                                context.totalSignals = 0; 
                            
                                context.barsSinceSignal = 0;
                                context.avgBPS = 0; 
                                context.sigBar = 0;									                                                
                            //#endregion Reset all performance variables
                            
                            //#region Reset All DataSeries Arrays
                                context.Data[0].Algosignals_eSignal = 0;// context.eSignal.Reset();
                                context.barsPastSignal.length = 0;										
                                context.totsigSeries.length = 0;									
                                context.gainSeries.length = 0;
                                context.maxGainSeries.length = 0;
                                context.maxLossSeries.length = 0;
                                context.avgGainSeries.length = 0;
                                context.wrateSeries.length = 0; 
                                context.avgWinSeries.length = 0;
                                context.avgLossSeries.length = 0;
                                context.maeSeries.length = 0; 
                                context.mfeSeries.length = 0;
                                
                                context.mktValCapList.length = 0; 
                                    
                                context.duration = 0; 	//Initilize time span to 0
                                context.avgDuration = 0; 
                                context.longestDuration = 0; 
                                context.shortestDuration = 0;
                                context.totalDuration = 0;		//The total timespan of all signals on the chart
                                
                                context.tradeDuration.length = 0;
                                context.tradeGain.length = 0;
                                context.winList.length = 0;
                                context.lossList.length = 0;
                                context.maeList.length = 0;
                                context.mfeList.length = 0;
                                context.tradeGainTally.length = 0;                        
                            //#endregion Reset All DataSeries Arrays							                
                        }
                    //#endregion Reset Variables For Inversion    

                //#endregion Dynamic Check To Determine If Optimization Is Necessary
                                        
                //#region Loop Through OnBarUpdate (NT8) Code / Main Function (TVCharting Library) For Signal Optimization
                if(context.invertSignals)
                {	
                    //Remember that CurrentBar is equal to Count - 2 and Bars on Chart - 1 ... 
                    //...because the first bar has an index value of 0 ... and the very last bar visable on the chart is not considered the current bar by Ninjatrader 					
                    
                    //for(int i = Count - 2; i >= 0; i--)  							
                    for(let i = 0; i <= context.Count - 2; i++)
                    {  
                        //Print("I :"+ i+ "  Count :"+ Count + "  Trendsignal :" + algosig.TrendSignal[i] + "  Diminus :" + adx.DiMinus[i] + "  Diplus :" + adx.DiPlus[i] + "  AdxyEsignal :" + adx.ESignal[i] +"  Smoothed :"+ ppo.Smoothed[i]+ "  Default :" + ppo.Default[i] + "  Time :" + Time[i].ToString());
                        let k = (context.Count - i) - 2;  	
                        //if (context.Data[k].Time >= context.date && context.Data[k].Time <= 1579096800000) 
                        {
                            // console.log("inversion date : ",dateFormat(new Date(context.Data[k].Time)) ,context.Data[k].Time)
                            if(i > context.slow && k <= context.CurrentBar)															
                            {
                                //#region Test Console Logs
                                    // console.log("BarsAgoIdx [k]: " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i) =: " + (i) +  " -algosig.TrendSignal[" + (k) + "] = " + context.Data[k].trendSignal + " - CurrentBar: " + context.CurrentBar + " - Count: " + context.Count); 	
                                    
                                    // console.log("BarsAgoIdx [k]: " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i) =: " + (i) + " - " + "Close[k] -> " + context.Data[k].Close 
                                    //          + " - adx.ESignal[" + (k) + "] = " + context.Data[k].ADXY_Esignal +  " - adx.DiMinus[" + (k) + "] = " + context.Data[k].DiMinus + " - adx.DiPlus[" + (k) + "] = " + context.Data[k].DiPlus); 		
                                    
                                    // console.log("BarsAgoIdx [k]: " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i) =: " + (i) + " - " + "Close[k] -> " + context.Data[k].Close 
                                    //          + " -ppo.Default[" + (k) + "] = " + context.Data[k].Default + " - ppo.ESignal[" + (k) + "] = " + context.Data[k].PPO_Esignal  + " - ppo.Smoothed[" + (k) + "] = " + context.Data[k].Smoothed); 
                                    
                                    // console.log("BarsAgoIdx [k]: " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i-1) =: - " + (i-1) + " - " + "Close[k] -> " + context.Data[k].Close 
                                    //          + " -ppo.Default[" + (i-1) + "] = " + context.Data[k+1].Default); 									
                                    
                                    // console.log("BarsAgoIdx (k): " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i-2) =: - " + (i-2) + " - " + "Close[k] -> " + context.Data[k].Close 
                                    //          + " -ppo.Default[" + (i-2) + "] = " + context.Data[k+2].Default); 
                                    
                                    // console.log("BarsAgoIdx (k): " + k + " - " + dateFormat(new Date(context.Data[k].Time)) + " -- BarIndex (i-2) =: - " + (i-2) + " - " + "Close[k] -> " + context.Data[k].Close 
                                    //          + " -algosig.TrendSignal[" + (i) + "] = " + context.Data[k].trendSignal); 		
                                
                                    console.log("---------------------------------------------------------------------------------------------------------------");
                            
                                //#endregion Test Console Logs	
                                
                                //#region Signal Logic                      		
                                    
                                    //#region Exits 						
                                        if (context.shortSig == true && ((context.invertSignals && context.UseCoreLogic && context.Data[k].Default < context.Data[k+1].Default && context.Data[k].Default < context.Data[k].Smoothed) || (!context.UseCoreLogic)) && 
                                            ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == -1) || (!context.invertStructure && context.Data[k].trendSignal == 1))) || (!context.UseStructureLogic)))// &&  
                                            //((UsePd2 == true && bip1ShtSeries.GetValueAt(i) == -i) || UsePd2 == false) && ((UsePd3 == true && bip2ShtSeries.GetValueAt(i) == -i) || UsePd3 == false))
                                        {												
                                            
                                            iexitShort(k, i, context)				
                                            if(context.RevOnExit){
                                                ienterLong(k, i, context);
                                            }												
                                        }																			
                                        //Exit Inverted Short Signals -> exitLong()
                                        if (context.longSig == true && ((context.invertSignals  && context.UseCoreLogic && context.Data[k].Default > context.Data[k+1].Default && context.Data[k].Default > context.Data[k].Smoothed) || (!context.UseCoreLogic)) && 
                                            ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == 1) || (!context.invertStructure && context.Data[k].trendSignal == -1))) || (!context.UseStructureLogic)))// &&  
                                            //((UsePd2 == true && bip1LngSeries.GetValueAt(i) == -i) || UsePd2 == false) && ((UsePd3 == true && bip2LngSeries.GetValueAt(i) == -i) || UsePd3 == false))
                                        {
                                                    
                                            iexitLong(k, i, context)												
                                            if(context.RevOnExit){
                                                ienterShort(k, i, context);
                                            }
                                            
                                        }					
                                    //#endregion Exits					
                                    
                                    //#region Entries										
                                        //Inverted Long Signals -> enterShort()
                                        else if(context.shortSig == false && !context.skipShtEntry && (SignalDirection == SignalSide.SignalDirectionFilter.Long || SignalDirection == SignalSide.SignalDirectionFilter.Both) && 
                                                ((context.invertSignals && context.UseCoreLogic && ((context.Data[k].ADXY_Esignal == 1 && (context.Data[k].PPO_Esignal == 1 || context.Data[k+1].PPO_Esignal == 1 || context.Data[k+2].PPO_Esignal == 1 )) ||
                                                (context.Data[k].ADXY_Esignal == -1 && (context.Data[k].PPO_Esignal == 1 || context.Data[k+1].PPO_Esignal == 1 || context.Data[k+2].PPO_Esignal == 1 ))) && 
                                                    context.Data[k].DiPlus > context.Data[k+1].DiPlus && context.Data[k].DiMinus < context.Data[k+1].DiMinus) || (!context.UseCoreLogic)) &&
                                                ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == 1) || (!context.invertStructure && context.Data[k].trendSignal == -1))) || (!context.UseStructureLogic)))// &&
                                                //((UsePd2 == true && bip1ShtSeries.GetValueAt(i) == i) || UsePd2 == false) && ((UsePd3 == true && bip2ShtSeries.GetValueAt(i) == i) || UsePd3 == false))                                                                                
                                        {					
                                            if(context.OppSigExit && context.longSig){
                                                
                                                iexitLong(k, i, context)	
                                                context.reversal = true;
                                                }												
                                                ienterShort(k, i, context);
                                        }					
                                                                                                
                                        //Inverted Short Signal -> enterLong()
                                        else if(context.longSig == false && !context.skipLngEntry && (SignalDirection == SignalSide.SignalDirectionFilter.Short || SignalDirection == SignalSide.SignalDirectionFilter.Both) && 
                                                ((context.invertSignals && context.UseCoreLogic && ((context.Data[k].ADXY_Esignal == 1 && (context.Data[k].PPO_Esignal == -1 || context.Data[k+1].PPO_Esignal == -1 || context.Data[k+2].PPO_Esignal == -1 )) ||
                                                (context.Data[k].ADXY_Esignal == -1 && (context.Data[k].PPO_Esignal == -1 || context.Data[k+1].PPO_Esignal == -1 || context.Data[k+2].PPO_Esignal == -1 ))) && 
                                                context.Data[k].DiPlus < context.Data[k+1].DiPlus && context.Data[k].DiMinus > context.Data[k+1].DiMinus) || (!context.UseCoreLogic)) &&
                                                ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == -1) || (!context.invertStructure && context.Data[k].trendSignal == 1))) || (!context.UseStructureLogic)))// && 
                                                //((UsePd2 == true && bip1LngSeries.GetValueAt(i) == i) || UsePd2 == false) && ((UsePd3 == true && bip2LngSeries.GetValueAt(i) == i) || UsePd3 == false))
                                        {	
                                            if(context.OppSigExit && context.shortSig){
                                                
                                                iexitShort(k, i, context)
                                                context.reversal = true;
                                            }																							
                                            ienterLong(k, i, context);												
                                        }
                                    //#endregion Entries
                                    
                                    //#region Reset Entry Variables	
                                        context.skipLngEntry = false; 				//This is used to prevent an Exit Signal & and Entry Signal Evaluating on the Same Bar; i.e. don't exit a long and enter a long on the same bar. 
                                        context.skipShtEntry = false; 				//This is used to prevent an Exit Signal & and Entry Signal Evaluating on the Same Bar; i.e. don't exit a short and enter a short on the same bar.
                                    //#endregion //Reset Entry Variables
                                                                                                                
                                    //#region Print Performance	- Not needed in Charting Library - Handling This Another Way
                                        
                                        //if(context.symbol.info.type == "stock")
                                        //{						
                                        // //                  console.log("Net Gain : " + context.netGain ,"Avg Gain/Signal: " + context.avgGain);
                                        // // console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
                                        // // console.log("Win Rate : " + context.winRate, "/" + context.totalSignals + "Signals");
                                        // // console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
                                        // // console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio + ":1" );
                                        // // console.log("k :" , k , " i " , i);
                                        //}
                                        //else if (context.symbol.info.type != "stock")
                                        //{
                                        // //                 console.log("Net Gain : " + context.netGain ,"Avg Gain/Signal: " + context.avgGain);
                                        // // console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
                                        // // console.log("Win Rate : " + context.winRate, "/" + context.totalSignals + "Signals");
                                        // // console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
                                        // // console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio + ":1" );                        
                                        //}
                                                                    
                                    //#endregion //Print Performance - Not needed in Charting Library - Handling This Another Way				
                                        
                                    //#region Set Dataseries Values	
                                        context.totsigSeries[k] = (context.totalSignals); 	//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
                                        context.wrateSeries[k] = (context.winRate); 			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
                                        context.gainSeries[k] = (context.netGain);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
                                        context.avgGainSeries[k] = (context.avgGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
                                        context.maxGainSeries[k] = (context.maxGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
                                        context.maxLossSeries[k] = (context.minGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
                                        context.avgWinSeries[k] = (context.avgWin);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
                                        context.avgLossSeries[k] = (context.avgLoss);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0											
                    
                                        //I don't think this section is correct here -> CurrentBar & SigBar need to be referred to using k & i ...
                                        if(context.longSig || context.shortSig){context.barsSinceSignal = i- context.sigBar;} //
                                        else if(!context.longSig || !context.shortSig) {context.barsSinceSignal = 0;}
                                        context.barsPastSignal[k] = context.barsSinceSignal;
                                    //#endregion //Set Dataseries Values																		
                                        
                                //#endregion //Signal Logic 						
                                                                
                            }
                        }                    
                    }						    				    
                }											
                //#endregion //Loop Through OnBarUpdate Code For Signal Optimization
        }
    
    //#endregion Signal Optimization

    //#region Dynamic Index Calculation
    if(context.userEndDate <= context.Data[0].Time && context.CurrentBar >= context.Count - 2 && !context.doOnce && context.cOptimize) 
    {	       
        console.log("Enter into context.userEndDate <= context.Data[0].Time")
        context.doOnce = true;
        let reachedEndDate = false;
        for(let i = 0; i <= context.Count - 2; i++)
        {              

        let k = (context.Count - i) - 2;  
            if(!reachedEndDate && context.Data[k].Time >= context.userEndDate) {

                for (let i = 0; i <= context.signalArray.length - 1; i++) // For eachSignal 
                { // Loop through signals array
                    //print all the performance values of each signal
                    
                    // dynamicIndex += 1 //increment Dynamic Index
                    if (!reachedEndDate && context.signalArray[i] >= context.Data[k].Time)
                    {
                    //     console.log( "signalArray",context.signalArray[i - 1],dateFormat(new Date(context.signalArray[i -1])),
                    // "Market Value Captured: " , context.marketvaluecapArray[i - 1].toFixed(2) , "% (Benchmark)",
                    //     "Net Gain : " , context.netgainAnalysisArray[i - 1].toFixed(2),"context.Data[k].Time",dateFormat(new Date(context.Data[k].Time)) ,context.Data[k].Time, "I", i ) 
                        reachedEndDate = true;
                        dynamicIndex = i - 1;                      
                        context.totSignals = dynamicIndex + 1;
                    }
                    // console.log("dynamicIndex",dynamicIndex);
                }    
                
                barCount = i + 1
            }
        }
        
        //#region Dynamic Check To Determine If Optimization Is Necessary
            context.avgBPS = (barCount / context.totSignals);	
            
            //#region Stocks
            if(context.symbol.info.type == "stock" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10)
            {						
                let k = dynamicIndex;
                // console.log("context.avgBPS",context.avgBPS,"context.totSignals",context.totSignals,"context.MktValCapThresh",context.MktValCapThresh,"context.mktValCapList.length",context.mktValCapList.length,"context.mktValCapList[k]",context.mktValCapList[k]+
                // "K :",k,"dynamicIndex",dynamicIndex);

                if(context.mktValCapList[k] <= context.MktValCapThresh || context.mktValCapList[(k - 1)] <= context.MktValCapThresh || context.mktValCapList[(k -2)] <= context.MktValCapThresh ||
                    (context.mktValCapList[k] <= (context.ThreshMult * context.MktValCapThresh) && (context.mktValCapList[k] <= context.MktValCapThresh || context.mktValCapList[(k - 1)] <= context.MktValCapThresh || context.mktValCapList[(k - 2)] <= context.MktValCapThresh)))
                {						
                    context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                    context.invertStructure = true;										
                }

            }
            //#endregion Stocks

            //#region Notes
                /******************************************************************************************************************************************************************
                 Need to add a detailed conditional with the netGain series for FX & Futures to avoid wacky inversion toggling around the toggle point. 	
                *******************************************************************************************************************************************************************
                gainSeries accepts a BarsAgoIndex, therefore - Since we need to evaluate the netGain from the current bar after all bars are loaded, we can simply use 0, 1, 2, 3 etc. 
                However, this won't work because we need the P&L associated with the LAST Several Trades and gainSeries give us the bar by bar gain - so we have to use the tradeGainTally List*/
            //#endregion Notes

            //#region Forex
            if(context.symbol.info.type == "Forex" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10 && context.netGain <= -500) //500 in this case means 500 pips
            {	
                context.tG = context.tradeGainTally.length - 1;
                //The Gain is <= the THRESHOLD for up to teh last 3 trades OR (The Gain is <= 80% of the Threshold AND Gain <= the THRESHOLD for up to the last 3 trades)
                if(context.tradeGainTally[context.tG] <= context.PiPThresh || context.tradeGainTally[context.tG - 1] <= context.PiPThresh || context.tradeGainTally[context.tG - 2] <= context.PiPThresh ||
                    (context.tradeGainTally[context.tG] <= (context.ThreshMult * context.PiPThresh) && (context.tradeGainTally[context.tG] <= context.PiPThresh || context.tradeGainTally[context.tG - 1] <= context.PiPThresh || context.tradeGainTally[context.tG - 2] <= context.PiPThresh)))
                {	
                    context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                    context.invertStructure = true;	
                }
            }
            //#endregion Forex

            //#region Futures
            if(context.symbol.info.type == "Future" && context.avgBPS <= context.AvgBarsPerSignal && context.totalSignals >= 10 && context.netGain <= -100)  //100 in this case means Ticks						
            {
                context.tG = context.tradeGainTally.length - 1;
                //The Gain is <= the THRESHOLD for up to teh last 3 trades OR (The Gain is <= 80% of the Threshold AND Gain <= the THRESHOLD for up to the last 3 trades)
                if(context.tradeGainTally[context.tG] <= context.TickThresh || context.tradeGainTally[context.tG - 1] <= context.TickThresh || context.tradeGainTally[context.tG - 2] <= context.TickThresh ||
                    (context.tradeGainTally[context.tG] <= (context.ThreshMult * context.TickThresh) && (context.tradeGainTally[context.tG] <= context.TickThresh || context.tradeGainTally[context.tG - 1] <= context.TickThresh || context.tradeGainTally[context.tG - 2] <= context.TickThresh)))
                {	
                    context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                    context.invertStructure = true;	
                }
                
                context.invertSignals = true; //This is the main switch to trigger 'Loop Through OnBarUpdate Code...'
                context.invertStructure = true;	
            }		
            //#endregion Futures

            console.log("Simple....... Out")

            if(!context.invertSignals) {
                window.parent.LastSimpleSignalForCalculations = window.parent.LastSimpleSignal[window.parent.LastSimpleSignal.length - 1]
                console.log("Simple.......", window.parent.LastSimpleSignalForCalculations,"Signal Price",window.parent.LastSimpleSignalForCalculations.signalPrice)
            }//we can push the DBSignals here

            // if(!context.invertSignals) {console.log("context.PerformaceCalc.......",context.PerformaceCalc)}
            
            if(!context.invertSignals) {
                //Pushing the performance calcultions for the simple logic
                if(window.parent.DBsignal === true) {
                
                    let name = "Algosignals";

                    //#region Loop through the Performance Store Array
                        for(let i = 0; i <= context.PerformaceCalc.length - 1; i++) {
                            // console.log("context.PerformaceCalc",context.PerformaceCalc[i])
                            api.apiAlgoSignals(name,context.PerformaceCalc[i].signal,context.PerformaceCalc[i].signalPrice,context.PerformaceCalc[i].mktValCap,context.PerformaceCalc[i].netGain,context.PerformaceCalc[i].avgGain,
                            context.PerformaceCalc[i].avgWin,context.PerformaceCalc[i].avgLoss,context.PerformaceCalc[i].wlRatio,context.PerformaceCalc[i].winRate,
                            context.PerformaceCalc[i].totalSignals,context.PerformaceCalc[i].maxGain,context.PerformaceCalc[i].minGain,context.PerformaceCalc[i].avgMFE,
                            context.PerformaceCalc[i].avgMAE,context.PerformaceCalc[i].aefeRatio,context.PerformaceCalc[i].totalDuration,
                            context.PerformaceCalc[i].totalDurationInHours,context.PerformaceCalc[i].avgDuratio,context.PerformaceCalc[i].averageDurationInHours,
                            context.PerformaceCalc[i].shortestDuration,context.PerformaceCalc[i].shortestDurationInHours,context.PerformaceCalc[i].longestDuration,
                            context.PerformaceCalc[i].longestDurationInHours,context.PerformaceCalc[i].tickerAlgosignals,context.PerformaceCalc[i].periodAlgosignals,
                            context.PerformaceCalc[i].timeAlgosignals,context.PerformaceCalc[i].UseCoreLogic,context.PerformaceCalc[i].UseStructureLogic,
                            context.PerformaceCalc[i].UseHighLow,context.PerformaceCalc[i].RevOnExit,context.PerformaceCalc[i].Fast,
                            context.PerformaceCalc[i].Slow,context.PerformaceCalc[i].Smooth,context.PerformaceCalc[i].MktValCapThresh,
                            context.PerformaceCalc[i].PiPThresh,context.PerformaceCalc[i].TickThresh,context.PerformaceCalc[i].ThreshMult,
                            context.PerformaceCalc[i].AvgBarsPerSignal,context);
                        }
                    //#endregion Loop through the Performance Store Array

                    //#region Call capiAlgoSignals
                        capi.capiAlgoSignals(name,context.PerformaceCalc[context.PerformaceCalc.length - 1].signal,context.PerformaceCalc[context.PerformaceCalc.length - 1].signalPrice,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].mktValCap,context.PerformaceCalc[context.PerformaceCalc.length - 1].netGain,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].avgGain,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].avgWin,context.PerformaceCalc[context.PerformaceCalc.length - 1].avgLoss,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].wlRatio,context.PerformaceCalc[context.PerformaceCalc.length - 1].winRate,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].totalSignals,context.PerformaceCalc[context.PerformaceCalc.length - 1].maxGain,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].minGain,context.PerformaceCalc[context.PerformaceCalc.length - 1].avgMFE,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].avgMAE,context.PerformaceCalc[context.PerformaceCalc.length - 1].aefeRatio,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].totalDuration,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].totalDurationInHours,context.PerformaceCalc[context.PerformaceCalc.length - 1].avgDuratio,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].averageDurationInHours,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].shortestDuration,context.PerformaceCalc[context.PerformaceCalc.length - 1].shortestDurationInHours,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].longestDuration,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].longestDurationInHours,context.PerformaceCalc[context.PerformaceCalc.length - 1].tickerAlgosignals,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].periodAlgosignals,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].timeAlgosignals,context.PerformaceCalc[context.PerformaceCalc.length - 1].UseCoreLogic,context.PerformaceCalc[context.PerformaceCalc.length - 1].UseStructureLogic,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].UseHighLow,context.PerformaceCalc[context.PerformaceCalc.length - 1].RevOnExit,context.PerformaceCalc[context.PerformaceCalc.length - 1].Fast,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].Slow,context.PerformaceCalc[context.PerformaceCalc.length - 1].Smooth,context.PerformaceCalc[context.PerformaceCalc.length - 1].MktValCapThresh,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].PiPThresh,context.PerformaceCalc[context.PerformaceCalc.length - 1].TickThresh,context.PerformaceCalc[context.PerformaceCalc.length - 1].ThreshMult,
                        context.PerformaceCalc[context.PerformaceCalc.length - 1].AvgBarsPerSignal,context);
                        // console.log("Last Signal: ", context.PerformaceCalc[context.PerformaceCalc.length - 1])
                    //#endregion Call capiAlgoSignals            
               
                }
            }

            if(context.invertSignals)
            {
                console.log("-------------------------------------- Contrarian Logic (" + context.symbol.ticker + ") --------------------------------------");
                //#region Reset Bool Variables            
                    //reset all relavant bool variables to thier initialized settings
                    context.automatedTesting.length = 0
                    context.automatedTestingIndex = 0;
                    context.Performance_Calculations_Array.length = 0;
                    context.Performance_Calculations_Index = 0;
                    context.splitExit = false;
                    context.firstEntryAfterSplit = false;

                    context.firstSignal = true; 
                    
                    context.longSig = false;						
                    context.skipLngEntry = false; 
                    
                    context.shortSig = false;						
                    context.skipShtEntry = false; 

                    context.reversal = false; 																									                            
                //#endregion reset Bool Variables																		

                //#region Reset All Performance Variables            
                context.lEntryPrice = 0; 
                context.lExitPrice = 0; 
                context.sEntryPrice = 0; 
                context.sExitPrice = 0; 
                context.tradePnL = 0; 
                context.netGain = 0; 
                context.mktValCap = 0; 
                context.wlRatio = 0;
                context.aefeRatio = 0; 
                context.avgGain = 0; 
                context.minGain = 0;
                context.maxGain = 0;
                context.avgWin = 0;	
                context.avgLoss = 0;
                context.totWin = 0; 
                context.totLoss = 0; 			
                context.winRate = 0; 
                context.winCount = 0; 
                context.lossCount = 0; 				
                context.maxMAE = 0; 
                context.maxMFE = 0; 
                context.avgMAE = 0; 
                context.avgMFE = 0;				
                
                context.totalSignals = 0; 

                context.barsSinceSignal = 0;
                context.avgBPS = 0; 
                context.sigBar = 0;									
                                            
                //#endregion Reset all performance variables
                
                //#region Reset All DataSeries Arrays
                context.Data[0].Algosignals_eSignal = 0;// context.eSignal.Reset();
                context.barsPastSignal.length = 0;										
                context.totsigSeries.length = 0;									
                context.gainSeries.length = 0;
                context.maxGainSeries.length = 0;
                context.maxLossSeries.length = 0;
                context.avgGainSeries.length = 0;
                context.wrateSeries.length = 0; 
                context.avgWinSeries.length = 0;
                context.avgLossSeries.length = 0;
                context.maeSeries.length = 0; 
                context.mfeSeries.length = 0;
                
                context.mktValCapList.length = 0; 
                    
                context.duration = 0; 	//Initilize time span to 0
                context.avgDuration = 0; 
                context.longestDuration = 0; 
                context.shortestDuration = 0;
                context.totalDuration = 0;		//The total timespan of all signals on the chart
                
                context.tradeDuration.length = 0;
                context.tradeGain.length = 0;
                context.winList.length = 0;
                context.lossList.length = 0;
                context.maeList.length = 0;
                context.mfeList.length = 0;
                context.tradeGainTally.length = 0;                
                //#endregion Reset All DataSeries Arrays							
            }
        //#endregion Dynamic Check To Determine If Optimization Is Necessary
                    
        //#region Loop Through OnBarUpdate Code For Signal Optimization
    if(context.invertSignals)
    {	
    //Remember that CurrentBar is equal to Count - 2 and Bars on Chart - 1 ... 
    //...because the first bar has an index value of 0 ... and the very last bar visable on the chart is not considered the current bar by Ninjatrader 					
                        

    //for(int i = Count - 2; i >= 0; i--)  							
    for(let i = 0; i <= context.Count - 2; i++)
    {  
    let k = (context.Count - i) - 2;
    //    if (context.Data[k].Time >= context.date && context.Data[k].Time <= 1579096800000) 


    {
    // console.log("inversion date : ",dateFormat(new Date(context.Data[k].Time)) ,context.Data[k].Time)
    if(i > context.slow && k <= context.CurrentBar)															
    {

    //#region CommentOut	

    //#region Signal Logic   


    //#region splitExits
    //((context.splitDate[i])).getTime()
    //24 Hours  = 86400000 milliseconds
    //  console.log("context.splitDate : ", context)
    //  console.log(dateFormat(new Date(context.splitDate[3])),((new Date(context.splitDate[3]))).getTime() -25200000, dateFormat(new Date(context.Data[k].Time)), context.Data[k].Time)
    for (let j = 0; j <= context.splitDate.length - 1; j++){
        let splitMilliseconds;
        if(context.dayName[i] == 'Mon'){
            splitMilliseconds = 284400000;
          }
          else if(context.dayName[i] == 'Sun')
          {
            splitMilliseconds = 212400000;
          }
          else if(context.dayName[i] == 'Sat')
          {
          splitMilliseconds = 126000000;
          }
          else{splitMilliseconds = 25200000}
        if (context.longSig === true && context.Data[k].Time === ((((new Date(context.splitDate[j]))).getTime()-25200000) - splitMilliseconds)) {
        console.log("LongSig Inversion", "CT DAte : ", dateFormat(new Date(context.Data[k].Time)), context.Data[k].Time, "SplitDate :", dateFormat((new Date(context.splitDate[j]).getTime()-25200000) ), (new Date(context.splitDate[j]).getTime()-25200000),"splitMilliseconds :",splitMilliseconds)
        context.splitExit = true;
        //   iexitShort(k, i, context)
        iexitLong(k, i, context);
        // context.splitExit = false;
        // context.firstEntryAfterSplit = true;
        }
        if (context.shortSig === true && context.Data[k].Time === ((((new Date(context.splitDate[j]))).getTime()-25200000) - splitMilliseconds)) {
        console.log("Shortsig Inversion", "CT DAte : ", dateFormat(new Date(context.Data[k].Time)), context.Data[k].Time, "SplitDate :", dateFormat((new Date(context.splitDate[j]).getTime()-25200000) ), (new Date(context.splitDate[j]).getTime()-25200000),"splitMilliseconds :",splitMilliseconds )
        context.splitExit = true;
        iexitShort(k, i, context);
        //   ienterLong(k, i, context);
        // context.splitExit = false;
        // context.firstEntryAfterSplit = true;
        } 
    }

    //#endregion 


        //#region Exit 						
            if (context.shortSig == true && ((context.invertSignals && context.UseCoreLogic && context.Data[k].Default < context.Data[k+1].Default && context.Data[k].Default < context.Data[k].Smoothed) || (!context.UseCoreLogic)) && 
                ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == -1) || (!context.invertStructure && context.Data[k].trendSignal == 1))) || (!context.UseStructureLogic)))// &&  
                //((UsePd2 == true && bip1ShtSeries.GetValueAt(i) == -i) || UsePd2 == false) && ((UsePd3 == true && bip2ShtSeries.GetValueAt(i) == -i) || UsePd3 == false))
            {												
                            
                iexitShort(k, i, context)	
                if(context.RevOnExit){
                    ienterLong(k, i, context);
                }												
            }																			
            //Exit Inverted Short Signals -> exitLong()
            if (context.longSig == true && ((context.invertSignals  && context.UseCoreLogic && context.Data[k].Default > context.Data[k+1].Default && context.Data[k].Default > context.Data[k].Smoothed) || (!context.UseCoreLogic)) && 
                ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == 1) || (!context.invertStructure && context.Data[k].trendSignal == -1))) || (!context.UseStructureLogic)))// &&  
                //((UsePd2 == true && bip1LngSeries.GetValueAt(i) == -i) || UsePd2 == false) && ((UsePd3 == true && bip2LngSeries.GetValueAt(i) == -i) || UsePd3 == false))
            {
                                
                iexitLong(k, i, context)											
                if(context.RevOnExit){
                    ienterShort(k, i, context);
                }
                
            }					
        //#endregion Exits					
        
        //#region Entries										
            //Inverted Long Signals -> enterShort()
            else if(context.shortSig == false && !context.skipShtEntry && (SignalDirection == SignalSide.SignalDirectionFilter.Long || SignalDirection == SignalSide.SignalDirectionFilter.Both) && 
                    ((context.invertSignals && context.UseCoreLogic && ((context.Data[k].ADXY_Esignal == 1 && (context.Data[k].PPO_Esignal == 1 || context.Data[k+1].PPO_Esignal == 1 || context.Data[k+2].PPO_Esignal == 1 )) ||
                    (context.Data[k].ADXY_Esignal == -1 && (context.Data[k].PPO_Esignal == 1 || context.Data[k+1].PPO_Esignal == 1 || context.Data[k+2].PPO_Esignal == 1 ))) && 
                        context.Data[k].DiPlus > context.Data[k+1].DiPlus && context.Data[k].DiMinus < context.Data[k+1].DiMinus) || (!context.UseCoreLogic)) &&
                    ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == 1) || (!context.invertStructure && context.Data[k].trendSignal == -1))) || (!context.UseStructureLogic)))// &&
                    //((UsePd2 == true && bip1ShtSeries.GetValueAt(i) == i) || UsePd2 == false) && ((UsePd3 == true && bip2ShtSeries.GetValueAt(i) == i) || UsePd3 == false))
                
                
            {					
                if(context.OppSigExit && context.longSig){
                    
                    iexitLong(k, i, context)	
                    context.reversal = true;
                    }												
                    ienterShort(k, i, context);
            }					

            
            //Inverted Short Signal -> enterLong()
            else if(context.longSig == false && !context.skipLngEntry && (SignalDirection == SignalSide.SignalDirectionFilter.Short || SignalDirection == SignalSide.SignalDirectionFilter.Both) && 
                    ((context.invertSignals && context.UseCoreLogic && ((context.Data[k].ADXY_Esignal == 1 && (context.Data[k].PPO_Esignal == -1 || context.Data[k+1].PPO_Esignal == -1 || context.Data[k+2].PPO_Esignal == -1 )) ||
                    (context.Data[k].ADXY_Esignal == -1 && (context.Data[k].PPO_Esignal == -1 || context.Data[k+1].PPO_Esignal == -1 || context.Data[k+2].PPO_Esignal == -1 ))) && 
                    context.Data[k].DiPlus < context.Data[k+1].DiPlus && context.Data[k].DiMinus > context.Data[k+1].DiMinus) || (!context.UseCoreLogic)) &&
                    ((context.UseStructureLogic && ((context.invertStructure && context.Data[k].trendSignal == -1) || (!context.invertStructure && context.Data[k].trendSignal == 1))) || (!context.UseStructureLogic)))// && 
                    //((UsePd2 == true && bip1LngSeries.GetValueAt(i) == i) || UsePd2 == false) && ((UsePd3 == true && bip2LngSeries.GetValueAt(i) == i) || UsePd3 == false))
            {	
                if(context.OppSigExit && context.shortSig){
                    
                    iexitShort(k, i, context)
                    context.reversal = true;
                }																							
                ienterLong(k, i, context);												
            }
        //#endregion Entries
        
        //#region Reset Entry Variables	
            context.skipLngEntry = false; 				//This is used to prevent an Exit Signal & and Entry Signal Evaluating on the Same Bar; i.e. don't exit a long and enter a long on the same bar. 
            context.skipShtEntry = false; 				//This is used to prevent an Exit Signal & and Entry Signal Evaluating on the Same Bar; i.e. don't exit a short and enter a short on the same bar.
        //#endregion //Reset Entry Variables
                                                                                    
        //#region Print Performance	
            
    //         if(context.symbol.info.type == "stock")
    //         {						
    // //                  console.log("Net Gain : " + context.netGain ,"Avg Gain/Signal: " + context.avgGain);
    // // console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
    // // console.log("Win Rate : " + context.winRate, "/" + context.totalSignals + "Signals");
    // // console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
    // // console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio + ":1" );
    // // console.log("k :" , k , " i " , i);
    //         }
    //         else if (context.symbol.info.type != "stock")
    //         {
    // //                 console.log("Net Gain : " + context.netGain ,"Avg Gain/Signal: " + context.avgGain);
    // // console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
    // // console.log("Win Rate : " + context.winRate, "/" + context.totalSignals + "Signals");
    // // console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
    // // console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio + ":1" );

    //         }
                                        
        //#endregion //Print Performance				
            
        //#region Set Dataseries Values	
        context.totsigSeries[k] = (context.totalSignals); 	//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
        context.wrateSeries[k] = (context.winRate); 			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
        context.gainSeries[k] = (context.netGain);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
        context.avgGainSeries[k] = (context.avgGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
        context.maxGainSeries[k] = (context.maxGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
        context.maxLossSeries[k] = (context.minGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
        context.avgWinSeries[k] = (context.avgWin);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
        context.avgLossSeries[k] = (context.avgLoss);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0											

            //I don't think this section is correct here -> CurrentBar & SigBar need to be referred to using k & i ...
            if(context.longSig || context.shortSig){context.barsSinceSignal = i- context.sigBar;} //
            else if(!context.longSig || !context.shortSig) {context.barsSinceSignal = 0;}
            context.barsPastSignal[k] = context.barsSinceSignal;
        //#endregion //Set Dataseries Values																		
            
    //#endregion //Signal Logic 						
            
    //#endregion //Comment Out
    }
    }

    }						    				    
    }											
    //#endregion //Loop Through OnBarUpdate Code For Signal Optimization

        if(context.invertSignals) {console.log("Inversion.......")}

        // if(context.invertSignals) {console.log("context.PerformaceCalcCognative.......",context.PerformaceCalcCognative)}
        
        if(context.invertSignals) {
            //Pushing the performance calculation for the inversion logic once.
            if(window.parent.DBsignal === true) {//true
                let name = "Algosignals Cognitive";
                for(let i = 0; i <= context.PerformaceCalcCognative.length - 1; i++) {
                    api.apiAlgoSignals(name,context.PerformaceCalcCognative[i].signal,context.PerformaceCalcCognative[i].signalPrice,
                    context.PerformaceCalcCognative[i].mktValCap,context.PerformaceCalcCognative[i].netGain,context.PerformaceCalcCognative[i].avgGain,
                    context.PerformaceCalcCognative[i].avgWin,context.PerformaceCalcCognative[i].avgLoss,context.PerformaceCalcCognative[i].wlRatio,context.PerformaceCalcCognative[i].winRate,
                    context.PerformaceCalcCognative[i].totalSignals,context.PerformaceCalcCognative[i].maxGain,context.PerformaceCalcCognative[i].minGain,context.PerformaceCalcCognative[i].avgMFE,
                    context.PerformaceCalcCognative[i].avgMAE,context.PerformaceCalcCognative[i].aefeRatio,context.PerformaceCalcCognative[i].totalDuration,
                    context.PerformaceCalcCognative[i].totalDurationInHours,context.PerformaceCalcCognative[i].avgDuratio,context.PerformaceCalcCognative[i].averageDurationInHours,
                    context.PerformaceCalcCognative[i].shortestDuration,context.PerformaceCalcCognative[i].shortestDurationInHours,context.PerformaceCalcCognative[i].longestDuration,
                    context.PerformaceCalcCognative[i].longestDurationInHours,context.PerformaceCalcCognative[i].tickerAlgosignals,context.PerformaceCalcCognative[i].periodAlgosignals,
                    context.PerformaceCalcCognative[i].timeAlgosignals,context.PerformaceCalcCognative[i].UseCoreLogic,context.PerformaceCalcCognative[i].UseStructureLogic,
                    context.PerformaceCalcCognative[i].UseHighLow,context.PerformaceCalcCognative[i].RevOnExit,context.PerformaceCalcCognative[i].Fast,
                    context.PerformaceCalcCognative[i].Slow,context.PerformaceCalcCognative[i].Smooth,context.PerformaceCalcCognative[i].MktValCapThresh,
                    context.PerformaceCalcCognative[i].PiPThresh,context.PerformaceCalcCognative[i].TickThresh,context.PerformaceCalcCognative[i].ThreshMult,
                    context.PerformaceCalcCognative[i].AvgBarsPerSignal,context);
                }

                //#region Call capiAlgoSignals
                    capi.capiAlgoSignals(name,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].signal,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].signalPrice,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].mktValCap,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].netGain,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgGain,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgWin,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgLoss,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].wlRatio,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].winRate,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].totalSignals,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].maxGain,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].minGain,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgMFE,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgMAE,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].aefeRatio,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].totalDuration,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].totalDurationInHours,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].avgDuratio,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].averageDurationInHours,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].shortestDuration,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].shortestDurationInHours,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].longestDuration,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].longestDurationInHours,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].tickerAlgosignals,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].periodAlgosignals,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].timeAlgosignals,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].UseCoreLogic,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].UseStructureLogic,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].UseHighLow,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].RevOnExit,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].Fast,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].Slow,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].Smooth,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].MktValCapThresh,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].PiPThresh,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].TickThresh,context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].ThreshMult,
                    context.PerformaceCalcCognative[context.PerformaceCalcCognative.length - 1].AvgBarsPerSignal,context);
                //#endregion Call capiAlgoSignals
            }
        }
        
        if(window.parent.DBsignal === false) {
            console.log("Hit this point")
            automatedTestObject.automatedtest(context);
        }
    }
    //#endregion
}