import IndicatorTemplate from '../endpoints/indicatortemplateapi';
let indTempObj = new IndicatorTemplate();
/**
 * Get the study/indicator template by hitting the api method "getindicatoTemContent".
 * @param studyTemplateData
 * @function getindicatorTemplateData
 */
export default async function getindicatorTemplateData(studyTemplateData){
  let returnData;

//#region Calling the "getindicatorTemContent" to "GET" the content of the study
    returnData = await indTempObj.getindicatorTemContent(studyTemplateData)
//#endregion Calling the "getindicatorTemContent" to "GET" the content of the study

    // console.log("Return data:", returnData)
    
//#region Rturning the response
    return returnData;
//#endregion Rturning the response

  }