import React from "react";
import { connect } from "react-redux";
import OptionInfo from "./OptionInfo";
import Ticket from "./Ticket";

const SubMain = props => {
  return (
    <>
      {props.infoTicket ? <OptionInfo theme={props.theme} /> : ""}
      {props.ticket ? <Ticket theme={props.theme} /> : ""}
    </>
  );
};

export default connect(
  state => ({
    infoTicket: state.ticket.infoTicket,
    ticket: state.ticket.ticket
  }),
  {}
)(SubMain);
