import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../config/links.config'
import { token } from '../../configMiddleware/getToken'
import {update_near_value} from '../../Apps/OptionWidget/actions/options.js'
// import axios from 'axios';
const axios = require("axios");

/*Comments: Our intention was to create functions in this single service file for API Endpoint Dependencies then just call
those function where required across the applicaiton, however, there are too many user-input derived dependencies in each of our mini-apps 
that makes this difficult. Therefore, the api requests are left directly where they are needed.  Only the getHistoricadata and  getAllSymbols 
functions below are used in our application; otherwise no other data api services are being created / used*/

//Hit secure endpoints by passing our token to get required data

//#region  Get All Symbols
export const getHistoricaldata = async () => {
  
      // await token().then( async(token) => {      
            let token = localStorage.getItem("jwtToken");
            // console.log("dataservice token Historical : ", token)
            // return 
            await axios({
                  method: "get",
                  url: `${chartURL}/api/chart/historical_data`,
                  params : {
                        symbol : "AAPL",
                        resolution : "1D"
            },
                  // headers: { Authorization: "Bearer " + token }
                  // headers: { Authorization:  token }
                  headers: { Authorization:  token }
            }).then(async( res ) =>{
                  // console.log("historical_data : ", res.data);
                  return await res.data
            }).catch((e) => console.log("axios error"))
            // }).catch( (e) => console.log(e))

}     
//#endregion  Get History


//#region  Get All Symbols
export const getAllSymbols = async () => {
  
      // await token().then( async(token) => {     
            // return
             await axios({
                  method: "get",
                  url:`https://charthistoricaldriver.azurewebsites.net/api/chart/all_symbolsUserAuth`,
                  headers: { Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw" }
            }).then(async( res ) =>{
                  // console.log("all_symbols : ", res.data);
                  localStorage.setItem("all_symbols", JSON.stringify(res.data));
                  return res.data
            }).catch((e) => console.log("axios error"))
            // }).catch( (e) => console.log(e))
            // `http://localhost:7072/api/chart/all_symbolsUserAuth`,//
      //             await axios({
      //                   method: "get",
      //                   url: `${chartURL}/api/chart/all_symbolsUserAuth`,
      //                   headers: { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw"}
      //             }).then(( res ) =>{
      // // console.log("all_symbols : ", res.data);
      // localStorage.setItem("all_symbols", JSON.stringify(res.data));
      //             }).catch((e) => console.log("axios error"))

}     
//#endregion  Get All Symbols

//#region Get 1 min History Data
export const get1minHistory = async( dispatch, symbol ) => {
      try {
            let Token = await token()
            const res = await axios({
                  method: 'get',
                  url: `${chartURL}/api/chart/historical_data?symbol=${symbol}&resolution=${1}`,
                  headers: { Authorization: "Bearer " + Token}
            })
            //Do Something Here
            let data = res
            dispatch(update_near_value(data.data[data.data.length - 1].close))
      }

      catch (err) {
            console.log("error getting historical 1 min data", err)
      }
}
//#endregion Get 1 min History Data

export const FX_Majors = async () => {
      let token = localStorage.getItem("jwtToken");
      await axios({
            method: "get",
            url: 'https://fxmajors.azurewebsites.net/api/get_FX_Majors',//'http://localhost:7099/api/get_FX_Majors',
            headers: { Authorization:  token }
      }).then(async( res ) =>{
            
            localStorage.setItem("FX_Majors", JSON.stringify(res.data));
      }).catch((e) => console.log("axios error"))
}
//#region 
export const getFuncName = async() => {
      try {
            let Token = await token()
            const res = await axios({
                  method: 'get',
                  url: '',
                  headers: { Authorization: "Bearer " + Token}
            })
            //Do Something Here
      }
      catch (err) {
            console.log("error getting MSG data", err)
      }
}
//#endregion

//export {getAllSymbols}