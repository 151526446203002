import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Market from "./Market";
import DebitAndCredit from "./DebitAndCredit";
import {
  on_change_price_type,
  on_change_duration
} from "../../../actions/optionTicket";

const SpeadMArket = ({
  theme,
  price_type,
  price_type_preset,
  spread,
  on_change_price_type,
  on_change_duration,
  duration
}) => {
  const clr_theme = theme;

  const classes = makeStyles(t => {
    return {
      toggle: {
        height: "20px",
        fontSize: "12px"
      },
      margin: {
        margin: 0
      },
      icon: {
        fontSize: 16,
        padding: 0,
        marginBottom: 0,
        marginRight: "5px"
      },
      switch: {
        fontSize: "16px",
        height1: "16px"
      },
      inputLikeBox: {
        // borderRadius: ,
        position: "relative",
        // backgroundColor: "black",
        // border: "1px solid #ced4da",
        fontSize: 12,
        padding: "2.5px 5px 2.5px 5px",
        display: "inline-block",
        color: clr_theme === "Dark" ? "#d7d8db" : "#131721"
      },
      range: {
        width: "200px",
        paddingLeft: "20px",
        padding: "0"
      },
      rangeInp: {
        color: clr_theme === "Dark" ? "white" : "black"
      }
    };
  })();
  return (
    <>
      <div className="mt-3">
        <div className={classes.inputLikeBox}>
          <label htmlFor="priceType" className="d-block">
            Price Type
          </label>
          <select
            name=""
            id="priceType"
            value={price_type}
            // onChange={e => {}}
            onChange={e => on_change_price_type(e.target.value)}
          >
            {price_type_preset.map((price_type, index) => (
              <option key={price_type.value} value={price_type.value}>
                {price_type.title}
              </option>
            ))}
          </select>
        </div>
        {price_type === "market" || price_type === "even" ? (
          <Market theme={theme} />
        ) : price_type === "debit" || price_type === "credit" ? (
          <DebitAndCredit theme={theme} />
        ) : (
          <></>
        )}

        <div className={classes.inputLikeBox}>
          <label htmlFor="Duration" className="d-block">
            Duration
          </label>
          <select
            name=""
            id="Duration"
            value={duration}
            onChange={e => on_change_duration(e.target.value)}
          >
            <option value="day">Day - Good for the Day</option>
            <option value="gtc">GTC - Good until Canceled</option>
          </select>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  price_type: state.ticket.price_type,
  price_type_preset: state.ticket.price_type_preset,
  spread: state.ticket.spread,
  duration: state.ticket.duration
});
export default connect(mapStateToProps, {
  on_change_price_type,
  on_change_duration
})(SpeadMArket);
