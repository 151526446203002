/**
 * Charting Library API/Method -> createStudy creates a new instance of indicator on the chart.
 * Here this createStudy is used to created the first instance od DB version of algosignals.
 * This is used to start the DB version of algosignals.
 * @param {*} tvWidget 
 * @function createStudy
 */
const createStudy = (tvWidget) =>{
  console.log("Create Study");
//#region Global variable for shapes when it is true then don't plot the shapes else plot the shapes
window.parent.DBsignal = true;
//#endregion Global variable for shapes when it is true then don't plot the shapes else plot the shapes

//#region creates a new instance of indicator on the chart.
  tvWidget.chart().createStudy('AlgosignalMongoDb', false, true).then(ID => {
    
//#region Check if the ID is there then add the ID to the Array
  if(ID){

    //#region Global Array having an indicator instance created ID's and here the index 0 is initialized
    window.parent.indicatorID[0] = ID;
    //#endregion Global Array having an indicator instance created ID's and here the index 0 is initialized

    // console.log("Create Study Library index : ",window.parent.indicatorID);
  }
  //#endregion Check if the ID is there then add the ID to the Array


  
      

  });
//#endregion creates a new instance of indicator on the chart.

  }
  export default createStudy;