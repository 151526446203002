import React from "react";
import { useState } from "react";
// import Select from 'react-select';
const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "50px",
  zIndex: 1000,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};
// const [dropdownvalue, setValue] = useState('');
// const DropdownChange = (e) => setValue(e.target.value);

// const [inputvalue, setValue1] = useState('');
// const InputChange = (e) => setValue1(e.target.value);

// const save = () => {
//   console.log("dropdownvalue :", dropdownvalue,"inputvalue :", inputvalue)
// }



export default function Modal({ open, children, close }) {
  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>

        {/* {children} */}
        <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Please Add your API Keys</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        {/* value={dropdownvalue} onChange={DropdownChange} */}
          <select class="form-control" >
              <option>Crypto Compare</option>
              <option>Twilio</option>
              <option>Tradier</option>
          </select>
          <br></br>
          {/* value={inputvalue} onChange={InputChange} */}
          <input class="form-control" type="text" placeholder="Api key" />
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" >Save changes</button>
          {/* onClick={save} */}
          <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={close}>Close</button>
        </div>
      </div>
      </div>
    </>
  );
}
