const axios = require("axios");
const config_server = "https://algopti-config.herokuapp.com/api/dev/get_config";
const server_access_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNhZmNmNDk4M2Q1NzAwMTcxYmI4NWEiLCJ1c2VybmFtZSI6IlNvaGFpYiIsImlhdCI6MTU3MzU4NDExNn0.QRoYq4UPkf7Z083MAQFZCogo_BgU2jh2mjMVQSDFb0g";

module.exports = () => {
  var config = null
  const set_config = (data) => {
    config = data
  }
  return axios({
    method: "get",
    url: config_server,
    headers: {
      Authorization: "Bearer " + server_access_token
    }
  }).then(res => {
    set_config(res.data)    
    return config
  })
};

//Using Async/Await
// const apiAuth = async function () {
//     try {
//         let conf = await config()
//         console.log(conf.apiAuth.username)
//         return conf.apiAuth.username  
//     }
//     catch (e) {
//         console.log(e)
//     }
// }

// apiAuth()