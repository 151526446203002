 /* eslint-disable no-unused-expressions */
import TimeSpan from "../TimeSpan/TimeSpan";
import createTextOnChart from '../library_Utils/createTextOnChart';
import createCircleOnChart from '../library_Utils/createCircleOnChart';
import createArrowOnChart from '../library_Utils/createArrowOnChart';

/**
 * @object algopivtz -> algopivtz indicator object
 * @object metaInfo -> Contains the info about the indicator like "inputs", "Plots", "name", "description", 
 * "Plot styles", "defaults".
 * @property is_hidden_study -> set to true means hide from the list of indicators on the chart and false means
 *  include it in the list of indicators on the chart.
 * @property is_price_study -> true means plot the indicator on the main chart bars. false means plot the 
 * indicator in a seperate window on the chart.
 * @property shortDescription -> is the name of the indicator you see on the chart.
 * @property id -> is what the charting linbaray used to call the custom indicator. 
 * Actually when the indicator load on the chart the charting library creates a unique id for an indicator 
 * which we can get through like below.
 * @function constructor
 * @function this.init called once when the indicator loads on the chart.
 * @function this.main indicator logic as an indicator written in "main" function . Calls on each Bar.
 * @DynamicSymbolHandler ((PineJS.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
 */
export const algopivtz ={
    name: "AlgoPivtz",
    metainfo: {
        "_metainfoVersion": 40,
        "id": "AlgoPivtz@tv-basicstudies-1",
        "scriptIdPart": "",
        "name": "AlgoPivtz",
        "description": "AlgoPivtz",
        "shortDescription": "AlgoPivtz",

        "is_hidden_study": true,
        "is_price_study": true,

        "plots": [{
            "id": "plot_0",
            "type": "line"
        }],
        "defaults": {

            "styles": {
                "plot_0": {
                    "linestyle": 0,
                    "visible": true,

                    // Make the line thinner
                    "linewidth": 1,

                    // Plot type is Line
                    "plottype": 0,

                    // Show price line
                    "trackPrice": true,

                    "transparency": 35,

                    // Set the dark red color for the plot line

                }
            },

            // Precision is one digit, like 777.7
            "precision": 4,


            "inputs": {
                "Period": 35
            }
        },
        "styles": {
            "plot_0": {
                // Output name will be displayed in the Style window
                "title": "AlogoADXY value",
                "histogramBase": 0,
            }
        },
        "inputs": [{
            "id": "Period",
            "type": "integer",
            "name": "Period ADX",
            "defval": 35

        }],
    },

    constructor: function() {
        this.init = function(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;

            var symbol = ((window.parent.pinejs.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
            this._context.new_sym(symbol, window.parent.pinejs.Std.period(this._context), window.parent.pinejs.Std.period(this._context));

            this._context.upTrnd = false;
            this._context.dnTrnd = false;
            this._context.doOnce = false;

            this._context.ctBars = 0;
            this._context.ptBars = 0;
            this._context.ctTime = 0; //Initilize time span to 0
            this._context.ptTime = 0;

            this._context.ptRngCC = 0; //Cosing Price of the Prior Trend End to Closing Price of Prior Trend Start
            this._context.ptRngMC = 0; //Max Price of the Prior Trend End to Closing Price of Prior Trend Start	
            this._context.ptRngCM = 0; //Cosing Price of the Prior Trend End to Max Price of Prior Trend Start
            this._context.ptRngMM = 0; //Max Price of the Prior Trend End to Max Price of Prior Trend Start

            this._context.ctRngCC = 0; //Cosing Price of the Current Trend End to Closing Price of Prior Trend Start
            this._context.ctRngMC = 0; //Max Price of the Current Trend End to Closing Price of Prior Trend Start	
            this._context.ctRngCM = 0; //Cosing Price of the Current Trend End to Max Price of Prior Trend Start
            this._context.ctRngMM = 0;

            this._context.ptEndM = 0; //High of the prior trend ending bar (if uptrend) or Low (if dntrend)
            this._context.ptEndC = 0; //Close of the prior trend ending bar

            this._context.ctEndM = 0; //this is the current bar High if current trend is up or current bar Low if the trend is down
            this._context.ctEndC = 0;

            this._context.calTime = 0;
            this._context.currTime = 0;

            //Variables to store trun time forecasts
            this._context.tfBars = 0; //Turn Forecst in Bars
            this._context.tfTime = 0; //Turn Forecast Calendar Time

            //Print variables
            this._context.offset = 1;
            this._context.offsetSig = 1;
            this._context.pos = 0;

            this._context.turnPriceMax = 0;
            this._context.turnPriceClose = 0;
            this._context.turnTime; //Trend Change Time
            this._context.priorTime;

            this._context.sigBar = 0;
            this._context.bAgoIdx = 0;

            this._context.pVal = [];
            this._context.pValClose = []; // double array
            this._context.pBar = [];
            this._context.pBarTime = []; //DateTime Array

            this._context.CurrentBar = -1;
    
            //Double Array
            this._context.deg45 = []; //Double Array	//Static List of 45 Degree Angles
            this._context.deg90 = []; //Double Array
            this._context.pList = []; //Double Array
            this._context.tList = []; //Double Array

            this._context.Use45 = true;
            this._context.Use90 = true;

            this._context.deg45.push(45);
            this._context.deg45.push(90);
            this._context.deg45.push(135);
            this._context.deg45.push(180);
            this._context.deg45.push(225);
            this._context.deg45.push(270);
            this._context.deg45.push(315);

            this._context.deg90.push(90);
            this._context.deg90.push(180);
            this._context.deg90.push(270);

            //window.parent.tvRef.removeAllShapes();
            if (this._context.Data == undefined)
                this._context.Data = [];
        }, 
            this.calcTime = function(context) {
        var ptTime = new TimeSpan(context.ptTime);
        var ctTime = new TimeSpan(context.ctTime);
        //Calculate Calendar Time
        if (context.symbol.resolution == "") {
            context.calTime = ptTime.totalMinutes(0) / context.symbol.period.replace(context.symbol.resolution, "");
            context.currTime = ctTime.totalMinutes(0) / context.symbol.period.replace(context.symbol.resolution, "");
        } else if (context.symbol.resolution == "D") {
            context.calTime = ptTime.totalDays(0) / context.symbol.period.replace(context.symbol.resolution, "");;
            //   alert(ptTime.totalMinutes(0) + " Total Minutes");                                
            context.currTime = ctTime.totalDays(0) / context.symbol.period.replace(context.symbol.resolution, "");;
            // alert(context.currTime);
            // alert("resltt" + context.symbol.period.replace(context.symbol.resolution, ""));
        } else if (context.symbol.resolution == "W") {
            context.calTime = ptTime.totalDays(0) / 7;
            context.currTime = ctTime.totalDays(0) / 7;
        } else if (context.symbol.resolution == "M") {
            context.calTime = ptTime.totalDays(0) / 30.436875;
            context.currTime = ctTime.totalDays(0) / 30.436875;
        } else if (context.symbol.resolution == "Y") {
            context.calTime = ptTime.totalDays(0) / 365.2425;
            context.currTime = ctTime.totalDays(0) / 365.2425;
        }
        },
            this.fcstTurns = function(context, Use45, HitStrength) {
            //#region Reset & Add Values To Price & Time Lists For Squaring Permutations Between Time & Price
            context.pList.length = 0;
            context.pList.push(context.ctEndM);
            context.pList.push(context.ctRngMM);
            context.pList.push(context.ptEndM);
            context.pList.push(context.ptRngMM);
            // console.log("context.ptRngMM " + context.ptRngMM);

            context.tList.length = 0;
            context.tList.push(context.ctBars);
            context.tList.push(context.currTime);
            // console.log("context.currTime: "+context.currTime);
            context.tList.push(context.ptBars);
            context.tList.push(context.calTime);
            //#endregion //Reset & Add Values To Price & Time Lists For Squaring Permutations Between Time & Price



            var sqHits = 0;
            var price = 0;
            var time = 0;

            for (var j = 0; j <= context.pList.length - 1; j++) {
                for (var i = 0; i <= context.pList.length - 1; i++) {

                    //Exclude combinations between prior trend prices and price trend ranges
                    if ((j < 2 && i < 2) || (j > 2 && i < 2)) {
                        //We only need to convert Price to a three digit number if applicable, not time.
                        if (Use45) {
                            // console.log("context.deg45.length " + context.deg45.length);
                            for (var l = 0; l <= context.deg45.length - 1; l++) {
                                //console.log("context.pList["+j+"] " + context.pList[j]);
                                // console.log("context.tList["+i+"] " + context.tList[i]);
                                // console.log("context.deg45["+l+"] " + context.deg45[l] );
                                //Check for direct Price & Time Squares & 45 Degree Offset Squares 
                                if (this.deg(this.conv(context.pList[j])) == this.deg(context.tList[i]) || this.deg(this.conv(context.pList[j])) == (this.deg(context.tList[i]) + context.deg45[l])) {
                                    sqHits += 1;
                                    price = context.pList[j];
                                    time = context.tList[j];
                                }
                            }
                        } else {
                            // console.log("context.deg90.length " + context.deg90.length);
                            for (var l = 0; l <= context.deg90.length - 1; l++) {
                            //  console.log("context.pList["+j+"] " + context.pList[j]);
                            //  console.log("context.tList["+i+"] " + context.tList[i]);
                            //    console.log("context.deg90["+l+"] " + context.deg90[l] );
                                //Check for direct Price & Time Squares & 90 Degree Offset Squares 
                                if (this.deg(this.conv(context.pList[j])) == this.deg(context.tList[i]) || this.deg(this.conv(context.pList[j])) == (this.deg(context.tList[i]) + context.deg90[l])) {
                                    sqHits += 1;
                                    price = context.pList[j];
                                    time = context.tList[j];
                                }
                                
                            }
                        }
                    }
                }
            }
            // console.log("-------------price--- " + price );
            if (sqHits >= HitStrength) {
                //  if(window["AlgoData"] == undefined)
                //window["AlgoData"] = [];

                //Draw here
                //  window["AlgoData"].unshift([ context.CurrentBar, this.deg(price) + "SQ"]);

                //  window.parent.tvRef.createShapeOnChart(context.Time, context.high + 700, );
                createTextOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].High, this.deg(price) + "SQ");

                //Draw.Text(this, CurrentBar.ToString(), deg(price).ToString() + "SQ", 0, pos + 2 * offsetSig);
                if (context.upTrnd)   createArrowOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].High + 1, "arrow_down");
                else if (context.dnTrnd)   createArrowOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].Low - 1, "arrow_up");
                // Print(Time[0] + "  : " + "digits:" + conv(price) + ":  Price: " + price + " - deg: " + deg(price) + "  Time: " + deg(currTime) + "  : ctBars: " + ctBars + "  : currTime: " 
                //+ currTime + "  : ptEnd(C): "  + ptEndC + "  : ptEnd(M): " + ptEndM + "   : upTrend: " + upTrnd + "  : dnTrend: " + dnTrnd);
            }
        }, 
            this.hma = function(context) {

        
            var Period = 35;
            var close = window.parent.pinejs.Std.close(context),

                
                closesym = context.new_var(close),
                wma1 = window.parent.pinejs.Std.wma(closesym, 17, context),
                
                closesym2 = context.new_var(close),
                wma2 = window.parent.pinejs.Std.wma(closesym2, Period, context),
                wmadiff = 2 * wma1 - wma2;
            

        
        
                var wmadiffvar = context.new_var(wmadiff),
                value = window.parent.pinejs.Std.wma(wmadiffvar, 5, context);

                
            return value;

        }, 
            this.setValues = function(context) {
            var k = context.pBar.length - 1;

            //Have to set the ct Time values outside of the conditional below to work from the very first pivot on the chart.
            context.ctBars = context.CurrentBar - context.sigBar;
            context.ctTime = context.Data[0].Time - context.pBarTime[k];
            //ctTime = DateTime.Parse(Time[0].ToString()).Subtract(DateTime.Parse(pBarTime[k].ToString()));
        //    console.log("context.pVal.length " + context.pVal.length + " context.pBar.length: " + context.pBar.length + " context.pValClose.length: " + context.pBarTime.length);
            if (context.pVal.length >= 2 && context.pBar.length >= 2 && context.pValClose.length >= 2 && context.pBarTime.length >= 2) {
                // Set "Time" Input Values For Squaring

                context.ptBars = context.pBar[k] - context.pBar[k - 1];

                //Both of the below methods work 
                context.ptTime = context.pBarTime[k] - context.pBarTime[k - 1];

                //2nd method for above calculation -> for reference	
                //ptTime = DateTime.Parse(turnTime.ToString()).Subtract(DateTime.Parse(priorTime.ToString()));

                //end //Set "Time" Input Values For Squaring

                // Calculate Calendar Time
                this.calcTime(context);
                //end //Calculate Calendar Time

                // Set "Price" Input Values For Squaring
                // console.log("context.pVal[k]: " + context.pVal[k]);
                context.ptRngMM = Math.abs(Math.abs(context.pVal[k]) - Math.abs(context.pVal[k - 1]));
                context.ptRngCC = Math.abs(Math.abs(context.pValClose[k]) - Math.abs(context.pValClose[k - 1]));
                context.ptRngCM = Math.abs(Math.abs(context.pValClose[k - 1]) - Math.abs(Math.abs(context.pVal[k])));
                context.ptRngMC = Math.abs(Math.abs(context.pVal[k - 1]) - (Math.abs(context.pValClose[k])));

                

                //Round Values - Will Need To Figure Out A Dynamic Solution Later
                context.ptRngMM = this.roundToTwo(context.ptRngMM);
                context.ptRngCC = this.roundToTwo(context.ptRngCC);
                context.ptRngCM = this.roundToTwo(context.ptRngCM);
                context.ptRngMC = this.roundToTwo(context.ptRngMC);

                context.ptEndC = Math.abs(context.pValClose[k]);

                context.ctRngCC = Math.abs(Math.abs(Math.abs(context.pValClose[k]) - context.Data[0].Close));
                context.ctEndC = context.Data[0].Close;

                // console.log("context.ptRngMM " + context.ptRngMM);
                // console.log("context.ptRngCC " + context.ptRngCC);
                // console.log("context.ptRngCM " +  context.ptRngCM);
                // console.log("context.ptEndC " + context.ptEndC);
                // console.log("context.ctRngCC " + context.ctRngCC);
                // console.log("context.ctEndC " + context.ctEndC);

                if (context.upTrnd) {
                    context.ptEndM = Math.abs(context.pVal[k]);
                    context.ctEndM = context.Data[0].High;
                    context.ctRngMM = Math.abs(context.Data[0].High - Math.abs(context.pVal[k]));

                    //Print(Time[0] + " : " + bAgoIdx);
                    for (var j = 0; j <= context.bAgoIdx; j++) {
                        //Evaluate range from all bars since the trend change to ensure maximum current range is identified. 
                        if (Math.abs(context.Data[j].High - Math.abs(context.pVal[k])) > context.ctRngMM) {
                            context.ctRngMM = Math.abs(context.Data[j].High - Math.abs(context.pVal[k]));
                        }
                    }

                    context.ctRngMM = this.roundToTwo(context.ctRngMM);
                } else if (context.dnTrnd) {
                    context.ptEndM = Math.abs(context.pVal[k]);
                    context.ctEndM = context.Data[0].Low;
                    context.ctRngMM = Math.abs(context.Data[0].Low - Math.abs(context.pVal[k]));

                    for (var j = 0; j <= context.bAgoIdx; j++) {
                        if (Math.abs(context.Data[j].Low - Math.abs(context.pVal[k])) > context.ctRngMM) {
                            context.ctRngMM = Math.abs(context.Data[j].Low - Math.abs(context.pVal[k]));
                        }
                    }

                    context.ctRngMM = this.roundToTwo(context.ctRngMM);

                }

                // console.log("context.ctRngMM " + context.ctRngMM);
                //end //Set "Price" Input Values For Squaring
            }
        }, 
            this.conv = function(price) {
            //Convert input price into a 3 digit price
            var intPart = price;
            //Determine how many places wer are off by from our 3 digit target
            var intLen = 0;

            if (intPart == 0) intLen = 0;
            else intLen = Math.round(intPart).toString().length;
            //  console.log(intPart + " intLen: "+ intLen);
            var intDiff = 3 - intLen;

            var convprice = price * Math.pow(10, intDiff);
            //   console.log(price + " convprice: "+ convprice);
            return convprice;

        }, 
            this.upTrend = function(context) {
            // Draw.Dot(this, "HmaLow" + CurrentBar, true, 0, Low[0] - offset, Brushes.GreenYellow);  //<- This is for testing only
            createCircleOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].Low - 1, "#9acd32");
            
            context.priorTime = context.turnTime; //set the prior turn time
            context.turnPriceMax = context.Data[0].Low;

            for (var j = 0; j <= (context.CurrentBar - context.sigBar); j++) //"j" is a Bars ago index from the current bar, so from the bar where the hma changed now, to the time it changed last
            {
                if (context.Data[j].Low <= context.turnPriceMax) {
                    context.turnPriceMax = context.Data[j].Low;
                    context.turnPriceClose = context.Data[j].Close;
                    context.turnTime = context.Data[j].Time;
                    context.bAgoIdx = j;                     
                }
            }
            // console.log("turnPriceMax " + context.turnPriceMax);
            // console.log("turnPriceClose " + context.turnPriceClose);
            // console.log("turnTime " + context.turnTime);
            // console.log("bAgoIdx " + context.bAgoIdx);

            context.upTrnd = true;
            context.dnTrnd = false;
            context.tfBars = Math.round(Math.sqrt(this.conv(context.turnPriceMax)));
            // console.log("context.tfBars " + context.tfBars);
            context.sigBar = context.CurrentBar - context.bAgoIdx;
            context.pVal.push(context.turnPriceMax * -1); //Store the low values as negative
            context.pValClose.push(context.turnPriceClose * -1);
            context.pBar.push(context.sigBar);
            context.pBarTime.push(context.turnTime);
            this.setValues(context);

        },
            this.dnTrend = function(context) {
            //Draw.Dot(this, "HmaHigh" + CurrentBar, true, 0, High[0] + offset, Brushes.Pink); //<- This is for testing only
            createCircleOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].High + 1, "#e26f78");
            context.priorTime = context.turnTime; //set the prior turn time
            context.turnPriceMax = context.Data[0].High;
            for (var j = 0; j <= (context.CurrentBar - (context.sigBar)); j++) //"j" is a Bars ago index from the current bar, so from the bar where the hma changed now, to the time it changed last
            {
                if (context.Data[j].High >= context.turnPriceMax) {
                    context.turnPriceMax = context.Data[j].High;
                    context.turnPriceClose = context.Data[j].Close;
                    context.turnTime = context.Data[j].Time;
                    context.bAgoIdx = j;
                }
            }
            // console.log("turnPriceMax " + context.turnPriceMax);
            // console.log("turnPriceClose " + context.turnPriceClose);
            // console.log("turnTime " + context.turnTime);
            // console.log("bAgoIdx " + context.bAgoIdx);
            
            context.dnTrnd = true;
            context.upTrnd = false;
            context.tfBars = Math.round(Math.sqrt(this.conv(context.turnPriceMax)));
            // console.log("context.tfBars " + context.tfBars);
            context.sigBar = context.CurrentBar - context.bAgoIdx;
            context.pVal.push(context.turnPriceMax); //Store the High values as positive
            context.pValClose.push(context.turnPriceClose);
            context.pBar.push(context.sigBar);
            context.pBarTime.push(context.turnTime);
            this.setValues(context);
            //Draw.Dot(this, "PvtLow" + CurrentBar, true, bAgoIdx, High[bAgoIdx] + offset, Brushes.Red);
            //Draw.Text(this, "PvtLowDeg" + CurrentBar, deg(High[bAgoIdx]).ToString() + "-" + tfBars, bAgoIdx, High[bAgoIdx] + 2 * offset); 

            //	Print(turnTime + "  :  CurrentBar: " + CurrentBar + " | sigBar: " + (CurrentBar - bAgoIdx) + " - turnPriceMax: " + turnPriceMax 
            //	+ " - bAgoIdx: " + bAgoIdx + " - ptBars: " + ptBars + " - ptTime: " + calTime + " |  ctTime: " + currTime + " | ctBars: " + ctBars);

            //	Print(turnTime + "  :  ptBars: " + ptBars + " | ptRng(MM): " + ptRngMM + " | ptRng(CC): " + ptRngCC + " | ptRng(CM): " + ptRngCM 
            //	+ " | ptRng(MC): " + ptRngMC + " | ptEnd(M): " + ptEndM + " | ptEnd(C): " + ptEndC + " | ctRng(MM): " + ctRngMM + " | ctEnd(C): " + ctEndC); 

            //	Print("-------------------------------------------------------------------------------------------------------------------------------------------------------");

        },
            this.roundToTwo = function(num) {    
                return +(Math.round(num + "e+2")  + "e-2");
        }, 
            this.deg = function(Val) {
            //If the result is negative, then add the negative value to 360 to get the Abs Deg Value
            if (Math.round(((Math.sqrt(Val) * 180) - 225) % 360, 0) < 0) {
                var value = 360 + Math.round(((Math.sqrt(Val) * 180) - 225) % 360, 0);
                //console.log(Val + "-----DEG----- " + value);
                return value;
            } else {                   
                var value =   Math.round(((Math.sqrt(Val) * 180) - 225) % 360, 0);
                //console.log(Val + "-----DEG----- " + value);
                return value;
            }
        }
        
        this.main = function(context, inputCallback) {

            this._context = context;
            this._input = inputCallback;

            this._context.select_sym(1);

            if (context.symbol.info != undefined) {

                if (context.symbol.info.type == "stock" || context.symbol.info.type == "index") {
                    context.offset = (context.symbol.minTick || 0.01) * 80;
                } else if (context.symbol.info.type == "forex") {
                    context.offset = (context.symbol.minTick || 0.01) * 100;
                } else if (context.symbol.info.type == "future") {
                    context.offset = (context.symbol.minTick || 0.01) * 8;
                }

            }
            var Period = inputCallback(0);

            var Use45 = true;
            var Use90 = true;
            var HitStrength = 5;
          
            var CurrentBar = this._context.CurrentBar;

            if(isNaN(context.symbol.open))
            return;

            context.Data.unshift({
                High: context.symbol.high || 0,
                Low: context.symbol.low || 0,
                Close: context.symbol.close || 0,
                dmPlus: 0,
                dmMinus: 0,
                sumDmPlus: 0,
                sumDmMinus: 0,
                sumTr: 0,
                tr: 0,
                Value: 0,
                Index: context.symbol.index,
                plotDirection: 0,
                Time: context.symbol.time
            });
            
            if(context.Data[0] != undefined && context.Data[1] != undefined) {
                if (context.Data[0].Index == context.Data[1].Index)
                return;
            }

            // console.log("high: "+ context.symbol.high +" low: "+ context.symbol.low+" close: "+ context.symbol.close + " context.ctBars: "+ context.ctBars );
        
            this._context.CurrentBar++;
    
            context.Data[0].hma = this.hma(context);

            if (CurrentBar < Period) {
                return;
            }

            //#region Assign Curr Time Values 
            if (context.upTrnd == true || context.dnTrnd == true) {
                context.ctBars += 1;

                if (context.pBarTime.length >= 1) {
                    var k = context.pBarTime.length - 1;

                    context.ctTime = context.Data[0].Time - context.pBarTime[k];
                
                    // alert(context.ctTime);
                    this.calcTime(context);
                }
            }
            //#endregion Assign Curr Time Values

            // console.log("context.pVal.length " + context.pVal.length );
            if (context.pVal.length >= 1) {                

                var k = context.pVal.length - 1;
                context.ctRngCC = Math.abs(Math.abs(Math.abs(context.pValClose[k]) - context.Data[0].Close));
       
                context.ctEndC = context.Data[0].Close;
   
                if (context.upTrnd) {

                    context.ctEndM = context.Data[0].High;

                    if (Math.abs(context.Data[0].High - Math.abs(context.pVal[k])) > context.ctRngMM) context.ctRngMM = Math.abs(context.Data[0].High - Math.abs(context.pVal[k]));
                    context.ctRngMM = this.roundToTwo (context.ctRngMM);
                    context.pos = context.Data[0].High;
                    context.offsetSig = Math.abs(context.offset);
                    // console.log("context.offsetSig " + context.offsetSig);
                } else if (context.dnTrnd) {

                    context.ctEndM = context.Data[0].Low;

                    if (Math.abs(context.Data[0].Low - Math.abs(context.pVal[k])) > context.ctRngMM) context.ctRngMM = Math.abs(context.Data[0].Low - Math.abs(context.pVal[k]));
                    context.ctRngMM = this.roundToTwo(context.ctRngMM);
                    context.pos = context.Data[0].Low;
                    context.offsetSig = -Math.abs(context.offset);
                    // console.log("context.offsetSig " + context.offsetSig);
                }
                //End 
                this.fcstTurns(context, Use45, HitStrength);
            }

                //console.log("hma: " + context.Data[0].hma);
                //Determine High & Low Pivot Points (Actual Trend Change Points)

            if (context.Data[0].hma > context.Data[1].hma && context.Data[1].hma < context.Data[2].hma && context.Data[2].hma < context.Data[3].hma) {
                //console.log("upTrend");
                this.upTrend(context);
            }

            if (context.Data[0].hma < context.Data[1].hma && context.Data[1].hma > context.Data[2].hma && context.Data[2].hma > context.Data[3].hma) {
                // console.log("dnTrend");
                this.dnTrend(context);
            }            
            // console.log("----------------------------------------------");
            return;
        }
    }
}
