import {ApproxCompare} from './ApproxCompare';

/**
 * The script is the logic of algoswing indicator
 * @param {*} Strength 
 * @param {*} context 
 * @function swing
 */

const swing = (Strength, context) => {
    
    //#region Initialize variables
        var high0 = context.Data[0].High;
        var low0 = context.Data[0].Low;
        var close0 = context.Data[0].Close;
        //console.log("high0: " + high0 + " low0: " + low0 + " close0: " + close0 + " Open0: " + context.Data[0].Open+ " CurrentBar:" + context.CurrentBar);
    //#endregion Initialize variables
    
    //#region Update Arrays
        context.swingHighSwings.push([0]);
        context.swingLowSwings.push([0]);
        context.swingHighSeries.unshift([0]);
        context.swingLowSeries.unshift([0]);
        context.SwingHighPlot.unshift([0]);
        context.SwingLowPlot.unshift([0]);
        context.SwingHighPlot_Valid.unshift([true]);
        context.SwingLowPlot_Valid.unshift([true]);
    //#endregion Arrays
    
    //#region CurrentBar < Reference
        if (context.CurrentBar < context.saveCurrentBar) {
            //  console.log("1 if");
            context.currentSwingHigh = context.SwingHighPlot_Valid[0] ? context.SwingHighPlot[0] : 0;
            context.currentSwingLow = context.SwingLowPlot_Valid[0] ? context.SwingLowPlot[0] : 0;
            context.lastSwingHighValue = context.swingHighSeries[0];
            context.lastSwingLowValue = context.swingLowSeries[0];
            context.swingHighSeries[Strength] = 0;
            context.swingLowSeries[Strength] = 0;

            context.lastHighCache.length = 0;
            context.lastLowCache.length = 0;
            for (var barsBack = Math.min(context.CurrentBar, context.constant) - 1; barsBack >= 0; barsBack--) {
                context.lastHighCache.push(context.Data[barsBack].High);
                context.lastLowCache.push(context.Data[barsBack].Low);
            }
            context.saveCurrentBar = context.CurrentBar;
            return;
        }
    //#endregion CurrentBar < Reference

    //#region Reference != CurrentBar
        if (context.saveCurrentBar != context.CurrentBar) {

            // console.log("2 if");

            context.swingHighSwings[0] = 0; // initializing important internal        
            context.swingLowSwings[0] = 0;  // initializing important internal
            context.swingHighSeries[0] = 0; // initializing important internal
            context.swingLowSeries[0] = 0;  // initializing important internal

            context.lastHighCache.push(high0);
            
            if (context.lastHighCache.length > context.constant)
                context.lastHighCache.splice(0, 1);
                context.lastLowCache.push(low0);

            if (context.lastLowCache.length > context.constant)
                context.lastLowCache.splice(0, 1);

                //   console.log("lastHighCache.Count:" + context.lastHighCache.length);
                //   console.log("lastLowCache.Count:" + context.lastLowCache.length);
            
            if (context.lastHighCache.length == context.constant) {
                var isSwingHigh = true;
                var swingHighCandidateValue = context.lastHighCache[Strength];

                //  console.log("swingHighCandidateValue: " + swingHighCandidateValue);
            
                for (var i = 0; i < Strength; i++) {
                    if (ApproxCompare(context.lastHighCache[i], swingHighCandidateValue) >= 0)
                        isSwingHigh = false;
                }
                //  console.log("isSwingHigh1" + isSwingHigh);
                for (var i = Strength + 1; i < context.lastHighCache.length; i++) {
                    //   console.log(context.lastHighCache[i] + " ````````` " + swingHighCandidateValue);
                    if (ApproxCompare(context.lastHighCache[i], swingHighCandidateValue) > 0)
                        isSwingHigh = false;
                }
                // console.log("isSwingHigh2" + isSwingHigh);

                context.swingHighSwings[context.CurrentBar - Strength] = isSwingHigh ? swingHighCandidateValue : 0.0;
                //   console.log("====================================context.swingHighSwings["+Strength+"]   " + context.swingHighSwings[Strength]);
                
                if (isSwingHigh)
                    context.lastSwingHighValue = swingHighCandidateValue;
                    //   console.log("lastSwingHighValue" + context.lastSwingHighValue);
                
                if (isSwingHigh) {
                    context.currentSwingHigh = swingHighCandidateValue;
                    for (var i = 0; i <= Strength; i++)
                        context.SwingHighPlot[i] = context.currentSwingHigh;
                } else if (high0 > context.currentSwingHigh || ApproxCompare(context.currentSwingHigh, 0.0) == 0) {
                    context.currentSwingHigh = 0.0;
                    context.SwingHighPlot[0] = close0;
                    context.SwingHighPlot_Valid[0] = false;
                } else
                    context.SwingHighPlot[0] = context.currentSwingHigh;

                //console.log("context.SwingHighPlot[0]: " + context.SwingHighPlot[0]);
                // console.log("SwingHighPlot: " + context.SwingHighPlot[0] + "  lastSwingHighValue:" + context.lastSwingHighValue + "  swingHighSwings[Strength]:" + context.swingHighSwings[Strength]);

                if (isSwingHigh) {
                    for (var i = 0; i <= Strength; i++){
                        context.swingHighSeries[i] = context.lastSwingHighValue;
                    //  console.log("=====================================context.swingHighSeries["+i+"]: " + context.swingHighSeries[i] );
                    }
                } else {
                    context.swingHighSeries[0] = context.lastSwingHighValue;
                //   console.log("=====================================context.swingHighSeries[0]: " + context.swingHighSeries[0] );
                }
            }

            if (context.lastLowCache.length == context.constant) {
                //console.log("bishaa");
                var isSwingLow = true;
                var swingLowCandidateValue = context.lastLowCache[Strength];
                //console.log("swingLowCandidateValue: " + context.lastLowCache[Strength]);
                for (var i = 0; i < Strength; i++) {
                    if (ApproxCompare(context.lastLowCache[i], swingLowCandidateValue) <= 0)
                        isSwingLow = false;
                }
                //console.log("isSwingLow1: " + isSwingLow);
                for (var i = Strength + 1; i < context.lastLowCache.length; i++) {
                    if (ApproxCompare(context.lastLowCache[i], swingLowCandidateValue) < 0)
                        isSwingLow = false;
                }
                //console.log("isSwingLow2: " + isSwingLow);
                context.swingLowSwings[context.CurrentBar - Strength] = isSwingLow ? swingLowCandidateValue : 0.0;
                //console.log(" context.swingLowSwings[Strength]: " +  context.swingLowSwings[Strength]);
                if (isSwingLow)
                    context.lastSwingLowValue = swingLowCandidateValue;
                    //   console.log(" context.lastSwingLowValue: " +  context.lastSwingLowValue);
                if (isSwingLow) {
                    context.currentSwingLow = swingLowCandidateValue;
                    //  console.log("  context.currentSwingLow: " +   context.currentSwingLow);
                    for (var i = 0; i <= Strength; i++){
                        context.SwingLowPlot[i] = context.currentSwingLow;
                    //      console.log("  context.SwingLowPlot["+i+"] :" +   context.SwingLowPlot[i]);
                    }
                } else if (low0 < context.currentSwingLow || ApproxCompare(context.currentSwingLow, 0.0) == 0) {
                    
                    context.currentSwingLow = 1.79769313486232E+308;
                    //console.log("  context.currentSwingLow------------------- :" +   context.currentSwingLow);
                    context.SwingLowPlot[0] = close0;
                    //console.log("  context.SwingLowPlot[0] :" +   context.SwingLowPlot[0]);
                    context.SwingLowPlot_Valid[0] = false;

                } else
                    
                    context.SwingLowPlot[0] = context.currentSwingLow;
                    //  console.log("  isSwingLow " +   isSwingLow);

                if (isSwingLow) {
                    for (var i = 0; i <= Strength; i++){
                        context.swingLowSeries[i] = context.lastSwingLowValue;
                    //    console.log("   context.swingLowSeries["+i+"] :" +     context.swingLowSeries[i]);
                    }
                } else {
                    context.swingLowSeries[0] = context.lastSwingLowValue;
                //    console.log("   context.swingLowSeries[0] :" +      context.swingLowSeries[0]);
                }
            }

            context.saveCurrentBar = context.CurrentBar;
        } 
    //#endregion Reference != CurrentBar
    
    //#region CurrentBar >= Reference
        else if (context.CurrentBar >= context.constant - 1) {

            if (context.lastHighCache.length == context.constant && ApproxCompare(high0, context.lastHighCache[context.lastHighCache.length - 1]) > 0)
                context.lastHighCache[context.lastHighCache.length - 1] = high0;
            
            if (context.lastLowCache.length == context.constant && ApproxCompare(low0, context.lastLowCache[context.lastLowCache.length - 1]) < 0)
                context.lastLowCache[context.lastLowCache.length - 1] = low0;

            if (high0 > context.currentSwingHigh && context.swingHighSwings[Strength] > 0.0) {
                context.swingHighSwings[Strength] = 0.0;
                for (var i = 0; i <= Strength; i++) {
                    context.SwingHighPlot[i] = close0;

                    context.SwingHighPlot_Valid[i] = false;
                    context.currentSwingHigh = 0.0;
                }
            } else if (high0 > context.currentSwingHigh && ApproxCompare(context.currentSwingHigh, 0.0) != 0) {
                
                context.SwingHighPlot[0] = close0;
                context.SwingHighPlot_Valid[0] = false;
                context.currentSwingHigh = 0.0;

            } else if (high0 <= context.currentSwingHigh)
                context.SwingHighPlot[0] = context.currentSwingHigh;

            if (low0 < context.currentSwingLow && context.swingLowSwings[Strength] > 0.0) {
                
                context.swingLowSwings[Strength] = 0.0;
                
                for (var i = 0; i <= Strength; i++) {
                    context.SwingLowPlot[i] = close0;

                    context.SwingLowPlot_Valid[i] = false;
                    context.currentSwingLow = 1.79769313486232E+308;
                }

            } else if (low0 < context.currentSwingLow && context.currentSwingLow.ApproxCompare(1.79769313486232E+308) != 0) {
                
                context.SwingLowPlot_Valid[0] = false;
                context.currentSwingLow = 1.79769313486232E+308;

            } else if (low0 >= context.currentSwingLow)
                context.SwingLowPlot[0] = context.currentSwingLow;
        }
    //#endregion

    //#region Console Log References
        //   console.log("-----------------------");
        //	for(var i = 0;i <= context.CurrentBar;i++){
        //	console.log("GetValueAt " + i + "   :" + context.swingHighSwings[i]);

        //	}
        //    console.log("-----------End------------");
    //#endregion Console Log References
    
}
export {swing};