import request from "axios"
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../config/links.config';
import { token } from '../../../../configMiddleware/getToken';
const axios = require("axios");
//const measure series
export const calculateSeries = date => {
  const currentDate = formatDate(new Date());
  const aDay = 86400000;
  const days = Math.floor(
    (Date.parse(date.replace(/-/g, "/")) -
      Date.parse(currentDate.replace(/-/g, "/"))) /
      aDay
  );

  if (days < 14) return "DGS1MO";
  else if (days > 14 && days <= 30) return "DGS3MO";
  else if (days > 31 && days <= 90) return "DGS6MO";
  else if (days > 90 && days <= 120) return "DGS5";
  else if (days > 120) return "DGS10";
};

const formatDate = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

//get interest rate variable
export const getInterestRateVariable = async series => {
  let token = localStorage.getItem("jwtToken");
  try {
       //call to api
    const response = await request.get(
      `${optionsURL}/api/options/get_interest_rate_variable?series=${series}`,{headers: { Authorization:  token }}
    );

    //check for response
    if (response) return response.data;
    throw new Error(response.error);
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }

  // return await token().then( (token) => {      
  //   return axios({
  //         method: "get",
  //         url: `${optionsURL}/api/options/get_interest_rate_variable`,
  //         params : {
  //           series :series
  //         },
  //         headers: { Authorization: "Bearer " + token }
  //   }).then(( response ) =>{
  //     if (response) return response.data;
  //         // return res.data
  //   }).catch((e) => console.log("axios error"))
  //   }).catch( (e) => console.log(e))
};

export const getDaysDiffInYear = date => {
  const currentDate = formatDate(new Date());
  const aDay = 86400000;
  const days = Math.floor(
    (Date.parse(date.replace(/-/g, "/")) -
      Date.parse(currentDate.replace(/-/g, "/"))) /
      aDay
  );
  return days / 365; //year in days
};
