import React from "react";
import Modal from "react-modal-resizable-draggable";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  close_info_ticket,
  set_option_ticket
} from "./../../actions/optionTicket";

const OptionInfo = ({
  close_info_ticket,
  theme,
  infoTicket,
  symbol,
  selected_options,
  columns,
  set_option_ticket
}) => {
  const styles = {
    header1: {
      height: "40px",
      width: "100",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row-reverse"
    },
    header2: {
      //   height:"60px",
      backgroundColor: theme === "Dark" ? "#252e42" : "#f1f1f1",
      padding: "10px",
      paddingRight: "20px",
      paddingLeft: "20px"
    },
    closeIcon: {
      zIndex: 200000,
      cursor: "pointer"
    },
    symbolName: {},
    symbolDesc: {},
    body: {
      padding: "20px"
    }
  };
  const option = selected_options.filter(opt => opt.symbol === symbol)[0];
  return !option ? (
    <></>
  ) : (
    <>
      <Modal
        isOpen={infoTicket}
        onRequestClose={close_info_ticket}
        onFocus={() => {}}
        className={"my-modal-custom-class"}
        initWidth={600}
        initHeight={610}
      >
        <div
          id="style-3"
          style={{
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            backgroundColor: theme === "Dark" ? "#131721" : "white",
            color: theme === "Light" ? "#131721" : "white"
          }}
        >
          <div style={styles.header1}>
            <div style={styles.closeIcon} onClick={() => close_info_ticket()}>
              <CloseIcon />
            </div>
          </div>

          <div style={styles.header2}>
            <h6>{symbol}</h6>
            <p className="mb-0">{option.description}</p>
          </div>
          <div style={styles.body}>
            <div className="row">
              <div className="col-4 d-flex flex-column ">
                <h6>Mark</h6>
                <p>
                  <small>{((option.ask + option.bid) / 2).toFixed(3)}</small>
                </p>
              </div>

              <div className="col-4 d-flex flex-column ">
                <h6>Ask</h6>
                <p>
                  <small>{option.ask}</small>
                </p>
              </div>
              <div className="col-4 d-flex flex-column ">
                <h6>Bid</h6>
                <p>
                  <small>{option.bid}</small>
                </p>
              </div>
              <div className="row mt-5 mr-2 ml-2">
                {// Object.keys(option)

                columns
                  .filter(
                    opt => opt.name !== "description" && opt.name !== "symbol"
                  )
                  .map(opt => {
                    return opt.type !== "greek" ? (
                      <div
                        key={opt.name}
                        className="col-auto d-flex flex-column"
                      >
                        <p
                          className="bold mb-0"
                          style={{ whiteSpace: "no-wrap" }}
                        >
                          <small>
                            {opt.title.split(" ")[0]}
                            <br />{" "}
                            {opt.title.split(" ")[1]
                              ? opt.title.split(" ")[1]
                              : " ."}
                          </small>
                        </p>
                        <p>
                          <small>
                            <small>{option[opt.name]}</small>
                          </small>
                        </p>
                      </div>
                    ) : (
                      ""
                    );
                  })}{" "}
              </div>

              <h6 className="mr-2 ml-2 mt-3">Greeks</h6>
              <div className="row mt-1 mr-2 ml-2">
                {columns
                  .filter(
                    opt => opt.name !== "description" && opt.name !== "symbol"
                  )
                  .map(opt => {
                    return opt.type !== "greek" ? (
                      ""
                    ) : (
                      <div
                        key={opt.name}
                        className="col-auto d-flex flex-column"
                      >
                        <p
                          className="bold mb-0"
                          style={{ whiteSpace: "no-wrap" }}
                        >
                          <small>
                            {opt.title.split(" ")[0]}
                            <br />{" "}
                            {opt.title.split(" ")[1]
                              ? opt.title.split(" ")[1]
                              : " ."}
                          </small>
                        </p>
                        <p>
                          <small>
                            <small>{option.greeks[opt.name].toFixed(3)}</small>
                          </small>
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <button
              className={`btn btn-outline-${
                theme === "Dark" ? "light" : "dark"
              } btn-sm`}
              onClick={() => {
                close_info_ticket();
                set_option_ticket(option);
              }}
            >
              trade
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    infoTicket: state.ticket.infoTicket,
    symbol: state.ticket.selected_symbol,
    selected_options: state.ticket.selected_options,
    columns: state.ticket.allColumns
  };
};

export default connect(mapStateToProps, {
  close_info_ticket,
  set_option_ticket
})(OptionInfo);
