import React, { Component } from "react";
import Table from "./components/table";
import OptionBar from "./components/OptionBar";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../../config/links.config'
import { connect } from "react-redux";
import {
  get_expiration,
  select_expiry,
  select_colums,
  select_strike,
  set_symbol
} from "../../actions/options";
import StrikeBar from "./components/strikesbar";
import StockPanel from "./components/StockePanel";
import axios from "axios";

class BottomOptionWidget extends Component {
  state = {
    ex_vis: false
  };
  componentDidMount() {
    const is_symbols_present = Boolean(
      JSON.parse(localStorage.getItem("all_symbols"))
    );
    if (!is_symbols_present) {
      axios
        .get(`${chartURL}/api/chart/all_symbols`)
        .then(res => {
          localStorage.setItem("all_symbols", JSON.stringify(res.data));
        })
        .catch(e => console.log("error getting symbols", e));
    }
    this.props.set_symbol("AAPL");
    // this.props.get_expiration(this.props.symbol);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.symbol !== this.props.symbol) {
      // this.props.get_expiration(this.props.symbol);
    }
  }

  toggle_exp_vis = () => {
    this.setState({ ex_vis: !this.state.ex_vis });
  };

  render() {
    const style = {
      container: {
        width: "100%"
      },
      bigCol: {
        width: "46%",
        display: "inline-block",
        overflowX: "scroll"
      },
      smCol: {
        width: "8%",
        display: "inline-block",
        overflowX: "scroll"
      },
      expiryContainer: {
        maxWidth: "1200px",
        height: "auto",
        // borderWidth: "1px",
        // borderStyle: "solid",
        padding: "5px",
        // borderColor: "grey",
        overflowX: "scroll"
      },
      expiryDate: {
        cursor: "pointer",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontSize: "11px",
        fontWeight: "bold",
        whiteSpace: "nowrap"
      },
      strikeContainer: {
        width: "100%",
        height: "100%",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "grey"
      }
    };
    return (
      <>
        <StockPanel theme={this.props.theme} />
        <OptionBar
          theme={this.props.theme}
          toggle_expiry={this.toggle_exp_vis}
        />
        <div
          style={{
            ...style.expiryContainer,
            display: this.state.ex_vis ? "block" : "none"
          }}
          id="style-3"
        >
          {this.props.expirations.map((expiry, index) => {
            let months = [
              "JAN",
              "FEB",
              "MAR",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
              "OCT",
              "NOV",
              "DEC"
            ];
            let expiry_to_show = `${months[Number(expiry.split("-")[1]) - 1]}-${
              expiry.split("-")[2]
            }-${expiry.split("-")[0]}`;
            return (
              <span
                key={expiry}
                style={{
                  ...style.expiryDate,
                  color:
                    this.props.selectedExpiration === expiry ? "blue" : "grey"
                }}
                onClick={() => {
                  this.props.select_expiry(
                    index,
                    expiry,
                    this.props.symbol,
                    this.props.strikeRange,
                    this.props.near,
                    this.props.custom_srike
                  );
                }}
              >
                {expiry_to_show}
              </span>
            );
          })}
        </div>
        <div
          style={{
            ...style.expiryContainer,
            display: this.props.custom_srike ? "block" : "none"
          }}
          id="style-3"
        >
          <span style={{ color: "grey" }}> Strikes</span>
          {this.props.strikes_to_select.map((strike, index) => {
            let is_selected = Boolean(
              this.props.strikes.filter(str => str === strike).length
            );

            return (
              <span
                key={strike}
                style={{
                  ...style.expiryDate,
                  color: is_selected ? "blue" : "grey"
                }}
                onClick={() =>
                  this.props.select_strike(
                    strike,
                    this.props.strikes,
                    this.props.symbol,
                    this.props.selectedExpiration,
                    this.props.near
                  )
                }
              >
                {/* {is_selected ? <CheckIcon size="small" /> : ""} */}
                {strike}
              </span>
            );
          })}
        </div>
        <div className="table_container mt3">
          <div style={style.container}>
            <div style={style.bigCol} id="style-3">
              <Table
                rows={this.props.options.call_options}
                columns={this.props.selectedColumns}
                theme={this.props.theme}
                last_price={this.props.near}
                select_colums={this.props.select_colums}
                set_info_ticket={this.props.onClickRows}
                set_option_ticket={this.props.onClickAskBid}
              />
            </div>
            <div style={style.smCol} id="style-3-cust">
              <StrikeBar rows={this.props.strikes} theme={this.props.theme} />
            </div>
            <div style={style.bigCol} id="style-3">
              <Table
                rows={this.props.options.put_options}
                columns={this.props.selectedColumns}
                theme={this.props.theme}
                last_price={this.props.near}
                select_colums={this.props.select_colums}
                set_info_ticket={this.props.onClickRows}
                set_option_ticket={this.props.onClickAskBid}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    expirations: state.option.allExpirations,
    symbol: state.option.symbol,
    strikes: state.option.strikes,
    options: state.option.options,
    strikeRange: state.option.strikeRange,
    near: state.option.near,
    selectedColumns: state.option.selectedColumns,
    selectedExpiration: state.option.selectedExpiration,
    custom_srike: state.option.custom_srike,
    strikes_to_select: state.option.strikes_to_select
  }),
  { get_expiration, select_expiry, select_colums, select_strike, set_symbol }
)(BottomOptionWidget);
