import chartLayoutAPI from '../endpoints/chartapi';
let chartLayoutObj = new chartLayoutAPI();
/**
 * This script save the chart data into our DB by calling the "chartLayout" method
 * @param {*} chartData 
 * @function saveChartData
 */
export default function saveChartData (chartData) {

        chartLayoutObj.chartLayout(chartData)

  }

  