
import {swing,SwingHighBar,SwingLowBar,dnTrend,upTrend} from '../Helper_Methods/Middleware';
/**
 * we have a "swing" function that contains the logic of the swing indicator as
 * it is used in the algoswing logic, we have created a seperate function foe swing indicator to perform
 * the calculation for algoswing indicator.
 * @param SwingStrength
 * @param context
 * @param CurrentBar
 * @function swing -> have the core logic of a swing indicator
 * @function algoSwing -> Have the core logic for "AlgoSwing" indicator.
 */
export default class AlgoSWING {

    algoSwing = function(SwingStrength, context,CurrentBar) {
        
        swing(SwingStrength, context);
        
        var UseHighLow = true;

        if (CurrentBar < SwingStrength) {
            return;
        }
  
        var swingHighBar3 = SwingHighBar(1, 3, context.CurrentBar, SwingStrength, context);
        //console.log("--------------------------------  swingHighBar3   :  " + swingHighBar3);
        var swingHighBar2 = SwingHighBar(1, 2, context.CurrentBar, SwingStrength, context);
        //console.log("--------------------------------  swingHighBar2   :  " + swingHighBar2);
        var swingHighBar1 = SwingHighBar(1, 1, context.CurrentBar, SwingStrength, context);
        //console.log("--------------------------------  swingHighBar1   :  " + swingHighBar1);

        var swingLowBar3 = SwingLowBar(1, 3, context.CurrentBar, SwingStrength, context);
        // console.log("--------------------------------  swingLowBar3   :  " + swingLowBar3);
        var swingLowBar2 = SwingLowBar(1, 2, context.CurrentBar, SwingStrength, context);
        // console.log("--------------------------------  swingLowBar2   :  " + swingLowBar2);
        var swingLowBar1 = SwingLowBar(1, 1, context.CurrentBar, SwingStrength, context);
        
        // console.log("--------------------------------  swingLowBar1   :  " + swingLowBar1);
        // console.log(dateFormat(new Date(context.Data[0].Time)) + " -- swingHighBar1: " + swingHighBar1 + " | " + "swingHighBar2: " + swingHighBar2 + " | " + "swingHighBar3: " + swingHighBar3 , " | CurrentBAr :", CurrentBar);
        // console.log(dateFormat(new Date(context.Data[0].Time)) + " -- swingLowBar1: " + swingLowBar1 + " | " + "swingLowBar2: " + swingLowBar2 + " | " + "swingLowBar3: " + swingLowBar3);
        // console.log("-------------------------------------------------------------------------------"); 
        
        if (swingHighBar1 < 1 || swingHighBar2 < 1 || swingHighBar3 < 1 ||
            swingLowBar1 < 1 || swingLowBar2 < 1 || swingLowBar3 < 1) {
            //   console.log(dateFormat(new Date(context.Data[0].Time)) + " - Returning due to swingbar filter");
            return;
        }

        var swingHigh3 = context.swingHighSeries[swingHighBar3];
        // console.log("--------------------------------  swingLowBar3   :  " + swingHigh3);
        var swingHigh2 = context.swingHighSeries[swingHighBar2];
        // console.log("--------------------------------  swingLowBar3   :  " + swingHigh2);
        var swingHigh1 = context.swingHighSeries[swingHighBar1];
        // console.log("--------------------------------  swingLowBar3   :  " + swingHigh1);

        var swingLow3 = context.swingLowSeries[swingLowBar3];
        // console.log("--------------------------------  swingLow3   :  " + swingLow3);
        var swingLow2 = context.swingLowSeries[swingLowBar2];
        // console.log("--------------------------------  swingLow2   :  " + swingLow2);
        var swingLow1 = context.swingLowSeries[swingLowBar1];
        // console.log("--------------------------------  swingLow1   :  " + swingLow1);

        if (((context.Data[0].Close < swingLow1 && !UseHighLow) || (UseHighLow && (context.Data[0].Close < swingLow1 || context.Data[0].Low < swingLow1))) && context.Data[0].Close < swingHigh1) {
          dnTrend(context);
        }

        // Price > High1 && Price > Low1 -> UpTrend ... Maybe also add && Close[0] > High3?
        else if (((context.Data[0].Close > swingHigh1 && !UseHighLow) || (UseHighLow && context.Data[0].Close > swingHigh1 || context.Data[0].High > swingHigh1)) && context.Data[0].Close > swingLow1) {
          upTrend(context);
        }

        // Price > Low1 && Price < Low2 && Price < High1 && Low1 < Low2  -> DnTrend
        else if (context.Data[0].Close > swingLow1 && context.Data[0].Close < swingLow2 && context.Data[0].Close < swingHigh1 && swingLow1 < swingLow2) {
          dnTrend(context);
        }

        // Price < High1 && Price > High2 && Price > Low1 && High1 > High2 -> UpTrend
        else if (context.Data[0].Close < swingHigh1 && context.Data[0].Close > swingHigh2 && context.Data[0].Close > swingLow1 && swingHigh1 > swingHigh2) {
          upTrend(context);
        }

        // Price > Low1 && Price < High1 && Low2 > Low3 && High1 > High2 -> UpTrend
        else if (context.Data[0].Close > swingLow1 && context.Data[0].Close < swingHigh1 && swingLow2 > swingLow3 && swingHigh1 > swingHigh2) {
          upTrend(context);
        }

        // Price < High1 && Price > Low1 && High2 < High3 && Low1 < Low2 -> DnTrend
        else if (context.Data[0].Close < swingHigh1 && context.Data[0].Close > swingLow1 && swingHigh1 < swingHigh3 && swingLow1 < swingLow2) {
          dnTrend(context);
        }        
        return;
    }
}