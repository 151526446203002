import React from "react";
import TabsCust from "./../Tabs/index";
import History from "./components/History";
import Orders from "./components/Orders";
import GainLoss from "./components/GainLoss";
import Position from "./components/Position";

const AccountInfo = props => {
  const TabData = [
    {
      label: "history",
      component: <History user={props.user} theme={props.theme} />
    },
    {
      label: "Orders",
      component: <Orders user={props.user} theme={props.theme} />
    },
    {
      label: "Performance",
      component: <GainLoss  user={props.user} theme={props.theme} />
    },
    {
      label: "Position",
      component: <Position user={props.user} theme={props.theme} />
    }
  ];
  return (
    <>
      <TabsCust
        theme={props.theme}
        // setBottomWidgetStatus={setBottomWidgetStatus}
        // BottomWidgetStatus={BottomWidgetStatus}
        tabs={TabData}
      />
    </>
  );
};

export default AccountInfo;
