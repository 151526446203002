import IndicatorTemplate from '../endpoints/indicatortemplateapi';
let indTempObj = new IndicatorTemplate();
/**
 * remove the study/indicator template from the chart we are sending/passing the study data in "studyTemplateData" property.
 * The template removed on the basis of indicator name.
 * @param {*} studyTemplateData 
 * @function removeindicatorTemplate
 */
export default function removeindicatorTemplate(studyTemplateData){
  console.log("studyTemplateData: ", studyTemplateData)

//#region Calling the "removeByName" method to "REMOVE" the study templates
  indTempObj.removeByName(studyTemplateData);
//#endregion Calling the "removeByName" method to "REMOVE" the study templates

  }