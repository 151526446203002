import chartLayoutAPI from '../endpoints/chartapi';
let chartLayoutObj = new chartLayoutAPI();
/**
 * Get the chart content on the basis of chartID. 
 * @param {*} chartId 
 * @function getChartContent
 */
export default async function getChartData(chartId) {
    let returnData;

    //#region Calling the "getChartContent" method to get the chart content
      returnData = await chartLayoutObj.getChartContent(chartId)
      console.log("Return data:", returnData)
    //#endregion Calling the "getChartContent" method to get the chart content

    //#region return the GET response
    return returnData 
//#endregion return the GET response

}