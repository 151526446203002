import GetMarks from '../PLot_Marks/get_Marks';
import GetTimeScaleMarks from "../PLot_Marks/get_timescale_Marks";
import refreshMarks from '../library_Utils/refreshMarks';
import createTextOnChart from '../library_Utils/createTextOnChart';
let getmarks = new GetMarks();
let timescalemarks = new GetTimeScaleMarks();
var dateFormat = require('dateformat');
/**
 * @param {*} context 
 * @function enterLong
 */

const enterLong = (context) => {
    
    context.Data[0].Algosignals_eSignal = 1; //Esignal[0] == 1 indicates the indicator fired a buy signal
    context.longSig = true;
    context.shortSig = false;
    context.sigBar = context.CurrentBar;
    if (!context.invertSignals) { context.yOffset = context.Data[0].Low - context.txtMult1 * context.symbol.script.symbols[0].minTick; context.yPixOffset = -15; }
    else if (context.invertSignals) { context.yOffset = context.Data[0].High + context.txtMult1 * context.symbol.script.symbols[0].minTick; context.yPixOffset = 15; }
    context.lEntryPrice = context.Data[0].Close;
    context.openTrade = context.Data[0].Time;
    
    if (context.firstSignal) { 
      context.firstTrade = context.Data[0].Time;
        context.firstSignal = false; 
    }
    
    //#region Handle Split Exits
    if (context.firstEntryAfterSplitt && !context.splitExit) {
      // console.log("Enter after First Split Long")
      // console.log("context.SplitExitPeformaceCal", context.SplitExitPeformaceCal);
      refreshMarks(window.parent.tvWidget);
      // Callout here with unchanged perf values
      var Arr = [   
          {
            id:  context.Data[0].Time / 1000 + context.Data[0].Close,
            time: context.Data[0].Time / 1000,
            color: 'yellow',
            text: 'Buy',
            label: 'B',
            labelFontColor: 'red',
            border: 'red',
            minSize: 20,
          },           
      ]
      var TimeScale = [
          {
            id: context.Data[0].Time / 1000 + context.Data[0].Close,
            time: context.Data[0].Time  / 1000,
            color: 'yellow',
            label: 'EL',
            // minSize: 5,
            background: '#00ff00',
            tooltip:[
              "Long "+ "<br>" + "Date: " + (new Date(context.Data[0].Time).getMonth() + 1) + "/" + new Date(context.Data[0].Time).getDate() + "/" + 
              new Date(context.Data[0].Time).getFullYear() + " 4pm ET"+ "<br>" + "<br>" 
              + "Net Gain: " + context.SplitExitPeformaceCal.netGain + "<br>" +  "WinRate: " + context.SplitExitPeformaceCal.winRate  + "%" + " / " + 
              context.SplitExitPeformaceCal.totalSignals + " Signals" + "<br>" + "Mkt Val Captured: " + context.SplitExitPeformaceCal.mktValCap + "%",
              "<br>",
              "Signal price: " + context.Data[0].Close.toFixed(2),//context.SplitExitPeformaceCal.signalPrice.toFixed(2),
              "Last Trade P&L: " + context.SplitExitPeformaceCal.tradePnL,
              
              "<br>",
              "Avg Gain/Signal: " + context.SplitExitPeformaceCal.avgGain,
              "Avg Win: " + context.SplitExitPeformaceCal.avgWin , "Avg Loss: " + context.SplitExitPeformaceCal.avgLoss + " -> " + context.SplitExitPeformaceCal.wlRatio + ":1",
              "Max Winner: " + context.SplitExitPeformaceCal.maxGain,
              "Max Loser: " + context.SplitExitPeformaceCal.minGain,
              "<br>",
              "Avg MFE: " + context.SplitExitPeformaceCal.avgMFE ,
              "Avg MAE: " + context.SplitExitPeformaceCal.avgMAE , 
              "Avg MFE / Avg MAE -> " + context.SplitExitPeformaceCal.aefeRatio + ":1",
              "<br>",
              "Tot Dur: " + context.SplitExitPeformaceCal.totalDuration + "d " +  context.SplitExitPeformaceCal.totalDurationInHours + "h",
              "Avg Dur: " + context.SplitExitPeformaceCal.avgDuration  +"d " +  context.SplitExitPeformaceCal.averageDurationInHours + "h",
              "Min Dur: " + context.SplitExitPeformaceCal.shortestDuration  + "d " +  context.SplitExitPeformaceCal.shortestDurationInHours + "h",
              "Max Dur: " + context.SplitExitPeformaceCal.longestDuration  + "d " +  context.SplitExitPeformaceCal.longestDurationInHours + "h"            
            ],
          }
      ]

      context.SplitExitPeformaceCalEnterVal = {
        mktValCap : context.SplitExitPeformaceCal.mktValCap,
        netGain : context.SplitExitPeformaceCal.netGain,
        avgGain : context.SplitExitPeformaceCal.avgGain,
        avgWin : context.SplitExitPeformaceCal.avgWin,
        avgLoss : context.SplitExitPeformaceCal.avgLoss,
        wlRatio : context.SplitExitPeformaceCal.wlRatio,
        winRate : context.SplitExitPeformaceCal.winRate,
        totalSignals : context.SplitExitPeformaceCal.totalSignals,
        maxGain : context.SplitExitPeformaceCal.maxGain,
        minGain : context.SplitExitPeformaceCal.minGain,
        avgMFE : context.SplitExitPeformaceCal.avgMFE,
        avgMAE : context.SplitExitPeformaceCal.avgMAE,
        aefeRatio : context.SplitExitPeformaceCal.aefeRatio,
        totalDuration :context.SplitExitPeformaceCal.totalDuration,
        totalDurationInHours : context.SplitExitPeformaceCal.totalDurationInHours,
        avgDuratio : context.SplitExitPeformaceCal.avgDuratio,
        averageDurationInHours : context.SplitExitPeformaceCal.averageDurationInHours,
        shortestDuration : context.SplitExitPeformaceCal.shortestDuration,
        shortestDurationInHours : context.SplitExitPeformaceCal.shortestDurationInHours,
        longestDuration :context.SplitExitPeformaceCal.longestDuration,
        longestDurationInHours : context.SplitExitPeformaceCal.longestDurationInHours,
        name : context.SplitExitPeformaceCal.name,
        signal : context.SplitExitPeformaceCal.signal,
        signalPrice : context.Data[0].Close,
        tradePnL : context.SplitExitPeformaceCal.tradePnL,
        tickerAlgosignals : context.SplitExitPeformaceCal.tickerAlgosignals,
        periodAlgosignals : context.SplitExitPeformaceCal.periodAlgosignals,
        timeAlgosignals : context.Data[0].Time,
        UseCoreLogic : context.SplitExitPeformaceCal.UseCoreLogic,
        UseStructureLogic : context.SplitExitPeformaceCal.UseStructureLogic,
        UseHighLow : context.SplitExitPeformaceCal.UseHighLow,
        RevOnExit : context.SplitExitPeformaceCal.RevOnExit,
        Fast : context.SplitExitPeformaceCal.fast,
        Slow : context.SplitExitPeformaceCal.slow,
        Smooth : context.SplitExitPeformaceCal.smooth,
        MktValCapThresh : context.SplitExitPeformaceCal.MktValCapThresh,
        PiPThresh : context.SplitExitPeformaceCal.PiPThresh,
        TickThresh : context.SplitExitPeformaceCal.TickThresh,
        ThreshMult : context.SplitExitPeformaceCal.ThreshMult,
        AvgBarsPerSignal : context.SplitExitPeformaceCal.AvgBarsPerSignal


      }

      window.parent.LastSimpleSignal[window.parent.LastSimpleSignalIndex] = context.SplitExitPeformaceCalEnterVal;
      window.parent.LastSimpleSignalIndex += 1;

      //#region Performance Calcuations storage Object and Array for DB version
      if (window.parent.DBsignal === true) {//true
        context.PerformaceCalc[context.PerformaceCalcIndex] = context.SplitExitPeformaceCalEnterVal;
        context.PerformaceCalcIndex += 1;
      }
      //#endregion Performance Calcuations storage Object and Array for DB version

      //#region Script for automated testing
      if(window.parent.DBsignal === false) {
        context.automatedTesting[context.automatedTestingIndex] = context.SplitExitPeformaceCalEnterVal;
        context.automatedTestingIndex += 1;
        }
      //#endregion Script for automated testing

      if(window.parent.DBsignal === false) {

          getmarks.createMarksOnChart(Arr);
          timescalemarks.createTimeScaleMarksOnChart(TimeScale);
          // createTextOnChart(window.parent.tvWidget,context.Data[0].Time,context.Data[0].price, context.Data[0].Close.toFixed(2));          
      
      }          
      context.firstEntryAfterSplit = false
    }
    //#endregion Handle Split Exits

}
export {enterLong};