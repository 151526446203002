import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger"
import rootReducer from "./reducer";
import setAuthToken from "../src/services/setAuthToken";
import { get_user } from "./actions/auth.action";
import {setCurrentUser,logout} from '../src/actions/auth.action';
const jwt = require('jsonwebtoken')
const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk ))
);

if(localStorage.jwtToken){
  setAuthToken(localStorage.jwtToken)
  store.dispatch(get_user())
}

export default store;
