import refreshMarks from '../library_Utils/refreshMarks';

/**
 * onSymbolChanged calls when we change the symbol from the chart. Like from AAPL to BIDU. 
 * @param {*} tvWidget 
 * @function onSymbolChanged
 */

const onSymbolChanged = (tvWidget) => {
    //#region Subscribe to the onSymbolChanged event - triggers when the symbol changed on the chart like from AAPL to AA
    tvWidget.chart().onSymbolChanged().subscribe(null, () => {
        
        //#region Empty the array as another instance of indicator is loaded then we have the new set of array
            refreshMarks(tvWidget);
            for (let i = 0; i <= window.parent.textShapeID.length - 1 ; i++){
                tvWidget.chart().removeEntity(window.parent.textShapeID[i])
            }
            for (let j = 0; j <= window.parent.arrowShapeID.length - 1; j++){
                tvWidget.chart().removeEntity(window.parent.arrowShapeID[j]);
            }
            for (let k = 0; k <= window.parent.circleShapeID.length - 1; k++){
                tvWidget.chart().removeEntity(window.parent.circleShapeID[k]);
            }
            window.parent.textShapeID.length = 0;
            window.parent.arrowShapeID.length = 0;
            window.parent.circleShapeID.length = 0;
        //#endregion Empty the array as another instance of indicator is loaded then we have the new set of array
        
        console.log('The symbol is changed');
        
        //#region Removing the performace calcuation from the local storage as when the symbol is changed we have the new data and the performace calculation array should also updated so are are removing the previous values
            window.localStorage.removeItem('Performance_Calculations');
        //#endregion Removing the performace calcuation from the local storage as when the symbol is changed we have the new data and the performace calculation array should also updated so are are removing the previous values
    
    });
    //#endregion Subscribe to the onSymbolChanged event - triggers when the symbol changed on the chart like from AAPL to AA
  }
  export default onSymbolChanged;