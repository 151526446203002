/**
 * createMultipointShape is a Charting library Api, creates different shapes on the chart.
 * Here we are using arrow_up to plot the arrow on the chart.
 * This method **getShapeById** is responsible for creating/Manging the custom shapes on the chart.
 * @param {*} tvWidget 
 * @param {*} time 
 * @param {*} price 
 * @param {*} arrow 
 * @function createArrowOnChart
 */


const createArrowOnChart = (tvWidget,time,price,arrow) => {
  window.parent.arrow = true;
    var color_ = arrow === "arrow_up" ? "#008000" : "#92001a";

    //#region Arrow creation process
      var shapeId=  tvWidget.chart().createMultipointShape([{time: time / 1000, price:price}],  {shape: arrow,lock: true,
        //#region Override the default properties of the shape the charting library have provide some more properties so we can customize them
          overrides: {
          color:color_,
          // disableSave:true,
          // disableUndo: true,
          zOrder: 'bottom',
          size: 5,
        }
        //#endregion Override the default properties of the shape the charting library have provide some more properties so we can customize them
      });

      tvWidget.chart().getShapeById(shapeId);//.setProperties({ arrow: arrow });
    //#endregion Arrow creation process
    
    //#region To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them
      window.parent.arrowShapeID[window.parent.arrowShapeID_Index] = shapeId;
      window.parent.arrowShapeID_Index += 1;
    //#endregion To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them
}
export default createArrowOnChart;

