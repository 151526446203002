/**
 * @param {*} barsAgo 
 * @param {*} instance 
 * @param {*} lookBackPeriod 
 * @param {*} Strength 
 * @param {*} context 
 * @function swingLowSwings
 */
const SwingLowBar = (barsAgo, instance, lookBackPeriod, Strength, context) => {
 
    for (var idx = context.CurrentBar - barsAgo - Strength; idx >= context.CurrentBar - barsAgo - Strength - lookBackPeriod; idx--) {
            if (idx < 0)
                return -1;
            
                if (idx >= context.swingLowSwings.length)
                continue;

            if (context.swingLowSwings[idx] == 0.0)
                continue;

            if (instance == 1) // 1-based, < to be save
                return context.CurrentBar - idx;

            instance--;
    }
    return -1;    
}
export {SwingLowBar};