/* eslint-disable no-unused-expressions */

import TimeSpan from '../TimeSpan/TimeSpan';
import inversion from "./inversion";
import { enterShort, enterLong, exitLong, exitShort, iexitShort } from '../Helper_Methods/Middleware';
var dateFormat = require('dateformat');
const SignalSide = {
    SignalDirectionFilter : {
        Long: "Long",
        Short:"Short",
        Both:"Both"
    }
  }
let SignalDirection = SignalSide.SignalDirectionFilter.Both;
/**
 * @param {*} context 
 * @param {*} fast 
 * @param {*} slow 
 * @param {*} smooth 
 * @function algosignalLogic -> contains the core logic for "Algosignals" indicator
 */
export default function algosignalLogic(context,fast,slow,smooth){
//  console.log("Split Date : ",new Date(context.splitDate[1]), new Date(context.splitDate[1]).getTime(), "Time : ", new Date(context.Data[0].Time), context.Data[0].Time, "context.longSig  :", context.longSig , "context.shortSig :", context.shortSig)
 //#region splitExits
 //((context.splitDate[i].splitDate)).getTime()
 //24 Hours  = 86400000 milliseconds
  for (let i = 0; i <= context.splitDate.length - 1; i++){
    let splitMilliseconds;
    if(context.dayName[i] == 'Mon'){
      splitMilliseconds = 284400000;
    }
    else if(context.dayName[i] == 'Sun')
    {
      splitMilliseconds = 212400000;
    }
    else if(context.dayName[i] == 'Sat')
    {
      splitMilliseconds = 126000000;
    }
    else{splitMilliseconds = 25200000}
    context.splitDateInMilliseconds = new Date((new Date(
      new Date(context.splitDate[i]).toLocaleString("en-US", {
        timeZone: "America/New_York",
      })).setHours(40))
    )
    // console.log("context.splitDateInMilliseconds : ",context.splitDateInMilliseconds,context.splitDateInMilliseconds.getTime(),context.splitDateInMilliseconds.getTime() - splitMilliseconds , 
    // "context.Data[0].Time :", new Date(context.Data[0].Time), context.Data[0].Time)
    if (context.longSig === true && context.Data[0].Time === (context.splitDateInMilliseconds.getTime() - splitMilliseconds)) {
      console.log("LongSig", "CT DAte : ", dateFormat(new Date(context.Data[0].Time)), context.Data[0].Time, "SplitDate :", dateFormat((new Date(context.splitDate[i]).getTime() - 370800000) ), (new Date(context.splitDate[i]).getTime() - 370800000) )
      context.splitExit = true;
        exitLong(context,fast,slow,smooth,SignalSide);
      // context.splitExit = false;
      // context.firstEntryAfterSplit = true;
    }
    if (context.shortSig === true && context.Data[0].Time === (context.splitDateInMilliseconds.getTime() - splitMilliseconds)) {
      console.log("Shortsig", "CT DAte : ", dateFormat(new Date(context.Data[0].Time)), context.Data[0].Time, "SplitDate :", dateFormat((new Date(context.splitDate[i]).getTime() - 370800000) ), (new Date(context.splitDate[i]).getTime() - 370800000) )
      context.splitExit = true;
        exitShort(context,fast,slow,smooth,SignalSide);
      // context.splitExit = false;
      // context.firstEntryAfterSplit = true;
    } 
  }

 //#endregion 

 //#region Exits
//Exit Long Signals	
    if (context.longSig === true && ((!context.invertSignals && context.UseCoreLogic && (context.Data[0].Default < context.Data[1].Default) && context.Data[0].Default < context.Data[0].Smoothed) || (!context.UseCoreLogic)) && 
    ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == 1) || (!context.invertStructure && context.Data[0].trendSignal == -1))) || (!context.UseStructureLogic)))//&&
    //((context.UsePd2 === true && context.bip1longSig === false) || context.UsePd2 === false) &&((context.UsePd3 === true && context.bip2longSig === false) || context.UsePd3 === false))
    {	
      exitLong(context,fast,slow,smooth,SignalSide)
        if(context.RevOnExit){enterShort(context);}
    }
  
//Exit Inverted Long Signals -> exitShort()
  if (context.shortSig === true && ((context.invertSignals && context.UseCoreLogic && (context.Data[0].Default < context.Data[1].Default) && context.Data[0].Default < context.Data[0].Smoothed) || (!context.UseCoreLogic)) && 
  ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == -1) || (!context.invertStructure && context.Data[0].trendSignal == 1))) || (!context.UseStructureLogic)))
  //&&  ((context.UsePd2 === true && context.bip1shortSig === false) || context.UsePd2 === false) &&((context.UsePd3 === true && context.bip2shortSig === false) || context.UsePd3 === false))
  {	
    
    exitShort(context,fast,slow,smooth,SignalSide);
    if(context.RevOnExit){enterLong(context);}
  }
  //Exit Short Signals
  if (context.shortSig === true && ((!context.invertSignals && context.UseCoreLogic && (context.Data[0].Default > context.Data[1].Default) && context.Data[0].Default > context.Data[0].Smoothed) || (!context.UseCoreLogic)) && 
    ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == -1) || (!context.invertStructure && context.Data[0].trendSignal == 1))) || (!context.UseStructureLogic)))   
   // && ((context.UsePd2 === true && context.bip1shortSig === false) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2shortSig === false) || this.UsePd3 === false))
  {	
   
  exitShort(context,fast,slow,smooth,SignalSide);
    if(context.RevOnExit){enterLong(context);}
  }
  if (context.longSig === true && ((context.invertSignals  && context.UseCoreLogic && (context.Data[0].Default > context.Data[1].Default) && context.Data[0].Default > context.Data[0].Smoothed) || (!context.UseCoreLogic)) && 
    ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == 1) || (!context.invertStructure && context.Data[0].trendSignal == -1))) || (!context.UseStructureLogic))) //&&
    // ((context.UsePd2 === true && context.bip1longSig === false) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2longSig === false) || context.UsePd3 === false))
  {	
    
    exitLong(context,fast,slow,smooth,SignalSide) 
    if(context.RevOnExit){enterShort(context);}
  }
  //#endregion Exits               
  
 //#region Entries
  if(context.longSig === false && !context.skipLngEntry  && (SignalDirection === SignalSide.SignalDirectionFilter.Long || SignalDirection === SignalSide.SignalDirectionFilter.Both) &&
  ((!context.invertSignals && context.UseCoreLogic && ((context.Data[0].ADXY_Esignal  === 1 && (context.Data[0].PPO_Esignal === 1 || context.Data[1].PPO_Esignal === 1 || context.Data[2].PPO_Esignal === 1 ) &&context.longSig === false) ||
  (context.Data[0].ADXY_Esignal === -1 && (context.Data[0].PPO_Esignal === 1 || context.Data[1].PPO_Esignal === 1 || context.Data[2].PPO_Esignal === 1 ))) && (context.Data[0].DiPlus > context.Data[1].DiPlus) && (context.Data[0].DiMinus < context.Data[1].DiMinus)) || (!context.UseCoreLogic)) &&
  ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == -1) || (!context.invertStructure && context.Data[0].trendSignal == 1))) || (!context.UseStructureLogic)))// &&
  //((context.UsePd2 === true && context.bip1longSig === true) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2longSig === true) || context.UsePd3 === false))
  {					
  
  if(context.OppSigExit && context.shortSig){
    
    exitShort(context,fast,slow,smooth,SignalSide); 
    context.reversal = true;}
  enterLong(context); 
  }
  //Inverted Long Signals -> enterShort()
  else if(context.shortSig === false && !context.skipShtEntry  && (SignalDirection === SignalSide.SignalDirectionFilter.Long || SignalDirection === SignalSide.SignalDirectionFilter.Both)&&  
  ((context.invertSignals && context.UseCoreLogic && ((context.Data[0].ADXY_Esignal === 1 && (context.Data[0].PPO_Esignal === 1 || context.Data[1].PPO_Esignal === 1 || context.Data[2].PPO_Esignal === 1 ) && context.shortSig === false) ||
  (context.Data[0].ADXY_Esignal === -1 && (context.Data[0].PPO_Esignal === 1 || context.Data[1].PPO_Esignal === 1 || context.Data[2].PPO_Esignal === 1 ))) && (context.Data[0].DiPlus > context.Data[1].DiPlus) && (context.Data[0].DiMinus < context.Data[1].DiMinus)) || (!context.UseCoreLogic)) &&
  ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == 1) || (!context.invertStructure && context.Data[0].trendSignal == -1))) || (!context.UseStructureLogic)))// &&    
  //((context.UsePd2 === true && context.bip1shortSig === true) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2shortSig === true) || context.UsePd3 === false))
  {			
                                  
  if(context.OppSigExit && context.longSig)
  {
  
  exitLong(context,fast,slow,smooth,SignalSide)
  context.reversal = true;
  }
  enterShort(context); 
  }	
    //Short Signal
  if(context.shortSig === false && !context.skipShtEntry  && (SignalDirection === SignalSide.SignalDirectionFilter.Short || SignalDirection === SignalSide.SignalDirectionFilter.Both) &&
  ((!context.invertSignals && context.UseCoreLogic && ((context.Data[0].ADXY_Esignal === 1 && (context.Data[0].PPO_Esignal === -1 ||context.Data[1].PPO_Esignal === -1 || context.Data[2].PPO_Esignal === -1 ) && context.shortSig === false) ||
  (context.Data[0].ADXY_Esignal === -1 && (context.Data[0].PPO_Esignal === -1 || context.Data[1].PPO_Esignal === -1 || context.Data[2].PPO_Esignal === -1 ))) && (context.Data[0].DiPlus < context.Data[1].DiPlus) && (context.Data[0].DiMinus > context.Data[1].DiMinus)) || (!context.UseCoreLogic)) &&
  ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == 1) || (!context.invertStructure && context.Data[0].trendSignal == -1))) || (!context.UseStructureLogic)))// &&
  //((context.UsePd2 === true && context.bip1shortSig === true) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2shortSig === true) || context.UsePd3 === false))
  {			
  
  if(context.OppSigExit && context.longSig){
     
    exitLong(context,fast,slow,smooth,SignalSide)
    context.reversal = true;}
  enterShort(context); 
  }
  //Inverted Short Signal -> enterLong()
  else if(context.longSig === false && !context.skipLngEntry  && (SignalDirection === SignalSide.SignalDirectionFilter.Short || SignalDirection === SignalSide.SignalDirectionFilter.Both) &&
  ((context.invertSignals && context.UseCoreLogic && ((context.Data[0].ADXY_Esignal === 1 && (context.Data[0].PPO_Esignal === -1 || context.Data[1].PPO_Esignal === -1 || context.Data[2].PPO_Esignal === -1 ) && context.longSig === false) ||
  (context.Data[0].ADXY_Esignal === -1 && (context.Data[0].PPO_Esignal === -1 || context.Data[1].PPO_Esignal === -1 || context.Data[2].PPO_Esignal === -1 ))) && (context.Data[0].DiPlus < context.Data[1].DiPlus) && (context.Data[0].DiMinus > context.Data[1].DiMinus)) || (!context.UseCoreLogic)) &&
  ((context.UseStructureLogic && ((context.invertStructure && context.Data[0].trendSignal == -1) || (!context.invertStructure && context.Data[0].trendSignal == 1))) || (!context.UseStructureLogic)))// &&
  //((context.UsePd2 === true && context.bip1longSig === true) || context.UsePd2 === false) && ((context.UsePd3 === true && context.bip2longSig === true) || context.UsePd3 === false))
  {			
  
  if(context.OppSigExit && context.shortSig){
     
    exitShort(context,fast,slow,smooth,SignalSide);
    context.reversal = true;}
  enterLong(context); 
  }
  
  //#endregion Entries     
  
  /***********Reset Entry Variables	 *** */
  context.skipLngEntry = false; 				//This is used to prevent an Exit Signal & and Entry Signal Evaluating on the Same Bar; i.e. don't exit a long and enter a long on the same bar. 
  context.skipShtEntry = false;
  //#region Print Performance
  /**********************       Print Performance	        ********/
  //            if(context.symbol.info.type == "stock")
  //            {
  //              if(context.exitLong == true || context.exitShort == true){
  //                console.log("Close : ", context.Data[0].Close)
  //                console.log("Market Value Captured: " + context.mktValCap.toFixed(2) + "% (Benchmark)");
  //                console.log("Net Gain : " + context.netGain.toFixed(2) ," | Avg Gain/Signal: " + context.avgGain.toFixed(2));
  //                console.log("Avg Win : " + context.avgWin.toFixed(2) , " | Avg Loss: " + context.avgLoss.toFixed(2) + " -> " + context.wlRatio.toFixed(2) + ":1" );
  //                console.log("Win Rate : " + context.winRate.toFixed(5) * 100 + "%", "/" + context.totalSignals + " Signals");
  //                console.log("Max Winner : " + context.maxGain.toFixed(2)  + " / Max Loser: " + context.minGain.toFixed(2));
  //                console.log("Average MFE : " + context.avgMFE.toFixed(2) + " | Avg MAE: " + context.avgMAE.toFixed(2) + " -> " + context.aefeRatio.toFixed(1) + ":1" );
  //                console.log("-------------------------------------------------------------------");

  //              }
                
  //  }
  
  //            else if(context.symbol.info.type != "stock")
  //            {
  //               // console.log("Net Gain : " + context.netGain ,"Avg Gain/Signal: " + context.avgGain);
  //               // console.log("Avg Win : " + context.avgWin , " | Avg Loss: " + context.avgLoss + " -> " + context.wlRatio + ":1" );
  //               // console.log("Win Rate : " + context.winRate, "/" + context.totalSignals + "Signals");
  //               // console.log("Max Winner : " + context.maxGain  + " / Max Loser: " + context.minGain);
  //               // console.log("Average MFE : " + context.avgMFE + " | Avg MAE: " + context.avgMAE + " -> " + context.aefeRatio + ":1" );
  
  //            }
  
  
  //#endregion Print Performance
  
  /******************               	 Set Dataseries Values        **************** */
  context.totsigSeries[0] = (context.totalSignals); 	//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
  context.wrateSeries[0] = (context.winRate); 			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0
  context.gainSeries[0] = (context.netGain);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
  context.avgGainSeries[0] = (context.avgGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
  context.maxGainSeries[0] = (context.maxGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0				
  context.maxLossSeries[0] = (context.minGain);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
  context.avgWinSeries[0] = (context.avgWin);  			//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0	
  context.avgLossSeries[0] = (context.avgLoss);  		//must set this on each bar to reflect the current gain value at the close of each bar. Otherwise gainSeries will = 0                                            
  if(context.longSig || context.shortSig){context.barsSinceSignal = context.CurrentBar - context.sigBar;}
  else if(!context.longSig || !context.shortSig) {context.barsSinceSignal = 0;}
  context.barsPastSignal[0] = (context.barsSinceSignal);
  inversion(context,fast,slow,smooth);
}
 