
// api/stream.js
import historyProvider from "./historyProvider.js";
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../config/links.config'
var W3CWebSocket = require('websocket').w3cwebsocket
const ccStreamer = new W3CWebSocket('wss://ws.tradier.com/v1/markets/events');
// const socket = new W3CWebSocket('wss://ws.tradier.com/v1/markets/events');
// const socket = new WebSocket('wss://ws.tradier.com/v1/markets/events');
let axios = require('axios')
const config = require('../../../config/keys.config');

let g_last_price = 0;

setInterval(function() {
  if (g_last_price === 0) return null;
  // store.dispatch(update_near_value(g_last_price));
}, 10000);

// keep track of subscriptions
var _subs = [];


export default {
  subscribeBars: async function(symbolInfo, resolution, updateCb, uid, resetCache) {
    const channelString = symbolInfo.name;
    g_last_price=0
   
    const conf =  await config();
    const tradier_config = conf.tradierToken;
    await axios({
      method: 'post',
      url: 'https://api.tradier.com/v1/markets/events/session',
      form: {
        
      },
      headers: {
        'Authorization': `Bearer ${tradier_config}`,
        'Accept': 'application/json'
      }
    }).then(response => {
      // console.log(response.data.stream.sessionid)
      let streamData = {
        "symbols": [symbolInfo.name], 
        "sessionid": response.data.stream.sessionid, 
        "linebreak": true
      }
      console.log('Connected, sending subscription commands...');
      ccStreamer.send(JSON.stringify(streamData));
    });

  

    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb
    };
    newSub.channelString = newSub.channelString.replace("*", '')
    _subs.push(newSub);
  },
  unsubscribeBars: function(uid) {
    var subIndex = _subs.findIndex(e => e.uid === uid);
    if (subIndex === -1) {
      console.log("No subscription found for ", uid);
      return;
    }
    var sub = _subs[subIndex]
    _subs.splice(subIndex, 1);
    // let streamData = {
    //   "symbols": [sub.channelString], 
    //   "sessionid": "", 
    //   "linebreak": true
    // }
    // console.log('Connected, sending subscription commands...');
    // ccStreamer.send(JSON.stringify(streamData));
  }
};

// Take a single trade, and subscription record, return updated bar
ccStreamer.onopen = () => {
  console.log('WebSocket Client Connected');

};
ccStreamer.onmessage = (data) => {

  let obj = JSON.parse(data.data);
  console.log("Live data from Tradier",obj);

  const dataFromTradier = {
    sub_type: obj.symbol,
    exchange: obj.exch,
    ts: parseInt(obj.date) ,
    volume: parseFloat(obj.cvol),
    price: parseFloat(obj.price)
   }
  //  console.log("dataFromTradier",dataFromTradier);

   const channelString = dataFromTradier.sub_type;

   const sub = _subs.find(e => e.channelString == channelString);
//  console.log( "channelString : ", channelString, "sub : ", sub)
   if (sub) {
     // disregard the initial catchup snapshot of trades for already closed candles
     if (dataFromTradier.ts < sub.lastBar.time / 1000) {
       return console.log(
         " disregard the initial catchup snapshot of trades for already closed candles"
       );
     }
     if(obj.type == "trade"){
     var _lastBar = updateBar(dataFromTradier, sub);
     g_last_price = _lastBar.close;
     // store.dispatch(update_near_value(_lastBar.close))
 
     // send the most recent bar back to TV's realtimeUpdate callback
 
     sub.listener(_lastBar);
     // update our own record of lastBar
     sub.lastBar = _lastBar;
    //  console.log("_lastBar :", _lastBar)
   }
}

};

function updateBar(data, sub) {
  sub.lastBar.volume = data.volume 
  var lastBar = sub.lastBar;
  // console.log("lastBar ;", lastBar)

  let resolution = sub.resolution;
  if (resolution.includes("D")) {
    // 1 day in minutes === 1440
    resolution = 1440;
  } else if (resolution.includes("W")) {
    // 1 week in minutes === 10080
    resolution = 10080;
  }
  var coeff = resolution * 60;
  var rounded = Math.floor(data.ts / 1000 / coeff) * coeff;
  var lastBarSec = lastBar.time / 1000;
  var _lastBar;

  if (rounded > lastBarSec) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: rounded * 1000,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: data.volume
    };
    console.log("Socket Data _lastBar :", _lastBar);
  } else {
    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }

    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar = lastBar;
  }
  // console.log( "Data :", data)

  return _lastBar;
}
