const initial_state = {
  _id: "",
  is_Authenticated: false,
  is_approved: false,
  is_verified: false,
  is_completed: false,
  is_tradier_linked: false,
  username: "",
  password: "",
  first_name: "",
  last_name: "",
  email: "",
  account_type: "",
  tradier_ID: "",
  tradier_access_token: "",
  tradier_accounts: [],
  selected_tradier_account: ""
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
        is_Authenticated: true,
        selected_tradier_account: action.payload.tradier_accounts.length
          ? action.payload.tradier_accounts[0].account_number
          : ""
      };
    case "ON_INPUT_CHANGE":
      return { ...state, [action.payload.name]: action.payload.value };
    case "SELECT_ACCOUNT":
      return { ...state, selected_tradier_account: action.payload };
    case "LOGOUT":
      return { ...initial_state };
    default:
      return state;
  }
};
