import AlgoSWING from '../IndicatorLogic/algoSWING';
import AlgoAdxy from '../IndicatorLogic/algoadxy';
import AlgoPPOY from '../IndicatorLogic/algoPPOY';
import algosignalLogic from '../IndicatorLogic/algoSignal';
let algoPPOYObject = new AlgoPPOY();
let algoswingObject = new AlgoSWING();
let algoAdxyObject = new AlgoAdxy();
let sliceOne = 1 + "/" + 1 + "/" + 2010;//1 + "/" + 1 + "/" + 2010;
let sliceTwo = 1 + "/" + 1 + "/" + 2015;
let sliceThree = 1 + "/" + 1 + "/" + 2010;
var dateFormat = require('dateformat');
/**
 * @object algosignal -> algosignal indicator object
 * @object metaInfo -> Contains the info about the indicator like "inputs", "Plots", "name", "description", 
 * "Plot styles", "defaults".
 * @property is_hidden_study -> set to true means hide from the list of indicators on the chart and false means
 *  include it in the list of indicators on the chart.
 * @property is_price_study -> true means plot the indicator on the main chart bars. false means plot the 
 * indicator in a seperate window on the chart.
 * @property shortDescription -> is the name of the indicator you see on the chart.
 * @property id -> is what the charting linbaray used to call the custom indicator. 
 * Actually when the indicator load on the chart the charting library creates a unique id for an indicator 
 * which we can get through like below.
 * @function constructor
 * @function this.init called once when the indicator loads on the chart.
 * @function this.main indicator logic as an indicator written in "main" function . Calls on each Bar.
 * @DynamicSymbolHandler ((PineJS.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
 */
export const algosignal ={

    name: "_Algopti\xAE Signal Engine",
    //#region contains the info about the custom indicator.
    metainfo: {
        "_metainfoVersion": 40,
        /** id is what the charting linbaray used to call the custom indicator. Actually when the indicator
         * load on the chart the charting library creates a unique id for an indicator.
         */ 
        "id": "_Algopti\xAE Signal Engine@tv-basicstudies-1",
        "scriptIdPart": "",
        "name": "_Algopti\xAE Signal Engine",
        // This description will be displayed in the Indicators window
        // It is also used as a "name" argument when calling the createStudy method
        "description": "_Algopti\xAE Signal Engine",
        //shortDescription is the name of the indicator you see on the chart.This description will be displayed on the chart
        "shortDescription": "_Algopti\xAE Signal Engine",
        //is_hidden_study **ture** means the indicator is hidden on the chart we cannot see the indicator in the indicators menu.
        "is_hidden_study": false,
        //is_price_study is applicable when the use the plots from the meta info. And what is does is, it plots the indicator in a seperate window.
        "is_price_study": true,
        "isCustomIndicator": true,
        //Defining the plots for the custom indicator, its *ID* and its *type*.
        "plots": [{"id": "plot_0", "type": "line"}],
        //Default properties for the custom indicator.
        "defaults": {
            "styles": {
                "plot_0": {
                    "linestyle": 0,
                    "visible": true,

                    // Plot line width.
                    "linewidth": 2,

                    // Plot type:
                    //    1 - Histogram
                    //    2 - Line
                    //    3 - Cross
                    //    4 - Area
                    //    5 - Columns
                    //    6 - Circles
                    //    7 - Line With Breaks
                    //    8 - Area With Breaks
                    "plottype": 2,

                    // Show price line?
                    "trackPrice": false,

                    // Plot transparency, in percent.
                    "transparency": 40,

                    // Plot color in #RRGGBB format
                    "color": "#0000FF"
                }
            },
            //Percision is the upto decimal place value want to show on the chart.
            "precision": 2,
            //inputs object, byfefault values assigned to the inputs
            "inputs": {
                "Slice One": 'Slice One',
                "Slice Two": sliceTwo,
                "Start Year" : 2017,
                "ML End Day": 15,
                "ML End Month": 1,
                "ML End Year" : 2020,
                "Period": 14,
                "UseHighLow" : true,
                "UseCoreLogic":true,
                "UseStructureLogic":true,
                "slow": 26,
                "fast": 12,
                "smooth": 9,
                "MktValCapThresh" : -25,
                "PiPThresh" : -500,
                "TickThresh" : -250,
                "ThreshMult" : 0.8,
                "AvgBarsPerSignal" : 53,				
                "RevOnExit" : true
            }
        },
        "styles": {
            "plot_0": {
                // Output name will be displayed in the Style window
                "title": "-- output name --",
                "histogramBase": 0,
            }
        },
        //An array of inputs added as a property to the custom study.Values assign to inputs, intialization and declaration of inputs. Data types are also assigned here
        "inputs": [
            {
                "id":"Slice One",
                "type":"text",
                "name":"Date Range Slices",
                "defval": 'Slice One',
                options: [
                    'Slice One',
                    'Slice Two',
                    'Slice Three',
                ],
            },
            {
                "id":"Slice Two",
                "type":"bool",
                "name":"Slice Two",
                "defval": sliceTwo,
                min : 1,
                max : 12,
                isHidden: true
            },
            {
                "id":"Start Year",
                "type":"integer",
                "name":"Start Year",
                "defval": 2017,
                max : 2020,
                isHidden: true
            },
            {
                "id":"ML End Day",
                "type":"integer",
                "name":"ML End Day",
                "defval": 15,
                min : 1,
                max : 31,
                isHidden: true
            },
            {
                "id":"ML End Month",
                "type":"integer",
                "name":"ML End Month",
                "defval": 1,
                min : 1,
                max : 12,
                isHidden: true
            },
            {
                "id":"ML End Year",
                "type":"integer",
                "name":"ML End Year",
                "defval": 2020,
                max : 2020,
                isHidden: true
            },
            {
                "id":"Period",
                "type":"integer",
                "name":"Algo Period",
                "defval":14
            },
            {
                "id":"UseHighLow",
                "type":"bool",
                "name":"Use High Low",
                "options":['true','false'],
                "defval":"true"
            },
            {
                "id":"UseCoreLogic",
                "type":"bool",
                "name":"Use Core Logic",
                "options":['true','false'],
                "defval":"true"
            },
            {
                "id":"UseStructureLogic",
                "type":"bool",
                "name":"Use Structure Logic",
                "options":['true','false'],
                "defval":"true"
            },
            {
                "id":"slow",
                "type":"integer",
                "name":"Slow",
                "defval":26
            },
            {
                "id":"fast",
                "type":"integer",
                "name":"Fast",
                "defval":12
            },
            {
                "id":"smooth",
                "type":"integer",
                "name":"Smooth",
                "defval":9
            },
            {
                "id":"MktValCapThresh",
                "type":"integer",
                "name":"MktValCapThresh",
                "defval":-25
            },
            {
                "id":"PiPThresh",
                "type":"integer",
                "name":"PiPThresh",
                "defval": -500
            },
            {
                "id":"TickThresh",
                "type":"integer",
                "name":"TickThresh",
                "defval": -250
            },
            {
                "id":"ThreshMult",
                "type":"integer",
                "name":"ThreshMult",
                "defval": 0.8
            },
            {
                "id":"AvgBarsPerSignal",
                "type":"integer",
                "name":"AvgBarsPerSignal",
                "defval": 53
            },
            {
                "id":"RevOnExit",
                "type":"bool",
                "name":"Rev On Exit",
                "options":['true','false'],
                "defval":"true"
            }                        
        ],
    },
    //#endregion contains the info about the custom indicator.

    constructor: function() {
    
        /* eslint-disable no-unused-expressions */

        //#region "init" function called once when the indicator loads on the chart
        this.init = function(context, inputCallback) {

            /*context is an object provided by the charting library and we are using this oject to complete our calculations. 
            we are adding our variables, arrays every thing we need for the calculation in the context object so we can access it anywhere.
            and it helps the script to run properly.*/
            this._context = context;
            //inputCallback call the input from the inputs array
            this._input = inputCallback;
            //Dynamic way to get the symbol that is loaded on the chart. || Dynamic handler for symbol while changing the symbols on the chart.
            var symbol = ((window.parent.pinejs.Std.ticker(this._context)).replace(/[{}]/g, "")).replace(/\"/g, "");
            context.automatedSymbol = symbol;
            //new_sym handle/controls the server-side studies
            this._context.new_sym(symbol, window.parent.pinejs.Std.period(this._context), window.parent.pinejs.Std.period(this._context));
            this._context.CurrentBar = -1;
            if (this._context.Data == undefined)
                this._context.Data = [];
                context.splitDate = [];
                context.dayName = [];
                context.splitDateInMilliseconds;

            //#region Get stocksplits
                if(symbol.charAt(symbol.length - 1) === '*')
                {
                    symbol = symbol.replace('*','')
                    console.log("Symbol : ", symbol)
                    let j = 0;
                    for (var i=0; i < window.parent.getAllstockSplitsData.length; i++) {
                        
                        if (window.parent.getAllstockSplitsData[i].symbol === symbol) {
                            console.log('window.parent.getAllstockSplitsData[i].symbol  :',  window.parent.getAllstockSplitsData[i].symbol , symbol)
                            var d =  new Date((new Date(
                                new Date(window.parent.getAllstockSplitsData[i].splitDate).toLocaleString("en-US", {
                                  timeZone: "America/New_York",
                                })).setHours(40))
                              )
                            context.dayName[j] = d.toString().split(' ')[0];
                            console.log("Day: ",context.dayName[j], "d : ",d);
                            context.splitDate[j] = window.parent.getAllstockSplitsData[i].splitDate
                            console.log(context.splitDate);
                            j++;
                        }
                    }

                }
               
            //#endregion

            //#region Internal Script Variables	

                //#region Swing	Data
                context.upArrPlot = false;
                context.dnArrPlot = false;
                var Strength = 5;
                context.currentSwingHigh = 0;
                context.currentSwingLow = 0;
                context.lastSwingHighValue = 0;
                context.lastSwingLowValue = 0;
                context.saveCurrentBar = -1;
                context.constant = (2 * Strength) + 1;

                context.swingHighSeries = [];
                context.swingHighSwings = [];

                context.swingLowSeries = [];
                context.swingLowSwings = [];


                context.lastHighCache = []; //ArrayList
                context.lastLowCache = []; //ArrayList

                context.SwingHighPlot = [];
                context.SwingLowPlot = [];

                context.SwingHighPlot_Valid = [];
                context.SwingLowPlot_Valid = [];
                //#endregion Swing	Data

                //#region Performance Variables
                            context.lEntryPrice = 0; 
                            context.lExitPrice = 0; 
                            context.sEntryPrice = 0; 
                            context.sExitPrice = 0; 
                            context.tradePnL = 0; 
                            context.netGain = 0; 
                            context.mktValCap = 0; 
                            context.wlRatio = 0;
                            context.aefeRatio = 0; 
                            context.avgGain = 0; 
                            context.minGain = 0;
                            context.maxGain = 0;
                            context.avgWin = 0;	
                            context.avgLoss = 0;
                            context.totWin = 0; 
                            context.totLoss = 0; 			
                            context.winRate = 0; 
                            context.winCount = 0; 
                            context.lossCount = 0; 				
                            context.maxMAE = 0; 
                            context.maxMFE = 0; 
                            context.avgMAE = 0; 
                            context.avgMFE = 0;				
                        
                            context.totalSignals = 0; 
                        
                            context.barsSinceSignal = 0;
                            context.avgBPS = 0; 
                            context.sigBar = 0;
                            context.sigBarIdx = 0; 
                            context.tG = 0;				//tradeGainTally List index counter variable
                            
                            //The following temps are used for Multi-Timeframe Cognitive Optimization
                            context.temp1 = 0;
                            context.temp2 = 0; 
                            context.temp3 = 0;
                            context.temp4 = 0;
                        
                            context.firstTrade;
                            context.openTrade;
                            context.closeTrade; 
                            
                            //In NT8 which is based on .Net 4 I can use the TimeSpan type formats. However, In NT7 (.Net 3.5) TimeSpan formatting features are not available.
                            context.duration = 0; 	//Initilize time span to 0
                            context.avgDuration = 0; 
                            context.longestDuration = 0; 
                            context.shortestDuration = 0;
                            context.totalDuration = 0;		//The total timespan of all signals on the chart
                        
                            context.tradeDuration = [];
                            context.tradeGain = [];			
                            context.tradeGainTally = [];
                            context.winList = [];
                            context.lossList = [];		
                            context.maeList = [];		
                            context.mfeList = [];

                //#endregion Performance Variables

                //#region Text Display Offset Variables	
                            context.txtMult1 = 0; 
                            context.txtMult2 = 0; 
                            context.txtMult3 = 0; 						
                            context.sigCount = 0;
                            
                            context.yPixOffset = 0; 
                            context.yPixOffset2 = 0;
                            context.yOffset = 0; 
                //#endregion Text Display Offset Variables	

                //#region Series<double> Declarations 
                            // context.eSignal;			//eSignal representing the final Signal evaluation 				
                            context.barsPastSignal = [];
                                    
                            //these are used for Cognitive Optimization Referencing
                            context.bip1LngSeries = []; 				
                            context.bip1ShtSeries = []; 
                    
                            context.bip2LngSeries = [];		
                            context.bip2ShtSeries = [];
                            
                            //Used for Strategy to determine Inversion
                            context.invertSeries = [];
                    
                            context.totsigSeries = []; 
                    
                            context.gainSeries = []; 
                            context.wrateSeries = [];							
                            context.maxGainSeries = []; 
                            context.maxLossSeries = []; 
                            context.avgGainSeries = []; 
                            context.avgWinSeries = [];
                            context.avgLossSeries = [];
                            context.maeSeries = [];		//Avg Adverse Excursion of signals
                            context.mfeSeries = [];	
                //#endregion Series<double> Declarations 

                //#region Boolean Values
                context.firstSignal = true; 

                context.longSig = false;		
                context.bip1longSig = false; 					
                context.bip2longSig = false; 					
                context.skipLngEntry = false; 

                context.shortSig = false;		
                context.bip1shortSig = false; 					
                context.bip2shortSig = false; 					
                context.skipShtEntry = false; 

                context.reversal = false; 
                context.OppSigExit = true;  //This was originally a user definable variable, but due to interoperatiblity issues with the AlgoSignalsATO auto trader, this has to always be true. 

                context.cOptimize = true;
                context.invertSignals = false; 
                context.invertStructure = false; 	
                context.doOnce = false; 
                context.initialDelVert = false;
                context.initialDelBSprd = false;
                //#endregion Boolean Values

                //#region List Variables
                    context.mktValCapList = [];
                //#endregion List Variables        

            //#endregion Internal Script Variables

            //#region Declare/Initialize Arrays & Switches
            context.date = 1495630800000; // 
            context.signalArray = [];
            context.marketvaluecapArray = [];
            context.netgainAnalysisArray = [];
            context.signalArrayIndex = 0;
            context.Performance_Calculations_Array = [];
            context.Performance_Calculations_Index = 0;
            context.exist = false;
            context.exitLong = false;
            context.exitShort = false;
            context.splitExit = false;
            context.PerformaceCalc = [];
            context.PerformaceCalcIndex = 0;
            window.parent.LastSimpleSignalIndex = 0
            context.PerformaceCalcCognative = [];
            context.PerformaceCalcCognativeIndex = 0;
            context.firstEntryAfterSplit = false;
            context.SplitExitPeformaceCal = {};
            context.automatedTesting = [];
            context.automatedTestingIndex = 0;
            context.automationSlicetest;
            //#endregion Declare/Initialize Arrays & Switches
        };
        //#endregion "init" function called once when the indicator loads on the chart.

        //#region "main" function called on each bar. The main logic of the custom indicator will be wrote in "this.main"

        this.main = function(context, inputCallback) {

            this._context = context;
            this._input = inputCallback;
            var CurrentBar = this._context.CurrentBar;
            this._context.select_sym(1);
            //By select_sym we can retrieve several "server-side" studies and combine it in one custom study implementation.
            var sigBar = context.sigBar;
            context.exitLong = false;
            context.exitShort = false;

            //Getting the inputs from an array of inputs through inputCallback.
            
            //#region Intialization of variables              
            var SwingStrength = 5;	
            // context.Start_Day = inputCallback(0);
            // console.log("Start Day: ", context.Start_Day)
            // context.Start_Month = inputCallback(1);
            // context.Start_Year = inputCallback(2);
            // context.Start_Day_Month_Year = context.Start_Month + "/" + context.Start_Day + "/" + context.Start_Year;
            //This statement will allow us to use the input in our logic. This is how we access input
            context.Start_Day_Month_Year = inputCallback(0);

            if(context.Start_Day_Month_Year == 'Slice One')
            {
            // console.log("Slice one");
            context.Start_Day_Month_Year = sliceOne;
            // console.log("Start date :", context.Start_Day_Month_Year)
            context.Start_Day_Month_Year = new Date(context.Start_Day_Month_Year).getTime();
            context.Start_Day_Month_Year = context.Start_Day_Month_Year + 32400000;
            context.ML_End_Day = 31;
            context.ML_End_Month = 12;
            context.ML_End_Year = 2014;
            context.automationSlicetest = 'S1';
            }
            if(context.Start_Day_Month_Year == 'Slice Two')
            {
            // console.log("Slice Two")
            context.Start_Day_Month_Year = sliceTwo;
            // console.log("Start date :", context.Start_Day_Month_Year)
            context.Start_Day_Month_Year = new Date(context.Start_Day_Month_Year).getTime();
            context.Start_Day_Month_Year = context.Start_Day_Month_Year + 32400000;
            context.ML_End_Day = 31;
            context.ML_End_Month = 12;
            context.ML_End_Year = 2019;
            context.automationSlicetest = 'S2';
            }
            if(context.Start_Day_Month_Year == 'Slice Three')
            {
            // console.log("Slice Three")
            context.Start_Day_Month_Year = sliceThree;
            // console.log("Start date :", context.Start_Day_Month_Year)
            context.Start_Day_Month_Year = new Date(context.Start_Day_Month_Year).getTime();
            context.Start_Day_Month_Year = context.Start_Day_Month_Year + 32400000;
            context.ML_End_Day = 31;
            context.ML_End_Month = 12;
            context.ML_End_Year = 2019;
            context.automationSlicetest = 'S3';
            }
            
            context.ML_End_Day_Month_Year = context.ML_End_Month + "/" + context.ML_End_Day + "/" +  context.ML_End_Year;
            // console.log("End date: ", context.ML_End_Day_Month_Year)
            context.ML_End_Day_Month_Year = new Date(context.ML_End_Day_Month_Year).getTime();
            context.ML_End_Day_Month_Year = context.ML_End_Day_Month_Year + 32400000;

            var Period = inputCallback(6); 
            context.UseHighLow = inputCallback(7);
            context.UseCoreLogic = inputCallback(8);
            context.UseStructureLogic = inputCallback(9); 
                
            let slow = inputCallback(10);
            let fast = inputCallback(11);
            let smooth = inputCallback(12); 

            context.slow = inputCallback(12);
            context.MktValCapThresh = inputCallback(13);
            context.PiPThresh = inputCallback(14);
            context.TickThresh = inputCallback(15);
            context.ThreshMult = inputCallback(16);
            context.AvgBarsPerSignal = inputCallback(17);				
            context.RevOnExit = inputCallback(18); 

            context.OppSigExit = true; 
            //#endregion Intialization of variables 

            /*context.Data array is an array in which wea re adding our core values used for calculation of custom indicator that are lists in Ninja Trader
            and unshift is an array method to add the lements at the start of an array.*/
            context.Data.unshift({
                High: context.symbol.high || 0,
                Low: context.symbol.low || 0,
                Close: context.symbol.close || 0,
                Open:context.symbol.open || 0,
                dmPlus: 0,
                dmMinus: 0,
                sumDmPlus: 0,
                sumDmMinus: 0,
                sumTr: 0,
                tr: 0,
                Value: 0,
                Index: context.symbol.index,
                plotDirection: 0,
                Time: context.symbol.time,
                Smoothed:0,
                Default: 0,
                Algosignals_eSignal:0,	//eSignal
                PPO_Esignal:0,
                ADXY_Esignal : 0,
                eSignal : 0,
                EMA:0,
                trendSignal: 0,
                DiPlus: 0,
                DiMinus : 0
            });
            
            /*This line indicates/control/manages the custom indicator when it approaches the last bar to stop its calculations. Becauseafter the last bar
            the index for the last bar is going to repeat.*/
            if (context.Data.length < 2  || context.Data[0].Index === context.Data[1].Index )
                return;
                
            this._context.CurrentBar++;

            //Count -> this is the dynamic way to calculate the number of bars that are loaded on the chart against the specific symbol.
            context.Count = context.symbol.script.runner.barsets[0].bars.length;
            if (context.Data[0].Time >= context.Start_Day_Month_Year)
            {
            //Calling the algoswing indicator function to get the required values from the algoswing.
            algoswingObject.algoSwing(SwingStrength, context,CurrentBar);

            //Calling the algoadxy indicator function to get the required values from the algoadxy.
            algoAdxyObject.algoAdxy(context,CurrentBar,Period);

            //Calling the algoppoy indicator function to get the required values from the algoppoy.
            algoPPOYObject.AlgoPPOY(context,CurrentBar,fast,slow,smooth,sigBar,window.parent.pinejs)

            if (this._context.CurrentBar < slow || (!context.UseCoreLogic && !context.UseStructureLogic))
            return;
            // console.log(dateFormat(new Date(context.Data[0].Time)) + " - " + "Close[0] -> " + context.Data[0].Close + " - algosig.TrendSignal[0]: " + context.Data[0].trendSignal + " - CurrentBar: " + context.CurrentBar); 	
                            
            // console.log(dateFormat(new Date(context.Data[0].Time)) + " - " + "Close[0] -> " + context.Data[0].Close + " - adx.ESignal[0] = " + context.Data[0].ADXY_Esignal + " - adx.DiMinus[0] = " + context.Data[0].DiMinus + " - adx.DiPlus[0] = " + context.Data[0].DiPlus); 		
                            
            // console.log(dateFormat(new Date(context.Data[0].Time)) + " - " + "Close[0] -> " + context.Data[0].Close , " - ppo.Default = " + context.Data[0].Default  + " - ppo.ESignal[0] = " + context.Data[0].PPO_Esignal  + " - ppo.Smoothed[0] = " + context.Data[0].Smoothed); 
                            
            // console.log("---------------------------------------------------------------------------------------------------------------");
            // console.log("context.Start_Day_Month_Year", context.Start_Day_Month_Year, dateFormat(new Date(context.Start_Day_Month_Year)),"Date", dateFormat(new Date(context.Data[0].Time)), context.Data[0].Time);
            // console.log(dateFormat(new Date(context.Data[0].Time)),"context.CurrentBar : ",context.CurrentBar, "context.Count : ", context.Count,"Index: ", context.Data[0].Index,"Close: ", context.Data[0].Close);
            // console.log(context.symbol.script.symbols[0].minTick)
            // console.log("context.Data[0].Close :", context.Data[0].Close, "Time : ", new Date(context.Data[0].Time))
            //Starting the indicator from a specific start date.
            
            // if (context.Data[0].Time >= context.Start_Day_Month_Year)
            // {
                //Calling the algoSignals logic to performnace its calculations.
                algosignalLogic(context,fast,slow,smooth);
            }
        }

    //#endregion "main" function called on each bar. The main logic of the custom indicator will be wrote in "this.main"
  
    }
}