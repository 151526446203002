
// api/stream.js
// import historyProvider from './histroyProviderCrypto'
import historyProvider from './historyProvider'
import Axios from 'axios'
import { symbol } from 'prop-types'
import createPositionLine from '../../../chartIndicator_library/library_Utils/createPositionLine'
var axios = require('axios')
// import cryptoCompare from './cryptoComparestream'
// we use Socket.io client to connect to cryptocompare's socket.io stream
var io = require('socket.io-client')
// var socket_url = 'wss://streamer.cryptocompare.com'
var socket_url = 'wss://streamer.cryptocompare.com/v2?api_key=82380ba81cee28c37a94cf37777d10e67762d52634c749cf1342f922a6f421da'
// var socket = io(socket_url)
var lastSignal;
let llBar
var W3CWebSocket = require('websocket').w3cwebsocket
const ccStreamer = new W3CWebSocket(socket_url);
// this is where you paste your api key

// socket.connect(socket_url);
// console.log("Socket : " , socket)
// keep track of subscriptions
var _subs = []
// cryptoCompare()

function getCurrentSignsla(symbol){
   axios({
    method :'get',
    url : "https://eodperformancecalforexcryptoindxcomm.azurewebsites.net/api/cgetCC",
    params:{symbol:symbol},
    headers: { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw" }
  }).then((res)=>{
    console.log("getCC response :", res.data)
    lastSignal = res.data[0]
  }).catch((E)=>{
    console.log(E)
  })
}
export default {
 subscribeBars: function(symbolInfo, resolution, updateCb, uid, resetCache) {
  const channelString = createChannelString(symbolInfo)
  console.log("channelString Upper:", channelString, symbolInfo)

  //#region Current Signals Crypto
  
  getCurrentSignsla(symbolInfo.ticker)

  //#endregion Current Signals Crypto
  // let newChaStr = channelString.split(':')
  // let exchange = newChaStr[0]
  // let symbolPre = newChaStr[1]
  // let postSymbol = symbolPre.split('/')
  // postSymbol = postSymbol[0] + '~' + postSymbol[1]

var subRequest = {
  "action": "SubAdd",
  "subs": [channelString]
  // "subs": ['2~'+exchange+'~'+postSymbol]
};
ccStreamer.send(JSON.stringify(subRequest));
  
  var newSub = {
   channelString,
   uid,
   resolution,
   symbolInfo,
   lastBar: historyProvider.history[symbolInfo.name].lastBar,
   listener: updateCb,
  }

  console.log("newSub cryptoCompare:",newSub)
_subs.push(newSub)
 },
 unsubscribeBars: function(uid) {
   console.log("crypto compare unsubscribeBars called")
  //  var subRequest = {
  //   "action": "SubRemove",
  //   "subs": [channelString]
  // };
  // ccStreamer.send(JSON.stringify(subRequest));
  var subIndex = _subs.findIndex(e => e.uid === uid)
  if (subIndex === -1) {
   console.log("No subscription found for ",uid)
   return
  }
  var sub = _subs[subIndex]
  // socket.emit('SubRemove', {subs: [sub.channelString]})
  // client.send(JSON.stringify({
  //   subs: [channelString]
  // }));
  _subs.splice(subIndex, 1)
 }
}

ccStreamer.onopen = () => {
     console.log('WebSocket Client Connected');

  };
  ccStreamer.onmessage = (e) => {
    console.log("E :", e)
    let obj = JSON.parse(e.data);
    console.log("Received: " , obj);
 if (obj.TYPE === "3") {
  // console.log('Websocket Snapshot load event complete')
  return
 }
 const data = {
  sub_type: 0,
  exchange: obj.M,
  to_sym: obj.TSYM,
  from_sym: obj.FSYM,
  trade_id: obj.ID,
  ts: obj.TS,
  volume: obj.RTS,
  price: obj.P
 }
 console.log("data :",data)
 const channelString = `${data.sub_type}~${data.exchange}~${data.from_sym}~${data.to_sym}`
 console.log("channelString :",channelString)
 const sub = _subs.find(e => e.channelString === channelString)
 console.log("sub :", sub)
 if (sub) {
  // disregard the initial catchup snapshot of trades for already closed candles
  // if (data.ts < sub.lastBar.time / 1000) {
  //   return
  //  }
  
var _lastBar = updateBar(data, sub)
llBar = _lastBar
// send the most recent bar back to TV's realtimeUpdate callback
  sub.listener(_lastBar)
  // update our own record of lastBar
  sub.lastBar = _lastBar
 }
 if(llBar != undefined && (window.parent.LastSimpleSignalForCalculations.signalPrice != undefined || window.parent.LastSimpleSignalForCalculations.length != 0))
createPositionLine(window.parent.tvWidget, llBar, window.parent.LastSimpleSignalForCalculations.signalPrice)
// createPositionLine(window.parent.tvWidget)

 console.log("LAst bar :", llBar,lastSignal , _lastBar)

  };


// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
 var lastBar = sub.lastBar
//  console.log("Update bar Last bar :", lastBar)
 let resolution = sub.resolution
 if (resolution.includes('D')) {
  // 1 day in minutes === 1440
  resolution = 1440
 } else if (resolution.includes('W')) {
  // 1 week in minutes === 10080
  resolution = 10080
 }
var coeff = resolution * 60
 // console.log({coeff})
 var rounded = Math.floor(data.ts / coeff) * coeff
 var lastBarSec = lastBar.time / 1000
 var _lastBar
 
if (rounded > lastBarSec) {
  // create a new candle, use last close as open **PERSONAL CHOICE**
  _lastBar = {
   time: rounded * 1000,
   open: lastBar.close,
   high: lastBar.close,
   low: lastBar.close,
   close: data.price,
   volume: data.volume
  }
  
 } else {
  // update lastBar candle!
  if (data.price < lastBar.low) {
   lastBar.low = data.price
  } else if (data.price > lastBar.high) {
   lastBar.high = data.price
  }
  
  lastBar.volume += data.volume
  lastBar.close = data.price
  _lastBar = lastBar
 }
 return _lastBar
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
  var channel = symbolInfo.name.split(/[:/]/)
  console.log("channel :", channel)
  const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
  console.log("exchage :", exchange)
  const to = channel[2]
  const from = channel[1]
 // subscribe to the CryptoCompare trade channel for the pair and exchange
  return `0~${exchange}~${from}~${to}`
}
