/**
 * TimescaleMarks : Plot the callouts on the chart below the bars. And we can print some required info
 * in this
 * @class GetTimeScaleMarks
 * @function createTimeScaleMarksOnChart
 */
export default class GetTimeScaleMarks{
  createTimeScaleMarksOnChart = function(Arr) {
    //#region Function responsible to create the callouts below the Bars
      window.parent.storeCallBackTimeScaleFunction(Arr); 
    //#endregion Function responsible to create the callouts below the Bars             
  }
} 