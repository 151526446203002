

import axios from "axios";

const set_tradier_account = account => {
  // console.log(account)
  if (account) {
    axios.defaults.headers.common["tradier_account"] = account;
  } else {
    delete axios.defaults.headers.common["tradier_account"];
  }
};

export default set_tradier_account;
