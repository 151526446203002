/**
 * An api that pushes the performance calculation to the MongoDB. Here we are converting date, adding milliseconds to change the timespan 
 * from 9:00 AM to 4PM->16:00 PM. This api is hitting the endPoint of TviApi Package.
 */ 
import {postSignals} from '../config/middleware.links';
const axios = require("axios");
let algosignals = "algosignals";
var dateFormat = require('dateformat');
const { token } = require('../middleware/getToken');
let mlr;
let mlr_desc;

//#region Playground Reference URLs
    //'http://localhost:4000/algoSignals/postSignals'
    //http://tviapi.algotechapis.com/algosignals/searchSymbol'
    //http://tviapi.algotechapis.com/algosignals/postSignals
    // const apiURL = 'http://localhost:4000/algoSignals/postSignals'
//#endregion

//#region Reference Parameters / Explanations
/**
     * We are using this endpoint to push the optimization calculations to the DB.
     * We are hitting endpoint of the "tviapi" package
     * @param {*} name 
     * @param {*} signal 
     * @param {*} signalPrice 
     * @param {*} mktValCap 
     * @param {*} netGain 
     * @param {*} avgGain 
     * @param {*} avgWin 
     * @param {*} avgLoss 
     * @param {*} wlRatio 
     * @param {*} winRate 
     * @param {*} totalSignals 
     * @param {*} maxGain 
     * @param {*} minGain 
     * @param {*} avgMFE 
     * @param {*} avgMAE 
     * @param {*} aefeRatio 
     * @param {*} totalDuration 
     * @param {*} totalDurationInHours 
     * @param {*} avgDuratio 
     * @param {*} averageDurationInHours 
     * @param {*} shortestDuration 
     * @param {*} shortestDurationInHours 
     * @param {*} longestDuration 
     * @param {*} longestDurationInHours 
     * @param {*} tickerAlgosignals 
     * @param {*} periodAlgosignals 
     * @param {*} timeAlgosignals 
     * @param {*} UseCoreLogic 
     * @param {*} UseStructureLogic 
     * @param {*} UseHighLow 
     * @param {*} RevOnExit 
     * @param {*} Fast 
     * @param {*} Slow 
     * @param {*} Smooth 
     * @param {*} MktValCapThresh 
     * @param {*} PiPThresh 
     * @param {*} TickThresh 
     * @param {*} ThreshMult 
     * @param {*} AvgBarsPerSignal 
     * @param {*} context 
     * @function apiAlgoSignals
     * @class API
     */
//#endregion Reference Parameters / Explanations
export default class API {
    apiAlgoSignals = function apiAlgoSignals(name,signal,signalPrice,mktValCap,netGain,avgGain,avgWin,avgLoss,wlRatio,winRate,totalSignals,maxGain,minGain,avgMFE,avgMAE,aefeRatio,totalDuration,totalDurationInHours,avgDuratio,averageDurationInHours,shortestDuration,shortestDurationInHours,longestDuration,longestDurationInHours,tickerAlgosignals,periodAlgosignals,timeAlgosignals,UseCoreLogic,UseStructureLogic,UseHighLow,RevOnExit,Fast,Slow,Smooth,MktValCapThresh,PiPThresh,TickThresh,ThreshMult,AvgBarsPerSignal,context)
    {        
        //#region Data Transformation & Variable Assignments    

            let time = timeAlgosignals;

            //#region 25200000 Adding 7 Hours
            time = time + 25200000;
            //#endregion

            //#region Simplifing the DateTime Format
            let convertTime =dateFormat(new Date(time));
            //#endregion Simplifing the DateTime Format

            //#region back slash
            let workingtickerAlgosignals = tickerAlgosignals.replace(/\\\//g, "/");
            //#endregion back slash

            //#region Curly brackets
            let updatedTicker = workingtickerAlgosignals.replace(/[{}]/g, "");
            //#endregion Curly brackets

            //#region Double Quotes
            let replaceString = updatedTicker.replace(/\"/g, "");
            //#endregion Double Quotes

            //#region Calculating the "Start date" and "End Date" from milliseconds to DateTime
            let StartDate = dateFormat(new Date(context.Start_Day_Month_Year+25200000))
            let EndDate = dateFormat(new Date(context.ML_End_Day_Month_Year+25200000));
            //#endregion Calculating the "Start date" and "End Date" from milliseconds to DateTime

            //#region Designing the required Date format - change from milliseconds to DateTime
            let startDateSimplified = (new Date(context.Start_Day_Month_Year+25200000).getMonth() + 1 )+"/" +new Date(context.Start_Day_Month_Year+25200000).getDate()+"/" +  new Date(context.Start_Day_Month_Year+25200000).getFullYear();
            let endDateDateSimplified = (new Date(context.ML_End_Day_Month_Year+25200000).getMonth() + 1 )+"/" +new Date(context.ML_End_Day_Month_Year+25200000).getDate()+"/" +  new Date(context.ML_End_Day_Month_Year+25200000).getFullYear();
            //#endregion Designing the required Date format - change from milliseconds to DateTime

            //#region Calculation for Slice 1
            mlr = "S1";
            mlr_desc = "S1: " +  startDateSimplified + " to " + endDateDateSimplified
            //#endregion Calculation for Slice 1

            //#region Dynamic DateTime assignation
            let S1 = startDateSimplified + "-" + endDateDateSimplified;
            //#endregion Dynamic DateTime assignation

            //#region calculation for Slice 2
            if(window.parent.sliceCount === 2){
                mlr = "S2";
                mlr_desc = "S2: " +  startDateSimplified + " to " + endDateDateSimplified
            }
            //#endregion calculation for Slice 2

            //#region calculation for Slice 3
        if(window.parent.sliceCount === 3){
            mlr = "S3";
            mlr_desc = "S3: " +  startDateSimplified + " to " + endDateDateSimplified
        }
        //#endregion calculation for Slice 3
        
        //#endregion Data Transformation & Variable Assignments

        //#region Define the AlgoSignals Performance DB Model Object to push to the DB
            const AlgoSignals = {//+ "SS:"+ signalSide
                _id : algosignals +"_" + "UCL:"+UseCoreLogic + "_"+"USL:" +UseStructureLogic + "_"+ "UHL:"+ UseHighLow+ "_"+ "ROE:" +RevOnExit+ "_"+ "fPD:" + Fast+ "_"+ "sPD:"+ Slow+ "_"+ "smPD:"+ Smooth + "_"+ "MVCT:"+ MktValCapThresh+ "_"+ "pipT:"+ PiPThresh+ "_"+ "tickT:"+ TickThresh+ "_"+ "tMult:"+ThreshMult +"_"+ "avgBPS:"+ AvgBarsPerSignal+"_" + replaceString + "_" +periodAlgosignals + "_" + time + "_" + mlr +":" + S1 ,
                name : name,
                Signal_date : convertTime,
                Start_date: StartDate,
                End_date: EndDate,
                Signal_date_String : convertTime,
                Start_date_String : StartDate,
                End_date_String : EndDate,
                mlr : mlr,
                mlr_desc :mlr_desc,
                symbol: replaceString,
                signal :signal,
                signalPrice : signalPrice,
                mktValCap : mktValCap,
                netGain : netGain,
                avgGain : avgGain,
                avgWin : avgWin,
                avgLoss : avgLoss,
                wlRatio : wlRatio,
                winRate : winRate,
                totalSignals : totalSignals,
                maxGain : maxGain,
                minGain : minGain,
                avgMFE : avgMFE,
                avgMAE : avgMAE,
                aefeRatio : aefeRatio,
                totalDuration : totalDuration,
                totalDurationInHours : totalDurationInHours,
                avgDuratio : avgDuratio,
                averageDurationInHours : averageDurationInHours,
                shortestDuration : shortestDuration,
                shortestDurationInHours : shortestDurationInHours, 
                longestDuration : longestDuration,
                longestDurationInHours : longestDurationInHours                
            };
        //#endregion Define the AlgoSignals Performance DB Model Object to push to the DB

        //#region "POST" request without Authentication/auth
        //  .post("http://localhost:4000/algoSignalsaApi/add-tvapi", AlgoSignals)
        // .post("http://tviapi.algotechapis.com/algosignals/postSignals", AlgoSignals)
        //.get("http://localhost:4000/algoSignalsaApi/get-tvapi")
            //  axios
            //  .post("http://localhost:4000/algoSignalsaApi/add-tvapi", AlgoSignals)
            //  .then(res => {
            //    console.log(res.data);
            //  })
            //  .catch(e => console.log("Error", e));
        //#endregion "POST" request without Authentication/auth

        //#region "POST" request with Authentication/auth
            token().then( (token) => {      
                // console.log(token)      
                return axios({
                    method: "post",
                    //URL is coming from middleware config and points to the TVIAPI service.
                    url: postSignals,
                    data:AlgoSignals,
                    //  params: { symbol: 'AAPL'},
                    headers: { Authorization: "Bearer " + token }
                }).then(( res ) =>{
                        console.log(res.data)
                    return res.data
                }).catch((e) => console.log("axios error"))
            }).catch( (e) => console.log(e)) 
        //#endregion "POST" request with Authentication/auth

    }
}