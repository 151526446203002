//#region Import required modules
  import GetMarks from '../PLot_Marks/get_Marks';
  import GetTimeScaleMarks from "../PLot_Marks/get_timescale_Marks";
  import refreshMarks from '../library_Utils/refreshMarks';
  import createTextOnChart from '../library_Utils/createTextOnChart'; 
//#endregion Import required modules

//#region Declare/Initialize Variables
  let getmarks = new GetMarks();
  let timescalemarks = new GetTimeScaleMarks();
  var dateFormat = require('dateformat');
//#endregion Declare/Initialize Variables

/**
 * @param {*} k 
 * @param {*} l 
 * @param {*} context 
 * @function ienterLong
 */

const ienterLong = (k , l , context) => {
      
    //#region Assign variable values
      context.Data[k].Algosignals_eSignal = 1; //Esignal[k] == 1 indicates the indicator fired a buy signal
      context.longSig = true;
      context.shortSig = false;
      context.sigBar = l;
      context.sigBarIdx = k;

      // { context.yOffset = context.Data[0].High + context.txtMult1* context.Data[0].TickSize; context.yPixOffset = 15;}
      context.yOffset = context.Data[k].Low - context.txtMult1 * context.symbol.script.symbols[0].minTick;
      context.yPixOffset = -15; 

      context.lEntryPrice = context.Data[k].Close;
      context.openTrade = context.Data[k].Time;    

      //Alets & Send E-mails Not Necessary Here Because They Only Work In Real-Time

      if (context.firstSignal) { context.firstTrade = context.Data[k].Time; context.firstSignal = false; }
    //#endregion Assign variable values

    //#region Handle Split Exits
      if (context.firstEntryAfterSplitt && !context.splitExit) {
          
        // console.log("Enter after First Split Long")
          // console.log("context.SplitExitPeformaceCal", context.SplitExitPeformaceCal);
          refreshMarks(window.parent.tvWidget);
          
          // Callout here with unchanged perf values
          var Arr = [   
              {
                id:  context.Data[k].Time / 1000 + context.Data[k].Close,
                time: context.Data[k].Time / 1000,
                color: 'yellow',
                text: 'Buy',
                label: 'B',
                labelFontColor: 'red',
                border: 'red',
                minSize: 20,          
              },            
          ]
          var TimeScale = [
              {
                id: context.Data[k].Time / 1000 + context.Data[k].Close,
                time: context.Data[k].Time  / 1000,
                color: 'yellow',
                label: 'cEL',
                // minSize: 5,
                background: '#00ff00',
                tooltip:[
                  "Long "+ "<br>" + "Date: " + (new Date(context.Data[k].Time).getMonth() + 1) + "/" +
                  new Date(context.Data[k].Time).getDate() + "/" + new Date(context.Data[k].Time).getFullYear() + " 4pm ET" + "<br>" + "<br>" 
                  + "Net Gain: " + context.SplitExitPeformaceCal.netGain + "<br>" + "WinRate: " + context.SplitExitPeformaceCal.winRate  + "%" +
                   " / " + context.SplitExitPeformaceCal.totalSignals + " Signals" + "<br>" + "Mkt Val Captured: " + context.SplitExitPeformaceCal.mktValCap + "%",
                 "<br>",
                 "Signal price: " + context.Data[k].Close.toFixed(2),
                 "Last Trade P&L: " + context.SplitExitPeformaceCal.tradePnL,
                 
                 "<br>",
                 "Avg Gain/Signal: " + context.SplitExitPeformaceCal.avgGain,
                 "Avg Win: " + context.SplitExitPeformaceCal.avgWin , "Avg Loss: " + context.SplitExitPeformaceCal.avgLoss + " -> " + context.SplitExitPeformaceCal.wlRatio + ":1",
                 "Max Winner: " + context.SplitExitPeformaceCal.maxGain,
                 "Max Loser: " + context.SplitExitPeformaceCal.minGain,
                 "<br>",
                 "Avg MFE: " + context.SplitExitPeformaceCal.avgMFE ,
                 "Avg MAE: " + context.SplitExitPeformaceCal.avgMAE , 
                 "Avg MFE / Avg MAE -> " + context.SplitExitPeformaceCal.aefeRatio + ":1",
                 "<br>",
                 "Tot Dur: " + context.SplitExitPeformaceCal.totalDuration + "d " +  context.SplitExitPeformaceCal.totalDurationInHours + "h",
                 "Avg Dur: " + context.SplitExitPeformaceCal.avgDuration  +"d " +  context.SplitExitPeformaceCal.averageDurationInHours + "h",
                 "Min Dur: " + context.SplitExitPeformaceCal.shortestDuration  + "d " +  context.SplitExitPeformaceCal.shortestDurationInHours + "h",
                 "Max Dur: " + context.SplitExitPeformaceCal.longestDuration  + "d " +  context.SplitExitPeformaceCal.longestDurationInHours + "h"        
                ],
              }
          ]
          //#region Performance Calcuations storage Object and Array for DB version

          context.SplitExitPeformaceCalEnterVal = {
            mktValCap : context.SplitExitPeformaceCal.mktValCap,
            netGain : context.SplitExitPeformaceCal.netGain,
            avgGain : context.SplitExitPeformaceCal.avgGain,
            avgWin : context.SplitExitPeformaceCal.avgWin,
            avgLoss : context.SplitExitPeformaceCal.avgLoss,
            wlRatio : context.SplitExitPeformaceCal.wlRatio,
            winRate : context.SplitExitPeformaceCal.winRate,
            totalSignals : context.SplitExitPeformaceCal.totalSignals,
            maxGain : context.SplitExitPeformaceCal.maxGain,
            minGain : context.SplitExitPeformaceCal.minGain,
            avgMFE : context.SplitExitPeformaceCal.avgMFE,
            avgMAE : context.SplitExitPeformaceCal.avgMAE,
            aefeRatio : context.SplitExitPeformaceCal.aefeRatio,
            totalDuration :context.SplitExitPeformaceCal.totalDuration,
            totalDurationInHours : context.SplitExitPeformaceCal.totalDurationInHours,
            avgDuratio : context.SplitExitPeformaceCal.avgDuratio,
            averageDurationInHours : context.SplitExitPeformaceCal.averageDurationInHours,
            shortestDuration : context.SplitExitPeformaceCal.shortestDuration,
            shortestDurationInHours : context.SplitExitPeformaceCal.shortestDurationInHours,
            longestDuration :context.SplitExitPeformaceCal.longestDuration,
            longestDurationInHours : context.SplitExitPeformaceCal.longestDurationInHours,
            name : context.SplitExitPeformaceCal.name,
            signal : context.SplitExitPeformaceCal.signal,
            signalPrice : context.Data[k].Close,
            tradePnL : context.SplitExitPeformaceCal.tradePnL,
            tickerAlgosignals : context.SplitExitPeformaceCal.tickerAlgosignals,
            periodAlgosignals : context.SplitExitPeformaceCal.periodAlgosignals,
            timeAlgosignals : context.Data[k].Time,
            UseCoreLogic : context.SplitExitPeformaceCal.UseCoreLogic,
            UseStructureLogic : context.SplitExitPeformaceCal.UseStructureLogic,
            UseHighLow : context.SplitExitPeformaceCal.UseHighLow,
            RevOnExit : context.SplitExitPeformaceCal.RevOnExit,
            Fast : context.SplitExitPeformaceCal.fast,
            Slow : context.SplitExitPeformaceCal.slow,
            Smooth : context.SplitExitPeformaceCal.smooth,
            MktValCapThresh : context.SplitExitPeformaceCal.MktValCapThresh,
            PiPThresh : context.SplitExitPeformaceCal.PiPThresh,
            TickThresh : context.SplitExitPeformaceCal.TickThresh,
            ThreshMult : context.SplitExitPeformaceCal.ThreshMult,
            AvgBarsPerSignal : context.SplitExitPeformaceCal.AvgBarsPerSignal
    
    
          }


        if (window.parent.DBsignal === true) {//true
          context.PerformaceCalcCognative[context.PerformaceCalcCognativeIndex] = context.SplitExitPeformaceCalEnterVal;
          context.PerformaceCalcCognativeIndex += 1;
        }
        //#endregion Performance Calcuations storage Object and Array for DB version

        //#region Script for automated testing
        if(window.parent.DBsignal === false) {
        context.automatedTesting[context.automatedTestingIndex] = context.SplitExitPeformaceCalEnterVal;
        context.automatedTestingIndex += 1;
        }
        //#endregion Script for automated testing



          if(window.parent.DBsignal === false) {
            getmarks.createMarksOnChart(Arr);
            timescalemarks.createTimeScaleMarksOnChart(TimeScale);
            // createTextOnChart(window.parent.tvWidget,context.Data[k].Time,context.Data[k].price, context.Data[k].Close.toFixed(2));

          }
          context.firstEntryAfterSplit = false
      }
    //#endregion Handling the ExitSplits

}
export {ienterLong};