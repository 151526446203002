/**
 * We are subscribing to the **study** event. By subscribing this event we get the Name of an indicator.
 * Through this script we are also checking if the indicator is loaded on the chart.
 * @param {*} tvWidget 
 * @function studyName
 * @event study
 */

const studyName = (tvWidget) => {
  //#region 'study' event triggers when the indicator is loaded on the chart and gives us the name of an indicator
    tvWidget.subscribe('study', (value) => {

        window.parent.indicatorName = value.value;
        //#region Setting the property to "true" as here we know an instance of indicator is created/loaded on the chart
          window.parent.checkIndicatorLoadedONChart = true;
        //#endregion Setting the property to "true" as here we know an instance of indicator is created/loaded on the chart
        
        // console.log("************ID***********", window.parent.indicatorName);

    })
  //#endregion 'study' event triggers when the indicator is loaded on the chart and gives us the name of an indicator
  
}
export default studyName;