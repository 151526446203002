var dateFormat = require('dateformat');
/**
 * @class AlgoAdxy
 * @function algoAdxy -> contains the core logic for "Algoadxy" indicator.
 */
export default  class AlgoAdxy {
    algoAdxy = function (context,CurrentBar,Period) {
        
        var trueRange = context.Data[0].High - context.Data[0].Low;
        
        if (CurrentBar == 0) {
            context.Data[0].tr = trueRange;
            context.Data[0].dmPlus = 0;
            context.Data[0].dmMinus = 0;
            context.Data[0].sumTr = context.Data[0].tr;
            context.Data[0].sumDmPlus = context.Data[0].dmPlus;
            context.Data[0].sumDmMinus = context.Data[0].dmMinus;
            context.Data[0].Value = 50;
        } else if (CurrentBar <= Period) {
            return;
        } else {            
            context.Data[0].tr = (Math.max(Math.abs(context.Data[0].Low - context.Data[1].Close), Math.max(trueRange, Math.abs(context.Data[0].High - context.Data[1].Close))));
            context.Data[0].dmPlus = (context.Data[0].High - context.Data[1].High > context.Data[1].Low - context.Data[0].Low ? Math.max(context.Data[0].High - context.Data[1].High, 0) : 0);
            context.Data[0].dmMinus = (context.Data[1].Low - context.Data[0].Low > context.Data[0].High - context.Data[1].High ? Math.max(context.Data[1].Low - context.Data[0].Low, 0) : 0);

            if (CurrentBar < Period) {
                context.Data[0].sumTr = (context.Data[1].sumTr + context.Data[0].tr);
                context.Data[0].sumDmPlus = (context.Data[1].sumDmPlus + context.Data[0].dmPlus);
                context.Data[0].sumDmMinus = (context.Data[1].sumDmMinus + context.Data[0].dmMinus);
            } else {
                context.Data[0].sumTr = (context.Data[1].sumTr - context.Data[1].sumTr / Period + context.Data[0].tr);
                context.Data[0].sumDmPlus = (context.Data[1].sumDmPlus - context.Data[1].sumDmPlus / Period + context.Data[0].dmPlus);
                context.Data[0].sumDmMinus = (context.Data[1].sumDmMinus - context.Data[1].sumDmMinus / Period + context.Data[0].dmMinus);
            }
            var diPlus = 100 * (context.Data[0].sumTr == 0 ? 0 : context.Data[0].sumDmPlus / context.Data[0].sumTr);
            var diMinus = 100 * (context.Data[0].sumTr == 0 ? 0 : context.Data[0].sumDmMinus / context.Data[0].sumTr);
            var diff = Math.abs(diPlus - diMinus);
            var sum = diPlus + diMinus;

            context.Data[0].Value = (sum == 0 ? 50 : ((Period - 1) * context.Data[1].Value + 100 * diff / sum) / Period);

            context.Data[0].DiPlus = diPlus;
            context.Data[0].DiMinus = diMinus;


            if (context.Data[0].Value > context.Data[1].Value) {
                context.Data[0].plotDirection = 1;
            } else if (context.Data[0].Value < context.Data[1].Value) {
                context.Data[0].plotDirection = -1;
            } else {
                context.Data[0].plotDirection = 0;
            }

            //if((Value[0] > Value[1]) && Value[0] < 25)
            //Deactivating the above line of code and activating the below line of codes braeks the indicator. WHY?
            //if((Value[0] > Value[1]) && Value[0] > Value[2])
            //	if(plotDirection[0] == 1 && (plotDirection[1] == 1 || plotDirection[1] == 0) && (plotDirection[2] == -1 && plotDirection[3] == -1 || plotDirection[2] == 0 && plotDirection[3] == -1
            //	|| plotDirection[2] == -1 && plotDirection[3] == 0 || plotDirection[2] == 0 && plotDirection[3] == 0))
            var PlotValue0 = context.Data[0].Value;
            var PlotValue1Up = null;
            var PlotValue2Dn = null;
            var PlotBrushes = "Black";
            if (context.Data[0].plotDirection == 1 && (context.Data[1].plotDirection == 0 ||
                    context.Data[1].plotDirection == -1) && context.Data[2].plotDirection == -1) {
                PlotValue1Up = PlotValue0;
                PlotValue0 = context.Data[0].Value;
                context.Data[0].eSignal = (-1);
                context.Data[0].ADXY_Esignal = (-1);


            } else if (context.Data[0].plotDirection == -1 && (context.Data[1].plotDirection == 0 ||
                    context.Data[1].plotDirection == 1) && context.Data[2].plotDirection == 1) {
                PlotValue2Dn = PlotValue0;
                PlotValue0 = context.Data[0].Value;
                context.Data[0].eSignal = (1);
                context.Data[0].ADXY_Esignal = (1);
            }

            else{
            context.Data[0].eSignal = (0);
            context.Data[0].ADXY_Esignal = (0);
        }
        //   console.log(dateFormat(new Date(context.Data[0].Time)) + " - " + "Close[0] -> " + context.Data[0].Close + " - adx.ESignal[0] = " + context.Data[0].eSignal + " - adx.DiMinus[0] = " + context.Data[0].DiMinus + " - adx.DiPlus[0] = " + context.Data[0].DiPlus + " - CurrentBar: " + context.CurrentBar); 		
        }
        return [PlotValue0, PlotValue1Up, PlotValue2Dn];
    }
}