import React from "react";
import TabsCust from "./Tabs";
import { open_info_ticket, open_ticket } from "../../Apps/Ticket/index";
// const BottomOptionWidget = lazy(() => import("../../Apps/OptionWidget"));
import BottomOptionWidget from "../../Apps/OptionWidget";
import Account from "./../../Apps/Account/index"

export default function BottomWidget({
  theme,
  setBottomWidgetStatus,
  BottomWidgetStatus,
  user
}) {
  const TabData = [
    {
      label: "Options",
      component: (
        <BottomOptionWidget
          theme={theme}
          onClickRows={open_info_ticket}
          onClickAskBid={open_ticket}
        />
      )
    },
    {
      label: "Account",
      component: (
        <Account
          theme={theme}
          user={user}
        />
      )
    }
  ];

  return (
    <TabsCust
      theme={theme}
      setBottomWidgetStatus={setBottomWidgetStatus}
      BottomWidgetStatus={BottomWidgetStatus}
      tabs={TabData}
    />
  );
}
