import IndicatorTemplate from '../endpoints/indicatortemplateapi';
let indTempObj = new IndicatorTemplate();
/**
 * save the study/indicator template into the Db we are sending/passing the study data in "studyTemplateData" property
 * @param {*} studyTemplateData .
 * @function saveindicatorTemplate
 */
export default function saveindicatorTemplate (studyTemplateData) {

//#region Calling the method "indicatorTemplate" to "Save" the Templates 
        indTempObj.indicatorTemplate(studyTemplateData)
//#endregion Calling the method "indicatorTemplate" to "Save" the Templates

  }

  