import refreshMarks from '../library_Utils/refreshMarks';
/**
 * createShape is a Charting library Api, creates different shapes on the chart.
 * Here we are using text to plot the text on the chart.
 * @param {*} tvWidget 
 * @param {*} time 
 * @param {*} price 
 * @param {*} text 
 * @function createTextOnChart
 */

const createTextOnChart = (tvWidget,time,price,text) => {
  window.parent.text = true;
  //#region Refresh the callouts/markers
    refreshMarks(tvWidget);
  //#endregion Refresh the callouts/markers

  //#region Text creation process
    const shapeId = tvWidget.chart().createShape({ time: time/1000,  price:price }, {
        "shape": "text",
        lock: true,
        disableSave:true,
        
        //#region Override the default properties of the shape the charting library have provide some more properties so we can customize them        
        overrides: {
                backgroundColor: '#0f0',
                markerColor: '#0f0',
                color:"#778899",
                fontsize:11,
                zOrder: 'bottom'
              }
        //#endregion Override the default properties of the shape the charting library have provide some more properties so we can customize them
      
    });      
    tvWidget.chart().getShapeById(shapeId).setProperties({ text: text });
  //#endregion Text creation process
  
  //#region To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them
    window.parent.textShapeID[window.parent.textShapeID_Index] = shapeId; 
    window.parent.textShapeID_Index += 1;
  //#endregion To handle the shapes so we can remove them from chart as they are not in the metainfo of the indicator so we have write the script to handle them
  
}
export default createTextOnChart;
  

 