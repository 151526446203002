/**
 * "load_study template" trigers when the study template is loaded on the chart. The "loadstudyTemplate" varaible is used 
 * when we load the studytemplate on the chart we have custom shapes and those shapes won't remove when we remove the study template from the 
 * chart so we have to handle them manually for that in the "eventStudy.js file" we have a script that remove the custom shapes when we remove
 * the indicator template from the chart
 * @param {*} tvWidget 
 * @function load_study_template
 * @event load_study template
 */
const load_study_template = (tvWidget) => {
    //#region subscribe to load study template trigger event when the study loads on the chart
    tvWidget.subscribe('load_study template', () =>{

        //#region variable "loadstudyTemplate" set to true here as we only need to remove the custom shapes when the indicator template is loaded on the chart
            window.parent.loadstudyTemplate = true;
        //#endregion variable "loadstudyTemplate" set to true here as we only need to remove the custom shapes when the indicator template is loaded on the chart
        
        console.log("************load_study template***********", window.parent.loadstudyTemplate)

    });
    //#endregion subscribe to load study template trigger event when the study loads on the chart
}
export default load_study_template;