import axios from "axios";
import calc_greeks from "../services/calcgreeks";
import {get1minHistory} from '../../../services/apis/dataService';
import { token } from '../../../configMiddleware/getToken';
import {chartURL, optionsURL, authURL, orderURL, acctURL} from '../../../config/links.config'

// import stock_stream_gen from "../services/stock_stream_act_di";
var io = require("socket.io-client");
//var socket_url = "/stream";
// var socket_url = 'ostream.algotechapis.com/stream_option'
var socket_url = 'https://ostream.algotechapis.com/stream_option'
// var socket_url = 'http://localhost:5000/stream';
// var socket_url = `${optionsURL}/stream_option`
// var socket_url = 'http://localhost:5002/stream_option';


var socket = io(socket_url);
// const API_URL = "http://localhost:5000/api";



export const set_symbol = symbol => async(dispatch, getState) => {
  // console.log("called the set_symbol method in option.js file")
  socket.disconnect();
  symbol = symbol.toUpperCase();
  let match = Boolean(
    JSON.parse(localStorage.getItem("all_symbols")).filter(sym =>
      sym.symbol.includes(symbol.toUpperCase())
    ).length
  );

  if (match) {
    dispatch({
      type: "SET_SYMBOL",
      payload: symbol.toUpperCase()
    });
    // dispatch(get_expiration(true));
  } else return dispatch({ type: "STOCK_SYM_ERROR" });

  if (
    Boolean(
      JSON.parse(localStorage.getItem("all_symbols")).filter(
        sym => sym.symbol === symbol.toUpperCase()
      ).length
    )
  ) {
    // console.log("**********If condition for set symbol matched in option.js file ********")
    if (Boolean(getState().option.last_price_update_interval_ID))
      clearInterval(getState().option.last_price_update_interval_ID);
    dispatch(get_expiration(symbol.toUpperCase()));
    
    //get1minHistory(diapatch, symbol)
    let token = localStorage.getItem("jwtToken"); 
    // console.log("options Symbol :"+ symbol  + "Redolution : ", "1", token)  
   await axios(`${chartURL}/api/chart/historical_data?symbol=${symbol}&resolution=${1}`,{headers: { Authorization: token }}).then(
      data => {
        dispatch(update_near_value(data.data[data.data.length - 1].close));
      }
    );

    // await token().then( async(token) => {  
      // let token = localStorage.getItem("jwtToken");  
      // console.log("options Symbol :"+ symbol  + "Redolution : ", "1", token) 
      // // return
      //  await axios({
      //       method: "get",
      //       url: `${chartURL}/api/chart/historical_data`,
      //       params : {
      //             symbol : symbol,
      //             resolution : "1"
      // },
      //       // headers: { Authorization: "Bearer " + token }
      //       headers: { Authorization: token }
      // }).then(async ( data ) =>{
      //   await dispatch(update_near_value(data.data[data.data.length - 1].close));
      //       // console.log("historical_data option.js : ", data.data);
      //        return await data.data
      // }).catch((e) => console.log("axios error"))
      // }).catch( (e) => console.log(e))

    const last_price_update_interval_ID = setInterval(async() => {
      // console.log("****last_price_update_interval_ID called**************")
      // alert("hello" + last_price_update_interval_ID);
      let token = localStorage.getItem("jwtToken"); 
      // console.log("options Symbol :"+ symbol  + "Redolution : ", "1DDD", token)  
      await axios(
        `${chartURL}/api/chart/historical_data?symbol=${getState().option.symbol}&resolution=${1}`,{headers: { Authorization:  localStorage.getItem("jwtToken") }}
      ).then(data => {
        // console.log("last_price_update_interval_ID response ",data.data)
        dispatch(update_near_value(data.data[data.data.length - 1].close));
      }).catch(e => {console.log("last_price_update_interval_ID Error", e)});

      // await token().then( async(token) => {   
        // let token = localStorage.getItem("jwtToken");   
        // return
        //  await axios({
        //       method: "get",
        //       url: `${chartURL}/api/chart/historical_data`,
        //       params : {
        //             symbol : getState().option.symbol,
        //             resolution : "1"
        // },
        //       // headers: { Authorization: "Bearer " + token }
        //       headers: { Authorization:  token }
        // }).then(async( data ) =>{
        //   await dispatch(update_near_value(data.data[data.data.length - 1].close));
        //       // console.log("historical_data option.js 2: ", data.data);
        //       return await data.data
        // }).catch((e) => console.log("axios error"))
        // }).catch( (e) => console.log(e))
    }, 30000);
    dispatch({
      type: "SET_LAST_PRICE_INTERVAL_ID",
      payload: last_price_update_interval_ID
    });
  }
};

export const update_near_value = value => {
  return {
    type: "UPDATE_NEAR_VALUE",
    payload: value
  };
};

export const on_select_strike_range = (e, symbol, expiry, near) => dispatch => {
  dispatch({
    type: "SET_STRIKE_RANGE",
    payload: e.target.value
  });
  if (Boolean(expiry)) {
    dispatch(get_strikes(symbol, expiry, e.target.value, near));
  }
};

export const select_colums = columns => dispatch => {
  localStorage.setItem("columns", JSON.stringify(columns));
  dispatch({ type: "SELECT_COLUMNS", payload: columns });
};

export const get_expiration = symbol => dispatch => {
  let token = localStorage.getItem("jwtToken");
  axios
    .get(`${optionsURL}/api/options/get_expirations?symbol=${symbol}`,{headers: { Authorization:  token }})
    .then(res => {
      dispatch({ type: "GET_EXPIRATION", payload: res.data });
    })
    .catch(e => dispatch({ type: "ERROR", payload: e }));

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_expirations`,
      //         params : {
      //           symbol: symbol
      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     dispatch({ type: "GET_EXPIRATION", payload: res.data });
      //         return res.data
      //   }).catch((e) => console.log("axios error"))
      //   }).catch( (e) => dispatch({ type: "ERROR", payload: e }))
//#endregion auth endpoint




};

export const custom_strike_toggle = (expiry, data) => dispatch => {
  socket.disconnect();
  dispatch({ type: "STRIKE_TOGGLE" });
  if (Boolean(expiry)) {
    dispatch(
      get_strikes(data.symbol, expiry, data.strikeRange, data.near, true)
    );
  }
};

export const select_expiry = (
  index,
  expiry,
  symbol,
  range,
  near,
  custom_strike
) => dispatch => {
  socket.disconnect();
  dispatch({ type: "SELECT_EXPIRY", payload: expiry });
  dispatch(get_strikes(symbol, expiry, range, near, custom_strike));
};

export const get_strikes = (
  symbol,
  expiry,
  range,
  near,
  custom_strike
) => dispatch => {
  let token = localStorage.getItem("jwtToken");
  socket.disconnect();
  let url = !custom_strike
    ? `${optionsURL}/api/options/get_strikes?symbol=${symbol}&expiration=${expiry}&range=${range}&near=${near}`
    : `${optionsURL}/api/options/get_strikes?symbol=${symbol}&expiration=${expiry}`;
  axios
    .get(url,{headers: { Authorization:  token }})
    .then(res => {
      dispatch({ type: "GET_STRIKES", payload: res.data });
      if (custom_strike) {
        dispatch({ type: "SELECT_STRIKE", payload: [] });
        dispatch({
          type: "GET_OPTIONS_CHAIN",
          payload: { put_options: [], call_options: [] }
        });
      }
      if (!custom_strike) {
        dispatch({ type: "SELECT_STRIKE", payload: res.data });
        dispatch(get_options_chain());
      }
    })
    .catch(e => dispatch({ type: "ERROR", payload: e }));


    // token().then( (token) => {  
    //   let token = localStorage.getItem("jwtToken");
    //   if(!custom_strike){
    //     return axios({
    //       method: "get",
    //       url: `${optionsURL}/api/options/get_strikes`,
    //       params : {
    //         symbol : symbol,
    //         expiration : expiry,
    //         range : range,
    //         near : near
    // },
    //       // headers: { Authorization: "Bearer " + token }
    //       headers: { Authorization:  token }
    // }).then(( res ) =>{
    //   dispatch({ type: "GET_STRIKES", payload: res.data });
    //   if (custom_strike) {
    //     dispatch({ type: "SELECT_STRIKE", payload: [] });
    //     dispatch({
    //       type: "GET_OPTIONS_CHAIN",
    //       payload: { put_options: [], call_options: [] }
    //     });
    //   }
    //   if (!custom_strike) {
    //     dispatch({ type: "SELECT_STRIKE", payload: res.data });
    //     dispatch(get_options_chain());
    //   }
    //       return res.data
    // }).catch((e) => dispatch({ type: "ERROR", payload: e }))
    //   } 

    //   else {
    //     return axios({
    //       method: "get",
    //       url: `${optionsURL}/api/options/get_strikes`,
    //       params : {
    //         symbol : symbol,
    //         expiration : expiry,
    // },
    //       // headers: { Authorization: "Bearer " + token }
    //       headers: { Authorization:  token }
    // }).then(( res ) =>{
    //   dispatch({ type: "GET_STRIKES", payload: res.data });
    //   if (custom_strike) {
    //     dispatch({ type: "SELECT_STRIKE", payload: [] });
    //     dispatch({
    //       type: "GET_OPTIONS_CHAIN",
    //       payload: { put_options: [], call_options: [] }
    //     });
    //   }
    //   if (!custom_strike) {
    //     dispatch({ type: "SELECT_STRIKE", payload: res.data });
    //     dispatch(get_options_chain());
    //   }
    //       return res.data
    // }).catch((e) => dispatch({ type: "ERROR", payload: e }))
    //   }

      // }).catch( (e) => dispatch({ type: "ERROR", payload: e }))




};

export const select_strike = (strike, selected_strikes) => dispatch => {
  socket.disconnect();
  let is_strike_present = Boolean(
    selected_strikes.filter(str => str === strike).length
  );
  if (is_strike_present) {
    selected_strikes = selected_strikes.filter(str => str !== strike);
    selected_strikes.sort((a, b) => a - b);
    dispatch({ type: "SELECT_STRIKE", payload: selected_strikes });
    dispatch(get_options_chain());
  } else {
    selected_strikes.push(strike);
    if (selected_strikes.length > 8) {
      selected_strikes.splice(0, 1);
    }
    selected_strikes.sort((a, b) => a - b);
    dispatch({ type: "SELECT_STRIKE", payload: selected_strikes });
    dispatch(get_options_chain());
  }
};

export const get_options_chain = () => (dispatch, getState) => {
  socket.disconnect();
  const symbol = getState().option.symbol;
  const expiry = getState().option.selectedExpiration;
  const strikes = getState().option.strikes;
  let token = localStorage.getItem("jwtToken");
  axios
    .get(
      `${optionsURL}/api/options/get_options_chain?symbol=${symbol}&expiration=${expiry}&greeks=true&strikes=${strikes.join( ",")}`,
  {headers: { Authorization:  token }}
    )
    .then(res => {
      let all_options = res.data;
      const put_options = all_options.filter(
        option => option.option_type === "put"
      );
      const call_options = all_options.filter(
        option => option.option_type === "call"
      );

      dispatch({
        type: "GET_OPTIONS_CHAIN",
        payload: { put_options, call_options }
      });

      dispatch(update_option());
    });

//#region auth endpoint
      // token().then( (token) => {      
      //   return axios({
      //         method: "get",
      //         url: `${optionsURL}/api/options/get_options_chain`,
      //         params : {
      //           symbol: symbol,
      //           expiration : expiry,
      //           greeks : true,
      //           strikes : strikes.join( ",")

      //   },
      //         headers: { Authorization: "Bearer " + token }
      //   }).then(( res ) =>{
      //     let all_options = res.data;
      //     const put_options = all_options.filter(
      //       option => option.option_type === "put"
      //     );
      //     const call_options = all_options.filter(
      //       option => option.option_type === "call"
      //     );
    
      //     dispatch({
      //       type: "GET_OPTIONS_CHAIN",
      //       payload: { put_options, call_options }
      //     });
    
      //     dispatch(update_option());
      //   // }).catch((e) => console.log("axios error"))
      //   }).catch((e) => console.log("axios error", e, e.message))

      //   }).catch( (e) => console.log(e))
//#endregion auth endpoint

};

const update_option = () => (dispatch, getState) => {
  const all_options_symbol = [
    ...getState().option.options.put_options,
    ...getState().option.options.call_options
  ].map(opt => opt.symbol);
  socket.disconnect();
  socket.connect();
  // socket.emit("SubAdd", { subs: all_options_symbol });
  socket.emit("get_options", { syb: all_options_symbol });
  console.log(all_options_symbol)
  socket.on("connect", () => console.log("option socket connected"));
  socket.on("disconnect", e =>
    console.log("===option Socket disconnected:", e)
  );
  socket.on("error", err => console.log("====option socket error", err));
  // socket.on("m", e => {
    socket.on("option", e => {
    console.log('event recived', e)
    const all_options_symbol_type = [
      ...getState().option.options.put_options,
      ...getState().option.options.call_options
    ].map(option => ({
      symbol: option.symbol,
      type: option.option_type,
      option: option
    }));
    const expiry = getState().option.selectedExpiration;
    const u_symbol = getState().option.symbol;
    const last_price = getState().option.near;
    all_options_symbol_type.forEach(symbol => {
      if (symbol.symbol === e.symbol) {
        if (e.type === "trade") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: { ...option, volume: e.cvol, last: e.last }
          };
          calc_greeks(update.update, expiry, u_symbol, last_price).then(
            data => {
              if (Object.keys(data).length)
                return dispatch(execute_option_update(data));
              else return dispatch(execute_option_update(update.update));
            }
          );
        } else if (e.type === "summary") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: {
              ...option,
              high: e.high,
              low: e.low,
              open: e.open,
              open_interest: e.openInterest,
              prevclose: e.prevClose
            }
          };
          calc_greeks(update.update, expiry, u_symbol, last_price).then(
            data => {
              if (Object.keys(data).length)
                return dispatch(execute_option_update(data));
              else return dispatch(execute_option_update(update.update));
            }
          );
        } else if (e.type === "quote") {
          let option = symbol.option;
          let update = {
            symbol: e.symbol,
            type: symbol.type,
            update: {
              ...option,
              ask_date: e.askdate,
              ask: e.ask,
              asksize: e.asksz,
              bid_date: e.biddate,
              bid: e.bid,
              bidsize: e.bidsz
            }
          };
          calc_greeks(update.update, expiry, u_symbol, last_price).then(
            data => {
              if (Object.keys(data).length)
                return dispatch(execute_option_update(data));
              else return dispatch(execute_option_update(update.update));
            }
          );
        }
      }else{
        console.log("unknown symbol ->", e.symbol, '---', symbol.symbol)
      }
    });
  });
};

export const execute_option_update = option => (dispatch, getState) => {
  const all_options = getState().option.options;
  const option_type = option.option_type;
  const index = all_options[`${option_type}_options`].findIndex(
    ele => ele.symbol === option.symbol
  );
  // console.log("prev",all_options[`${option_type}_options`][index])
  all_options[`${option_type}_options`][index] = option;
  // console.log("next", all_options[`${option_type}_options`][index] )
  dispatch({type:"ERASE"})
  dispatch({ type: "UPDATE_OPTION", payload: all_options });
};

export const stock_stream = sym => dispatch => {
  // stock_stream_gen(sym, dispatch);
};
