/* All this information is from the TradingView API Library Documentation*/

//#region Imoprt required modules
import { widget } from "../../../charting_library/charting_library";
import historyProvider from "./historyProvider";
import stream from "./stream";
import cryptoStream from "./crypto_stream";
import {
  chartURL,
  optionsURL,
  authURL,
  orderURL,
  acctURL,
} from "../../../config/links.config";
// import { set_symbol } from "../../../actions/options";
// import store from "./../../../store";
// import{ useDispatch} from "react-redux";
import Csv_symbols from "./../../../chartIndicator_library/SymbolsApi/csv_symbols"; // '../../SymbolsApi/csv_symbols';
import StockSplits from "./../../../chartIndicator_library/SymbolsApi/stocksplits";
import { FX_Majors } from "../../../services/apis/dataService";
import { logout } from "../../../actions/auth.action";
import { getJSON } from "jquery";
// import {topcryptos,topCryptosReturnfunction} from '../../../chartIndicator_library/TopCryptos'
//#endregion Imoprt required modules

//#region Declare/Initialize variables
const jwt = require("jsonwebtoken");
var dateFormat = require("dateformat");
const axios = require("axios");
// const dispatch = useDispatch();
let storeOnDataCallback;
window.onData = (ondata) => {
  storeOnDataCallback = ondata;
  return storeOnDataCallback;
};

let storeResetCacheDataCallback;
window.onDataResetCach = (ondata) => {
  storeResetCacheDataCallback = ondata;
  return storeResetCacheDataCallback;
};

let csv_symbols = new Csv_symbols();
let stocksplitsObject = new StockSplits();
window.storeCallBackFunction = () => {};
window.storeResetCacheCallBackFunction = () => {};
window.storeCallBackTimeScaleFunction = () => {};
window.checkSymbol = (data) => {
  return data;
};
window.checkSymbolForAlgoSignals = () => {};
let restrictSplitDateCalloutsPlotting;
let topCrypTos;
let Forex = [];
let allCrypto;
let Comm = [];
let INDX = [];
let NYSEMKT = [];
let NYSE = [];
let NASDAQOMX = [];
let BATS = [];
let NationalStockExchange = [];
let NYSEArca = [];
let Stock = [];
// let weeklies = [];
let TopCryptos = [];
let CryptoComareEx = [];
let all_symbol = [];
let passedCryproCompareExchange,
  Bittrex = [],
  Bitfinex = [],
  symbolinfoUnsybscribe,stockSymbols = [];
async function topcryptos() {
  let token = localStorage.getItem("jwtToken");
  await axios({
    method: "get",
    url: "https://topcryptos.azurewebsites.net/api/getTopCryptos",
    headers: { Authorization: token },
  })
    .then((res) => {
      res.data.forEach((element) => {
        TopCryptos.push({
          symbol: element.symbol,
          full_name: element.symbol,
          exchange: element.exchange,
          description: element.project_name,
          type: element.previous_value + " : " + element.type,
        });
      });
      // console.log("Top Cryptos : ", TopCryptos)
    })
    .catch((e) => {
      console.log(e);
    });
}

async function getCryptos() {
  await axios({
    method: "get",
    url: "https://eodhdallsymbols.azurewebsites.net/api/getcryptos",
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw",
    },
  })
    .then((res) => {
      allCrypto = res.data;
      // console.log("AllCryptps :", allCrypto)
    })
    .catch((E) => {
      console.log(E);
    });
}

async function cryptoCompareExchnages() {
  let token = localStorage.getItem("jwtToken");
  await axios({
    method: "get",
    url: "https://cryptocompareex.azurewebsites.net/api/getCryptoCompare", //'http://localhost:7072/api/getCryptoCompare',
    headers: { Authorization: token },
  }).then((res) => {
    CryptoComareEx = res.data;
    console.log("CryptoComareEx :", CryptoComareEx);
    res.data.forEach((element) => {
      if (element.exchange == "BitTrex")
        Bittrex.push({
          symbol: element.symbol,
          //Notes: When we click on the search symbol while searching through keyboard or mouse the full_name initiates. Now if 2 symbols have same symbol name and full_name and they have the data from different
          // exchanges then the charting library will be considering them the same symbol and won't call the data for that symbol as the full_name is same for both of the exchnages for the symbol.
          //So inorder to get the data from different exchanges we need to differentiate the full_name
          full_name: element.full_name.replace("-", "/"),
          exchange: element.exchange,
          description: element.description,
          type: element.type,
        });
      // if(element.exchange == 'Bitfinex')
      // Bitfinex.push({
      //   symbol : element.symbol,
      //   full_name : element.full_name.replace('-', '~'),
      //   exchange : element.exchange,
      //   description : element.description,
      //   type : element.type

      // })
    });

    // console.log("CryptoComareEx : ", CryptoComareEx)
  });
}
//#endregion Declare/Initialize variables

FX_Majors();
topcryptos();
cryptoCompareExchnages();
getCryptos();
const supportedResolutions = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "60",
  "120",
  "240",
  "D",
  "1W",
  "M",
  "6M",
];
let fx_majors = JSON.parse(localStorage.getItem("FX_Majors"));
const config = {
  supported_resolutions: supportedResolutions,
  supports_search: false,
  supports_marks: true,
  supports_timescale_marks: true,
  //,{value : "FOREX", name : "Forex", desc : ""}{value : "INDX", name : "Index", desc : ""},{value : "CC", name : "Crypto", desc : ""}],
  exchanges: [
    { value: "", name: "Exchanges", desc: "" },
    { value: "NYSE MKT", name: "NYSE MKT", desc: "" },
    {
      value: "National Stock Exchange",
      name: "National Stock Exchange",
      desc: "",
    },
    { value: "NYSE", name: "NYSE", desc: "" },
    { value: "NYSE Arca", name: "NYSE Arca", desc: "" },
    { value: "NASDAQ OMX", name: "NASDAQ OMX", desc: "" },
    { value: "BATS", name: "BATS", desc: "" },
    { value: "BitTrex", name: "BitTrex", desc: "" },
    { value: "Coinbase", name: "Coinbase", desc: "" },
  ], //,{value : "Bitfinex", name : "Bitfinex", desc : ""}
  // {value : "Strong Cryptos", name : "Strong Cryptos", desc : ""},{value : "Really Strong Cryptos", name : "Really Strong Cryptos", desc : ""},{value : "Solid Cryptos", name : "Solid Cryptos", desc : ""},{value : "Ultra Cryptos", name : "Ultra Cryptos", desc : ""}],
  symbols_types: [
    { name: "All Types", value: "" },
    { name: "Stock", value: "stock" },
    { name: "Index", value: "index" },
    { name: "Weeklies", value: "weeklies" },
    { name: "FX Exotic", value: "forex" },
    { name: "FX Majors", value: "Fx_majors" },
    { name: "Top Crypto", value: "topcryptos" },
    { name: "Crypto", value: "crypto" },
    { name: "Commodities", value: "futures" },
  ],
  // {name : "Crypto", value : "crypto"},
  // supports_group_request : true,
  // supports_search : true
  // symbols_grouping : {
  //   "forex": `/^(.+)([12]!|[FGHJKMNQUVXZ]\d{1,2})$/`,
  //     "stock": `/^(.+)([12]!|[FGHJKMNQUVXZ]\d{1,2})$/`,
  // }
};

async function getAllSymbolsFromChartHistorical(){
  axios({
    method: "get",
    url: `https://charthistoricaldriver.azurewebsites.net/api/chart/all_symbolsUserAuth`,
    headers: {
      Authorization:
        "Bearer " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw",
    },
  })
    .then(async (res) => {
      console.log("all_symbols : ", res.data);
      localStorage.setItem("all_symbols", JSON.stringify(res.data));
      all_symbol =res.data
    })
    .catch((e) => console.log("axios error"));
  
}


  async function getStockSymbols(){
    stockSymbols = await axios.get(
      "https://charthistoricaldriver.azurewebsites.net/api/chart/stockSymbols",
      {
        headers: {
          Authorization:
            "Bearer " +
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhbGdvcHRpIiwiaWF0IjoxNTkwNzgyNDM5OTEzfQ.YsPXnY77jYqeo7XF6zCnQ0gKndiTeb5WSioQLtqynhw",
        },
      }
    );
    console.log("stockSymbols :", stockSymbols.data)
  }
  getStockSymbols()
  getAllSymbolsFromChartHistorical()
let token = localStorage.getItem("jwtToken");
axios({
  method: "get",
  url: "https://fxmajors.azurewebsites.net/api/get_FX_Majors", //'http://localhost:7099/api/get_FX_Majors',
  headers: { Authorization: token },
})
  .then(async (res) => {
    let ids = new Set(res.data.map(({ symbol }) => symbol));
    let selectedRows = Forex.filter(({ symbol }) => !ids.has(symbol));
    Forex = selectedRows;
    localStorage.setItem("FX_Majors", JSON.stringify(res.data));
  })
  .catch((e) => console.log("axios error"));

export default {
  /**  --------- ON Ready Callback  -----*/
  //#region ON Ready Callback
  onReady: (cb) => {
    console.log("=====onReady running");
    setTimeout(() => cb(config), 0);
    csv_symbols.getSymbolsFromBackend();
    stocksplitsObject.getAllstockSplits();
  },
  //#endregion ON Ready Callback

  /**------- Search for symbol as user types in---- */
  //#region Search for symbol - drop down per user keystroke
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    let allsymbolsfromHistoricalDriver = localStorage.getItem("all_symbols")
    // const Exchange = allsymbolsfromHistoricalDriver.filter((exchangeresult) =>
    //   exchangeresult.exchange.includes(exchange)
    // );

    // console.log("exchange ::::",exchange, Exchange,"Classificationsymbol : ",Classificationsymbol)
    // console.log("userInput : ",userInput, "symbolType : ", symbolType)
    if (symbolType == undefined || symbolType == "" || symbolType.length == 0) {
      let a = JSON.parse(allsymbolsfromHistoricalDriver)
      const allSymbols = a.filter((symbol) =>
      symbol.symbol.includes(userInput.toUpperCase())
    );
      onResultReadyCallback(allSymbols);
    }
    if (symbolType == "index") {
      // let indexsymbol = window.parent.INDX.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
      let indexsymbol = INDX.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(indexsymbol);
    }
    if (symbolType == "stock") {
      let Classificationsymbol = stockSymbols.data.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(Classificationsymbol);
    }
    if (symbolType == "weeklies") {
      let filter = window.parent.weeklies.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(filter);
    }
    if (symbolType == "forex") {
      // let forex = window.parent.Forex.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
      let forex = Forex.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(forex);
    }
    if (symbolType == "crypto") {
      let crypto = allCrypto.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(crypto);
    }
    if (symbolType == "topcryptos") {
      let Tcryptos = TopCryptos.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(Tcryptos);
    }
    if (symbolType == "Fx_majors") {
      let fxmajor = fx_majors.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(fxmajor);
    }
    if (symbolType == "futures") {
      let comm = Comm.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(comm);
    }

    //  if(!exchange || exchange.length == 0 || exchange == ""){
    //   onResultReadyCallback(Exchange);
    // }
    if (exchange == "NYSE MKT") {
      // const NYSEMKTUserFilter = window.parent.NYSEMKT.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
      const NYSEMKTUserFilter = NYSEMKT.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(NYSEMKTUserFilter);
    }
    if (exchange == "National Stock Exchange") {
      // const NationalStockExchange = window.parent.NationalStockExchange.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
      const NationalStockExchangeuserFilter = NationalStockExchange.filter(
        (symbol) => symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(NationalStockExchangeuserFilter);
    }
    if (exchange == "NYSE") {
      const NYSEUserFilter = NYSE.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(NYSEUserFilter);
    }
    if (exchange == "NYSE Arca") {
      const NYSEArcauserFilter = NYSEArca.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(NYSEArcauserFilter);
    }
    if (exchange == "NASDAQ OMX") {
      // const NASDAQOMX = window.parent.NASDAQOMX.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
      const NASDAQOMXuserFilter = NASDAQOMX.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(NASDAQOMXuserFilter);
    }
    if (exchange == "BATS") {
      const BATSuserFilter = BATS.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(BATSuserFilter);
    }
    if (exchange == "BitTrex") {
      passedCryproCompareExchange = exchange;
      // const BitTrexExchnage = CryptoComareEx.filter(exchangeresult => exchangeresult.exchange.includes(exchange))
      const BitTrex = Bittrex.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(BitTrex);
    }
    // if(exchange == "Bitfinex"){
    //   passedCryproCompareExchange = exchange;
    //   const BitfinexFil = Bitfinex.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
    //   onResultReadyCallback(BitfinexFil);
    // }
    if (exchange == "Coinbase") {
      passedCryproCompareExchange = exchange;
      const CoinbaseExchnage = CryptoComareEx.filter((exchangeresult) =>
        exchangeresult.exchange.includes(exchange)
      );
      const Coinbase = CoinbaseExchnage.filter((symbol) =>
        symbol.symbol.includes(userInput.toUpperCase())
      );
      onResultReadyCallback(Coinbase);
    }

    //  if(exchange == "FOREX"){
    //   const ForexExchange = Forex.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(ForexExchange);
    // }
    //  if(exchange == "INDEX"){
    //   let indexexchange = INDX.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()))
    //   onResultReadyCallback(indexexchange);
    // }
    //  if(exchange == "crypto"){
    //   const CryptoExchange = TopCryptos.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(CryptoExchange);
    // }
    //  if(exchange == "Strong Cryptos"){
    //   let StrongcryptosExchange = TopCryptos.filter(exchangeresult => exchangeresult.exchange.includes(exchange));
    //   let Strongcryptos = StrongcryptosExchange.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(Strongcryptos);
    // }
    //  if(exchange == "Really Strong Cryptos"){
    //   let ReallyStrongcryptosExchange = TopCryptos.filter(exchangeresult => exchangeresult.exchange.includes(exchange));
    //   let ReallyStrongcryptos = ReallyStrongcryptosExchange.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(ReallyStrongcryptos);
    // }
    //  if(exchange == "Solid Cryptos"){
    //   let SolidcryptosExchange = TopCryptos.filter(exchangeresult => exchangeresult.exchange.includes(exchange));
    //   let SolidCryptos = SolidcryptosExchange.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(SolidCryptos);
    // }
    //  if(exchange == "Ultra Cryptos"){
    //   let UltrscryptosExchange = TopCryptos.filter(exchangeresult => exchangeresult.exchange.includes(exchange));
    //   let UltraCryptos = UltrscryptosExchange.filter(symbol => symbol.symbol.includes(userInput.toUpperCase()));
    //   onResultReadyCallback(UltraCryptos);
    // }

    //************************  For Exchange ************************ */
  
  
  },
  //#endregion Search for symbol - drop down per user keystroke

  /**------ resolve the symbol as user clicked the desired symbol---- */
  //#region Resolve the symbol as user clicks the desired symbol
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    var split_data = symbolName.split(/[:/]/);

    // var symbol_stub = {
    //   name: symbolName,
    //   //   description: split_data[1],
    //   type: "stock",
    //   session: "0900-1630",
    //   timezone: "America/New_York",
    //   ticker: symbolName,
    //   exchange: " ",
    //   minmov: 0.1,//0.1->0.001, 0.001-> 0.00001 multiplying by the price scale
    //   pricescale: 100,
    //   has_intraday: true,
    //   intraday_multipliers: ["1", "60"],
    //   supported_resolution: supportedResolutions,
    //   volume_precision: 8,
    //   data_status: 'streaming',
    //   listed_exchange:" ",
    // };
    console.log("resolve symbol name : ", symbolName);
    // if(symbolName.includes('/')){
    //   symbolName = symbolName.replace('/','-')
    // }
    var symbol_stub = {
      name: symbolName,
      //   description: split_data[1],
      type: "stock",
      session: "0900-1630",
      timezone: "America/New_York",
      ticker: symbolName,
      exchange: " ", //split_data[0],
      minmov: 1, //0.1->0.001, 0.001-> 0.00001 multiplying by the price scale
      pricescale: 100, //10000,
      has_intraday: true,
      intraday_multipliers: ["1", "60"],
      supported_resolution: supportedResolutions,
      volume_precision: 8,
      data_status: "streaming",
      listed_exchange: " ",
      cryptoComapreEx: passedCryproCompareExchange,
    };
    const AllSymbols = JSON.parse(localStorage.getItem("all_symbols"));
    for (let i = 0; i <= allCrypto.length - 1; i++) {
      AllSymbols.push(allCrypto[i]);
    }
    for (let i = 0; i <= AllSymbols.length - 1; i++) {
      if (
        symbolName == AllSymbols[i].symbol ||
        symbolName.replace("/", "-") == AllSymbols[i].symbol
      ) {
        if (AllSymbols[i].exchange == "FOREX") {
          symbol_stub.type = "Forex";
          symbol_stub.minmov = 1;
          symbol_stub.pricescale = 10000; //if jpy then pricescale is 100
          if (symbolName.includes("JPY")) {
            symbol_stub.pricescale = 100;
          }
          // console.log(symbol_stub.type)
        }
        if (AllSymbols[i].exchange == "COMM") {
          symbol_stub.type = "Future";
          console.log("COMM :", symbol_stub);
        }
        if (
          AllSymbols[i].exchange == "crypto" ||
          AllSymbols[i].exchange == "CC"
        ) {
          symbol_stub.type = "crypto";
          symbol_stub.session = "24x7";
          symbol_stub.minmov = 1;
          symbol_stub.pricescale = 100000000;
          symbol_stub.exchange = AllSymbols[i].exchange;
          console.log("Crypto Symbol Stub :", symbol_stub);

          // console.log(symbol_stub.type )
        }
      }
    }

    if (passedCryproCompareExchange == "Coinbase") {
      symbol_stub.type = "crypto";
      symbol_stub.session = "24x7";
      symbol_stub.minmov = 1;
      symbol_stub.pricescale = 10000;
      symbol_stub.exchange = symbol_stub.cryptoComapreEx;
      console.log("CoinBase Symbol Stub :", symbol_stub);
      // console.log(symbol_stub.type )
    }
    if (passedCryproCompareExchange == "BitTrex") {
      symbol_stub.type = "crypto";
      symbol_stub.session = "24x7";
      symbol_stub.minmov = 1;
      symbol_stub.pricescale = 10000;
      symbol_stub.exchange = symbol_stub.cryptoComapreEx;
      console.log("BitTrex Symbol Stub :", symbol_stub);

      // console.log(symbol_stub.type )
    }

    // store.dispatch(set_symbol(symbolName))

    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
      console.log("========= resolveSymbol ===============", symbol_stub.name);
      //window.parent.symbolName = symbol_stub.name;
      //console.log("Resolve symbol running.... -> Symbolname" , window.parent.symbolName)
      // stocksplitsObject.stockSplits(symbol_stub.name);
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },
  //#endregion Resolve the symbol as user clicks the desired symbol

  /**--- will get the bars for the chart after resolving symbol */
  //#region Get the bars for the chart after resolving symbol

  getBars: function (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) {
    // store.dispatch(set_symbol(symbolInfo.name));
    stocksplitsObject.stockSplits(symbolInfo.ticker);
    historyProvider
      .getBars(symbolInfo, resolution, from, to, firstDataRequest)
      .then((bars) => {
        // console.log("JS Api Bars ::::", bars)
        if (symbolInfo.ticker.includes("*")) {
          restrictSplitDateCalloutsPlotting = bars[0].time.getTime();
          // console.log("restrictSplitDateCalloutsPlotting ;", restrictSplitDateCalloutsPlotting, "symbolInfo.ticker :", symbolInfo.ticker)
        } else {
          restrictSplitDateCalloutsPlotting = bars[0].time;
          // console.log("restrictSplitDateCalloutsPlotting ;", restrictSplitDateCalloutsPlotting, "symbolInfo.ticker :", symbolInfo.ticker)
        }

        if (bars.length) {
          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch((err) => {
        console.log("err.response.data :", err);
        if (err.response) {
          if (typeof err.response.data == "string") alert(err.response.data);
          if (err.response.data.tradier_token)
            return onErrorCallback(err.response.data.tradier_token);
          else return onErrorCallback(err.response.data);
        } else {
          alert(err.message);
          if (err.message) return onErrorCallback(err.message);
        }
      });
  },
  //#endregion Get the bars for the chart after resolving symbol

  //#region SubscribeBars
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    console.log("=====subscribeBars runnning", symbolInfo);
    if (
      (symbolInfo.exchange == " " && symbolInfo.type == "stock") ||
      (symbolInfo.exchange == "" && symbolInfo.type == "stock")
    )
    {
      stream.subscribeBars(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      );
    }
   
    symbolinfoUnsybscribe = symbolInfo;
    if (
      (symbolInfo.exchange != " " && symbolInfo.type != "stock") ||
      (symbolInfo.exchange != "" && symbolInfo.type != "stock")
    )
    {
      cryptoStream.subscribeBars(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      );
    }
 

    let data = window.onDataResetCach(onResetCacheNeededCallback);
    window.storeResetCacheCallBackFunction = data;
  },
  //#endregion SubscribeBars

  //#region UnsubscribeBars
  unsubscribeBars: (subscriberUID) => {
    debugger;
    console.log("=====unsubscribeBars running");
    if (
      (symbolinfoUnsybscribe.exchange != " " &&
        symbolinfoUnsybscribe.type != "stock") ||
      (symbolinfoUnsybscribe.exchange != "" &&
        symbolinfoUnsybscribe.type != "stock")
    ){
      cryptoStream.unsubscribeBars(subscriberUID);

    }
    else{
    stream.unsubscribeBars(subscriberUID);

    }
  },
  //#endregion UnsubscribeBars

  //#region CalculateHistoryDepth
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    // console.log("=====calculateHistoryDepth running");
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    // return resolution < 60
    //   ? { resolutionBack: "D", intervalBack: "1" }
    //   : undefined;

    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : { resolutionBack: "M", intervalBack: "200" }; //130//{resolutionBack: "M", intervalBack : "120"}->966 -> 1093-3Years--------370//132
  },
  //#endregion CalculateHistoryDepth

  //#region GetMarks
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    let print = window.onData(onDataCallback);
    window.storeCallBackFunction = print;
    var Arr = [
      {
        id: 1,
        time: 1551448800000 / 1000,
        color: "blue",
        text: "Hello world",
        label: "B",
        labelFontColor: "yellow",
        minSize: 20,
      },
    ];
    // onDataCallback(Arr);

    //return;
    console.log("=====getMarks running");
  },
  //#endregion GetMarks

  //#region GetTimeScaleMarks
  getTimescaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    let print = window.onData(onDataCallback);
    window.storeCallBackTimeScaleFunction = print;

    setTimeout(() => {
      for (let i = 0; i <= window.parent.stocksplitsVal.length - 1; i++) {
        // console.log((new Date(window.parent.stocksplitsVal[i].splitDate)).getTime(), window.parent.stocksplitsVal);
        // console.log(new Date((new Date(
        //   new Date(window.parent.stocksplitsVal[i].splitDate).toLocaleString("en-US", {
        //     timeZone: "America/New_York",
        //   })).setHours(40))
        // ))
        // var d = new Date(window.parent.stocksplitsVal[i].splitDate);
        var d = new Date(
          new Date(
            new Date(window.parent.stocksplitsVal[i].splitDate).toLocaleString(
              "en-US",
              {
                timeZone: "America/New_York",
              }
            )
          ).setHours(40)
        );
        // console.log((d.getMonth() + 1 )+"/" +d.getDate()+"/" +  d.getFullYear(),d.getTime())
        var dayName = d.toString().split(" ")[0];
        // window.parent.DayName = dayName;
        // console.log("d.getTime(): ",d.getTime(), "restrictSplitDateCalloutsPlotting : ", restrictSplitDateCalloutsPlotting);

        if (d.getTime() >= restrictSplitDateCalloutsPlotting) {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
          onDataCallback([
            {
              id: i + "",
              time:
                new Date(window.parent.stocksplitsVal[i].splitDate).getTime() /
                1000,
              color: "Turquoise",
              label: "SP",
              minSize: 20,
              tooltip: [
                "Stock Split",
                "Date: " +
                  (d.getMonth() +
                    1 +
                    "/" +
                    d.getDate() +
                    "/" +
                    d.getFullYear()),
                "Divisor: " + window.parent.stocksplitsVal[i].divisor,
              ],
            },
          ]);
        }
      }
    }, 1000);

    console.log("=====getTimeScaleMarks running");
  },
  //#endregion GetTimeScaleMarks

  //#region GetServerTime
  getServerTime: (cb) => {
    console.log("=====getServerTime running");
  },
  //#endregion GetServerTime
};
