var dateFormat = require('dateformat');
/**
 * @function AlgoPPOY -> contains the core logic for "AlgoPPOY" indicator.
 * @function EMA -> Ema indicator logic
 * @class AlgoPPOY
 * @PineJS Is defined Globally in TVContainer/index.jsx on line 72 -> window.PineJSExport = (PineJS) => {return PineJS} ... This is what allows us access to it.
 */
export default class AlgoPPOY {
    EMA(input, context, Period,PineJS) {
        var cons = context.new_var(input);
        var a = PineJS.Std.ema(cons, Period, context);        
        return a;
    }

    AlgoPPOY(context,CurrentBar,fast,slow,smooth,sigBar,PineJS) {

        var val = 100 * ((this.EMA(context.symbol.close, context, fast,PineJS) - this.EMA(context.symbol.close, context, slow,PineJS)) / this.EMA(context.symbol.close, context, slow,PineJS));

            context.Data[0].Default = (val);
            var Value = (val); //first value  this._context.Data[0].Default
            context.Data[0].Smoothed = (this.EMA(Value, context, smooth,PineJS));
            
            var PlotValue1Up = null;
            var PlotValue2Dn = null;
            
            if ((context.Data[0].Default > context.Data[1].Default) && context.Data[0].Default <= context.Data[0].Smoothed) {

                PlotValue1Up = context.Data[0].Default;
                context.Data[0].eSignal = (1);
                context.Data[0].PPO_Esignal = (1);
                sigBar = CurrentBar;                                                
            }            
            else if ((context.Data[0].Default < context.Data[1].Default) && context.Data[0].Default >= context.Data[0].Smoothed) {
                PlotValue2Dn = context.Data[0].Default;
                context.Data[0].eSignal = (-1);
                context.Data[0].PPO_Esignal = (-1);
                sigBar = CurrentBar;
            }
            else {
                context.Data[0].eSignal = (0);
                context.Data[0].PPO_Esignal = (0);
            }
            var barsSinceSignal = CurrentBar - sigBar;
            context.Data[0].barsPastSignal = (barsSinceSignal);
            return [context.Data[0].Default, PlotValue1Up, PlotValue2Dn, context.Data[0].Smoothed];
    }
}