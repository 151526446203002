import IndicatorTemplate from '../endpoints/indicatortemplateapi';
let indTempObj = new IndicatorTemplate();
/**
 * Getting all the study/indicator templates to load them on the chart by hitting the api method "getallIndicatorTemplates"
 * @param {*} indicator 
 * @function loadindicatorList
 */
export default async function loadindicatorList(indicator){

//#region Calling the "getallIndicatorTemplate" method to "GET" the template
    indicator = await indTempObj.getallIndicatorTemplates();
//#endregion Calling the "getallIndicatorTemplate" method to "GET" the template

    // console.log("Respnse: ", indicator)
  
//#region Returing the response
    return indicator;
//#endregion Returing the response

  }